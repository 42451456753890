import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import CustomRadioButton from "./CustomRadioButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Page({
  profileName,
  changeStatus,
  listOfProducts,
  listOfProductsData,
  addItemToListOfProducts,
  searchIndexListOfProducts,
  searchItemInListOfProducts,
  deleteItemInListOfProducts,
  setDescriptionToItemInListOfProducts,
  handleSendProfileData,
  selectedBlockLayout,
  setItemToListOfProducts,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [blockDesign, setBlockDesign] = useState("first");

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
    }
  }, [profileInfoStore]);

  // const data = [
  //   {
  //     name: "Product name 1",
  //     id: "asdasd1",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://images.satu.kz/164354116_w640_h640_nastennye-chasy-diametr.jpg",
  //       "/images/products/product1.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Product name 2",
  //     id: "asdasd2",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://object.pscloud.io/cms/cms/Uploads/qwer_1924.jpg",
  //       "/images/products/product2.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Product name 3",
  //     id: "asdasd3",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://object.pscloud.io/cms/cms/Photo/img_0_112_101_0.jpg",
  //       "/images/products/product3.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Product name 4",
  //     id: "asdasd4",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://allgifts.kz/assets/images/products/40666/e24060b992a88e047752698421b1f9e345beed29.jpg",
  //       "/images/products/product4.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Product name 5",
  //     id: "asdasd5",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://images.satu.kz/61912855_hronotron-strelochnye-chasy.jpg",
  //       "/images/products/product5.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Product name 6",
  //     id: "asdasd6",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
  //       "/images/products/product6.jpg",
  //     buttonName: "Call to action",
  //   },
  //   {
  //     name: "Бизнес экспедиция в Грузию",
  //     id: "asdasd7",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
  //       "/images/products/product7.jpg",
  //     buttonName: "Принять участие",
  //   },
  //   {
  //     name: "Как увеличить капитализацию компании",
  //     id: "asdasd8",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
  //       "/images/products/product8.jpg",
  //     buttonName: "Принять участие",
  //   },
  //   {
  //     name: "Как привлечь инвестиции",
  //     id: "asdasd9",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
  //       "/images/products/product9.jpg",
  //     buttonName: "Принять участие",
  //   },
  //   {
  //     name: "Масштабирование Бизнеса Через Инвестиции",
  //     id: "asdasd10",
  //     type: "Digital product",
  //     price: "$29",
  //     image:
  //       // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
  //       "/images/products/product10.jpg",
  //     buttonName: "Принять участие",
  //   },
  // ];

  //   useEffect(() => {
  //     console.log("profileName", profileName);
  //   }, [profileName]);

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    // "Side slider", "Big block"
    if (selectedBlockLayout === "Big block" && listOfProducts.length === 0) {
      setErrorCreate(t("create_block_product_error1"));
    } else if (
      selectedBlockLayout === "Side slider" &&
      listOfProducts.length < 2
    ) {
      setErrorCreate(t("create_block_product_error2"));
    } else {
      setErrorCreate("");
    }
  }, [listOfProducts, selectedBlockLayout]);

  return (
    <div>
      {/* <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen"> */}
      <div className="font-manrope px-[24px] pb-[80px] w-screen max-w-md">
        <BurgerMenu
          isOpen={burgerMenuOpen}
          profileName={profileName}
          toggleBurgerMenu={toggleBurgerMenu}
        />
        <div className="flex flex-col">
          <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
            <div className="h-[56px] flex justify-between items-center">
              <button
                onClick={() => {
                  changeStatus("Structure");
                }}
              >
                {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </button>
              <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                {t("edit_block")}
              </div>
              <button onClick={toggleBurgerMenu}>
                {!burgerMenuOpen ? (
                  <Icon
                    icon="iconamoon:menu-burger-horizontal-light"
                    className="w-[24px] h-[26px]"
                  />
                ) : (
                  <span> </span>
                )}
              </button>
            </div>
          </div>

          <div className="mt-[61px]">
            <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
              <div
                className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeStatus("Structure");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("structure")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                onClick={() => {
                  changeStatus("Content");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("content")}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[25px]">
            <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
              {t("list_of_products")}
            </div>
          </div>

          {listOfProducts.length > 0 && (
            <div className="mt-[15px]">
              {listOfProducts.map((elem: any, index: number) => (
                <div key={index}>
                  <div className="text-[16px] leading-[19.12px] text-[#161616]">
                    {elem.name}
                  </div>
                  <div className="mt-[10px]">
                    <input
                      type="text"
                      value={elem.description ? elem.description : ""}
                      maxLength={100}
                      placeholder={t("short_description_optional")}
                      className="w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] px-[15px]"
                      onChange={(e) => {
                        setDescriptionToItemInListOfProducts(
                          elem.id,
                          e.target.value
                        );
                      }}
                    />
                    <div className="mt-[5px] flex justify-end">
                      <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
                        {elem.description ? (
                          <span>{elem.description.length}/100</span>
                        ) : (
                          <span>0/100</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="mt-[29px]">
            <div
              className="w-full h-[50px] rounded-[10px] border-[1px] border-[#161616] text-[18px] text-[#161616] flex justify-center items-center"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t("select_product")}
            </div>
          </div>

          {errorCreate !== "" && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
              {errorCreate}
            </div>
          )}

          {listOfProducts.length === 0 ? (
            <div className="mt-[192px]">
              <div
                className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
                onClick={(event) => {
                  event.preventDefault();
                  // handleSendProfileData("Product");
                }}
              >
                <div className="text-white text-[18px] leading-[22px] font-semibold">
                  {t("publish")}
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[50px]">
              <div
                className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
                onClick={(event) => {
                  if (errorCreate === "") {
                    event.preventDefault();
                    handleSendProfileData("Product");
                  }
                }}
              >
                <div className="text-white text-[18px] leading-[22px] font-semibold">
                  {t("publish")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ============================================== BOTTOM WINDOW ============================================== */}
      <div
        className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
      >
        {/* Задний план с затемнением */}
        <div
          className="fixed inset-0 bg-gray-500 opacity-75"
          //   onClick={closeWindow}
        ></div>

        {/* Основное окно */}
        <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px]">
          <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
            <div className="flex justify-center mt-[10px]">
              <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
            </div>
            <div className="flex justify-between items-center mt-[23px] px-[24px]">
              <div className="text-white text-[1px] invisible">
                hiiiiiiadfadgasfgafafasfgsdfhsghdfghdfghdfghdfghdfghdfghdfghdfghasdfasfgs
              </div>
              <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
                {t("select_products")}
              </div>
              <button
                className="text-[16px] leading-[18px] text-[#8391A1]"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t("done")}
              </button>
            </div>
            <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
          </div>

          <div className="px-[24px] pb-[40px] mt-[80px]">
            <div className="flex flex-col">
              <div className="mt-[5px]">
                {listOfProductsData.map((elem: any) => (
                  <div
                    // className="mt-[10px] w-full border-[1px] border-[#E8ECF4] rounded-[10px] p-[10px]"
                    className={`mt-[10px] w-full ${blockDesign === "third" ? "rounded-[7px] pr-[10px]" : "rounded-[10px] p-[10px]"} shadow-custom`}
                    key={elem.id}
                    onClick={() => {
                      if (selectedBlockLayout !== "Big block") {
                        if (!searchItemInListOfProducts(elem.id)) {
                          addItemToListOfProducts(elem);
                        } else {
                          deleteItemInListOfProducts(elem.id);
                        }
                      } else {
                        setItemToListOfProducts(elem);
                      }
                    }}
                  >
                    <div className="flex justify-between items-center">
                      {blockDesign === "first" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[100px] h-[100px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[100px] h-[100px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {blockDesign === "second" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[100px] h-[100px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[100px] h-[100px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {blockDesign === "third" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[127px] h-[127px] flex-shrink-0 bg-[#F7F8F9] rounded-l-[7px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[127px] h-[127px] bg-[#F7F8F9] object-center object-cover rounded-l-[7px]"
                            />
                          )}
                          <div className="pr-[10px] py-[10px]">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <Radio
                        name={elem.id}
                        containerProps={{
                          className: "",
                        }}
                        checked={searchItemInListOfProducts(elem.id)}
                        crossOrigin={""}
                        onChange={() => {
                          if (selectedBlockLayout !== "Big block") {
                            if (!searchItemInListOfProducts(elem.id)) {
                              addItemToListOfProducts(elem);
                            } else {
                              deleteItemInListOfProducts(elem.id);
                            }
                          } else {
                            setItemToListOfProducts(elem);
                          }
                        }}
                      /> */}

                      <CustomRadioButton
                        checked={searchItemInListOfProducts(elem.id)}
                        index={searchIndexListOfProducts(elem.id)}
                        onChange={() => {
                          if (selectedBlockLayout !== "Big block") {
                            if (!searchItemInListOfProducts(elem.id)) {
                              addItemToListOfProducts(elem);
                            } else {
                              deleteItemInListOfProducts(elem.id);
                            }
                          } else {
                            setItemToListOfProducts(elem);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* WINDOW END */}
          </div>
        </div>
      </div>
    </div>
  );
}
