export default function Component({ src, onRemove }: any) {
  return (
    <div className="relative mb-[10px]">
      <img src={src} alt="Uploaded" className="w-full h-auto" />
      <button
        onClick={onRemove}
        className="absolute top-1 right-1 bg-[#161616] text-white px-[5px] rounded-full"
      >
        ✕
      </button>
    </div>
  );
}
