import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useTranslation } from "react-i18next";

// import "preline/preline";
// import { IStaticMethods } from "preline/preline";

export async function verifyCodeFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  // actions.constructorAction(
  //   constants.verifyRegistrationCode,
  //   dispatch,
  //   `api/auth/code_verify/`,
  //   "post",
  //   "",
  //   form,
  //   false
  // )();

  try {
    dispatch({ type: constants.verifyRegistrationCode.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.post(
      `${constants.API}api/auth/code_verify/`,
      // @ts-ignore
      // config,
      form
      // { data: [...form] },
    );
    if (response.data) {
      dispatch({
        type: constants.verifyRegistrationCode.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.verifyRegistrationCode.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.verifyRegistrationCode.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.verifyRegistrationCode.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function VerifyCodeEmailPage() {
  const { t, i18n } = useTranslation();

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [error, setError] = useState(false);

  const handleChange = (e: any, index: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Если значение существует и длина не превышает 1 символа, переходим к следующему полю ввода
      if (value && value.length === 1 && index < inputRefs.length - 1) {
        // @ts-ignore
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (e: any, index: any) => {
    // Если была нажата клавиша Backspace и текущее поле ввода пустое, переходим к предыдущему полю ввода
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      // @ts-ignore
      inputRefs[index - 1].current.focus();
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyRegistrationCodeStore = useSelector(
    (state: any) => state.verifyRegistrationCodeStore
  );

  useEffect(() => {
    console.log("verifyRegistrationCodeStore", verifyRegistrationCodeStore);
    if (verifyRegistrationCodeStore && verifyRegistrationCodeStore.data) {
      navigate("/auth/registration/completed");
    }
    if (
      verifyRegistrationCodeStore &&
      (verifyRegistrationCodeStore.fail || verifyRegistrationCodeStore.error)
    ) {
      setError(true);
    }
  }, [verifyRegistrationCodeStore]);

  const params = useParams();

  const handleClickVerifyCode = () => {
    verifyCodeFunc(dispatch, "", {
      token: params.token,
      code: "0000",
    });
  };

  const handleKeyPress = (e: any) => {
    // Разрешаем только цифры (ключи 0-9)
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  if (verifyRegistrationCodeStore && verifyRegistrationCodeStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={verifyRegistrationCodeStore.load} />
      </div>
    );
  }

  return (
    <div className="flex justify-center break-words">
      {/* <div className="font-manrope px-[24px] pb-[50px] bg-[#F5F5F5] w-screen max-w-md min-h-screen"> */}
      <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md">
        <div className="flex flex-col">
          <div className="mt-[104px]">
            <div className="text-[26px] text-[#161616] leading-[30px] font-bold flex justify-center">
              {t("email_verify_code_text1")}
            </div>
          </div>

          <div className="flex justify-center">
            <div className="mt-[30px] text-[#8391A1] leading-[18px] text-[16px] text-center">
              {t("email_verify_code_text2")}{" "}
              <p className="text-[#161616] text-center">example@email.com</p>
            </div>
          </div>

          <div className="pl-[24px] mt-[65px] flex justify-center">
            {inputRefs.map((inputRef, index) => (
              <input
                key={index}
                ref={inputRef}
                type="text"
                maxLength={1}
                onChange={(event: any) => {
                  handleChange(event, index);
                }}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onKeyPress={handleKeyPress}
                className="bg-[#E8ECF4] rounded border-[1px] text-center w-[70px] h-[70px] mr-[21px] text-[30px] font-semibold leading-[36px] text-[#161616]"
              />
            ))}
          </div>

          <div className="mt-[40px] flex justify-center text-[16px] leading-[18px] text-[#161616]">
            {t("email_verify_code_text3")}
          </div>
          <div className="mt-[15px] flex justify-center font-semibold text-[16px] leading-[18px]">
            <span className="underline">{t("email_verify_code_text4")}</span>
          </div>

          {error && (
            <div className="mt-[20px] flex justify-center">
              <div className="text-red-500 text-[18px]">
                {t("email_verify_code_text5")}
              </div>
            </div>
          )}

          <div className="mt-[186px] flex justify-center">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
              onClick={handleClickVerifyCode}
            >
              {t("continue")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
