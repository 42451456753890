import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as utils from "../../utils";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import CustomInput from "../CustomInput";
import NumberInput from "../NumberInput";
import { useTranslation } from "react-i18next";

export default function Component({
  burgerMenuOpen,
  toggleBurgerMenu,
  selectedProductType,
  digitalProductContent,
  handleChangeDigitalProductContent,
  handleChangeDigitalProductList,
  handleFileVersionChange,
  handleButtonClickFileVersionUrl,
  status,
  handleChangeStatus,
  handleChangeStage,
  handleSendProductData,
}: any) {
  const { t, i18n } = useTranslation();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  return (
    <div>
      {status === "content" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <span className="text-start text-[18px] text-[#161616] leading-[20px] font-semibold">
                {t("select_digital_product_type")}
              </span>
            </div>

            <div className="mt-[15px]">
              <div className="grid grid-cols-2 gap-2.5">
                <div
                  className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                  onClick={() => {
                    // handleChangeDigitalProductContent("type", "file");
                  }}
                >
                  <div className="pl-[10px] flex justify-between items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/create_product/digital_product/file.svg"
                        alt="file.svg"
                        className="w-[36px] h-[36px] opacity-30"
                      />
                    </div>
                    <div className="pl-[10px]">
                      <div className="text-[14px] leading-[27px] font-medium text-[#E8ECF4]">
                        {t("file")}
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="digital_product_type"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    className="opacity-30"
                    disabled
                    // checked={digitalProductContent.type === "file"}
                    // onChange={(e) => {
                    //   handleChangeDigitalProductContent("type", "file");
                    // }}
                  />
                </div>

                <div
                  className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                  onClick={() => {
                    handleChangeDigitalProductContent("type", "url");
                  }}
                >
                  <div className="pl-[10px] flex justify-between items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/create_product/digital_product/url.svg"
                        alt="url.svg"
                        className="w-[36px] h-[36px]"
                      />
                    </div>
                    <div className="pl-[10px]">
                      <div className="text-[14px] leading-[27px] font-medium text-[#161616]">
                        {t("url")}
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="digital_product_type"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={digitalProductContent.type === "url"}
                    onChange={(e) => {
                      handleChangeDigitalProductContent("type", "url");
                    }}
                  />
                </div>

                <div
                  className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                  onClick={() => {
                    //   setSelectedBlockType("Links/URL");
                    //   setSelectedBlockLayout("Tile");
                  }}
                >
                  <div className="pl-[10px] flex justify-between items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/create_product/digital_product/ticket.svg"
                        alt="ticket.svg"
                        className="w-[36px] h-[36px] opacity-30"
                      />
                    </div>
                    <div className="pl-[10px]">
                      <div className="text-[14px] leading-[27px] font-medium text-[#E8ECF4]">
                        {t("ticket")}
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="digital_product_type"
                    containerProps={{
                      className: "",
                    }}
                    className="opacity-30"
                    disabled
                    crossOrigin={""}
                    //   checked={selectedBlockType === "Links/URL"}
                    onChange={(e) => {
                      // setSelectedBlockType("Links/URL");
                      // setSelectedBlockLayout("Tile");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <div className="text-start text-[18px] text-[#161616] leading-[20px]">
                <span className="font-semibold">{t("versions")}</span>{" "}
                <span>ⓘ</span>
              </div>
            </div>

            {digitalProductContent.type === "file" &&
              digitalProductContent.fileVersions.map(
                (elem: any, index: number) => (
                  <div className="mt-[15px]" key={`file-version-${index + 1}`}>
                    <div className="shadow-custom rounded-[10px] p-[10px]">
                      <div className="flex justify-between items-center">
                        <div className="text-[16px] leading-[18px] text-[#161616] font-semibold">
                          {t("version")} {index + 1}
                        </div>
                        {index !== 0 && (
                          <button
                            className="w-[18.5px] h-[19.5px]"
                            onClick={() => {
                              handleChangeDigitalProductList(
                                "file",
                                index,
                                "",
                                "",
                                "delete"
                              );
                            }}
                          >
                            <img
                              src="/images/create_product/delete.svg"
                              alt="delete.svg"
                              className="w-full h-full"
                            />
                          </button>
                        )}
                      </div>

                      <div className="pt-[15px]">
                        <CustomInput
                          handleChangeValue={(newVal: string) => {
                            handleChangeDigitalProductList(
                              "file",
                              index,
                              "name",
                              newVal
                            );
                          }}
                          value={digitalProductContent.fileVersions[index].name}
                          placeholder={t("version_name")}
                          rows={1}
                          maxLength={30}
                        />
                      </div>

                      <div className="pt-[10px]">
                        <CustomInput
                          handleChangeValue={(newVal: string) => {
                            handleChangeDigitalProductList(
                              "file",
                              index,
                              "details",
                              newVal
                            );
                          }}
                          value={
                            digitalProductContent.fileVersions[index].details
                          }
                          placeholder={t("version_details")}
                          rows={1}
                          maxLength={100}
                          delimiter={false}
                        />
                      </div>

                      <div className="mt-[5px] flex justify-end items-center">
                        <span className="text-[12px] text-[#8391A1] leading-[14px]">
                          {
                            digitalProductContent.fileVersions[index].details
                              .length
                          }
                          /100
                        </span>
                      </div>

                      <div className="pt-[15px]">
                        <div
                          className="w-full h-[128px] border-[2px] border-[#8391A1] border-dotted bg-[#F7F8F9] rounded-[10px]"
                          onClick={handleButtonClickFileVersionUrl}
                        >
                          <div className="pt-[15px] flex justify-center">
                            <img
                              src="/images/create_block/upload_file.svg"
                              alt="upload_file.svg"
                              className="w-[40px] h-[40px]"
                            />
                            <input
                              id="fileVersionInput"
                              type="file"
                              // accept=".jpg,.jpeg,.png"
                              onChange={(event: any) => {
                                handleFileVersionChange(event, index);
                              }}
                              style={{ display: "none" }}
                            />
                          </div>
                          <div className="pt-[10px] text-[14px] leading-[16px] text-[#8391A1] text-center px-[10px]">
                            <p className="">Upload file up to 25Mb.</p>
                            <p className="">
                              Increased file size and many other exclusive
                              features available with{" "}
                              <span className="text-[#161616] font-medium">
                                Hubbbox Premium
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}

            {digitalProductContent.type === "url" &&
              digitalProductContent.urlVersions.map(
                (elem: any, index: number) => (
                  <div className="mt-[15px]" key={`url-version-${index + 1}`}>
                    <div className="shadow-custom rounded-[10px] p-[10px]">
                      <div className="flex justify-between items-center">
                        <div className="text-[16px] leading-[18px] text-[#161616] font-semibold">
                          {t("version")} {index + 1}
                        </div>
                        {index !== 0 && (
                          <button
                            className="w-[18.5px] h-[19.5px]"
                            onClick={() => {
                              handleChangeDigitalProductList(
                                "url",
                                index,
                                "",
                                "",
                                "delete"
                              );
                            }}
                          >
                            <img
                              src="/images/create_product/delete.svg"
                              alt="delete.svg"
                              className="w-full h-full"
                            />
                          </button>
                        )}
                      </div>

                      <div className="pt-[15px]">
                        <CustomInput
                          handleChangeValue={(newVal: string) => {
                            handleChangeDigitalProductList(
                              "url",
                              index,
                              "name",
                              newVal
                            );
                          }}
                          value={digitalProductContent.urlVersions[index].name}
                          placeholder={t("version_name")}
                          rows={1}
                          maxLength={30}
                        />
                      </div>

                      <div className="pt-[10px]">
                        <CustomInput
                          handleChangeValue={(newVal: string) => {
                            handleChangeDigitalProductList(
                              "url",
                              index,
                              "details",
                              newVal
                            );
                          }}
                          value={
                            digitalProductContent.urlVersions[index].details
                          }
                          placeholder={t("version_details")}
                          rows={1}
                          maxLength={100}
                          delimiter={false}
                        />
                      </div>

                      <div className="mt-[5px] flex justify-end items-center">
                        <span className="text-[12px] text-[#8391A1] leading-[14px]">
                          {
                            digitalProductContent.urlVersions[index].details
                              .length
                          }
                          /100
                        </span>
                      </div>

                      <div className="pt-[5px]">
                        <CustomInput
                          handleChangeValue={(newVal: string) => {
                            handleChangeDigitalProductList(
                              "url",
                              index,
                              "url",
                              newVal
                            );
                          }}
                          value={digitalProductContent.urlVersions[index].url}
                          placeholder={t("url")}
                          rows={1}
                          // maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}

            <div className="mt-[15px]">
              <button
                className="w-full h-[47px] flex justify-center items-center text-[#161616] border-[1px] border-[#161616] rounded-[8px]"
                onClick={() => {
                  handleChangeDigitalProductList(
                    digitalProductContent.type,
                    0,
                    "",
                    "",
                    "add"
                  );
                }}
              >
                {t("create_version")}
              </button>
            </div>

            <div className="mt-[36px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleChangeStatus("price");
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      )}

      {status === "price" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[15px]"></div>

            {digitalProductContent.type === "file" &&
              digitalProductContent.fileVersions.map(
                (elem: any, index: number) => (
                  <div
                    className="mt-[10px]"
                    key={`file-version-price-${index + 1}`}
                  >
                    {index === 0 ? (
                      <div className="">
                        <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                          {elem.name
                            ? elem.name
                            : `${t("version")} ${index + 1}`}{" "}
                          {t("price")}
                        </span>

                        <div className="mt-[10px] flex space-x-[10px] items-center">
                          <div className="w-full h-[56px]">
                            <NumberInput
                              value={elem.price}
                              placeholder={t("set_price")}
                              changeFunc={(newValue: any) => {
                                handleChangeDigitalProductList(
                                  "file",
                                  index,
                                  "price",
                                  newValue,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="w-[76px] h-[56px] flex-shrink-0">
                            <Menu>
                              <MenuHandler>
                                <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                                  {digitalProductContent.currencySymbol}
                                  <img
                                    src="/images/create_product/arrow_down.svg"
                                    alt="arrow_down"
                                    className="w-[24px] h-[24px] flex-shrink-0"
                                  />
                                </div>
                              </MenuHandler>
                              <MenuList className="w-[228px] rounded-[10px] font-manrope">
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "dollar"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      US {t("dollar")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      $
                                    </div>
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "tenge"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      KZ {t("tenge")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      ₸
                                    </div>
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "ruble"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      RU {t("ruble")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      ₽
                                    </div>
                                  </div>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                          {elem.name
                            ? elem.name
                            : `${t("version")} ${index + 1}`}{" "}
                          {t("price")}
                        </span>

                        <div className="mt-[10px]">
                          <div className="w-full h-[56px]">
                            <NumberInput
                              value={elem.price}
                              placeholder={t("set_price")}
                              changeFunc={(newValue: any) => {
                                handleChangeDigitalProductList(
                                  "file",
                                  index,
                                  "price",
                                  newValue,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}

            {digitalProductContent.type === "url" &&
              digitalProductContent.urlVersions.map(
                (elem: any, index: number) => (
                  <div
                    className="mt-[10px]"
                    key={`file-version-price-${index + 1}`}
                  >
                    {index === 0 ? (
                      <div className="">
                        <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                          {elem.name ? elem.name : `Version ${index + 1}`} (
                          {t("price_product")})
                        </span>

                        <div className="mt-[10px] flex space-x-[10px] items-center">
                          <div className="w-full h-[56px]">
                            <NumberInput
                              value={elem.price}
                              placeholder={t("set_price")}
                              changeFunc={(newValue: any) => {
                                handleChangeDigitalProductList(
                                  "url",
                                  index,
                                  "price",
                                  newValue,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="w-[76px] h-[56px] flex-shrink-0">
                            <Menu>
                              <MenuHandler>
                                <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                                  {digitalProductContent.currencySymbol}
                                  <img
                                    src="/images/create_product/arrow_down.svg"
                                    alt="arrow_down"
                                    className="w-[24px] h-[24px] flex-shrink-0"
                                  />
                                </div>
                              </MenuHandler>
                              <MenuList className="w-[228px] rounded-[10px]">
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "dollar"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      US {t("dollar")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      $
                                    </div>
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "tenge"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      KZ {t("tenge")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      ₸
                                    </div>
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      handleChangeDigitalProductContent(
                                        "currency",
                                        "ruble"
                                      );
                                    }}
                                  >
                                    <div className="text-[16px] leading-[22px] text-[#161616]">
                                      RU {t("ruble")}
                                    </div>
                                    <div className="text-[22px] leading-[24px] text-[#161616]">
                                      ₽
                                    </div>
                                  </div>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                          {elem.name ? elem.name : `Version ${index + 1}`} (
                          {t("price_product")})
                        </span>

                        <div className="mt-[10px]">
                          <div className="w-full h-[56px]">
                            <NumberInput
                              value={elem.price}
                              placeholder={t("set_price")}
                              changeFunc={(newValue: any) => {
                                handleChangeDigitalProductList(
                                  "url",
                                  index,
                                  "price",
                                  newValue,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}

            <div className="mt-[20px]">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                {t("call_to_action")}
              </span>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <Menu>
                  <MenuHandler>
                    <div className="flex justify-between items-center w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                      {getAction(digitalProductContent.action)}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="px-[24px] w-[300px] rounded-[10px] font-manrope">
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeDigitalProductContent(
                            "action",
                            "I want this"
                          );
                        }}
                      >
                        {getAction("I want this")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeDigitalProductContent(
                            "action",
                            "Buy now"
                          );
                        }}
                      >
                        {getAction("Buy now")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeDigitalProductContent(
                            "action",
                            "Take the course"
                          );
                        }}
                      >
                        {getAction("Take the course")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <div className="mt-[215px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleSendProductData();
                }}
              >
                {t("publish")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
