import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

export async function changeSettingsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.changeSettings,
    dispatch,
    `api/profile/settings/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    dispatch({ type: constants.changePasswordSettings.reset });
  }, []);

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      if (profileInfoStore.data.response.language) {
        setLanguage(profileInfoStore.data.response.language);
      } else {
        setLanguage("ru-RU");
      }

      if (profileInfoStore.data.response.email) {
        setEmail(profileInfoStore.data.response.email);
      }
    }
  }, [profileInfoStore]);

  function getLanguage(newVal: string) {
    if (newVal === "ru-RU") {
      return t("russian");
    } else if (newVal === "en-EN") {
      return t("english");
    }
  }

  function handleChangeLanguage(newVal: string) {
    setLanguage(newVal);
    changeSettingsFunc(dispatch, "", { language: newVal });
    //   dispatch({ type: constants.profileInfo.reset });
  }

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeSettingsStore = useSelector(
    (state: any) => state.changeSettingsStore
  );

  useEffect(() => {
    // console.log("changeSettingsStore", changeSettingsStore);
    if (changeSettingsStore && changeSettingsStore.data) {
      utils.profileInfoFunc(dispatch, "", {});
    }
  }, [changeSettingsStore]);

  if (changeSettingsStore && changeSettingsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={changeSettingsStore.load} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/home");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("settings")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <span className="text-[18px] leading-[20px] text-[#161616] font-semibold">
                {t("language")}
              </span>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <Menu>
                  <MenuHandler>
                    <div className="flex justify-between items-center w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                      {getLanguage(language)}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="px-[24px] w-[300px] rounded-[10px] font-manrope">
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeLanguage("en-EN");
                        }}
                      >
                        {t("english")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeLanguage("ru-RU");
                        }}
                      >
                        {t("russian")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <div className="mt-[10px]">
              <span className="text-[18px] leading-[20px] text-[#161616] font-semibold">
                Email
              </span>

              <div className="mt-[10px]">
                <div className="flex justify-start items-center w-full h-[56px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                  {email}
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="h-[50px] rounded-[10px] border-[#161616] text-[#161616] border flex justify-center items-center text-[18px] leading-[22px]">
                {t("change_email_settings")}
              </div>
            </div>

            <div className="mt-[10px]">
              <span className="text-[18px] leading-[20px] text-[#161616] font-semibold">
                {t("password")}
              </span>
            </div>

            <div className="mt-[10px]">
              <div
                className="h-[50px] rounded-[10px] border-[#161616] text-[#161616] border flex justify-center items-center text-[18px] leading-[22px]"
                onClick={() => {
                  navigate("password");
                }}
              >
                {t("change_password_settings")}
              </div>
            </div>

            <div className="mt-[10px]">
              <span className="text-[18px] leading-[20px] text-[#161616] font-semibold">
                {t("information")}
              </span>
            </div>

            <div className="mt-[10px]">
              <button
                className="w-full h-[70px] rounded-[15px] shadow-customblock flex justify-start items-center px-[10px]"
                onClick={() => {
                  window.open(
                    // `https://www.hubbbox.com/term-of-service`,
                    "https://api.hubbbox.com/static/media/Пользовательское%20соглашение.pdf",
                    "_blank"
                  );
                }}
              >
                <div className="flex space-x-[10px] items-center">
                  <div className="w-[50px] h-[50px] bg-[#F7F8F9] rounded-[10px] flex justify-center items-center">
                    <img
                      src="/images/settings/term_of_service.svg"
                      alt="term_of_service"
                      className="w-[36px] h-[36px]"
                    />
                  </div>
                  <div className="text-[#161616] text-start text-[16px] font-bold">
                    {t("term_of_service_settings")}
                  </div>
                </div>
              </button>
            </div>

            <div className="mt-[10px]">
              <button
                className="w-full h-[70px] rounded-[15px] shadow-customblock flex justify-start items-center px-[10px]"
                onClick={() => {
                  window.open(
                    // `https://www.hubbbox.com/privacy-policy`,
                    "https://api.hubbbox.com/static/media/Политика%20конфиденциальности.pdf",
                    "_blank"
                  );
                }}
              >
                <div className="flex space-x-[10px] items-center">
                  <div className="w-[50px] h-[50px] bg-[#F7F8F9] rounded-[10px] flex justify-center items-center">
                    <img
                      src="/images/settings/privacy_policy.svg"
                      alt="privacy_policy"
                      className="w-[36px] h-[36px]"
                    />
                  </div>
                  <div className="text-[#161616] text-start text-[16px] font-bold">
                    {t("privacy_policy_settings")}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      ;
    </utils.AuthGuard>
  );
}
