import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import CustomInput from "../../components/ui/CustomInput";
import NumberInput from "../../components/ui/NumberInput";
import SelectADistributor from "../../components/ui/MakeCollaboration/SelectADistributor";
import BecomeADistributor from "../../components/ui/MakeCollaboration/BecomeADistributor";

export async function createCollaborationFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.createCollaboration,
    dispatch,
    `api/collaboration/create/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("become");
  const [step, setStep] = useState("selection");
  const [listOfAllProducts, setlListOfAllProducts] = useState([]);

  const [becomeDistributorData, setBecomeDistributorData] = useState({
    productLink: "",
    price: "",
    currency: "dollar",
    currencySymbol: "$",
    fee: "",
    terms: "duration",
    duration: "",
    quantity: "",
    comments: "",
  });

  const handleChangeBecomeDistributorData = (key: string, newVal: string) => {
    let oldBecomeDistributorData = structuredClone(becomeDistributorData);

    if (key === "currency") {
      oldBecomeDistributorData.currency = newVal;

      if (newVal === "dollar") {
        oldBecomeDistributorData.currencySymbol = "$";
      }

      if (newVal === "tenge") {
        oldBecomeDistributorData.currencySymbol = "₸";
      }

      if (newVal === "ruble") {
        oldBecomeDistributorData.currencySymbol = "₽";
      }
    } else if (key === "productLink") {
      oldBecomeDistributorData.productLink = newVal;
    } else if (key === "price") {
      oldBecomeDistributorData.price = newVal;
    } else if (key === "fee") {
      oldBecomeDistributorData.fee = newVal;
    } else if (key === "terms") {
      oldBecomeDistributorData.terms = newVal;
      if (newVal === "duration") {
        oldBecomeDistributorData.quantity = "";
      } else if (newVal === "quantity") {
        oldBecomeDistributorData.duration = "";
      }
    } else if (key === "duration") {
      oldBecomeDistributorData.duration = newVal;
    } else if (key === "quantity") {
      oldBecomeDistributorData.quantity = newVal;
    } else if (key === "comments") {
      oldBecomeDistributorData.comments = newVal;
    }

    setBecomeDistributorData(oldBecomeDistributorData);
  };

  const [selectDistributorData, setSelectDistributorData] = useState({
    distributorUsername: "",
    choosenProductId: "",
    price: "",
    currency: "dollar",
    currencySymbol: "$",
    fee: "",
    terms: "duration",
    duration: "",
    quantity: "",
    comments: "",
  });

  const handleChangeSelectDistributorData = (key: string, newVal: string) => {
    let oldSelectDistributorData = structuredClone(selectDistributorData);

    if (key === "currency") {
      oldSelectDistributorData.currency = newVal;

      if (newVal === "dollar") {
        oldSelectDistributorData.currencySymbol = "$";
      }

      if (newVal === "tenge") {
        oldSelectDistributorData.currencySymbol = "₸";
      }

      if (newVal === "ruble") {
        oldSelectDistributorData.currencySymbol = "₽";
      }
    } else if (key === "distributorUsername") {
      oldSelectDistributorData.distributorUsername = newVal;
    } else if (key === "choosenProductId") {
      oldSelectDistributorData.choosenProductId = newVal;
    } else if (key === "price") {
      oldSelectDistributorData.price = newVal;
    } else if (key === "fee") {
      oldSelectDistributorData.fee = newVal;
    } else if (key === "terms") {
      oldSelectDistributorData.terms = newVal;
      if (newVal === "duration") {
        oldSelectDistributorData.quantity = "";
      } else if (newVal === "quantity") {
        oldSelectDistributorData.duration = "";
      }
    } else if (key === "duration") {
      oldSelectDistributorData.duration = newVal;
    } else if (key === "quantity") {
      oldSelectDistributorData.quantity = newVal;
    } else if (key === "comments") {
      oldSelectDistributorData.comments = newVal;
    }

    setSelectDistributorData(oldSelectDistributorData);
  };

  const [errorResponse, setErrorResponse] = useState("");

  const [choosenProductId, setChoosenProductId] = useState("");
  const [choosenProduct, setChoosenProduct] = useState({});

  const setItemToListOfProducts = (productId: any) => {
    setChoosenProductId(productId);
    handleChangeSelectDistributorData("choosenProductId", productId);
  };

  useEffect(() => {
    // console.log("choosenProductId", choosenProductId);
    if (choosenProductId !== "") {
      for (let i = 0; i < listOfAllProducts.length; i++) {
        //@ts-ignore
        if (listOfAllProducts[i].id === choosenProductId) {
          // thumbnail, name, type, price, currencySymbol
          setChoosenProduct({
            //@ts-ignore
            thumbnail: listOfAllProducts[i].thumbnail,
            //@ts-ignore
            name: listOfAllProducts[i].name,
            //@ts-ignore
            type: listOfAllProducts[i].type,
            //@ts-ignore
            price: listOfAllProducts[i].price,
            //@ts-ignore
            currencySymbol: listOfAllProducts[i].currencySymbol,
          });
        }
      }
    }
  }, [choosenProductId, listOfAllProducts]);

  const handleChangeStep = (newVal: string) => {
    setStep(newVal);
  };

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const allListOfProductsStore = useSelector(
    (state: any) => state.allListOfProductsStore
  );

  useEffect(() => {
    // console.log("allListOfProductsStore", allListOfProductsStore);
    if (
      allListOfProductsStore &&
      allListOfProductsStore.data &&
      allListOfProductsStore.data.response
    ) {
      const response = allListOfProductsStore.data.response;
      let newData = [];

      for (let i = 0; i < response.length; i++) {
        newData.push({
          id: response[i].id,
          currencySymbol: response[i].currencySymbol,
          name: response[i].name,
          price: response[i].price,
          thumbnail: response[i].thumbnail,
          type: response[i].type,
        });
      }

      // @ts-ignore
      setlListOfAllProducts(newData);
    }
  }, [allListOfProductsStore]);

  async function getAllProductsFunc() {
    await actions.constructorAction(
      constants.allListOfProducts,
      dispatch,
      "api/product/all/",
      "get",
      "",
      {},
      true
    )();
  }

  useEffect(() => {
    dispatch({ type: constants.BecomeADistributorProductLink.reset });

    const newCollaborationDataStorage = utils.LocalStorage.get(
      "NewCollaborationData"
    );
    if (newCollaborationDataStorage) {
      if (newCollaborationDataStorage.status) {
        setStatus(newCollaborationDataStorage.status);
      }
      if (newCollaborationDataStorage.step) {
        setStep(newCollaborationDataStorage.step);
      }
      if (newCollaborationDataStorage.becomeDistributorData) {
        setBecomeDistributorData(
          newCollaborationDataStorage.becomeDistributorData
        );
      }
      if (newCollaborationDataStorage.selectDistributorData) {
        setSelectDistributorData(
          newCollaborationDataStorage.selectDistributorData
        );

        setChoosenProductId(
          newCollaborationDataStorage.selectDistributorData.choosenProductId
        );
      }
    }

    getAllProductsFunc();
  }, []);

  useEffect(() => {
    utils.LocalStorage.set("NewCollaborationData", {
      status: status,
      step: step,
      becomeDistributorData: becomeDistributorData,
      selectDistributorData: selectDistributorData,
    });
  }, [status, step, becomeDistributorData, selectDistributorData]);

  const createCollaborationStore = useSelector(
    (state: any) => state.createCollaborationStore
  );

  const handleSendCollaborationData = (statusVal: string) => {
    let dataForm = {};

    if (statusVal === "become") {
      dataForm = {
        type: "become",
        productLink: becomeDistributorData.productLink,
        price: becomeDistributorData.price,
        currency: becomeDistributorData.currency,
        fee: becomeDistributorData.fee,
        terms: becomeDistributorData.terms,
        duration: becomeDistributorData.duration,
        quantity: becomeDistributorData.quantity,
        comments: becomeDistributorData.comments,
      };
    } else if (statusVal === "select") {
      dataForm = {
        type: "select",
        username: selectDistributorData.distributorUsername,
        productId: selectDistributorData.choosenProductId,
        price: selectDistributorData.price,
        currency: selectDistributorData.currency,
        fee: selectDistributorData.fee,
        terms: selectDistributorData.terms,
        duration: selectDistributorData.duration,
        quantity: selectDistributorData.quantity,
        comments: selectDistributorData.comments,
      };
    }

    createCollaborationFunc(dispatch, "", dataForm);
  };

  useEffect(() => {
    console.log("createCollaborationStore", createCollaborationStore);
    if (createCollaborationStore) {
      if (createCollaborationStore.data) {
        navigate("/collaboration");
        utils.LocalStorage.remove("NewCollaborationData");
      } else if (createCollaborationStore.error) {
        setErrorResponse(createCollaborationStore.error);
      } else if (createCollaborationStore.fail) {
        setErrorResponse(createCollaborationStore.fail);
      }
    }
  }, [createCollaborationStore]);

  if (createCollaborationStore && createCollaborationStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={createCollaborationStore.load} />
      </div>
    );
  }

  if (allListOfProductsStore && allListOfProductsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={allListOfProductsStore.load} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="w-screen overflow-hidden max-w-md min-h-screen break-words">
        {step === "selection" && (
          <div className="flex justify-center">
            <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
              <BurgerMenu
                isOpen={burgerMenuOpen}
                toggleBurgerMenu={toggleBurgerMenu}
              />

              <div className="flex flex-col">
                <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                  <div className="h-[56px] flex justify-between items-center">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        navigate("/collaboration");
                      }}
                    >
                      {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                      <div className="p-[10px]">
                        <img
                          src="/images/back_arrow.svg"
                          alt="back_arrow.svg"
                          className="w-[7px] h-[14px]"
                        />
                      </div>
                    </button>
                    <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                      {t("make_collaboration")}
                    </div>
                    <button onClick={toggleBurgerMenu}>
                      {!burgerMenuOpen ? (
                        // <Icon
                        //   icon="iconamoon:menu-burger-horizontal-light"
                        //   className="w-[24px] h-[26px]"
                        // />
                        <img
                          src="/images/hamburger_menu/hamburger_menu.svg"
                          alt="hamburger_menu.svg"
                          className="w-[24px] h-[26.88px]"
                        />
                      ) : (
                        <span> </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="mt-[66px]">
                  <div className="text-[#161616] text-[18px] leading-[22px] font-semibold">
                    {t("select_collaboration_type")}
                  </div>
                </div>

                <div className="mt-[15px] flex justify-center">
                  <div
                    className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
                    onClick={() => {
                      changeStatus("become");
                    }}
                  >
                    <div className="flex space-x-[10px] items-center">
                      <img
                        src="/images/make_collaboration/become_a_distributor.svg"
                        alt="svg"
                      />
                      <div className="">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {t("become_a_distributor")}
                        </div>
                        <div className="text-[12px] leading-[16px] text-[#161616]">
                          {t("become_a_distributor_text")}
                        </div>
                      </div>
                    </div>
                    <Radio
                      name="description"
                      crossOrigin={""}
                      checked={status === "become"}
                      onChange={() => {
                        changeStatus("become");
                      }}
                    />
                  </div>
                </div>

                <div className="mt-[10px] flex justify-center">
                  <div
                    className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
                    onClick={() => {
                      changeStatus("select");
                    }}
                  >
                    <div className="flex space-x-[10px] items-center">
                      <img
                        src="/images/make_collaboration/select_a_distributor.svg"
                        alt="svg"
                      />
                      <div className="">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {t("select_a_distributor")}
                        </div>
                        <div className="text-[12px] leading-[16px] text-[#161616]">
                          {t("select_a_distributor_text")}
                        </div>
                      </div>
                    </div>
                    <Radio
                      name="description"
                      crossOrigin={""}
                      checked={status === "select"}
                      onChange={() => {
                        changeStatus("select");
                      }}
                    />
                  </div>
                </div>

                <div className="mt-[320px]">
                  <div
                    className="bg-[#161616] h-[50px] rounded-[10px] text-white flex justify-center items-center font-semibold text-[18px]"
                    onClick={() => {
                      setStep("main");
                    }}
                  >
                    {t("continue")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === "main" && status === "become" && (
          <BecomeADistributor
            handleChangeStep={handleChangeStep}
            handleChangeBecomeDistributorData={
              handleChangeBecomeDistributorData
            }
            becomeDistributorData={becomeDistributorData}
            handleSendCollaborationData={handleSendCollaborationData}
            errorResponse={errorResponse}
          />
        )}

        {step === "main" && status === "select" && (
          <SelectADistributor
            handleChangeStep={handleChangeStep}
            selectDistributorData={selectDistributorData}
            handleChangeSelectDistributorData={
              handleChangeSelectDistributorData
            }
            data={listOfAllProducts}
            setItemToListOfProducts={setItemToListOfProducts}
            choosenProductId={choosenProductId}
            choosenProduct={choosenProduct}
            handleSendCollaborationData={handleSendCollaborationData}
            errorResponse={errorResponse}
          />
        )}
      </div>
      ;
    </utils.AuthGuard>
  );
}
