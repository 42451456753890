import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useTranslation } from "react-i18next";

// import "preline/preline";
// import { IStaticMethods } from "preline/preline";

export async function checkUsernameFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.checkUsername,
    dispatch,
    `api/auth/check_username/`,
    "post",
    "",
    form,
    false
  )();

  // try {
  //   dispatch({ type: constants.checkUsername.load });
  //   const config = { headers: { Authorization: `Bearer ${access_token}` } };
  //   const response = await axios.post(
  //     `${constants.API}api/auth/check_username/`,
  //     // @ts-ignore
  //     // config,
  //     form
  //     // { data: [...form] },
  //   );
  //   if (response.data) {
  //     dispatch({
  //       type: constants.checkUsername.success,
  //       payload: response.data,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.checkUsername.error,
  //       payload: response.statusText,
  //     });
  //   }
  // } catch (error: any) {
  //   if (error.response && error.response.data) {
  //     dispatch({
  //       type: constants.checkUsername.fail,
  //       payload: error.response.data.error,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.checkUsername.fail,
  //       payload: error.toString(),
  //     });
  //   }
  //   console.error("error: ", error);
  // }
}

export async function checkEmailFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.checkEmail,
    dispatch,
    `api/auth/check_email/`,
    "post",
    "",
    form,
    false
  )();

  // try {
  //   dispatch({ type: constants.checkUsername.load });
  //   const config = { headers: { Authorization: `Bearer ${access_token}` } };
  //   const response = await axios.post(
  //     `${constants.API}api/auth/check_username/`,
  //     // @ts-ignore
  //     // config,
  //     form
  //     // { data: [...form] },
  //   );
  //   if (response.data) {
  //     dispatch({
  //       type: constants.checkUsername.success,
  //       payload: response.data,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.checkUsername.error,
  //       payload: response.statusText,
  //     });
  //   }
  // } catch (error: any) {
  //   if (error.response && error.response.data) {
  //     dispatch({
  //       type: constants.checkUsername.fail,
  //       payload: error.response.data.error,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.checkUsername.fail,
  //       payload: error.toString(),
  //     });
  //   }
  //   console.error("error: ", error);
  // }
}

export async function registrationFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  // actions.constructorAction(
  //   constants.registration,
  //   dispatch,
  //   `api/auth/registration/`,
  //   "post",
  //   "",
  //   form,
  //   false
  // )();

  try {
    dispatch({ type: constants.registration.load });
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const response = await axios.post(
      `${constants.API}api/auth/registration/`,
      // @ts-ignore
      // config,
      form
      // { data: [...form] },
    );
    if (response.data) {
      dispatch({
        type: constants.registration.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.registration.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch({
        type: constants.registration.fail,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: constants.registration.fail,
        payload: error.toString(),
      });
    }
    console.error("error: ", error);
  }
}

export default function RegistrationPage() {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [registrationError, setRegistrationError] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [response, setResponse] = useState("");

  const [isCorrectLength, setIsCorrectLength] = useState(false);
  const [isOneSymbol, setIsOneSymbol] = useState(false);
  const [isOneNumber, setIsOneNumber] = useState(false);
  const [isOneUpperOneLowerLetter, setIsOneUpperOneLowerLetter] =
    useState(false);
  const [emailError, setEmailError] = useState("");

  const [userLanguage, setUserLanguage] = useState("");

  useEffect(() => {
    // console.log("userLanguage", userLanguage);
    if (userLanguage === "") {
      const userLocalLanguage = navigator.language || navigator.languages[0];
      i18n.changeLanguage(userLocalLanguage);
      setUserLanguage(userLocalLanguage);
    } else {
      i18n.changeLanguage(userLanguage);
    }
  }, [userLanguage]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // This is a test bio of my profile to evaluate the visual perception of this aspect of customization. This is additional text to fill out the field.

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const checkUsernameStore = useSelector(
    (state: any) => state.checkUsernameStore
  );

  const checkEmailStore = useSelector((state: any) => state.checkEmailStore);

  const registrationStore = useSelector(
    (state: any) => state.registrationStore
  );

  const handleClickRegistration = () => {
    if (
      !usernameTaken &&
      !emailTaken &&
      username !== "" &&
      email !== "" &&
      password !== "" &&
      isCorrectLength &&
      isOneNumber &&
      isOneSymbol &&
      isOneUpperOneLowerLetter &&
      emailError == ""
    ) {
      registrationFunc(dispatch, "", {
        username: username,
        email: email,
        password: password,
      });
    }
  };

  useEffect(() => {
    // console.log("username", username);
    if (username !== "") {
      if (username.length > 2) {
        checkUsernameFunc(dispatch, "", { username: username });
      } else {
        setUsernameTaken(true);
      }
    } else {
      setUsernameTaken(false);
    }
  }, [username]);

  useEffect(() => {
    // console.log("email", email);
    if (email !== "") {
      checkEmailFunc(dispatch, "", { email: email });
    }
  }, [email]);

  useEffect(() => {
    const isCorrectLengthBool = password.length >= 8;
    setIsCorrectLength(isCorrectLengthBool);

    const isOneNumberBool = /\d/.test(password);
    setIsOneNumber(isOneNumberBool);

    const isOneUpperOneLowerLetterBool = /^(?=.*[a-z])(?=.*[A-Z])/.test(
      password
    );
    setIsOneUpperOneLowerLetter(isOneUpperOneLowerLetterBool);

    // !, ?, @, $, #, &
    const isOneSymbolBool = /[@$!%*?&]/.test(password);
    setIsOneSymbol(isOneSymbolBool);
  }, [password]);

  useEffect(() => {
    if (checkUsernameStore && checkUsernameStore.data) {
      setUsernameTaken(checkUsernameStore.data.response);
    }
  }, [checkUsernameStore]);

  useEffect(() => {
    if (checkEmailStore && checkEmailStore.data) {
      setEmailTaken(checkEmailStore.data.response);
    }
  }, [checkEmailStore]);

  useEffect(() => {
    // console.log("usernameTaken", usernameTaken);
    if (!usernameTaken) {
      if (username === "available") {
        setResponse(t("registration_error1"));
      } else {
        setResponse(t("registration_error2"));
      }
    } else {
      if (username.length < 3) {
        setResponse(t("registration_error6"));
      } else if (username === "unavailable") {
        setResponse(t("registration_error3"));
      } else {
        setResponse(t("registration_error4"));
      }
    }
  }, [usernameTaken, username]);

  useEffect(() => {
    // console.log("registrationStore", registrationStore);
    if (
      registrationStore &&
      registrationStore.data &&
      registrationStore.data.response === "OK"
    ) {
      // navigate(`/auth/registration/${registrationStore.data.response}/verify`);
      navigate(`/auth/registration/withoutcode/completed`);
    }

    if (
      registrationStore &&
      (registrationStore.fail || registrationStore.error)
    ) {
      if (registrationStore.fail) {
        setRegistrationError(registrationStore.fail);
      }

      if (registrationStore.error) {
        setRegistrationError(registrationStore.error);
      }
    }
  }, [registrationStore]);

  if (registrationStore && registrationStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={registrationStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    // <div className="font-manrope flex justify-center bg-[#F5F5F5] px-[24px] pb-[50px] w-screen min-h-screen">
    <div className="flex justify-center break-words">
      {/* <div className="font-manrope bg-[#F5F5F5] px-[24px] pb-[50px] w-screen max-w-md min-h-screen"> */}
      <div className="font-manrope pb-[40px] overflow-hidden w-screen max-w-md">
        <div className="w-full py-[35px] bg-[#161616]">
          <div className="px-[24px]">
            <div className="flex justify-center items-center">
              <div className="flex flex-col text-white">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/logo_light.svg"
                    alt="logo_light"
                    className="w-[132px] h-[80px]"
                  />
                </div>
                <div className="mt-[25px] font-extrabold text-[46px] leading-[50px] text-center">
                  {t("login_image_text1-1")}
                  <br />
                  {t("login_image_text1-2")}
                </div>

                <div className="mt-[20px] text-[15px] leading-[24px] text-center">
                  {t("login_image_text2")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[24px]">
          <div className="mt-[15px]">
            <div className="text-[24px] font-bold leading-[30px] text-[#161616]">
              {t("login_text3")}
            </div>
          </div>

          <div className="mt-[20px]">
            {/* {username == "" ? (
            <input
              type="username"
              className="w-[342px] h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[15px] leading-[18.75px]"
              placeholder="Username"
              // value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          ) :  */}
            {!usernameTaken ? (
              <div>
                <input
                  type="username"
                  maxLength={30}
                  className={`w-full h-[56px] rounded-xl border-[1px] ${username === "" ? "border-[#E8ECF4]" : "border-[#18C07A]"} bg-[#F7F8F9] px-6 py-4 placeholder:text-[#8391A1] text-[#8391A1] text-[16px] leading-[18.75px]`}
                  placeholder={t("username")}
                  value={username}
                  onChange={(e) => {
                    const value = e.target.value;

                    const generalPattern = /^[A-Za-z0-9][A-Za-z0-9._]*$/;
                    const isValidSequence = !/(_\.)|(\._)$/.test(value);

                    if (
                      (generalPattern.test(value) && isValidSequence) ||
                      value === ""
                    ) {
                      setUsername(value);
                    }
                  }}
                />
                {username !== "" && (
                  <div className="mt-[5px] text-[14px] leading-[24px] text-[#18C07A]">
                    {/* This username is available */}
                    {response}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <input
                  type="username"
                  maxLength={30}
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#FF2C20] bg-[#F7F8F9] px-6 py-4 text-[#8391A1] placeholder:text-[#8391A1] text-[16px] leading-[18.75px]"
                  placeholder={t("username")}
                  value={username}
                  onChange={(e) => {
                    const value = e.target.value;

                    const generalPattern = /^[A-Za-z0-9][A-Za-z0-9._]*$/;
                    const isValidSequence = !/(_\.)|(\._)$/.test(value);

                    if (
                      (generalPattern.test(value) && isValidSequence) ||
                      value === ""
                    ) {
                      setUsername(value);
                    }
                  }}
                />
                <div className="mt-[5px] text-[14px] leading-[24px] text-[#FF2C20]">
                  {/* * This username is already taken */}
                  {response}
                </div>
              </div>
            )}
          </div>

          <div className="mt-[10px]">
            {!emailTaken ? (
              <div>
                <input
                  type="email"
                  className={`w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] px-6 py-4 text-[#8391A1] placeholder:text-[#8391A1] text-[16px] leading-[18.75px]`}
                  placeholder={t("email_address")}
                  value={email}
                  onChange={(e) => {
                    const value = e.target.value;
                    setEmail(value);

                    // Регулярное выражение для проверки email
                    const emailPattern =
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    if (emailPattern.test(value)) {
                      setEmailError("");
                    } else {
                      setEmailError(t("registration_error7"));
                    }
                    // setEmail(e.target.value);
                  }}
                />

                {emailError !== "" && (
                  <div className="mt-[5px] text-[14px] leading-[24px] text-[#FF2C20]">
                    {emailError}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <input
                  type="email"
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#FF2C20] bg-[#F7F8F9] px-6 py-4 text-[#8391A1] placeholder:text-[#8391A1] text-[16px] leading-[18.75px]"
                  placeholder={t("email_address")}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div className="mt-[5px] text-[14px] leading-[24px] text-[#FF2C20]">
                  {t("registration_error5")}
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <div className="mt-[10px] flex flex-row items-center w-full relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] placeholder:text-[#8391A1] text-[16px] leading-[18.75px]"
                placeholder={t("password")}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <img
                src="/images/login/eye-invisible.svg"
                alt="eye-invisible.svg"
                className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                onClick={togglePasswordVisibility}
              />

              {/* <Icon
                icon={
                  showPassword ? "streamline:invisible-1" : "streamline:visible"
                }
                className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                onClick={togglePasswordVisibility}
              /> */}
            </div>
          </div>

          {password !== "" && (
            <div className="flex justify-start">
              <div className="mt-[5px]">
                <div className="flex flex-row items-center">
                  <img
                    src={
                      isCorrectLength
                        ? "/images/login/check.svg"
                        : "/images/login/cross.svg"
                    }
                    alt={isCorrectLength ? "check.svg" : "cross.svg"}
                    className="w-[22px] h-[22px]"
                  />

                  <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                    {t("registration_password_error1")}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <img
                    src={
                      isOneSymbol
                        ? "/images/login/check.svg"
                        : "/images/login/cross.svg"
                    }
                    alt={isOneSymbol ? "check.svg" : "cross.svg"}
                    className="w-[22px] h-[22px]"
                  />

                  <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                    {t("registration_password_error2")}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <img
                    src={
                      isOneNumber
                        ? "/images/login/check.svg"
                        : "/images/login/cross.svg"
                    }
                    alt={isOneNumber ? "check.svg" : "cross.svg"}
                    className="w-[22px] h-[22px]"
                  />

                  <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                    {t("registration_password_error3")}
                  </div>
                </div>
                <div className="flex space-x-2 items-center">
                  {/* <Icon
                    icon={
                      isOneUpperOneLowerLetter
                        ? "carbon:checkmark"
                        : "heroicons:x-mark"
                    }
                    className="w-[14px] h-[14px]"
                  /> */}

                  <img
                    src={
                      isOneUpperOneLowerLetter
                        ? "/images/login/check.svg"
                        : "/images/login/cross.svg"
                    }
                    alt={isOneUpperOneLowerLetter ? "check.svg" : "cross.svg"}
                    className="w-[22px] h-[22px]"
                  />

                  <div className="text-[14px] leading-[16px] text-[#161616]">
                    {t("registration_password_error4")}
                  </div>
                </div>
              </div>
            </div>
          )}

          {registrationError !== "" && (
            <div className="mt-[20px] flex justify-center">
              <div className="text-red-500 text-[14px] leading-[24px]">
                {registrationError}
              </div>
            </div>
          )}

          <div className="mt-[20px]">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white text-[18px] font-medium"
              onClick={handleClickRegistration}
            >
              {t("sign_up")}
            </button>
          </div>

          <div className="flex justify-center">
            <div className="mt-[10px] text-[16px] leading-[22px] text-[#8391A1]">
              {t("registration_text2")}{" "}
              <span
                className="text-[#161616] underline"
                onClick={() => {
                  window.open(
                    // `https://www.hubbbox.com/term-of-service`,
                    "https://api.hubbbox.com/static/media/Пользовательское%20соглашение.pdf",
                    "_blank"
                  );
                }}
              >
                {t("term_of_service")}
              </span>{" "}
              {t("and")}{" "}
              <span
                className="text-[#161616] underline"
                onClick={() => {
                  window.open(
                    // `https://www.hubbbox.com/privacy-policy`,
                    "https://api.hubbbox.com/static/media/Политика%20конфиденциальности.pdf",
                    "_blank"
                  );
                }}
              >
                {" "}
                {t("privacy_policy")}.
              </span>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="mt-[25px] text-[16px] leading-[22px] text-[#8391A1]">
              {t("registration_text3")}{" "}
              <button
                className="text-[#161616]"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                {t("sign_in")}
              </button>
            </div>
          </div>

          <div className="mt-[25px] flex justify-center">
            <div className="flex space-x-[10px] items-center">
              <div
                onClick={() => {
                  setUserLanguage("ru-RU");
                }}
                className={`w-[70px] h-[30px] rounded-[5px] ${userLanguage === "ru-RU" ? "bg-[#161616] text-white" : "border-[1px] border-[#161616] text-[#161616]"} text-center`}
              >
                Рус
              </div>
              <div
                onClick={() => {
                  setUserLanguage("en-EN");
                }}
                className={`w-[70px] h-[30px] rounded-[5px] ${userLanguage === "en-EN" ? "bg-[#161616] text-white" : "border-[1px] border-[#161616] text-[#161616]"} text-center`}
              >
                Eng
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
