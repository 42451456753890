import { Icon } from "@iconify/react";
import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Radio,
} from "@material-tailwind/react";
import SocialLinks from "../ui/SocialLinks";
import * as utils from "../utils";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShareQR from "../ui/ShareQR";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import { useDispatch, useSelector } from "react-redux";

export async function sendAnalyticsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendAnalytics,
    dispatch,
    `api/profile/send_analytics/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Component({
  page = "",
  username = "username",
  profileName,
  status = "",
  profileStatusLogo = "",
  bio,
  avatar,
  profileCover,
  iconDesign,
  listOfSelectedSocialMediaLinks,
  profileType,
  isPreview = false,
  isEdit = false,
  openFunc = null,
}: any) {
  const { t, i18n } = useTranslation();

  const handleCopy = (url: string) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("Ссылка успешно скопирована в буфер обмена.");
      })
      .catch((error) => {
        console.error("Ошибка при копировании ссылки:", error);
      });
  };

  const textRef = useRef(null);
  const [lineProfileNameCount, setLineProfileNameCount] = useState(0);
  const [marginBotProfileName, setMarginBotProfileName] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const navigate = useNavigate();

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      const lineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight
      );
      //@ts-ignore
      const elementHeight = element.clientHeight;
      setLineProfileNameCount(Math.round(elementHeight / lineHeight));
    }
  }, [profileName, profileType]);

  useEffect(() => {
    if (profileType === "Minimalist") {
      if (lineProfileNameCount < 2) {
        setMarginBotProfileName(0);
      } else {
        setMarginBotProfileName(8);
      }
    }
  }, [profileName, profileType, lineProfileNameCount]);

  const handleShare = async (url: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: url,
        });
        console.log("Ссылка успешно поделена!");
      } else {
        throw new Error("Web Share API не поддерживается в вашем браузере.");
      }
    } catch (error) {
      console.error("Ошибка при попытке поделиться ссылкой:", error);
    }
  };

  // useEffect(() => {
  //   console.log("marginBotProfileName", marginBotProfileName);
  // }, [marginBotProfileName]);

  return (
    <div className="break-words">
      {/* Страницы: 
      AccLaunch (Main.tsx), 
      PersonalInformation (PersonalInformation.tsx),
      Profile (Profile.tsx),
      ProfilePreview (ProfilePreview.tsx)
      */}

      {isModalOpen && (
        <ShareQR
          username={username}
          avatar={avatar}
          onClose={handleCloseModal}
        />
      )}

      {page === "AccLaunch" && (
        <div className="">
          <div className="">
            {profileType === "Classic" && (
              <div className="">
                <div className="w-full">
                  <div className="flex justify-center">
                    <div className="flex space-x-[26px] items-center">
                      <div
                        className={`rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0`}
                      >
                        <Icon
                          icon="solar:qr-code-linear"
                          className="w-[24px] h-[24px] text-[#161616]"
                        />
                      </div>

                      {!avatar ? (
                        <div className="relative" onClick={openFunc}>
                          <div className="rounded-full w-[110px] h-[110px] shadow-customblock m-[5px] flex-shrink-0">
                            <img
                              src="/images/avatar.svg"
                              alt="avatar.svg"
                              className="w-full h-full"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0">
                            <div className="rounded-full p-[6px] bg-white shadow-customblock">
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[20px] h-[20px] text-[#161616]"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <div onClick={openFunc}>
                        //   <div className="border-[6px] border-white rounded-full shadow-customblock">
                        //     <Avatar
                        //       src={avatar}
                        //       alt="avatar"
                        //       className="w-[110px] h-[110px]"
                        //     />
                        //   </div>
                        // </div>

                        <div className="relative" onClick={openFunc}>
                          <div className="border-[6px] border-white rounded-full shadow-customblock flex-shrink-0">
                            <Avatar
                              src={avatar}
                              alt="avatar"
                              className="w-[110px] h-[110px]"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0">
                            <div className="rounded-full p-[6px] bg-white shadow-customblock flex-shrink-0">
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[20px] h-[20px] text-[#161616]"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="rounded-full p-[6px] shadow-customblock flex-shrink-0">
                        <Icon
                          icon="solar:share-outline"
                          className="w-[24px] h-[24px] text-[#161616]"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[10px] px-[24px] flex justify-center items-center">
                  <span className="text-[#8391A1] text-[15px]">
                    @{username}
                  </span>
                </div>

                <div className="flex justify-center items-center">
                  {profileName === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px] px-[24px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[30px] leading-[33.6px] text-center font-semibold px-[24px]">
                      {profileName}
                    </div>
                  )}
                </div>

                <div className="mt-[15px] flex justify-center items-center">
                  {profileStatusLogo !== "" ? (
                    <div className="flex space-x-[10px] items-center px-[24px]">
                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] rounded-[5px]"
                      />

                      {status === "" ? (
                        <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                      ) : (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                          {status}
                        </div>
                      )}

                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] invisible"
                      />
                    </div>
                  ) : (
                    <div className="">
                      {status === "" ? (
                        <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px] px-[24px]"></div>
                      ) : (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold px-[24px]">
                          {status}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex justify-center items-center">
                  {bio === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px] px-[24px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] font-light text-center px-[24px]">
                      {utils.splitText(bio)}
                    </div>
                  )}
                </div>

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    isEdit={true}
                    accType={"classical"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            )}

            {profileType === "Minimalist" && (
              <div className="">
                <div className="px-[24px]">
                  {!avatar ? (
                    // <div className="float-left mr-[15px]">
                    //   <div className="relative" onClick={openFunc}>
                    //     <div className="">
                    //       <img
                    //         src="/images/avatar.svg"
                    //         alt="avatar.svg"
                    //         className="w-[65px] h-[65px] m-[5px]"
                    //       />
                    //     </div>

                    //     <div className="absolute bottom-0 right-0">
                    //       <img
                    //         src="/images/profile/edit_avatar.svg"
                    //         alt="edit_avatar.svg"
                    //         className="w-[25px] h-[25px]"
                    //         // onClick={openFunc}
                    //       />
                    //     </div>
                    //   </div>
                    // </div>

                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <div className="relative" onClick={openFunc}>
                        <img
                          src="/images/avatar.svg"
                          alt="avatar.svg"
                          className="w-[65px] h-[65px] m-[5px] flex-shrink-0"
                        />

                        <div className="absolute bottom-0 right-0">
                          <div className="rounded-full p-[4px] bg-white shadow-customblock flex-shrink-0">
                            <Icon
                              icon="iconamoon:edit-light"
                              className="w-[12px] h-[12px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // <div className="float-left mr-[15px]" onClick={openFunc}>
                    //   <Avatar
                    //     src={avatar}
                    //     alt="avatar"
                    //     className="w-[65px] h-[65px]"
                    //   />
                    // </div>
                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <div className="relative" onClick={openFunc}>
                        <Avatar
                          src={avatar}
                          alt="avatar"
                          className="w-[65px] h-[65px] flex-shrink-0"
                        />

                        <div className="absolute bottom-0 right-0">
                          <div className="rounded-full p-[4px] bg-white shadow-customblock flex-shrink-0">
                            <Icon
                              icon="iconamoon:edit-light"
                              className="w-[12px] h-[12px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="ml-[80px] pt-[7px]">
                    {profileName === "" ? (
                      <div className="pt-[12px] bg-[#F7F8F9] w-[220px] h-[24px] rounded-[20px]"></div>
                    ) : (
                      <div
                        className={`font-semibold text-[#161616] text-[24px] pt-[10px] leading-[12px] text-start`}
                      >
                        {profileName}
                      </div>
                    )}
                  </div>

                  <div className="mt-[12px] mb-[15px] flex space-x-[10px] items-center">
                    <div className="text-[15px] text-[#8391A1]">
                      @{username}
                    </div>

                    <div className="rounded-full p-[4px] shadow-customblock">
                      <Icon
                        icon="solar:share-outline"
                        className="w-[16px] h-[16px] text-[#161616]"
                      />
                    </div>

                    <div className={`rounded-full p-[4px] shadow-customblock`}>
                      <Icon
                        icon="solar:qr-code-linear"
                        className="w-[16px] h-[16px] text-[#161616]"
                      />
                    </div>
                  </div>

                  <div className="flex justify-start">
                    {profileStatusLogo !== "" ? (
                      <div className="flex space-x-[10px] items-center">
                        <img
                          src={profileStatusLogo}
                          alt="profileStatusLogo"
                          className="w-[24px] h-[24px] rounded-[5px]"
                        />

                        {status === "" ? (
                          <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                        ) : (
                          <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                            {status}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="">
                        {status === "" ? (
                          <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                        ) : (
                          <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                            {status}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {bio === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[17px] font-light leading-[19px]">
                      {utils.splitText(bio)}
                    </div>
                  )}
                </div>

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    accType={"minimalist"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                    lineProfileNameCount={lineProfileNameCount}
                    isEdit={true}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            )}
          </div>
        </div>
      )}

      {page === "PersonalInformation" && (
        <div className="">
          <div className="">
            {profileType === "Classic" && (
              <div className="">
                <div className="w-full">
                  <div className="flex justify-center">
                    <div className="flex space-x-[26px] items-center">
                      <div
                        className={`rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0`}
                      >
                        <Icon
                          icon="solar:qr-code-linear"
                          className="w-[24px] h-[24px] text-[#161616]"
                        />
                      </div>

                      {!avatar ? (
                        <div className="relative" onClick={openFunc}>
                          <div className="rounded-full w-[110px] h-[110px] shadow-customblock m-[5px] flex-shrink-0">
                            <img
                              src="/images/avatar.svg"
                              alt="avatar.svg"
                              className="w-full h-full"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0">
                            <div className="rounded-full p-[6px] bg-white shadow-customblock">
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[20px] h-[20px] text-[#161616]"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <div onClick={openFunc}>
                        //   <div className="border-[6px] border-white rounded-full shadow-customblock">
                        //     <Avatar
                        //       src={avatar}
                        //       alt="avatar"
                        //       className="w-[110px] h-[110px]"
                        //     />
                        //   </div>
                        // </div>

                        <div className="relative" onClick={openFunc}>
                          <div className="border-[6px] border-white rounded-full shadow-customblock flex-shrink-0">
                            <Avatar
                              src={avatar}
                              alt="avatar"
                              className="w-[110px] h-[110px]"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0">
                            <div className="rounded-full p-[6px] bg-white shadow-customblock flex-shrink-0">
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[20px] h-[20px] text-[#161616]"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="rounded-full p-[6px] shadow-customblock flex-shrink-0">
                        <Icon
                          icon="solar:share-outline"
                          className="w-[24px] h-[24px] text-[#161616]"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[10px] px-[24px] flex justify-center items-center">
                  <span className="text-[#8391A1] text-[15px]">
                    @{username}
                  </span>
                </div>

                <div className="flex justify-center items-center">
                  {profileName === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px] px-[24px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[30px] leading-[33.6px] text-center font-semibold px-[24px]">
                      {profileName}
                    </div>
                  )}
                </div>

                <div className="mt-[15px] flex justify-center items-center">
                  {profileStatusLogo !== "" ? (
                    <div className="flex space-x-[10px] items-center px-[24px]">
                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] rounded-[5px]"
                      />

                      {status === "" ? (
                        <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                      ) : (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                          {status}
                        </div>
                      )}

                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] invisible"
                      />
                    </div>
                  ) : (
                    <div className="">
                      {status === "" ? (
                        <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px] px-[24px]"></div>
                      ) : (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold px-[24px]">
                          {status}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex justify-center items-center">
                  {bio === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px] px-[24px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] font-light text-center px-[24px]">
                      {utils.splitText(bio)}
                    </div>
                  )}
                </div>

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    isEdit={true}
                    accType={"classical"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            )}

            {profileType === "Minimalist" && (
              <div className="">
                <div className="px-[24px]">
                  {!avatar ? (
                    // <div className="float-left mr-[15px]">
                    //   <div className="relative" onClick={openFunc}>
                    //     <div className="">
                    //       <img
                    //         src="/images/avatar.svg"
                    //         alt="avatar.svg"
                    //         className="w-[65px] h-[65px] m-[5px]"
                    //       />
                    //     </div>

                    //     <div className="absolute bottom-0 right-0">
                    //       <img
                    //         src="/images/profile/edit_avatar.svg"
                    //         alt="edit_avatar.svg"
                    //         className="w-[25px] h-[25px]"
                    //         // onClick={openFunc}
                    //       />
                    //     </div>
                    //   </div>
                    // </div>

                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <div className="relative" onClick={openFunc}>
                        <img
                          src="/images/avatar.svg"
                          alt="avatar.svg"
                          className="w-[65px] h-[65px] m-[5px] flex-shrink-0"
                        />

                        <div className="absolute bottom-0 right-0">
                          <div className="rounded-full p-[4px] bg-white shadow-customblock flex-shrink-0">
                            <Icon
                              icon="iconamoon:edit-light"
                              className="w-[12px] h-[12px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // <div className="float-left mr-[15px]" onClick={openFunc}>
                    //   <Avatar
                    //     src={avatar}
                    //     alt="avatar"
                    //     className="w-[65px] h-[65px]"
                    //   />
                    // </div>
                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <div className="relative" onClick={openFunc}>
                        <Avatar
                          src={avatar}
                          alt="avatar"
                          className="w-[65px] h-[65px] flex-shrink-0"
                        />

                        <div className="absolute bottom-0 right-0">
                          <div className="rounded-full p-[4px] bg-white shadow-customblock flex-shrink-0">
                            <Icon
                              icon="iconamoon:edit-light"
                              className="w-[12px] h-[12px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="ml-[80px] pt-[7px]">
                    {profileName === "" ? (
                      <div className="pt-[12px] bg-[#F7F8F9] w-[220px] h-[24px] rounded-[20px]"></div>
                    ) : (
                      <div
                        className={`font-semibold text-[#161616] text-[24px] pt-[10px] leading-[12px] text-start`}
                      >
                        {profileName}
                      </div>
                    )}
                  </div>

                  <div className="mt-[12px] flex space-x-[10px] items-center">
                    <div className="text-[15px] text-[#8391A1]">
                      @{username}
                    </div>

                    <div className="rounded-full p-[4px] shadow-customblock">
                      <Icon
                        icon="solar:share-outline"
                        className="w-[16px] h-[16px] text-[#161616]"
                      />
                    </div>

                    <div className={`rounded-full p-[4px] shadow-customblock`}>
                      <Icon
                        icon="solar:qr-code-linear"
                        className="w-[16px] h-[16px] text-[#161616]"
                      />
                    </div>
                  </div>

                  <div className="mt-[15px] flex justify-start">
                    {profileStatusLogo !== "" ? (
                      <div className="flex space-x-[10px] items-center">
                        <img
                          src={profileStatusLogo}
                          alt="profileStatusLogo"
                          className="w-[24px] h-[24px] rounded-[5px]"
                        />

                        {status === "" ? (
                          <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                        ) : (
                          <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                            {status}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="">
                        {status === "" ? (
                          <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                        ) : (
                          <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                            {status}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* {status === "" ? (
                    <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px]"></div>
                  ) : (
                    <div className="text-[#161616] text-[16px] leading-[19px] font-semibold">
                      {status}
                    </div>
                  )} */}

                  {bio === "" ? (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px]"></div>
                  ) : (
                    <div className="mt-[10px] text-[#161616] text-[17px] font-light leading-[19px]">
                      {utils.splitText(bio)}
                    </div>
                  )}
                </div>

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    accType={"minimalist"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                    lineProfileNameCount={lineProfileNameCount}
                    isEdit={true}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            )}
          </div>
        </div>
      )}

      {page === "Profile" && (
        <div className="">
          {profileType === "Classic" && (
            <div className="">
              <div className="flex flex-col items-center">
                {profileCover ? (
                  <div className="relative w-full">
                    <div className="relative">
                      <img
                        src={profileCover}
                        alt="Background"
                        className="w-full h-full object-cover"
                      />

                      <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
                        <Menu>
                          <MenuHandler>
                            <div className="flex justify-end items-center">
                              <img
                                src="/images/profile/dots.svg"
                                alt="menu_dots"
                                className="w-[18px] h-[4px] mx-[6px] my-[13px] rounded-[1px]"
                              />
                            </div>
                          </MenuHandler>
                          <MenuList
                            className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                          >
                            <MenuItem
                              onClick={() => {
                                window.open(
                                  `https://www.hubbbox.com/${username}`,
                                  "_blank"
                                );
                                // navigate("/profile/preview");
                              }}
                            >
                              <div className="flex justify-between">
                                <div className="text-[#161616] text-[16px] leading-[22px]">
                                  {t("preview")}
                                </div>
                                <img
                                  src="/images/profile/preview.svg"
                                  alt="preview.svg"
                                  className="w-[24px] h-[24px]"
                                />
                              </div>
                            </MenuItem>
                            <hr className="border-gray-300" />
                            <MenuItem
                              onClick={() => {
                                handleCopy(
                                  `https://www.hubbbox.com/${username}`
                                );
                              }}
                            >
                              <div className="flex justify-between items-center">
                                <div className="text-[#161616] text-[16px] leading-[22px]">
                                  {t("copy_link")}
                                </div>
                                <img
                                  src="/images/profile/copy_link.svg"
                                  alt="copy_link.svg"
                                  className="w-[24px] h-[24px]"
                                />
                              </div>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </button>

                      <div className="absolute -bottom-[58px] left-1/2 transform -translate-x-1/2">
                        {/* <div className="absolute -bottom-[58px] px-[24px]"> */}
                        <div className="flex space-x-[26px] items-center">
                          <div
                            className={`rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0`}
                            onClick={handleOpenModal}
                          >
                            <Icon
                              icon="solar:qr-code-linear"
                              className="w-[24px] h-[24px] text-[#161616]"
                            />
                          </div>

                          <div
                            className="w-[116px] h-[116px] border-4 border-white rounded-full overflow-hidden shadow-customblock flex-shrink-0"
                            onClick={openFunc}
                          >
                            {avatar ? (
                              <img
                                src={avatar}
                                alt="Avatar"
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <img
                                src="/images/avatar.svg"
                                alt="avatar.svg"
                                className="w-full h-full bg-white"
                              />
                            )}
                          </div>

                          <div
                            onClick={() => {
                              handleShare(`https://hubbbox.com/${username}`);
                            }}
                            className="rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0"
                          >
                            <Icon
                              icon="solar:share-outline"
                              className="w-[24px] h-[24px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-[54px]"></div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    {page === "Profile" && (
                      <Menu>
                        <MenuHandler>
                          <div className="flex justify-between items-center px-[24px]">
                            <div className="invisible">
                              <img
                                src="/images/profile/dots.svg"
                                alt="menu_dots"
                                className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                              />
                            </div>
                            <img
                              src="/images/profile/dots.svg"
                              alt="menu_dots"
                              className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                            />
                          </div>
                        </MenuHandler>
                        <MenuList
                          className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                        >
                          <MenuItem
                            onClick={() => {
                              window.open(
                                `https://www.hubbbox.com/${username}`,
                                "_blank"
                              );
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("preview")}
                              </div>
                              <img
                                src="/images/profile/preview.svg"
                                alt="preview.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                          <hr className="border-gray-300" />
                          <MenuItem
                            onClick={() => {
                              handleCopy(`https://www.hubbbox.com/${username}`);
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("copy_link")}
                              </div>
                              <img
                                src="/images/profile/copy_link.svg"
                                alt="copy_link.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}

                    <div className="flex justify-center">
                      <div className="flex space-x-[26px] items-center">
                        <div className="">
                          <div
                            className={`rounded-full p-[6px] shadow-customblock bg-white ${page === "Profile" ? "" : "invisible"}`}
                            onClick={handleOpenModal}
                          >
                            <Icon
                              icon="solar:qr-code-linear"
                              className="w-[24px] h-[24px] text-[#161616]"
                            />
                          </div>
                        </div>

                        {!avatar ? (
                          <div className="rounded-full w-[110px] h-[110px] shadow-customblock">
                            <img
                              src="/images/avatar.svg"
                              alt="avatar.svg"
                              className="w-full h-full"
                            />
                          </div>
                        ) : (
                          <div onClick={openFunc}>
                            <div className="border-[6px] border-white rounded-full shadow-customblock">
                              <Avatar
                                src={avatar}
                                alt="avatar"
                                className="w-[110px] h-[110px]"
                              />
                            </div>
                          </div>
                        )}

                        <div
                          onClick={() => {
                            handleShare(`https://hubbbox.com/${username}`);
                          }}
                          className="rounded-full p-[6px] shadow-customblock"
                        >
                          <Icon
                            icon="solar:share-outline"
                            className="w-[24px] h-[24px] text-[#161616]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px] px-[24px] flex space-x-[10px] items-center">
                  <div className="rounded-full p-[4px] shadow-customblock invisible">
                    <Icon
                      icon="iconamoon:edit-light"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>
                  <span className="text-[#8391A1] text-[15px]">
                    @{username}
                  </span>
                  <div
                    className="rounded-full p-[4px] shadow-customblock"
                    onClick={() => {
                      navigate("/profile/info");
                    }}
                  >
                    <Icon
                      icon="iconamoon:edit-light"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>
                </div>

                {profileName === "" ? (
                  <div className="mt-[10px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px] px-[24px]"></div>
                ) : (
                  <div className="mt-[10px] text-[#161616] text-[30px] leading-[33.6px] text-center font-semibold px-[24px]">
                    {profileName}
                  </div>
                )}

                <div className="mt-[15px] flex justify-center items-center px-[24px]">
                  {profileStatusLogo !== "" ? (
                    <div className="flex space-x-[10px] items-center">
                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] rounded-[5px]"
                      />

                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                          {status}
                        </div>
                      )}

                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] invisible"
                      />
                    </div>
                  ) : (
                    <div className="">
                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold px-[24px]">
                          {status}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {bio !== "" && (
                  <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] font-light text-center px-[24px]">
                    {utils.splitText(bio)}
                  </div>
                )}

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    isEdit={false}
                    accType={"classical"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            </div>
          )}

          {profileType === "Minimalist" && (
            <div className="">
              <div>
                {profileCover ? (
                  <div className="relative w-full">
                    <div className="relative">
                      <img
                        src={profileCover}
                        alt="Background"
                        className="w-full h-full mb-[15px] object-cover"
                      />

                      <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
                        <Menu>
                          <MenuHandler>
                            <div className="flex justify-end items-center">
                              <img
                                src="/images/profile/dots.svg"
                                alt="menu_dots"
                                className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                              />
                            </div>
                          </MenuHandler>
                          <MenuList
                            className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                          >
                            <MenuItem
                              onClick={() => {
                                window.open(
                                  `https://www.hubbbox.com/${username}`,
                                  "_blank"
                                );
                              }}
                            >
                              <div className="flex justify-between">
                                <div className="text-[#161616] text-[16px] leading-[22px]">
                                  {t("preview")}
                                </div>
                                <img
                                  src="/images/profile/preview.svg"
                                  alt="preview.svg"
                                  className="w-[24px] h-[24px]"
                                />
                              </div>
                            </MenuItem>
                            <hr className="border-gray-300" />
                            <MenuItem
                              onClick={() => {
                                handleCopy(
                                  `https://www.hubbbox.com/${username}`
                                );
                              }}
                            >
                              <div className="flex justify-between items-center">
                                <div className="text-[#161616] text-[16px] leading-[22px]">
                                  {t("copy_link")}
                                </div>
                                <img
                                  src="/images/profile/copy_link.svg"
                                  alt="copy_link.svg"
                                  className="w-[24px] h-[24px]"
                                />
                              </div>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <Menu>
                      <MenuHandler>
                        <div className="flex justify-between items-center px-[24px]">
                          <div className="invisible">
                            <img
                              src="/images/profile/dots.svg"
                              alt="menu_dots"
                              className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                            />
                          </div>
                          <img
                            src="/images/profile/dots.svg"
                            alt="menu_dots"
                            className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                          />
                        </div>
                      </MenuHandler>
                      <MenuList
                        className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                      >
                        <MenuItem
                          onClick={() => {
                            window.open(
                              `https://www.hubbbox.com/${username}`,
                              "_blank"
                            );
                          }}
                        >
                          <div className="flex justify-between">
                            <div className="text-[#161616] text-[16px] leading-[22px]">
                              {t("preview")}
                            </div>
                            <img
                              src="/images/profile/preview.svg"
                              alt="preview.svg"
                              className="w-[24px] h-[24px]"
                            />
                          </div>
                        </MenuItem>
                        <hr className="border-gray-300" />
                        <MenuItem
                          onClick={() => {
                            handleCopy(`https://www.hubbbox.com/${username}`);
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div className="text-[#161616] text-[16px] leading-[22px]">
                              {t("copy_link")}
                            </div>
                            <img
                              src="/images/profile/copy_link.svg"
                              alt="copy_link.svg"
                              className="w-[24px] h-[24px]"
                            />
                          </div>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                )}

                <div className="px-[24px]">
                  {!avatar ? (
                    <div className="float-left mr-[15px]">
                      <img
                        src="/images/avatar.svg"
                        alt="avatar.svg"
                        className="w-[65px] h-[65px]"
                      />
                    </div>
                  ) : (
                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <Avatar
                        src={avatar}
                        alt="avatar"
                        className="w-[65px] h-[65px]"
                      />
                    </div>
                  )}
                </div>

                <div className="ml-[80px]">
                  {profileName === "" ? (
                    <div className="pt-[12px] bg-[#F7F8F9] w-[220px] h-[24px] rounded-[20px]"></div>
                  ) : (
                    <div
                      className={`font-semibold text-[#161616] text-[24px] pt-[10px] leading-[12px] text-start`}
                    >
                      {profileName}
                    </div>
                  )}
                </div>

                <div className="mt-[12px] mb-[15px] flex space-x-[10px] items-center">
                  <div className="text-[15px] text-[#8391A1] ">@{username}</div>

                  <div
                    onClick={() => {
                      handleShare(`https://hubbbox.com/${username}`);
                    }}
                    className="rounded-full p-[4px] shadow-customblock"
                  >
                    <Icon
                      icon="solar:share-outline"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>

                  <div
                    onClick={() => {
                      navigate("/profile/info");
                    }}
                    className={`rounded-full p-[4px] shadow-customblock`}
                  >
                    <Icon
                      icon="iconamoon:edit-light"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>

                  <div
                    className={`rounded-full p-[4px] shadow-customblock`}
                    onClick={handleOpenModal}
                  >
                    <Icon
                      icon="solar:qr-code-linear"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>
                </div>

                <div className="flex justify-start px-[24px]">
                  {profileStatusLogo !== "" ? (
                    <div className="flex space-x-[10px] items-center">
                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] rounded-[5px]"
                      />

                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                          {status}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="">
                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[19px] text-center font-semibold">
                          {status}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {bio !== "" && (
                  <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] font-light text-start px-[24px]">
                    {utils.splitText(bio)}
                  </div>
                )}

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    accType={"minimalist"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                    lineProfileNameCount={lineProfileNameCount}
                    isEdit={false}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            </div>
          )}
        </div>
      )}

      {page === "ProfilePreview" && (
        <div className="">
          {profileType === "Classic" && (
            <div className="">
              <div className="flex flex-col items-center">
                {profileCover ? (
                  <div className="relative w-full">
                    <div className="relative">
                      <img
                        src={profileCover}
                        alt="Background"
                        className="w-full h-full object-cover"
                      />

                      <div className="absolute -bottom-[58px] left-1/2 transform -translate-x-1/2">
                        {/* <div className="absolute -bottom-[58px] px-[24px]"> */}
                        <div className="flex space-x-[26px] items-center">
                          <div
                            className={`rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0`}
                            onClick={handleOpenModal}
                          >
                            <Icon
                              icon="solar:qr-code-linear"
                              className="w-[24px] h-[24px] text-[#161616]"
                            />
                          </div>

                          <div
                            className="w-[116px] h-[116px] border-4 border-white rounded-full overflow-hidden shadow-customblock flex-shrink-0"
                            onClick={openFunc}
                          >
                            {avatar ? (
                              <img
                                src={avatar}
                                alt="Avatar"
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <img
                                src="/images/avatar.svg"
                                alt="avatar.svg"
                                className="w-full h-full bg-white"
                              />
                            )}
                          </div>

                          <div
                            onClick={() => {
                              handleShare(`https://hubbbox.com/${username}`);
                            }}
                            className="rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0"
                          >
                            <Icon
                              icon="solar:share-outline"
                              className="w-[24px] h-[24px] text-[#161616]"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-[54px]"></div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <div className="pt-[38px]"></div>

                    <div className="flex justify-center">
                      <div className="flex space-x-[26px] items-center">
                        <div
                          className={`rounded-full p-[6px] shadow-customblock bg-white flex-shrink-0`}
                          onClick={handleOpenModal}
                        >
                          <Icon
                            icon="solar:qr-code-linear"
                            className="w-[24px] h-[24px] text-[#161616]"
                          />
                        </div>

                        {!avatar ? (
                          <div className="rounded-full w-[110px] h-[110px] shadow-customblock flex-shrink-0">
                            <img
                              src="/images/avatar.svg"
                              alt="avatar.svg"
                              className="w-full h-full"
                            />
                          </div>
                        ) : (
                          <div onClick={openFunc}>
                            <div className="border-[6px] border-white rounded-full shadow-customblock flex-shrink-0">
                              <Avatar
                                src={avatar}
                                alt="avatar"
                                className="w-[110px] h-[110px]"
                              />
                            </div>
                          </div>
                        )}

                        <div
                          onClick={() => {
                            handleShare(`https://hubbbox.com/${username}`);
                          }}
                          className="rounded-full p-[6px] shadow-customblock flex-shrink-0"
                        >
                          <Icon
                            icon="solar:share-outline"
                            className="w-[24px] h-[24px] text-[#161616]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px] px-[24px]">
                  <span className="text-[#8391A1] text-[15px]">
                    @{username}
                  </span>
                </div>

                {profileName === "" ? (
                  <div className="mt-[10px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px] px-[24px]"></div>
                ) : (
                  <div className="mt-[10px] text-[#161616] text-[30px] leading-[33.6px] text-center font-semibold px-[24px]">
                    {profileName}
                  </div>
                )}

                <div className="mt-[15px] flex justify-center items-center">
                  {profileStatusLogo !== "" ? (
                    <div className="flex space-x-[10px] items-center px-[24px]">
                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] rounded-[5px]"
                      />

                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[20px] text-center font-semibold">
                          {status}
                        </div>
                      )}

                      <img
                        src={profileStatusLogo}
                        alt="profileStatusLogo"
                        className="w-[24px] h-[24px] invisible"
                      />
                    </div>
                  ) : (
                    <div className="">
                      {status !== "" && (
                        <div className="text-[#161616] text-[16px] leading-[20px] text-center font-semibold px-[24px]">
                          {status}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {bio !== "" && (
                  <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] text-center font-light px-[24px]">
                    {utils.splitText(bio)}
                  </div>
                )}

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    page={"ProfilePreview"}
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    isEdit={false}
                    accType={"classical"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                    senderFunc={(newVal: string) => {
                      sendAnalyticsFunc(dispatch, "", {
                        username: username,
                        blockId: "",
                        type: "social_links",
                        body: newVal,
                      });
                    }}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            </div>
          )}

          {profileType === "Minimalist" && (
            <div className="">
              {profileCover ? (
                <div className="relative w-full">
                  <div className="relative">
                    <img
                      src={profileCover}
                      alt="Background"
                      className="w-full h-full mb-[15px] object-cover"
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-[20px]"></div>
              )}

              <div className="px-[24px]">
                {!avatar ? (
                  <div className="float-left mr-[15px]">
                    <img
                      src="/images/avatar.svg"
                      alt="avatar.svg"
                      className="w-[65px] h-[65px]"
                    />
                  </div>
                ) : (
                  <div className="float-left mr-[15px]" onClick={openFunc}>
                    <Avatar
                      src={avatar}
                      alt="avatar"
                      className="w-[65px] h-[65px]"
                    />
                  </div>
                )}
              </div>

              <div className="ml-[80px]">
                {profileName === "" ? (
                  <div className="pt-[12px] bg-[#F7F8F9] w-[220px] h-[24px] rounded-[20px]"></div>
                ) : (
                  <div
                    className={`font-semibold text-[#161616] text-[24px] pt-[10px] leading-[12px] text-start`}
                  >
                    {profileName}
                  </div>
                )}
              </div>

              <div className="mt-[12px] mb-[15px] flex space-x-[10px] items-center">
                <div className="text-[15px] text-[#8391A1] ">@{username}</div>

                <div
                  onClick={() => {
                    handleShare(`https://hubbbox.com/${username}`);
                  }}
                  className="rounded-full p-[4px] shadow-customblock"
                >
                  <Icon
                    icon="solar:share-outline"
                    className="w-[16px] h-[16px] text-[#161616]"
                  />
                </div>

                <div
                  className={`rounded-full p-[4px] shadow-customblock`}
                  onClick={handleOpenModal}
                >
                  <Icon
                    icon="solar:qr-code-linear"
                    className="w-[16px] h-[16px] text-[#161616]"
                  />
                </div>
              </div>

              <div className="flex justify-start px-[24px]">
                {profileStatusLogo !== "" ? (
                  <div className="flex space-x-[10px] items-center">
                    <img
                      src={profileStatusLogo}
                      alt="profileStatusLogo"
                      className="w-[24px] h-[24px] rounded-[5px]"
                    />

                    {status !== "" && (
                      <div className="text-[#161616] text-[16px] leading-[20px] text-center font-semibold">
                        {status}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="">
                    {status !== "" && (
                      <div className="text-[#161616] text-[16px] leading-[20px] text-center font-semibold">
                        {status}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {bio !== "" && (
                <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] font-light text-start px-[24px]">
                  {utils.splitText(bio)}
                </div>
              )}

              <div className="mt-[20px] px-[24px]">
                <SocialLinks
                  page={"ProfilePreview"}
                  listOfSocialLinks={listOfSelectedSocialMediaLinks}
                  iconDesign={iconDesign}
                  accType={"minimalist"}
                  username={username}
                  isPreview={isPreview}
                  handleCopy={handleCopy}
                  lineProfileNameCount={lineProfileNameCount}
                  isEdit={false}
                  senderFunc={(newVal: string) => {
                    sendAnalyticsFunc(dispatch, "", {
                      username: username,
                      blockId: "",
                      type: "social_links",
                      body: newVal,
                    });
                  }}
                />
              </div>

              <div className="-mb-[10px]"></div>
            </div>
          )}
        </div>
      )}

      {/* {page === "Profile" && (
        <div className="">
          {profileType === "Classic" && (
            // <div className="flex justify-center">
            <div className="">
              <div className="flex flex-col items-center">

                {profileCover ? (
                  <div className="relative w-full">
                    <div className="relative">
                      <img
                        src={profileCover}
                        alt="Background"
                        className="w-full h-full object-cover"
                      />

                      {page === "Profile" && (
                        <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
                          <Menu>
                            <MenuHandler>
                              <div className="flex justify-end items-center">
                                <img
                                  src="/images/profile/dots.svg"
                                  alt="menu_dots"
                                  className="w-[18px] h-[4px] mx-[6px] my-[13px] rounded-[1px]"
                                />
                              </div>
                            </MenuHandler>
                            <MenuList
                              className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                            >
                              <MenuItem
                                onClick={() => {
                                  window.open(
                                    `https://www.hubbbox.com/${username}`,
                                    "_blank"
                                  );
                                  // navigate("/profile/preview");
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>
                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem
                                onClick={() => {
                                  handleCopy(
                                    `https://www.hubbbox.com/${username}`
                                  );
                                }}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("copy_link")}
                                  </div>
                                  <img
                                    src="/images/profile/copy_link.svg"
                                    alt="copy_link.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </button>
                      )}

                      <div className="absolute -bottom-[58px] left-1/2 transform -translate-x-1/2">
                        <div className="flex space-x-[26px] items-center">
                          <div className="flex space-x-[10px] items-center">
                            <div
                              className={`rounded-full p-[6px] shadow-custom bg-white ${page === "Profile" ? "" : "invisible"}`}
                              onClick={() => {
                                navigate("/profile/info");
                              }}
                            >
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[24px] h-[24px] text-[#161616]"
                              />
                            </div>

                            <div
                              className={`rounded-full p-[6px] shadow-custom bg-white flex-shrink-0 ${page === "Profile" ? "" : "invisible"}`}
                              onClick={() => {
                                navigate("/profile/info");
                              }}
                            >
                              <img
                                src="/images/profile/qr_code.svg"
                                alt="qr_code.svg"
                                className=""
                              />
                            </div>
                          </div>

                          <div
                            className="w-[116px] h-[116px] border-4 border-white rounded-full overflow-hidden shadow-custom flex-shrink-0"
                            onClick={openFunc}
                          >
                            {avatar ? (
                              <img
                                src={avatar}
                                alt="Avatar"
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <img
                                src="/images/avatar.svg"
                                alt="avatar.svg"
                                className="w-full h-full bg-white"
                              />
                            )}
                          </div>

                          <div>
                            {isPreview ? (
                              <Menu>
                                <MenuHandler>
                                  <div className="rounded-full p-[6px] shadow-custom bg-white">
                                    <Icon
                                      icon="solar:share-outline"
                                      className="w-[24px] h-[24px] text-[#161616]"
                                    />
                                  </div>
                                </MenuHandler>
                                <MenuList
                                  className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleCopy(
                                        `https://www.hubbbox.com/${username}`
                                      );
                                    }}
                                  >
                                    <div className="flex justify-between">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Copy profile link
                                      </div>
                                      <img
                                        src="/images/profile_preview/copy_profile_link.svg"
                                        alt="copy_profile_link.svg"
                                        className="w-[24px] h-[24px]"
                                      />
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleCopy(username);
                                    }}
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Copy username
                                      </div>
                                      <img
                                        src="/images/profile_preview/copy_username.svg"
                                        alt="copy_username.svg"
                                        className="w-[24px] h-[24px]"
                                      />
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      navigate("/auth/registration");
                                    }}
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Create your account
                                      </div>
                                      <img
                                        src="/images/profile_preview/create_your_account.svg"
                                        alt="create_your_account.svg"
                                        className="w-[24px] h-[24px] ml-[20px]"
                                      />
                                    </div>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            ) : (
                              <div className="flex space-x-[10px] items-center">
                                <div
                                  onClick={() => {
                                    handleShare(
                                      `https://hubbbox.com/${username}`
                                    );
                                  }}
                                  className="rounded-full p-[6px] shadow-custom bg-white"
                                >
                                  <Icon
                                    icon="solar:share-outline"
                                    className="w-[24px] h-[24px] text-[#161616]"
                                  />
                                </div>

                                <div
                                  className={`rounded-full p-[6px] shadow-custom bg-white flex-shrink-0 ${page === "Profile" ? "" : "invisible"}`}
                                  onClick={() => {
                                    navigate("/profile/info");
                                  }}
                                >
                                  <img
                                    src="/images/profile/qr_code.svg"
                                    alt="qr_code.svg"
                                    className=""
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-[54px]"></div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    {page === "Profile" && (
                      // <div className="flex justify-between items-center">
                      //   <div className="text-[#F5F5F5] invisible">
                      //     aallasdasdfsfgsdfkgnsjdnfgjsdnfjgnsddd
                      //   </div>
                      <Menu>
                        <MenuHandler>
                          <div className="flex justify-between items-center px-[24px]">
                            <div className="invisible">
                              <img
                                src="/images/profile/dots.svg"
                                alt="menu_dots"
                                className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                              />
                            </div>
                            <img
                              src="/images/profile/dots.svg"
                              alt="menu_dots"
                              className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                            />
                          </div>
                        </MenuHandler>
                        <MenuList
                          className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                        >
                          <MenuItem
                            onClick={() => {
                              window.open(
                                `https://www.hubbbox.com/${username}`,
                                "_blank"
                              );
                              // navigate("/profile/preview");
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("preview")}
                              </div>
                              <img
                                src="/images/profile/preview.svg"
                                alt="preview.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                          <hr className="border-gray-300" />
                          <MenuItem
                            onClick={() => {
                              handleCopy(`https://www.hubbbox.com/${username}`);
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("copy_link")}
                              </div>
                              <img
                                src="/images/profile/copy_link.svg"
                                alt="copy_link.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      // </div>
                    )}

                    {page === "ProfilePreview" && (
                      <div className="pt-[38px]"></div>
                    )}

                    <div className="flex justify-center">
                      <div className="flex space-x-[26px] items-center">
                        <div className={page === "Profile" ? "" : "invisible"}>
                          <div className="flex space-x-[10px] items-center">
                            <div
                              className="rounded-full p-[6px] shadow-custom"
                              onClick={() => {
                                navigate("/profile/info");
                              }}
                            >
                              <Icon
                                icon="iconamoon:edit-light"
                                className="w-[24px] h-[24px] text-[#161616]"
                              />
                            </div>

                            <div
                              className={`rounded-full p-[6px] shadow-custom bg-white ${page === "Profile" ? "" : "invisible"}`}
                              onClick={() => {
                                navigate("/profile/info");
                              }}
                            >
                              <img
                                src="/images/profile/qr_code.svg"
                                alt="qr_code.svg"
                                className=""
                              />
                            </div>
                          </div>
                        </div>

                        {!avatar ? (
                          // <div className="border-white rounded-full shadow-custom">
                          //   <Icon
                          //     icon="carbon:user-avatar-filled"
                          //     className="w-[110px] h-[110px] text-gray-300"
                          //   />
                          // </div>
                          isEdit ? (
                            <div className="relative" onClick={openFunc}>
                              <div className="rounded-full w-[110px] h-[110px] shadow-custom m-[5px]">
                                <img
                                  src="/images/avatar.svg"
                                  alt="avatar.svg"
                                  className="w-full h-full"
                                />
                              </div>

                              <div className="absolute bottom-0 right-0">
                                <img
                                  src="/images/profile/edit_avatar.svg"
                                  alt="edit_avatar.svg"
                                  className="w-[46px] h-[46px]"
                                  // onClick={openFunc}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-full w-[110px] h-[110px] shadow-custom">
                              <img
                                src="/images/avatar.svg"
                                alt="avatar.svg"
                                className="w-full h-full"
                              />
                            </div>
                          )
                        ) : (
                          // <div className="border-[6px] border-white rounded-full shadow-custom">
                          //   <Avatar
                          //     src={avatar}
                          //     alt="avatar"
                          //     className="w-[110px] h-[110px]"
                          //   />
                          // </div>
                          <div onClick={openFunc}>
                            <div className="border-[6px] border-white rounded-full shadow-custom">
                              <Avatar
                                src={avatar}
                                alt="avatar"
                                className="w-[110px] h-[110px]"
                              />
                            </div>
                          </div>
                        )}

                        <div className="flex space-x-[10px] items-center">
                          {isPreview ? (
                            <div>
                              <Menu>
                                <MenuHandler>
                                  <div className="rounded-full p-[6px] shadow-custom">
                                    <Icon
                                      icon="solar:share-outline"
                                      className="w-[24px] h-[24px] text-[#161616]"
                                    />
                                  </div>
                                </MenuHandler>
                                <MenuList
                                  className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleCopy(
                                        `https://www.hubbbox.com/${username}`
                                      );
                                    }}
                                  >
                                    <div className="flex justify-between">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Copy profile link
                                      </div>
                                      <img
                                        src="/images/profile_preview/copy_profile_link.svg"
                                        alt="copy_profile_link.svg"
                                        className="w-[24px] h-[24px]"
                                      />
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleCopy(username);
                                    }}
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Copy username
                                      </div>
                                      <img
                                        src="/images/profile_preview/copy_username.svg"
                                        alt="copy_username.svg"
                                        className="w-[24px] h-[24px]"
                                      />
                                    </div>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      navigate("/auth/registration");
                                    }}
                                  >
                                    <div className="flex justify-between items-center">
                                      <div className="text-[#161616] text-[16px] leading-[22px]">
                                        Create your account
                                      </div>
                                      <img
                                        src="/images/profile_preview/create_your_account.svg"
                                        alt="create_your_account.svg"
                                        className="w-[24px] h-[24px] ml-[20px]"
                                      />
                                    </div>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                handleShare(`https://hubbbox.com/${username}`);
                              }}
                              className="rounded-full p-[6px] shadow-custom"
                            >
                              <Icon
                                icon="solar:share-outline"
                                className="w-[24px] h-[24px] text-[#161616]"
                              />
                            </div>
                          )}

                          <div
                            className={`rounded-full p-[6px] shadow-custom bg-white invisible`}
                          >
                            <img
                              src="/images/profile/qr_code.svg"
                              alt="qr_code.svg"
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px] px-[24px]">
                  <span className="text-[#8391A1] text-[15px] font-medium">
                    @{username}
                  </span>
                </div>

                {profileName === "" ? (
                  <div className="mt-[10px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px] px-[24px]"></div>
                ) : (
                  <div className="mt-[10px] text-[#161616] text-[30px] leading-[33.6px] text-center font-semibold px-[24px]">
                    {profileName}
                  </div>
                )}

                {status === "" ? (
                  isEdit && (
                    <div className="mt-[15px] bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px] px-[24px]"></div>
                  )
                ) : (
                  <div className="mt-[15px] text-[#161616] text-[17px] leading-[19px] text-center font-semibold px-[24px]">
                    {status}
                  </div>
                )}

                {bio === "" ? (
                  isEdit && (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px] px-[24px]"></div>
                  )
                ) : (
                  <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] text-center px-[24px]">
                    {utils.splitText(bio)}
                  </div>
                )}

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    isEdit={isEdit}
                    accType={"classical"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            </div>
          )}

          {profileType === "Minimalist" && (
            <div className="">
              <div>
                {profileCover ? (
                  <div className="relative w-full">
                    <div className="relative">
                      <img
                        src={profileCover}
                        alt="Background"
                        className="w-full h-full mb-[15px] object-cover"
                      />

                      {page === "Profile" && (
                        <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
                          <Menu>
                            <MenuHandler>
                              <div className="flex justify-end items-center">
                                <img
                                  src="/images/profile/dots.svg"
                                  alt="menu_dots"
                                  className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                                />
                              </div>
                            </MenuHandler>
                            <MenuList
                              className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                            >
                              <MenuItem
                                // className="border-b-[1px] border-[#161616]"
                                onClick={() => {
                                  window.open(
                                    `https://www.hubbbox.com/${username}`,
                                    "_blank"
                                  );
                                  // navigate("/profile/preview");
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>
                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem
                                onClick={() => {
                                  handleCopy(
                                    `https://www.hubbbox.com/${username}`
                                  );
                                }}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("copy_link")}
                                  </div>
                                  <img
                                    src="/images/profile/copy_link.svg"
                                    alt="copy_link.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {page === "Profile" && (
                      // <div className="flex justify-between items-center">
                      //   <div className="text-[#F5F5F5] invisible">
                      //     aallasdasdfsfgsdfkgnsjdnfgjsdnfjgnsddd
                      //   </div>
                      <Menu>
                        <MenuHandler>
                          <div className="flex justify-between items-center px-[24px]">
                            <div className="invisible">
                              <img
                                src="/images/profile/dots.svg"
                                alt="menu_dots"
                                className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                              />
                            </div>
                            <img
                              src="/images/profile/dots.svg"
                              alt="menu_dots"
                              className="w-[18px] h-[4px] mx-[5px] my-[10px] rounded-[7px]"
                            />
                          </div>
                        </MenuHandler>
                        <MenuList
                          className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                        >
                          <MenuItem
                            onClick={() => {
                              window.open(
                                `https://www.hubbbox.com/${username}`,
                                "_blank"
                              );
                              // navigate("/profile/preview");
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("preview")}
                              </div>
                              <img
                                src="/images/profile/preview.svg"
                                alt="preview.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                          <hr className="border-gray-300" />
                          <MenuItem
                            onClick={() => {
                              handleCopy(`https://www.hubbbox.com/${username}`);
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                {t("copy_link")}
                              </div>
                              <img
                                src="/images/profile/copy_link.svg"
                                alt="copy_link.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      // </div>
                    )}
                  </div>
                )}

                {page === "ProfilePreview" && !profileCover && (
                  <div className="pt-[29px]"></div>
                )}

                <div className="px-[24px]">
                  {!avatar ? (
                    <div className="float-left mr-[15px]">
                      {isEdit ? (
                        <div className="relative" onClick={openFunc}>
                          <div className="">
                            <img
                              src="/images/avatar.svg"
                              alt="avatar.svg"
                              className="w-[65px] h-[65px] m-[5px]"
                            />
                          </div>

                          <div className="absolute bottom-0 right-0">
                            <img
                              src="/images/profile/edit_avatar.svg"
                              alt="edit_avatar.svg"
                              className="w-[25px] h-[25px]"
                              // onClick={openFunc}
                            />
                          </div>
                        </div>
                      ) : (
                        <img
                          src="/images/avatar.svg"
                          alt="avatar.svg"
                          className="w-[65px] h-[65px]"
                        />
                      )}
                    </div>
                  ) : (
                    <div className="float-left mr-[15px]" onClick={openFunc}>
                      <Avatar
                        src={avatar}
                        alt="avatar"
                        className="w-[65px] h-[65px]"
                      />
                    </div>
                  )}
                </div>

                <div className="ml-[80px]">
                  {profileName === "" ? (
                    <div className="pt-[12px] bg-[#F7F8F9] w-[220px] h-[24px] rounded-[20px]"></div>
                  ) : (
                    <div
                      className={`font-semibold text-[#161616] text-[24px] pt-[10px] leading-[12px] text-start`}
                    >
                      {profileName}
                    </div>
                  )}
                </div>

                <div className="mt-[12px] mb-[15px] flex space-x-[10px] items-center">
                  <div className="text-[15px] text-[#8391A1] ">@{username}</div>

                  {isPreview ? (
                    <div>
                      <Menu>
                        <MenuHandler>
                          <div className="rounded-full p-[4px] shadow-custom">
                            <Icon
                              icon="solar:share-outline"
                              className="w-[16px] h-[16px] text-[#161616]"
                            />
                          </div>
                        </MenuHandler>
                        <MenuList
                          className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
                        >
                          <MenuItem
                            onClick={() => {
                              handleCopy(`https://www.hubbbox.com/${username}`);
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                Copy profile link
                              </div>
                              <img
                                src="/images/profile_preview/copy_profile_link.svg"
                                alt="copy_profile_link.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCopy(username);
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                Copy username
                              </div>
                              <img
                                src="/images/profile_preview/copy_username.svg"
                                alt="copy_username.svg"
                                className="w-[24px] h-[24px]"
                              />
                            </div>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate("/auth/registration");
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <div className="text-[#161616] text-[16px] leading-[22px]">
                                Create your account
                              </div>
                              <img
                                src="/images/profile_preview/create_your_account.svg"
                                alt="create_your_account.svg"
                                className="w-[24px] h-[24px] ml-[20px]"
                              />
                            </div>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        handleShare(`https://hubbbox.com/${username}`);
                      }}
                      className="rounded-full p-[4px] shadow-custom"
                    >
                      <Icon
                        icon="solar:share-outline"
                        className="w-[16px] h-[16px] text-[#161616]"
                      />
                    </div>
                  )}

                  <div
                    onClick={() => {
                      navigate("/profile/info");
                    }}
                    className={`rounded-full p-[4px] shadow-custom ${page === "Profile" ? "" : "invisible"}`}
                  >
                    <Icon
                      icon="iconamoon:edit-light"
                      className="w-[16px] h-[16px] text-[#161616]"
                    />
                  </div>
                </div>

                {status === "" ? (
                  isEdit && (
                    <div className="bg-[#F7F8F9] w-[140px] h-[19px] rounded-[10px] px-[24px]"></div>
                  )
                ) : (
                  <div className="text-[#161616] text-[17px] leading-[19px] text-start font-semibold px-[24px]">
                    {status}
                  </div>
                )}

                {bio === "" ? (
                  isEdit && (
                    <div className="mt-[10px] bg-[#F7F8F9] w-full h-[19px] rounded-[10px] px-[24px]"></div>
                  )
                ) : (
                  <div className="mt-[10px] text-[#161616] text-[17px] leading-[19px] text-start px-[24px]">
                    {utils.splitText(bio)}
                  </div>
                )}

                <div className="mt-[20px] px-[24px]">
                  <SocialLinks
                    listOfSocialLinks={listOfSelectedSocialMediaLinks}
                    iconDesign={iconDesign}
                    accType={"minimalist"}
                    username={username}
                    isPreview={isPreview}
                    handleCopy={handleCopy}
                    lineProfileNameCount={lineProfileNameCount}
                    isEdit={isEdit}
                  />
                </div>

                <div className="-mb-[10px]"></div>
              </div>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}
