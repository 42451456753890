import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export async function checkTransactionByTokenFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.checkTransactionByToken,
    dispatch,
    `api/payment/check/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  // ?token=2f5e3517eb1b785c1626c9f3d5141730255d295c173b0f213ee9d0b176f430f0&status=failed&uid=ffbb3950-211c-4ad3-b716-bb2c1bb77842
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentTime, setPaymentTime] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [senderName, setSenderName] = useState("");
  const [trackingId, setTrackingId] = useState(0);

  const checkTransactionByTokenStore = useSelector(
    (state: any) => state.checkTransactionByTokenStore
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    console.log("checkTransactionByTokenStore", checkTransactionByTokenStore);
    if (checkTransactionByTokenStore && checkTransactionByTokenStore.data) {
      const response = checkTransactionByTokenStore.data.response;

      setAmount(response.amount);
      setPaymentMethod(response.payment_method);
      setPaymentStatus(response.payment_status);
      setPaymentTime(response.payment_time);
      setPaymentType(response.payment_type);
      setRedirectUrl(response.redirect_url);
      setSenderName(response.sender_name);
      setTrackingId(response.tracking_id);
    }
  }, [checkTransactionByTokenStore]);

  useEffect(() => {
    if (token) {
      checkTransactionByTokenFunc(dispatch, "", { token: token });
    } else {
      navigate("/home");
    }
  }, [token]);

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  if (checkTransactionByTokenStore && checkTransactionByTokenStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={checkTransactionByTokenStore.load} />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[40px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
        {paymentStatus === "successful" ? (
          <div className="">
            <div className="mt-[58px] flex justify-center">
              <img
                src="/images/payment/success_icon.svg"
                alt="success_icon.svg"
                className="w-[72px] h-[72px]"
              />
            </div>

            <div className="mt-[15px]">
              <div className="flex justify-center text-[18px] font-bold text-[#23A26D]">
                {t("payment_success")}
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="flex justify-center text-[28px] font-bold">
                KZT {amount}
              </div>
            </div>

            <div className="mt-[10px] px-[24px]">
              <div className="w-full px-[10px] pt-[10px] pb-[25px] rounded-[16px] shadow-customblock">
                <div className="bg-[#F5F6F7] py-[20px] w-full flex justify-center items-center text-[16px] font-medium rounded-[12px]">
                  {t("payment_details")}
                </div>

                <div className="px-[8px]">
                  <div className="mt-[15px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("transaction_number")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        {trackingId}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("payment_time")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        {paymentTime}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("payment_type")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        {t("premium_subscription_payment")}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("payment_method")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        {paymentMethod}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px] pb-[10px] border-b-[#EDEDED] border-b-[1px] border-dashed">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("sender_name")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        {senderName}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("amount")}
                      </div>
                      <div className="text-[13px] fond-medium">
                        KZT {amount}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[10px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[13px] text-[#707070]">
                        {t("payment_status")}
                      </div>
                      <div className="text-[10px] fond-medium text-[#23A26D] px-[10px] py-[4px] rounded-[23px] bg-[#23A26D] bg-opacity-10">
                        {t("success")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <button
                  onClick={() => {
                    window.open(redirectUrl, "_blank");
                  }}
                  className="w-full h-[50px] border-[1px] border-[#161616] rounded-[10px] flex justify-center items-center text-[16px] font-semibold text-[#161616]"
                >
                  {t("details")}
                </button>
              </div>

              <div className="mt-[10px]">
                <button
                  onClick={() => {
                    navigate("/home");
                  }}
                  className="w-full h-[50px] bg-[#161616] rounded-[10px] flex justify-center items-center text-[16px] font-semibold text-white"
                >
                  {t("done")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="mt-[50px] flex justify-center items-center">
              <img
                src="/images/payment/error.svg"
                alt="error.svg"
                className="w-[312px] h-[272px]"
              />
            </div>

            <div className="mt-[30px]">
              <div className="font-bold text-[#24px] text-[#FF2C20] text-center">
                {t("oops")}
              </div>
            </div>

            <div className="mt-[10px] px-[24px]">
              <div className="text-[#16px] text-[#161616] font-medium text-center">
                {t("payment_check_fail")}
              </div>
            </div>

            <div className="mt-[152px]"></div>

            {redirectUrl !== "" && (
              <div className="px-[24px]">
                <button
                  onClick={() => {
                    window.open(redirectUrl, "_blank");
                  }}
                  className="w-full h-[50px] border-[1px] border-[#161616] rounded-[10px] flex justify-center items-center text-[16px] font-semibold text-[#161616]"
                >
                  {t("details")}
                </button>
              </div>
            )}

            <div className="mt-[10px] px-[24px]">
              <button
                onClick={() => {
                  navigate("/home");
                }}
                className="w-full h-[50px] border-[1px] border-[#161616] rounded-[10px] flex justify-center items-center text-[16px] font-semibold text-[#161616]"
              >
                {t("home")}
              </button>
            </div>

            <div className="mt-[10px] px-[24px]">
              <button
                onClick={() => {
                  navigate("/pricing");
                }}
                className="w-full h-[50px] bg-[#161616] rounded-[10px] flex justify-center items-center text-[16px] font-semibold text-white"
              >
                {t("try_again")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
