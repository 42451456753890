import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Switch,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate, Link } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import Newsletter from "../../components/ui/Profile/Newsletter";
import UrlLinks from "../../components/ui/Profile/UrlLinks";
import Gallery from "../../components/ui/Profile/Gallery";
import UrlGalleryImage from "../../components/ui/Profile/UrlGalleryImage";
import YoutubeVideo from "../../components/ui/Profile/YoutubeVideo";
import BigBlock from "../../components/ui/Profile/BigBlock";
import SmallBlock from "../../components/ui/Profile/SmallBlock";
import TextBlock from "../../components/ui/Profile/TextBlock";
import SideSlider from "../../components/ui/Profile/SideSlider";
import * as utils from "../../components/utils";
import ErrorPage from "../../components/ui/ErrorPage";
import "react-quill/dist/quill.snow.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import SocialLinks from "../../components/ui/SocialLinks";
import ProfileType from "../../components/ui/ProfileType";
import ProductSlider from "../../components/ui/ProductSlider";
import SlickSlider from "../../components/ui/SlickSlider";
import { useTranslation } from "react-i18next";
import SubscriptionBottomWindow from "../../components/ui/SubscriptionBottomWindow";

export async function getProfileDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getProfileData,
    dispatch,
    `api/profile/data/`,
    "get",
    "",
    form,
    true
  )();
}

export async function profileInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.profileInfo,
    dispatch,
    `api/profile/info/`,
    "get",
    "",
    form,
    true
  )();
}

export async function blockDeleteFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.blockDelete,
    dispatch,
    `api/block/delete/`,
    "post",
    "",
    form,
    true
  )();
}

export async function blockDeactivateFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.blockDeactivate,
    dispatch,
    `api/block/deactivate/`,
    "post",
    "",
    form,
    true
  )();
}

export async function moveElemInBlockFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.moveElemInBlockList,
    dispatch,
    `api/block/move/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [profileName, setProfileName] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [profileStatusLogo, setProfileStatusLogo] = useState("");
  const [bio, setBio] = useState("");
  const [avatar, setAvatar] = useState("");
  const [profileCover, setProfileCover] = useState("");
  const [listOfSocialMediaLinks, setListOfSocialMediaLinks] = useState([]);
  const [profileType, setProfileType] = useState("Classic");
  const [listOfSelectedSocialMediaLinks, setListOfSelectedSocialMediaLinks] =
    useState([]);
  const [planStatus, setPlanStatus] = useState("");

  const [data, setData] = useState([]);
  const [profileBlogData, setProfileBlogData] = useState([]);
  const [pageStatus, setPageStatus] = useState("blocks");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const [deletedBlockNum, setDeletedBlockNum] = useState({});
  const [deactivatedBlockNum, setDeactivatedBlockNum] = useState({});

  const [isOpenSubscriptionBottomWindow, setIsOpenSubscriptionBottomWindow] =
    useState(false);

  const toggleSubscriptionBottomWindow = () => {
    setIsOpenSubscriptionBottomWindow(!isOpenSubscriptionBottomWindow);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getProfileDataStore = useSelector(
    (state: any) => state.getProfileDataStore
  );

  const blockDeleteStore = useSelector((state: any) => state.blockDeleteStore);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const handleBlockDelete = (blockNum: number) => {
    blockDeleteFunc(dispatch, "", {
      blockNum: blockNum,
    });
    setDeletedBlockNum({ blockNum: blockNum });
  };

  useEffect(() => {
    // console.log("sectionDeleteStore", sectionDeleteStore);
    if (blockDeleteStore && blockDeleteStore.data) {
      getProfileDataFunc(dispatch, "", {});
      // // @ts-ignore
      // const blockNum = deletedBlockNum.blockNum;

      // const newData = data.filter(
      //   // @ts-ignore
      //   (obj) => obj.block_numeration !== blockNum
      // );

      // setData(newData);
    }
  }, [blockDeleteStore]);

  const blockDeactivateStore = useSelector(
    (state: any) => state.blockDeactivateStore
  );

  const handleBlockDeactivate = (blockNum: number) => {
    blockDeactivateFunc(dispatch, "", {
      blockNum: blockNum,
    });
    setDeactivatedBlockNum({ blockNum: blockNum });
  };

  useEffect(() => {
    // console.log("sectionDeleteStore", sectionDeleteStore);
    if (blockDeactivateStore && blockDeactivateStore.data) {
      getProfileDataFunc(dispatch, "", {});
      // // @ts-ignore
      // const blockNum = deactivatedBlockNum.blockNum;

      // const newData = structuredClone(data);

      // for (let i = 0; i < newData.length; i++) {
      //   // @ts-ignore
      //   if (newData[i].block_numeration === blockNum) {
      //     //@ts-ignore
      //     newData[i].deactivated = !newData[i].deactivated;
      //   }
      // }

      // setData(newData);
    }
  }, [blockDeactivateStore]);

  useEffect(() => {
    // profileInfoFunc(dispatch, "", {});
    getProfileDataFunc(dispatch, "", {});
    dispatch({ type: constants.sendInfoAboutNewBlock.reset });
    dispatch({ type: constants.sendInfoAboutEditBlock.reset });
    dispatch({ type: constants.getBlockInfo.reset });

    utils.LocalStorage.remove("CreateProductData");
    utils.LocalStorage.remove("DesignEditData");
    utils.LocalStorage.remove("NewCollaborationData");
    utils.LocalStorage.remove("EditProductData");
    utils.LocalStorage.remove("PersonalInformationEditData");
    utils.LocalStorage.remove("CreateBlockData");
    utils.LocalStorage.remove("EditBlockData");
    utils.LocalStorage.remove("AccountLaunchData");
    utils.LocalStorage.remove("MakeCollaborationData");
    utils.LocalStorage.remove("CreatePostData");
    utils.LocalStorage.remove("EditPostData");
  }, []);

  useEffect(() => {
    // console.log("getProfileDataStore", getProfileDataStore);
    if (getProfileDataStore && getProfileDataStore.data) {
      if (getProfileDataStore.data.response.length !== 0) {
        const response = getProfileDataStore.data.response;

        setData(response.data);
        setProfileBlogData(response.personal_blog);
        setProfileName(response.profile_name);
        setUsername(response.username);
        setBio(response.bio);
        setAvatar(response.avatar);
        setStatus(response.status);
        setProfileStatusLogo(response.profile_status_logo);
        setProfileCover(response.profile_cover);
        setProfileType(response.profile_type);
        setListOfSocialMediaLinks(response.list_of_social_media_links);
        setPlanStatus(response.plan);
      }
    }
  }, [getProfileDataStore]);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [blockDesign, setBlockDesign] = useState("first");
  const [iconDesign, setIconDesign] = useState("monochrome");

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
      setIconDesign(profileInfoStore.data.response.design_icons);
    }
  }, [profileInfoStore]);

  const moveElemInBlockListStore = useSelector(
    (state: any) => state.moveElemInBlockListStore
  );

  const isFirstListElem = (blockNum: number) => {
    // @ts-ignore
    if (blockNum === data[0].block_numeration) {
      return true;
    } else {
      return false;
    }
  };

  const isLastListElem = (blockNum: number) => {
    // @ts-ignore
    if (blockNum === data[data.length - 1].block_numeration) {
      return true;
    } else {
      return false;
    }
  };

  const handleMoveListElem = (blockNum: number, action: string) => {
    const newData = structuredClone(data);

    // Находим индекс элемента с block_num
    const index = newData.findIndex(
      //@ts-ignore
      (item) => item.block_numeration === blockNum
    );

    if (action === "move_up") {
      if (index === -1 || index === 0) {
        return;
      }

      // Обновляем block_num и перемещаем элемент вверх
      //@ts-ignore
      const tempBlockNum = newData[index].block_numeration;

      //@ts-ignore
      const numFrom = newData[index - 1].block_numeration;
      moveElemInBlockFunc(dispatch, "", {
        numFrom: numFrom,
        numTo: blockNum,
        action: action,
      });

      //@ts-ignore
      newData[index].block_numeration = newData[index - 1].block_numeration;
      //@ts-ignore
      newData[index - 1].block_numeration = tempBlockNum;

      // Обмениваем местами элементы с индексами index и index - 1
      const temp = newData[index - 1];
      newData[index - 1] = newData[index];
      newData[index] = temp;
    } else if (action === "move_down") {
      if (index === newData.length - 1) {
        return;
      }

      //@ts-ignore
      const tempBlockNum = newData[index].block_numeration;

      //@ts-ignore
      const numFrom = newData[index + 1].block_numeration;
      moveElemInBlockFunc(dispatch, "", {
        numFrom: numFrom,
        numTo: blockNum,
        action: action,
      });

      //@ts-ignore
      newData[index].block_numeration = newData[index + 1].block_numeration;
      //@ts-ignore
      newData[index + 1].block_numeration = tempBlockNum;

      const temp = newData[index + 1];
      newData[index + 1] = newData[index];
      newData[index] = temp;
    } else if (action === "move_to_top") {
      if (index === -1 || index === 0) {
        return;
      }

      moveElemInBlockFunc(dispatch, "", {
        numFrom: -1,
        numTo: blockNum,
        action: action,
      });

      let listOfOldBlockNums = [];

      // Обновляем block_num для всех элементов, чтобы освободить первую позицию
      for (let i = 0; i < newData.length; i++) {
        //@ts-ignore
        listOfOldBlockNums.push(newData[i].block_numeration);
      }

      // Извлечем объект из списка
      let [objectWithBlockNum] = newData.splice(index, 1);
      // Вставим объект на первое место
      newData.unshift(objectWithBlockNum);

      for (let i = 0; i < newData.length; i++) {
        //@ts-ignore
        newData[i].block_numeration = listOfOldBlockNums[i];
      }
    } else if (action === "move_to_bottom") {
      if (index === newData.length - 1) {
        return;
      }

      moveElemInBlockFunc(dispatch, "", {
        numFrom: -1,
        numTo: blockNum,
        action: action,
      });

      let listOfOldBlockNums = [];

      // Обновляем block_num для всех элементов, чтобы освободить первую позицию
      for (let i = 0; i < newData.length; i++) {
        //@ts-ignore
        listOfOldBlockNums.push(newData[i].block_numeration);
      }

      // Извлечем объект из списка
      let [objectWithBlockNum] = newData.splice(index, 1);
      // Вставим объект на первое место
      newData.push(objectWithBlockNum);

      for (let i = 0; i < newData.length; i++) {
        //@ts-ignore
        newData[i].block_numeration = listOfOldBlockNums[i];
      }

      // console.log("newData", newData);
      // console.log("listOfOldBlockNums", listOfOldBlockNums);
    }

    setData(newData);
  };

  useEffect(() => {
    // console.log("data", data);
    dispatch({ type: constants.moveElemInBlockList.reset });
  }, [data]);

  useEffect(() => {
    // console.log("listOfSocialMediaLinks", listOfSocialMediaLinks);
    if (listOfSocialMediaLinks.length > 0) {
      let listOfCheckedSocialMediaLinks = [];

      for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
        if (listOfSocialMediaLinks[i]["checked"]) {
          listOfCheckedSocialMediaLinks.push({
            //@ts-ignore
            ...listOfSocialMediaLinks[i],
            index: i + 1,
          });
        }
      }

      const share_obj = {
        icon: "/images/social_media_icons/share.svg",
      };

      // if (listOfCheckedSocialMediaLinks.length > 0) {
      listOfCheckedSocialMediaLinks.push({
        ...share_obj,
        index: listOfSocialMediaLinks.length + 1,
      });
      // }

      //@ts-ignore
      setListOfSelectedSocialMediaLinks(listOfCheckedSocialMediaLinks);
    }
  }, [listOfSocialMediaLinks]);

  // if (profileInfoStore && profileInfoStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={profileInfoStore.load} />
  //     </div>
  //   );
  // }

  if (blockDeleteStore && blockDeleteStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={blockDeleteStore.load} />
      </div>
    );
  }

  if (blockDeactivateStore && blockDeactivateStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={blockDeactivateStore.load} />
      </div>
    );
  }

  if (getProfileDataStore && getProfileDataStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getProfileDataStore.load} />
      </div>
    );
  }

  if (moveElemInBlockListStore && moveElemInBlockListStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={moveElemInBlockListStore.load} />
      </div>
    );
  }

  if (
    getProfileDataStore &&
    (getProfileDataStore.fail || getProfileDataStore.error)
  ) {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope pb-[80px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          {/* <div className="font-manrope px-[24px] pb-[50px] w-screen max-w-md bg-gray-200 break-words bg-[#FAFAFA]"> */}
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={profileName}
            username={username}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <SubscriptionBottomWindow
            isOpen={isOpenSubscriptionBottomWindow}
            closeWindow={toggleSubscriptionBottomWindow}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/home/profile");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("profile_page_page")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[58px]">
              <ProfileType
                profileName={profileName}
                bio={bio}
                avatar={avatar}
                status={status}
                profileStatusLogo={profileStatusLogo}
                profileCover={profileCover}
                iconDesign={iconDesign}
                listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
                profileType={profileType}
                username={username}
                page={"Profile"}
              />
            </div>

            <div className="mt-[30px]">
              <div className="px-[24px]">
                <div className="flex justify-between items-center">
                  <div
                    className={`flex justify-center items-center w-full pb-[10px] ${pageStatus === "blocks" && "border-b-[2px] border-[#161616]"}`}
                    onClick={() => {
                      setPageStatus("blocks");
                    }}
                  >
                    <img
                      src={`/images/profile/blocks_${pageStatus === "blocks" ? "" : "in"}active.svg`}
                      alt="blocks.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>

                  <div
                    className={`flex justify-center items-center w-full pb-[10px] ${pageStatus === "posts" && "border-b-[2px] border-[#161616]"}`}
                    onClick={() => {
                      setPageStatus("posts");
                    }}
                  >
                    <img
                      src={`/images/profile/posts_${pageStatus === "posts" ? "" : "in"}active.svg`}
                      alt="posts.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>
                </div>
              </div>
            </div>

            {pageStatus === "blocks" && (
              <div className="mt-[30px]">
                {data.length > 0 && (
                  <div>
                    {data.map((elem: any, indexBlock: number) => (
                      <div key={elem.block_numeration}>
                        <div
                          className={
                            (elem.block_type === "product" &&
                              elem.block_layout === "side_slider") ||
                            elem.block_type === "gallery"
                              ? "mt-[15px]"
                              : "mt-[15px] px-[24px]"
                          }
                        >
                          <div
                            className={
                              (elem.block_type === "product" &&
                                elem.block_layout === "side_slider") ||
                              elem.block_type === "gallery"
                                ? "px-[24px]"
                                : ""
                            }
                          >
                            <Menu>
                              <MenuHandler>
                                <div className="flex justify-between items-center">
                                  <div className="pb-[10px] px-[10px]">
                                    <img
                                      src="/images/profile/dots.svg"
                                      alt="menu_dots"
                                      className="w-[18px] h-[4px] invisible"
                                    />
                                  </div>

                                  <div className="pb-[10px] px-[10px]">
                                    <img
                                      src="/images/profile/dots.svg"
                                      alt="menu_dots"
                                      className="w-[18px] h-[4px]"
                                    />
                                  </div>
                                </div>
                              </MenuHandler>
                              <MenuList
                                // className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] overflow-x-hidden font-manrope`}
                                className={`mr-[10px] rounded-[15px] overflow-x-hidden font-manrope`}
                              >
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigate(
                                        `/profile/edit/${elem.block_numeration}`
                                      );
                                    }}
                                  >
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("edit")}
                                    </div>
                                    <img
                                      src="/images/profile/edit.svg"
                                      alt=""
                                      className="w-[24px] h-[24px] flex-shrink-0"
                                    />
                                    {/* <Icon
                                    icon="akar-icons:edit"
                                    className="w-[24px] h-[24px] text-[#8391A1]"
                                  /> */}
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem
                                  disabled={isFirstListElem(
                                    elem.block_numeration
                                  )}
                                  onClick={() => {
                                    handleMoveListElem(
                                      elem.block_numeration,
                                      "move_to_top"
                                    );
                                  }}
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("move_to_top")}
                                    </div>
                                    <img
                                      src="/images/profile/double_top_arrow.svg"
                                      alt=""
                                      className="w-[24px] h-[24px] flex-shrink-0"
                                    />
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem
                                  disabled={isFirstListElem(
                                    elem.block_numeration
                                  )}
                                  onClick={() => {
                                    handleMoveListElem(
                                      elem.block_numeration,
                                      "move_up"
                                    );
                                  }}
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("move_up")}
                                    </div>
                                    <img
                                      src="/images/profile/top_arrow.svg"
                                      alt=""
                                      className="w-[24px] h-[24px] flex-shrink-0"
                                    />
                                    {/* <Icon
                                    icon="uiw:up-circle-o"
                                    className="w-[24px] h-[24px] text-[#8391A1]"
                                  /> */}
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem
                                  disabled={isLastListElem(
                                    elem.block_numeration
                                  )}
                                  onClick={() => {
                                    handleMoveListElem(
                                      elem.block_numeration,
                                      "move_down"
                                    );
                                  }}
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("move_down")}
                                    </div>
                                    <img
                                      src="/images/profile/down_arrow.svg"
                                      alt=""
                                      className="w-[24px] h-[24px] flex-shrink-0"
                                    />
                                    {/* <Icon
                                    icon="uiw:down-circle-o"
                                    className="w-[24px] h-[24px] text-[#8391A1]"
                                  /> */}
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem
                                  // disabled
                                  disabled={isLastListElem(
                                    elem.block_numeration
                                  )}
                                  onClick={() => {
                                    handleMoveListElem(
                                      elem.block_numeration,
                                      "move_to_bottom"
                                    );
                                  }}
                                >
                                  <div className="flex justify-between items-center">
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("move_to_bottom")}
                                    </div>
                                    <img
                                      src="/images/profile/double_down_arrow.svg"
                                      alt=""
                                      className="w-[24px] h-[24px] flex-shrink-0"
                                    />
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={() => {
                                      let countDeactivatedBlock = 0;

                                      for (let i = 0; i < data.length; i++) {
                                        //@ts-ignore
                                        if (data[i].deactivated === false) {
                                          countDeactivatedBlock += 1;
                                        }
                                      }

                                      if (
                                        planStatus === "inactive" &&
                                        countDeactivatedBlock >= 5
                                      ) {
                                        if (elem.deactivated === true) {
                                          toggleSubscriptionBottomWindow();
                                        } else {
                                          handleBlockDeactivate(
                                            elem.block_numeration
                                          );
                                        }
                                      } else {
                                        handleBlockDeactivate(
                                          elem.block_numeration
                                        );
                                      }
                                    }}
                                  >
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("activate")}
                                    </div>
                                    <Switch
                                      crossOrigin={""}
                                      checked={!elem.deactivated}
                                      onChange={(event) => {
                                        let countDeactivatedBlock = 0;

                                        for (let i = 0; i < data.length; i++) {
                                          //@ts-ignore
                                          if (data[i].deactivated === false) {
                                            countDeactivatedBlock += 1;
                                          }
                                        }

                                        if (
                                          planStatus === "inactive" &&
                                          countDeactivatedBlock >= 5
                                        ) {
                                          if (elem.deactivated === true) {
                                            toggleSubscriptionBottomWindow();
                                          } else {
                                            handleBlockDeactivate(
                                              elem.block_numeration
                                            );
                                          }
                                        } else {
                                          handleBlockDeactivate(
                                            elem.block_numeration
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </MenuItem>
                                <hr className="border-gray-300" />
                                <MenuItem>
                                  <div
                                    className="flex justify-between items-center"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleBlockDelete(elem.block_numeration);
                                    }}
                                  >
                                    <div className="text-[#161616] text-[16px] leading-[22px] flex-shrink-0 whitespace-nowrap mr-[10px]">
                                      {t("delete")}
                                    </div>
                                    <img
                                      src="/images/profile/delete.svg"
                                      alt=""
                                      className="w-[24px] h-[24px]"
                                    />
                                    {/* <Icon
                                    icon="fluent:delete-48-regular"
                                    className="w-[24px] h-[24px] text-[#FF2C20]"
                                  /> */}
                                  </div>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </div>
                          <div
                          // className={`mt-[10px] flex ${elem.block_type === "product" && elem.block_layout === "side_slider" ? "justify-start" : "justify-center"}`}
                          >
                            {elem.block_type === "product" &&
                              elem.block_layout === "big_block" && (
                                <BigBlock
                                  product_id={elem.block_data.data[0].id}
                                  product_name={elem.block_data.data[0].name}
                                  description={
                                    elem.block_data.data[0].description
                                  }
                                  buttonName={
                                    elem.block_data.data[0].buttonName
                                  }
                                  image={elem.block_data.data[0].image}
                                  deactivated={elem.deactivated}
                                  blockDesign={blockDesign}
                                />
                              )}

                            {
                              elem.block_type === "product" &&
                                elem.block_layout === "side_slider" &&
                                // <ProductSlider
                                //   listOfSmallBlock={elem.block_data.data}
                                //   username={username}
                                //   isPreview={false}
                                // />
                                (data &&
                                data.length > 0 &&
                                elem.block_numeration ===
                                  //@ts-ignore
                                  data[data.length - 1].block_numeration &&
                                elem.block_layout === "side_slider" ? (
                                  <div className="mb-[35px]">
                                    <SlickSlider
                                      listOfSmallBlock={elem.block_data.data}
                                      username={username}
                                      isPreview={false}
                                      deactivated={elem.deactivated}
                                      blockDesign={blockDesign}
                                    />
                                  </div>
                                ) : (
                                  <SlickSlider
                                    listOfSmallBlock={elem.block_data.data}
                                    username={username}
                                    isPreview={false}
                                    deactivated={elem.deactivated}
                                    blockDesign={blockDesign}
                                  />
                                ))
                              // <div className="flex space-x-2">
                              //   <SmallBlock
                              //     product_name={elem.block_data.data[0].name}
                              //     description={
                              //       elem.block_data.data[0].description
                              //     }
                              //     image={elem.block_data.data[0].image}
                              //   />
                              //   <SmallBlock
                              //     product_name={elem.block_data.data[1].name}
                              //     description={
                              //       elem.block_data.data[1].description
                              //     }
                              //     image={elem.block_data.data[1].image}
                              //   />
                              // </div>
                            }

                            {elem.block_type === "youtube_video" && (
                              // <YoutubeVideo link={elem.block_data.data} />
                              <iframe
                                className={`w-full h-full aspect-video ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${elem.deactivated && "opacity-50"}`}
                                src={elem.block_data.data}
                                title="YouTube Video"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            )}

                            {elem.block_type === "links_url" && (
                              <UrlLinks
                                title={elem.block_data.data.title}
                                description={elem.block_data.data.description}
                                image={elem.image}
                                deactivated={elem.deactivated}
                                blockDesign={blockDesign}
                              />
                            )}

                            {elem.block_type === "pdf_file" && (
                              <UrlLinks
                                title={elem.block_data.data.title}
                                description={elem.block_data.data.description}
                                image={elem.image}
                                deactivated={elem.deactivated}
                                blockDesign={blockDesign}
                              />
                            )}

                            {/* {elem.block_type === "newsletter" && (
                              <Newsletter
                                title={elem.block_data.data.title}
                                deactivated={elem.deactivated}
                              />
                            )} */}

                            {elem.block_type === "gallery" && (
                              <div>
                                {elem.block_layout === "side_slider" ? (
                                  <Gallery
                                    listOfGalleryImages={elem.block_data}
                                    deactivated={elem.deactivated}
                                    blockDesign={blockDesign}
                                  />
                                ) : (
                                  <UrlGalleryImage
                                    listOfGalleryImages={elem.block_data.images}
                                    url={elem.block_data.url}
                                    block_layout={elem.block_layout}
                                    deactivated={elem.deactivated}
                                    blockDesign={blockDesign}
                                  />
                                )}
                              </div>
                            )}

                            {elem.block_type === "text" && (
                              <div
                                className={indexBlock === 0 ? "" : "mt-[40px]"}
                              >
                                <div className="text-start">
                                  <TextBlock
                                    id={elem.block_numeration}
                                    text={elem.block_data.data}
                                    deactivated={elem.deactivated}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="mt-[15px] px-[24px]">
                  <Link to={`create`}>
                    <div
                      className="flex justify-center rounded-[10px] w-full h-[50px] bg-white shadow-customblock"
                      // onClick={(event) => {
                      //   event.preventDefault();
                      //   navigate(`/create`);
                      // }}
                    >
                      <div className="flex space-x-2 items-center p-[16px]">
                        {/* <Icon
                          icon="solar:widget-add-bold"
                          className="w-[24px] h-[24px]"
                        /> */}
                        <img
                          src="/images/profile/add_block.svg"
                          alt="add_block.svg"
                          className="w-[24px] h-[24px]"
                        />
                        <div className="text-[18px] leading-[22px] text-[#161616]">
                          {t("add_block")}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {pageStatus === "posts" && (
              <div className="mt-[30px]">
                <div className="px-[24px]">
                  {profileBlogData.length > 0 &&
                    profileBlogData.map((post: any, indexPost: number) => (
                      <div key={`post-${indexPost}`}>
                        {blockDesign === "first" && (
                          <div
                            className={`mt-[20px] w-full rounded-[15px] p-[15px] shadow-custom ${post.deactivated && "opacity-30"}`}
                          >
                            <div className="flex flex-col">
                              {post.thumbnail === "" ? (
                                <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-[10px]">
                                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-[10px]"></div>
                                </div>
                              ) : (
                                <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                                  <img
                                    src={post.thumbnail}
                                    alt="productImage"
                                    className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                                  />
                                </div>
                              )}

                              <div className="mt-[15px]">
                                <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                                  {post.title}
                                </div>
                              </div>

                              <div className="mt-[5px]">
                                <span className="text-[14px] text-[#8391A1]">
                                  {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                                  {t("reading_time")}: {post.reading_time}{" "}
                                  {t("minutes")}
                                </span>
                              </div>

                              <div className="mt-[5px]">
                                <span className="text-[16px] text-[#161616]">
                                  {post.description}...
                                </span>
                              </div>

                              <div className="mt-[15px]">
                                <button
                                  onClick={() => {
                                    // window.open(
                                    //   `https://www.hubbbox.com/post/${post.id}`,
                                    //   "_blank"
                                    // );
                                  }}
                                  className="w-full h-[47px] flex justify-center items-center bg-[#161616] text-white rounded-[10px]"
                                >
                                  {t("read_more")}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {blockDesign === "third" && (
                          <div
                            onClick={() => {
                              // window.open(
                              //   `https://www.hubbbox.com/post/${post.id}`,
                              //   "_blank"
                              // );
                            }}
                            className={`mt-[20px] w-full rounded-[7px] shadow-custom ${post.deactivated && "opacity-30"}`}
                          >
                            <div className="flex flex-col">
                              {post.thumbnail === "" ? (
                                <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-t-[7px]">
                                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-t-[7px]"></div>
                                </div>
                              ) : (
                                <div className="relative w-full pt-[100%] overflow-hidden rounded-t-[7px]">
                                  <img
                                    src={post.thumbnail}
                                    alt="productImage"
                                    className="absolute inset-0 w-full h-full object-cover object-center rounded-t-[7px]"
                                  />
                                </div>
                              )}

                              <div className="my-[15px] px-[24px]">
                                <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                                  {post.title}
                                </div>

                                <div className="mt-[5px]">
                                  <span className="text-[14px] text-[#8391A1]">
                                    {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                                    {t("reading_time")}: {post.reading_time}{" "}
                                    {t("minutes")}
                                  </span>
                                </div>

                                <div className="mt-[5px]">
                                  <span className="text-[16px] text-[#161616]">
                                    {post.description}...
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                  <div className="mt-[15px]">
                    <button
                      onClick={() => {
                        navigate("/post/create");
                      }}
                      className="h-[50px] w-full flex justify-center items-center rounded-[15px] shadow-customblock"
                    >
                      <div className="flex space-x-[10px] items-center">
                        <img
                          src="/images/profile/posts_active.svg"
                          alt="posts.svg"
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-[#161616] text-[16px]">
                          {t("create_post")}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
