import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
  list,
} from "@material-tailwind/react";

export default function Component({ isOpen, closeWindow }: any) {
  const { t, i18n } = useTranslation();
  const [subscriptionPlan, setSubscriptionPlan] = useState("yearly");

  {
    /* ============================================== BOTTOM WINDOW ============================================== */
  }

  return (
    <div
      className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
    >
      {/* Задний план с затемнением */}
      <div
        className="fixed inset-0 bg-gray-500 opacity-75"
        //   onClick={closeWindow}
      ></div>

      {/* Основное окно */}
      <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px] font-manrope">
        <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
          <div className="flex justify-center mt-[10px]">
            <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
          </div>
          <div className="flex justify-between items-center mt-[23px] px-[24px]">
            <button className="text-[16px] leading-[18px] text-[#8391A1] invisible">
              {t("done")}
            </button>
            <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
              Hubbbox Premium
            </div>
            <button
              className="text-[16px] leading-[18px] text-[#8391A1]"
              onClick={() => {
                // setIsOpen(false);
                closeWindow();
              }}
            >
              {t("done")}
            </button>
          </div>
          <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
        </div>

        <div className="px-[24px] pb-[30px] mt-[80px] break-words">
          <div className="flex flex-col">
            <div className="mt-[20px]">
              <div className="text-[20px] font-extrabold">
                {t("subscription_window_text1")}{" "}
                <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                  Premium
                </span>
                {t("subscription_window_text2")}
              </div>
            </div>

            <div className="mt-[20px]">
              <div className="text-[14px]">
                {t("subscription_window_text3")}
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="text-[16px] font-bold">
                {t("subscription_window_text4")}
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="flex space-x-[10px] items-start">
                <img
                  src="/images/subscription_window/block.svg"
                  alt="block.svg"
                  className="w-[20px] h-[20px]"
                />
                <div className="">
                  <div className="text-[14px] font-bold">
                    {t("subscription_window_text5")}
                  </div>
                  <div className="mt-[10px]">
                    <span className="text-[14px] leading-[16px]">
                      {t("subscription_window_text6")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="flex space-x-[10px] items-start">
                <img
                  src="/images/subscription_window/product.svg"
                  alt="product.svg"
                  className="w-[20px] h-[20px]"
                />
                <div className="">
                  <div className="text-[14px] font-bold">
                    {t("subscription_window_text7")}
                  </div>
                  <div className="mt-[10px]">
                    <span className="text-[14px] leading-[16px]">
                      {t("subscription_window_text8")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="flex space-x-[10px] items-start">
                <img
                  src="/images/subscription_window/pen.svg"
                  alt="pen.svg"
                  className="w-[20px] h-[20px]"
                />
                <div className="">
                  <div className="text-[14px] font-bold">
                    {t("subscription_window_text9")}
                  </div>
                  <div className="mt-[10px]">
                    <span className="text-[14px] leading-[16px]">
                      {t("subscription_window_text10")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="flex space-x-[10px] items-start">
                <img
                  src="/images/subscription_window/palette.svg"
                  alt="palette.svg"
                  className="w-[20px] h-[20px]"
                />
                <div className="">
                  <div className="text-[14px] font-bold">
                    {t("subscription_window_text11")}
                  </div>
                  <div className="mt-[10px]">
                    <span className="text-[14px] leading-[16px]">
                      {t("subscription_window_text12")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <div
                className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
                onClick={() => {
                  setSubscriptionPlan("yearly");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="text-[#161616] text-[20px] font-bold">
                      {t("annual")}{" "}
                      <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                        -30%
                      </span>
                    </div>
                    <div className="text-[#8391A1] text-[12px]">
                      {t("annual_text1")}
                      &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                      {t("annual_text2")}
                    </div>
                  </div>

                  <Radio
                    name="subscription_plan"
                    crossOrigin={""}
                    checked={subscriptionPlan === "yearly"}
                    onChange={() => {
                      setSubscriptionPlan("yearly");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[10px]">
              <div
                className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
                onClick={() => {
                  setSubscriptionPlan("monthly");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <div className="text-[#161616] text-[20px] font-bold">
                      {t("monthly_pricing")}
                    </div>
                    <div className="text-[#8391A1] text-[12px]">
                      {t("monthly_text")}
                    </div>
                  </div>

                  <Radio
                    name="subscription_plan"
                    crossOrigin={""}
                    checked={subscriptionPlan === "monthly"}
                    onChange={() => {
                      setSubscriptionPlan("monthly");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <button
                className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
                onClick={() => {
                  //   handleClickProfileParameters(plan);
                }}
              >
                <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
                <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
                <span className="relative">{t("get_premium_plan")}</span>
              </button>
            </div>
          </div>
          {/* WINDOW END */}
        </div>
      </div>
    </div>
  );
}
