import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as utils from "../../utils";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import CustomRadioButton from "../CreateBlock/CustomRadioButton";
import NumberInput from "../NumberInput";
import { useTranslation } from "react-i18next";
// import Menu from "../Menu";

export default function Component({
  burgerMenuOpen,
  toggleBurgerMenu,
  selectedProductType,
  bundleListOfProducts,
  searchItemInListOfProducts,
  deleteItemInBundleListOfProducts,
  setDescriptionToItemInBundleListOfProducts,
  addItemToBundleListOfProducts,
  setItemToBundleListOfProducts,
  searchIndexBundleListOfProducts,
  bundlePrice,
  handleChangeBundlePrice,
  status,
  handleChangeStatus,
  handleChangeStage,
  handleSendProductData,
}: any) {
  const { t, i18n } = useTranslation();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const data = [
    {
      name: "Product name 1",
      id: "asdasd1",
      type: "Digital product",
      price: "$29",
      image:
        // "https://images.satu.kz/164354116_w640_h640_nastennye-chasy-diametr.jpg",
        "/images/products/product1.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Product name 2",
      id: "asdasd2",
      type: "Digital product",
      price: "$29",
      image:
        // "https://object.pscloud.io/cms/cms/Uploads/qwer_1924.jpg",
        "/images/products/product2.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Product name 3",
      id: "asdasd3",
      type: "Digital product",
      price: "$29",
      image:
        // "https://object.pscloud.io/cms/cms/Photo/img_0_112_101_0.jpg",
        "/images/products/product3.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Product name 4",
      id: "asdasd4",
      type: "Digital product",
      price: "$29",
      image:
        // "https://allgifts.kz/assets/images/products/40666/e24060b992a88e047752698421b1f9e345beed29.jpg",
        "/images/products/product4.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Product name 5",
      id: "asdasd5",
      type: "Digital product",
      price: "$29",
      image:
        // "https://images.satu.kz/61912855_hronotron-strelochnye-chasy.jpg",
        "/images/products/product5.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Product name 6",
      id: "asdasd6",
      type: "Digital product",
      price: "$29",
      image:
        // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
        "/images/products/product6.jpg",
      buttonName: "Call to action",
    },
    {
      name: "Бизнес экспедиция в Грузию",
      id: "asdasd7",
      type: "Digital product",
      price: "$29",
      image:
        // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
        "/images/products/product7.jpg",
      buttonName: "Принять участие",
    },
    {
      name: "Как увеличить капитализацию компании",
      id: "asdasd8",
      type: "Digital product",
      price: "$29",
      image:
        // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
        "/images/products/product8.jpg",
      buttonName: "Принять участие",
    },
    {
      name: "Как привлечь инвестиции",
      id: "asdasd9",
      type: "Digital product",
      price: "$29",
      image:
        // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
        "/images/products/product9.jpg",
      buttonName: "Принять участие",
    },
    {
      name: "Масштабирование Бизнеса Через Инвестиции",
      id: "asdasd10",
      type: "Digital product",
      price: "$29",
      image:
        // "https://ir.ozone.ru/s3/multimedia-c/c1000/6265949832.jpg",
        "/images/products/product10.jpg",
      buttonName: "Принять участие",
    },
  ];

  return (
    <div>
      {status === "content" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Info
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Content
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Price
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <span className="text-start text-[18px] text-[#161616] leading-[20px] font-semibold">
                List of products
              </span>
            </div>

            <div className="mt-[5px]"></div>

            {bundleListOfProducts.map((elem: any, index: number) => (
              <div className="mt-[10px]" key={`bundle-list-${index}`}>
                <div className="w-full p-[10px] rounded-[10px] shadow-custom">
                  <div className="flex space-x-[10px] items-center">
                    {elem.image ? (
                      <div className="w-[100px] h-[75px]">
                        <img
                          src={elem.image}
                          alt="image"
                          className="rounded-[5px] w-full h-full"
                        />
                      </div>
                    ) : (
                      <div className="rounded-[5px] w-[100px] h-[75px] bg-[#F7F8F9]"></div>
                    )}
                    <div className="flex flex-col">
                      <div className="text-[16px] font-semibold leading-[27px] text-[#161616]">
                        {elem.name}
                      </div>
                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                        {elem.type}
                      </div>
                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                        Price: {elem.price}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-[15px]">
              <button
                className="w-full h-[50px] border-[1px] border-[#161616] text-[#161616] text-[18px] leading-[22px] rounded-[10px] flex justify-center items-center"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Select product
              </button>
            </div>

            <div className="mt-[200px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleChangeStatus("price");
                }}
              >
                Continue
              </button>
            </div>

            {/* ============================================== BOTTOM WINDOW ============================================== */}
            <div
              className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
            >
              {/* Задний план с затемнением */}
              <div
                className="fixed inset-0 bg-gray-500 opacity-75"
                //   onClick={closeWindow}
              ></div>

              {/* Основное окно */}
              <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px] font-urbanist">
                <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
                  <div className="flex justify-center mt-[10px]">
                    <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
                  </div>
                  <div className="flex justify-between items-center mt-[23px] px-[24px]">
                    <div className="text-white text-[1px] invisible">
                      hiiiiiiadfadgasfgafafasfgsdfhsghdfghdfghdfghdfghdfghdfghdfghdfghasdfasfgs
                    </div>
                    <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
                      Select products
                    </div>
                    <button
                      className="text-[16px] leading-[18px] text-[#8391A1]"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                  <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
                </div>

                <div className="px-[24px] pb-[40px] mt-[80px]">
                  <div className="flex flex-col">
                    <div className="mt-[5px]">
                      {data.map((elem: any) => (
                        <div
                          className="mt-[10px] w-full border-[1px] border-[#E8ECF4] rounded-[10px] p-[10px]"
                          key={elem.id}
                          onClick={() => {
                            if (!searchItemInListOfProducts(elem.id)) {
                              addItemToBundleListOfProducts(elem);
                            } else {
                              deleteItemInBundleListOfProducts(elem.id);
                            }
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex justify-between items-center">
                              {elem.image === "" ? (
                                <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                              ) : (
                                <img
                                  src={elem.image}
                                  alt="image"
                                  className="max-w-[100px] max-h-[75px] rounded-[5px]"
                                />
                              )}
                              <div className="ml-[10px]">
                                <div className="text-[16px] leading-[27px] text-[#161616] font-semibold">
                                  {elem.name}
                                </div>
                                <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                  {elem.type}
                                </div>
                                <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                  Price: {elem.price}
                                </div>
                              </div>
                            </div>

                            <CustomRadioButton
                              checked={searchItemInListOfProducts(elem.id)}
                              index={searchIndexBundleListOfProducts(elem.id)}
                              onChange={() => {
                                if (!searchItemInListOfProducts(elem.id)) {
                                  addItemToBundleListOfProducts(elem);
                                } else {
                                  deleteItemInBundleListOfProducts(elem.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* WINDOW END */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === "price" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Info
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Content
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    Price
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                Price for bundle
              </span>
            </div>

            <div className="mt-[8px]">
              <div className="flex space-x-[10px] items-center">
                <div className="w-full h-[56px]">
                  <NumberInput
                    value={bundlePrice.price}
                    placeholder={"Set price"}
                    changeFunc={(newValue: any) => {
                      handleChangeBundlePrice("price", newValue);
                    }}
                  />

                  {/* <input
                    type="number"
                    className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                    placeholder="Set price"
                    min="0"
                    value={bundlePrice.price && bundlePrice.price}
                    onChange={(event: any) => {
                      const newValue = event.target.value;
                      if (newValue === "" || parseFloat(newValue) >= 0) {
                        handleChangeBundlePrice("price", newValue);
                      }
                    }}
                  /> */}
                </div>
                <div className="w-[76px] h-[56px] flex-shrink-0">
                  <Menu>
                    <MenuHandler>
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {bundlePrice.currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </MenuHandler>
                    <MenuList className="w-[228px] rounded-[10px] font-manrope">
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            handleChangeBundlePrice("currency", "dollar");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            US dollar
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            $
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            handleChangeBundlePrice("currency", "tenge");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            KZ tenge
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₸
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            handleChangeBundlePrice("currency", "ruble");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            RU ruble
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₽
                          </div>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="mt-[20px]">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                Call to action
              </span>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <Menu>
                  <MenuHandler>
                    <div className="flex justify-between items-center w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                      {bundlePrice.action}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="w-full px-[24px] rounded-[10px] font-manrope">
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeBundlePrice("action", "I want this");
                        }}
                      >
                        I want this
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeBundlePrice("action", "Buy now");
                        }}
                      >
                        Buy now
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeBundlePrice("action", "Take the course");
                        }}
                      >
                        Take the course
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <div className="mt-[310px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleSendProductData();
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
