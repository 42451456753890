import React from "react";
//@ts-ignore
import Slider from "react-slick";
import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Radio,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function Component({ listOfImages, productId }: any) {
  const { t, i18n } = useTranslation();

  //   const settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: false,
  //   };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: (i: any) => (
      <div className="w-2 h-2 bg-[#8391A1] rounded-full"></div>
    ),
    dotsClass: "slick-dots custom-dots", // Кастомный класс для точек
  };

  const handleShare = async (url: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: url,
        });
        console.log("Ссылка успешно поделена!");
      } else {
        throw new Error("Web Share API не поддерживается в вашем браузере.");
      }
    } catch (error) {
      console.error("Ошибка при попытке поделиться ссылкой:", error);
    }
  };

  return (
    <div>
      {listOfImages.length === 0 ? (
        <div className="w-full pt-[100%] bg-gray-300"></div>
      ) : listOfImages.length === 1 ? (
        <div className="relative">
          <div className="relative w-full pt-[100%] bg-gray-200 overflow-hidden">
            <img
              src={listOfImages[0]}
              alt={`productSlider${0}.svg`}
              className="absolute inset-0 w-full h-full object-cover object-center"
            />
          </div>

          <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
            <Menu>
              <MenuHandler>
                <div className="flex justify-end items-center">
                  <img
                    src="/images/profile/dots.svg"
                    alt="menu_dots"
                    className="w-[18px] h-[4px] mx-[6px] my-[13px] rounded-[1px]"
                  />
                </div>
              </MenuHandler>
              <MenuList className="mr-[10px] w-[228px] rounded-[15px] font-manrope">
                <MenuItem
                  onClick={() => {
                    handleShare(`https://www.hubbbox.com/product/${productId}`);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-[#161616] text-[16px] leading-[22px]">
                      {t("share_product_link")}
                    </div>
                    <img
                      src="/images/profile/copy_link.svg"
                      alt="copy_link.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>
                </MenuItem>
                <hr className="border-gray-300" />
                <MenuItem
                  onClick={() => {
                    // window.open(
                    //   `https://www.hubbbox.com/${productId}`,
                    //   "_blank"
                    // );
                    // navigate("/profile/preview");
                  }}
                >
                  <div className="flex justify-between">
                    <div className="text-[#161616] text-[16px] leading-[22px]">
                      {t("report_product")}
                    </div>
                    <img
                      src="/images/product_detail/danger_circle.svg"
                      alt="preview.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* // </div> */}
          </button>
        </div>
      ) : (
        // <img
        //   src={listOfImages[0]}
        //   alt={`productSlider${0}.svg`}
        //   className="w-full pt-[75%] object-cover object-top"
        // />
        <div className="">
          <Slider {...settings}>
            {listOfImages.map((elem: any, index: number) => (
              <div key={`gallery-photo-${index}`} className="relative">
                <div className="relative w-full pt-[100%] overflow-hidden">
                  <img
                    src={elem}
                    alt={`productSlider${index}.svg`}
                    className="absolute inset-0 w-full h-full object-cover object-center"
                  />
                  {/* <img
                    src={elem}
                    alt={`productSlider${index}.svg`}
                    className="w-full pt-[75%] object-cover object-center -mb-[5px]"
                  /> */}
                </div>

                <button className="absolute top-4 right-[24px] bg-[#F7F8F9] opacity-80 p-[2px] rounded-[10px]">
                  <Menu>
                    <MenuHandler>
                      <div className="flex justify-end items-center">
                        <img
                          src="/images/profile/dots.svg"
                          alt="menu_dots"
                          className="w-[18px] h-[4px] mx-[6px] my-[13px] rounded-[1px]"
                        />
                      </div>
                    </MenuHandler>
                    <MenuList className="mr-[10px] w-[228px] rounded-[15px] font-manrope">
                      <MenuItem
                        onClick={() => {
                          handleShare(
                            `https://www.hubbbox.com/product/${productId}`
                          );
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("share_product_link")}
                          </div>
                          <img
                            src="/images/profile/copy_link.svg"
                            alt="copy_link.svg"
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem
                        onClick={() => {
                          // window.open(
                          //   `https://www.hubbbox.com/${productId}`,
                          //   "_blank"
                          // );
                          // navigate("/profile/preview");
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("report_product")}
                          </div>
                          <img
                            src="/images/product_detail/danger_circle.svg"
                            alt="preview.svg"
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  {/* // </div> */}
                </button>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}
