import { useEffect, useState } from "react";
import LoadingScreen from "../../ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../constants";
import * as actions from "../../actions";
import * as utils from "../../utils";
import BurgerMenu from "../BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import CustomInput from "../CustomInput";
import NumberInput from "..//NumberInput";

export async function getProductInfoByProductLink(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.BecomeADistributorProductLink,
    dispatch,
    `api/product/data_by_url/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Component({
  handleChangeStep,
  handleChangeBecomeDistributorData,
  becomeDistributorData,
  handleSendCollaborationData,
  errorResponse,
}: any) {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const dispatch = useDispatch();

  const [clickButton, setClickButton] = useState(false);
  const [firstClickButton, setFirstClickButton] = useState(false);

  const [productLinkErrorBool, setProductLinkErrorBool] = useState(false);
  const [priceErrorBool, setPriceErrorBool] = useState(false);
  const [feeErrorBool, setFeeErrorBool] = useState(false);
  const [termsErrorBool, setTermsErrorBool] = useState(false);
  const [commentsErrorBool, setCommentsErrorBool] = useState(false);

  const [responseError, setResponseError] = useState("");
  const [recipientUsername, setRecipientUsername] = useState("");
  const [product, setProduct] = useState({});

  const BecomeADistributorProductLinkStore = useSelector(
    (state: any) => state.BecomeADistributorProductLinkStore
  );

  useEffect(() => {
    console.log(
      "BecomeADistributorProductLinkStore",
      BecomeADistributorProductLinkStore
    );
    if (BecomeADistributorProductLinkStore) {
      if (BecomeADistributorProductLinkStore.data) {
        const response = BecomeADistributorProductLinkStore.data.response;
        setProduct(response.product);
        setRecipientUsername(`@${response.recipient_username}`);
        setResponseError("");
      } else if (BecomeADistributorProductLinkStore.error) {
        setResponseError(BecomeADistributorProductLinkStore.error);
        setRecipientUsername("");
        setProduct({});
      } else if (BecomeADistributorProductLinkStore.fail) {
        setResponseError(BecomeADistributorProductLinkStore.fail);
        setRecipientUsername("");
        setProduct({});
      }
    }
  }, [BecomeADistributorProductLinkStore]);

  useEffect(() => {
    if (firstClickButton) {
      if (becomeDistributorData.productLink === "") {
        setProductLinkErrorBool(true);
      }
      if (becomeDistributorData.price === "") {
        setPriceErrorBool(true);
      }
      if (becomeDistributorData.fee === "") {
        setFeeErrorBool(true);
      }
      if (
        becomeDistributorData.terms === "duration" &&
        becomeDistributorData.duration === ""
      ) {
        setTermsErrorBool(true);
      } else if (
        becomeDistributorData.terms === "quantity" &&
        becomeDistributorData.quantity === ""
      ) {
        setTermsErrorBool(true);
      }
      if (becomeDistributorData.comments === "") {
        setCommentsErrorBool(true);
      }
    }
  }, [clickButton]);

  useEffect(() => {
    if (firstClickButton) {
      if (becomeDistributorData.productLink !== "") {
        setProductLinkErrorBool(true);
      }
      if (becomeDistributorData.price !== "") {
        setPriceErrorBool(false);
      }
      if (becomeDistributorData.fee !== "") {
        setFeeErrorBool(false);
      }
      if (
        becomeDistributorData.terms === "duration" &&
        becomeDistributorData.duration !== ""
      ) {
        setTermsErrorBool(false);
      } else if (
        becomeDistributorData.terms === "quantity" &&
        becomeDistributorData.quantity !== ""
      ) {
        setTermsErrorBool(false);
      }
      if (becomeDistributorData.comments !== "") {
        setCommentsErrorBool(false);
      }
    }
  }, [becomeDistributorData]);

  return (
    <div className="flex justify-center">
      <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
        <BurgerMenu
          isOpen={burgerMenuOpen}
          toggleBurgerMenu={toggleBurgerMenu}
        />

        <div className="flex flex-col">
          <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
            <div className="h-[56px] flex justify-between items-center">
              <button
                onClick={() => {
                  handleChangeStep("selection");
                }}
              >
                {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </button>
              <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                <span className="text-[#F5F5F5] invisible">iiii</span>
                {t("become_a_distributor")}
              </div>
              <button onClick={toggleBurgerMenu}>
                {!burgerMenuOpen ? (
                  // <Icon
                  //   icon="iconamoon:menu-burger-horizontal-light"
                  //   className="w-[24px] h-[26px]"
                  // />
                  <img
                    src="/images/hamburger_menu/hamburger_menu.svg"
                    alt="hamburger_menu.svg"
                    className="w-[24px] h-[26.88px]"
                  />
                ) : (
                  <span> </span>
                )}
              </button>
            </div>
          </div>

          <div className="mt-[66px]">
            <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
              {t("product_link")}
            </div>
          </div>

          <div className="mt-[10px]">
            <CustomInput
              handleChangeValue={(newVal: string) => {
                handleChangeBecomeDistributorData("productLink", newVal);
                getProductInfoByProductLink(dispatch, "", {
                  productLink: newVal,
                });
              }}
              value={becomeDistributorData.productLink}
              placeholder={t("product_link_placeholder")}
              rows={1}
              maxLength={100}
              borderColor={productLinkErrorBool ? "FF2C20" : "E8ECF4"}
            />
          </div>

          {responseError && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
              * {responseError}
            </div>
          )}

          {errorResponse && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
              * {errorResponse}
            </div>
          )}

          {Object.keys(product).length > 0 && (
            <div className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom">
              <div className="flex space-x-[10px] items-center">
                {
                  //@ts-ignore
                  product.thumbnail === "" ? (
                    <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                  ) : (
                    <img
                      src={
                        //@ts-ignore
                        product.thumbnail
                      }
                      alt="image"
                      className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                    />
                  )
                }
                <div className="ml-[10px]">
                  <div className="text-[16px] leading-[27px] text-[#161616] font-semibold">
                    {
                      //@ts-ignore
                      product.name
                    }
                  </div>
                  <div className="text-[14px] leading-[22.5px] text-[#161616]">
                    {
                      //@ts-ignore
                      t(product.type)
                    }
                  </div>
                  <div className="text-[14px] leading-[22.5px] text-[#161616]">
                    {t("price")}:{" "}
                    {
                      //@ts-ignore
                      product.price
                        ? //@ts-ignore
                          `${product.currencySymbol}${product.price}`
                        : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mt-[10px]">
            <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
              {t("recipient")}
            </div>
          </div>

          <div className="mt-[10px]">
            <CustomInput
              value={recipientUsername}
              placeholder={"@username"}
              rows={1}
              maxLength={100}
              disabled={true}
            />
          </div>

          <div className="mt-[10px]">
            <div className="text-[#161616] text-[16px] leading-[20px]">
              <span className="font-medium">{t("your_price")}</span> ⓘ
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="flex space-x-[10px] items-center">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={becomeDistributorData.price}
                  placeholder={t("set_price")}
                  changeFunc={(newValue: any) => {
                    handleChangeBecomeDistributorData("price", newValue);
                  }}
                  borderColor={priceErrorBool ? "FF2C20" : "E8ECF4"}
                />
              </div>
              <div className="w-[76px] h-[56px] flex-shrink-0">
                <Menu>
                  <MenuHandler>
                    <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                      {becomeDistributorData.currencySymbol}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="w-[228px] rounded-[10px]">
                    <MenuItem>
                      <div
                        className="flex justify-between items-center font-manrope"
                        onClick={() => {
                          handleChangeBecomeDistributorData(
                            "currency",
                            "dollar"
                          );
                        }}
                      >
                        <div className="text-[16px] leading-[22px] text-[#161616]">
                          US {t("dollar")}
                        </div>
                        <div className="text-[22px] leading-[24px] text-[#161616]">
                          $
                        </div>
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="flex justify-between items-center font-manrope"
                        onClick={() => {
                          handleChangeBecomeDistributorData(
                            "currency",
                            "tenge"
                          );
                        }}
                      >
                        <div className="text-[16px] leading-[22px] text-[#161616]">
                          KZ {t("tenge")}
                        </div>
                        <div className="text-[22px] leading-[24px] text-[#161616]">
                          ₸
                        </div>
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="flex justify-between items-center font-manrope"
                        onClick={() => {
                          handleChangeBecomeDistributorData(
                            "currency",
                            "ruble"
                          );
                        }}
                      >
                        <div className="text-[16px] leading-[22px] text-[#161616]">
                          RU {t("ruble")}
                        </div>
                        <div className="text-[22px] leading-[24px] text-[#161616]">
                          ₽
                        </div>
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
              {t("your_fee")}
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="w-full h-[56px]">
              <NumberInput
                value={becomeDistributorData.fee}
                placeholder={t("your_fee_placeholder")}
                changeFunc={(newValue: any) => {
                  if (0 > parseInt(newValue)) {
                    newValue = "0";
                  } else if (parseInt(newValue) > 100) {
                    newValue = "100";
                  }
                  handleChangeBecomeDistributorData("fee", newValue);
                }}
                borderColor={feeErrorBool ? "FF2C20" : "E8ECF4"}
              />
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="text-[#161616] text-[16px] leading-[20px]">
              <span className="font-medium">
                {t("duration_of_the_collaboration")}
              </span>{" "}
              ⓘ
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="flex justify-between items-center">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                {t("time_days")}
              </span>
              <Switch
                crossOrigin={""}
                checked={becomeDistributorData.terms === "duration"}
                onChange={() => {
                  if (becomeDistributorData.terms === "duration") {
                    handleChangeBecomeDistributorData("terms", "quantity");
                  } else {
                    handleChangeBecomeDistributorData("terms", "duration");
                  }
                }}
              />
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="w-full h-[56px]">
              <NumberInput
                value={becomeDistributorData.duration}
                placeholder={t("time_days_placeholder")}
                changeFunc={(newValue: any) => {
                  if (becomeDistributorData.terms === "duration") {
                    handleChangeBecomeDistributorData("duration", newValue);
                  }
                }}
                borderColor={
                  termsErrorBool && becomeDistributorData.terms === "duration"
                    ? "FF2C20"
                    : "E8ECF4"
                }
              />
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="flex justify-between items-center">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                {t("product_quantity")}
              </span>
              <Switch
                crossOrigin={""}
                checked={becomeDistributorData.terms === "quantity"}
                onChange={() => {
                  if (becomeDistributorData.terms === "quantity") {
                    handleChangeBecomeDistributorData("terms", "duration");
                  } else {
                    handleChangeBecomeDistributorData("terms", "quantity");
                  }
                }}
              />
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="w-full h-[56px]">
              <NumberInput
                value={becomeDistributorData.quantity}
                placeholder={t("product_quantity_placeholder")}
                changeFunc={(newValue: any) => {
                  if (becomeDistributorData.terms === "quantity") {
                    handleChangeBecomeDistributorData("quantity", newValue);
                  }
                }}
                borderColor={
                  termsErrorBool && becomeDistributorData.terms === "quantity"
                    ? "FF2C20"
                    : "E8ECF4"
                }
              />
            </div>
          </div>

          <div className="mt-[10px]">
            <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
              {t("сomments")}
            </div>
          </div>

          <div className="mt-[10px]">
            <CustomInput
              handleChangeValue={(newVal: string) => {
                handleChangeBecomeDistributorData("comments", newVal);
              }}
              value={becomeDistributorData.comments}
              placeholder={t("сomments_placeholder")}
              rows={3}
              maxLength={240}
              delimiter={false}
              borderColor={commentsErrorBool ? "FF2C20" : "E8ECF4"}
            />
          </div>

          <div className="mt-[5px]">
            <div className="flex justify-end items-center">
              <div className="text-[12px] text-[#161616]">
                {becomeDistributorData.comments.length}/240
              </div>
            </div>
          </div>

          <div className="mt-[15px]">
            <div
              className="bg-[#161616] h-[50px] rounded-[10px] text-white flex justify-center items-center font-semibold text-[18px]"
              onClick={() => {
                setClickButton(!clickButton);
                setFirstClickButton(true);

                if (
                  becomeDistributorData.productLink !== "" &&
                  becomeDistributorData.price !== "" &&
                  becomeDistributorData.fee !== "" &&
                  becomeDistributorData.comments !== ""
                ) {
                  if (
                    (becomeDistributorData.terms === "duration" &&
                      becomeDistributorData.duration !== "") ||
                    (becomeDistributorData.terms === "quantity" &&
                      becomeDistributorData.quantity !== "")
                  ) {
                    handleSendCollaborationData("become");
                  }
                }
              }}
            >
              {t("send_a_request")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
