export default function Component({ checked, index, onChange }: any) {
  return (
    <div className="">
      {checked ? (
        <button
          className="flex items-center justify-center w-[20px] h-[20px] bg-blue-500 text-white text-[14px] font-bold rounded-full"
          onClick={onChange}
        >
          {index}
        </button>
      ) : (
        <div className="border-[1px] border-gray-400 w-[18px] h-[18px] rounded-full"></div>
      )}
    </div>
  );
}
