import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import { useNavigate, Link } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "../../components/utils";
import LoadingScreen from "../../components/ui/LoadingScreen";
import ErrorPage from "../../components/ui/ErrorPage";
import ProductDetailImageSlider from "../../components/ui/Product/ProductDetailImageSlider";
import { useTranslation } from "react-i18next";

export async function getPostDetailsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.postDetails,
    dispatch,
    `api/post/details/`,
    "post",
    "",
    form,
    false
  )();
}

export async function sendAnalyticsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendAnalytics,
    dispatch,
    `api/profile/send_analytics/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // User Data
  const [username, setUsername] = useState("");
  const [profileName, setProfileName] = useState("");
  const [avatar, setAvatar] = useState("");

  //   Product Details
  const [postInfo, setPostInfo] = useState({
    category: [],
    created: "",
    reading_time: "",
    thumbnail: "",
    title: "",
  });
  const [postData, setPostData] = useState([
    {
      type: "textarea",
      src: "",
    },
  ]);

  const postDetailsStore = useSelector((state: any) => state.postDetailsStore);

  useEffect(() => {
    // console.log("postDetailsStore", postDetailsStore);
    if (postDetailsStore && postDetailsStore.data) {
      setUsername(postDetailsStore.data.response.user_data.username);
      setProfileName(postDetailsStore.data.response.user_data.profile_name);
      setAvatar(postDetailsStore.data.response.user_data.avatar);
      setPostInfo(postDetailsStore.data.response.post_details.post_info);
      setPostData(postDetailsStore.data.response.post_details.post_data);
    }
  }, [postDetailsStore]);

  useEffect(() => {
    // const productDetailStorage = utils.LocalStorage.get(`ProductDetail`);

    // if (productDetailStorage) {
    //   if (productDetailStorage.selectedOptions) {
    //     setSelectedOptions(productDetailStorage.selectedOptions);
    //   }
    // }

    sendAnalyticsFunc(dispatch, "", {
      username: "",
      postId: params.postId,
      type: "post",
      body: "",
    });

    getPostDetailsFunc(dispatch, "", { id: params.postId });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //   useEffect(() => {
  //     utils.LocalStorage.set(`ProductDetail`, {
  //       selectedOptions: selectedOptions,
  //     });
  //   }, [selectedOptions]);

  function splitText(text: string, key: string) {
    if (text !== "") {
      const words = text.split("\n"); // Split the text into an array of words using space as a separator
      // console.log("words", words);
      return (
        <div>
          {words.map((elem: any, index: number) =>
            elem !== "" ? (
              <div
                className="leading-[20px] font-medium"
                key={`${key}-${index}`}
              >
                {elem}
              </div>
            ) : (
              <br key={`${key}-${index}`} />
            )
          )}
          {/* <div className="-mb-[4px]"></div> */}
        </div>
      );
    }
  }

  const handleShare = async (url: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: url,
        });
        console.log("Ссылка успешно поделена!");
      } else {
        throw new Error("Web Share API не поддерживается в вашем браузере.");
      }
    } catch (error) {
      console.error("Ошибка при попытке поделиться ссылкой:", error);
    }
  };

  if (postDetailsStore && postDetailsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={postDetailsStore.load} />
      </div>
    );
  }

  if (postDetailsStore && (postDetailsStore.fail || postDetailsStore.error)) {
    return (
      <div>
        <ErrorPage error={postDetailsStore.fail} />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
        <div className="w-full h-[70px] flex justify-center items-center fixed inset-x-0 z-10 bg-white shadow-custom">
          <div className="w-full bg-white py-[10px] px-[15px]">
            <div
              onClick={() => {
                window.location.href = `https://www.hubbbox.com/${username}`;
              }}
              className="flex space-x-[15px] items-center"
            >
              {!avatar ? (
                <img
                  src="/images/avatar.svg"
                  alt="avatar.svg"
                  className="w-[50px] h-[50px]"
                />
              ) : (
                <Avatar
                  src={avatar}
                  alt="avatar"
                  className="w-[50px] h-[50px]"
                />
              )}

              <div className="">
                <div className="text-[18px] leading-[19.2px] font-semibold tracking-[-1%]">
                  {profileName}
                </div>
                <div className="mt-[5px] text-[14px] font-medium text-[#161616] leading-[14.4px]">
                  @{username}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[70px]">
          <div className="flex flex-col">
            <div className="mt-[20px] px-[24px]">
              <span className="text-[32px] leading-[36px] font-extrabold text-[#161616]">
                {postInfo.title}
              </span>
            </div>

            {postInfo.category.length > 0 && (
              // <div className="mt-[15px] px-[24px] grid grid-cols-4 gap-2.5">
              <div className="mt-[15px] px-[24px] flex flex-wrap gap-[5px]">
                {postInfo.category.map(
                  (category: any, categoryIndex: number) => (
                    <div key={`category-${categoryIndex}`}>
                      <div className="rounded-[40px] h-[26px] bg-[#333333] flex justify-center items-center text-white px-[15px] text-[12px] font-semibold">
                        {category}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            <div className="mt-[15px] px-[24px] flex space-x-[20px] items-center">
              <span className="text-[14px] leading-[18px]">
                {postInfo.created}
              </span>

              <div className="flex space-x-[5px] items-center">
                <img
                  src="/images/post/clock.svg"
                  alt="clock.svg"
                  className="w-[20px] h-[20px]"
                />

                <span className="text-[14px] leading-[18px]">
                  {postInfo.reading_time} {t("minutes")}
                </span>
              </div>

              <div className="flex space-x-[5px] items-center">
                <img
                  src="/images/post/eye.svg"
                  alt="eye.svg"
                  className="w-[20px] h-[20px]"
                />

                <span className="text-[14px] leading-[18px]">1</span>
              </div>
            </div>

            <div className="mt-[10px]">
              {postInfo.thumbnail === "" ? (
                <div className="relative w-full pt-[80%] overflow-hidden bg-gray-200">
                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200"></div>
                </div>
              ) : (
                <div className="relative w-full pt-[80%] overflow-hidden">
                  <img
                    src={postInfo.thumbnail}
                    alt="productImage"
                    className="absolute inset-0 w-full h-full object-cover object-center"
                  />
                </div>
              )}
            </div>

            <div className="mt-[15px]">
              {postData.length > 0 &&
                postData.map((elem: any, index: number) => (
                  <div className="" key={`post-${index}`}>
                    {elem.type === "textarea" ? (
                      <div className="px-[24px]">
                        {splitText(elem.src, `post-${index}`)}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center my-[15px]">
                        <img
                          src={elem.src}
                          alt="Uploaded"
                          className="w-full h-auto"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <div className="mt-[20px] px-[24px]">
              <div className="flex justify-between items-center">
                <button
                  onClick={() => {
                    handleShare(
                      `https://www.hubbbox.com/post/${params.postId}`
                    );
                  }}
                  className="h-[36px] flex justify-center items-center px-[15px] shadow-custom rounded-[10px]"
                >
                  <div className="flex space-x-[5px]">
                    <img
                      src="/images/post/share.svg"
                      alt="share.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="">{t("share_post")}</span>
                  </div>
                </button>

                <img
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Для плавного скролла
                    });
                  }}
                  src="/images/post/arrow_up.svg"
                  alt="arrow_up.svg"
                  className="w-[48px] h-[48px]"
                />
              </div>
            </div>

            <div
              className="mt-[40px] px-[24px] flex justify-center items-center"
              onClick={() => {
                window.open(`https://hubbbox.com/`, "_blank");
              }}
            >
              <div className="h-[40px] px-[15px] bg-white rounded-[100px] shadow-customblock text-[#161616] text-[16px] leading-[18px] flex justify-center items-center">
                {t("profile_preview_text")}&nbsp;
                <span className="font-extrabold">Hubbbox</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
