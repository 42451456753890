export default function Component({
  title,
  description,
  image,
  deactivated,
  blockDesign,
  // senderFunc = null,
}: any) {
  return (
    // <div onClick={senderFunc}>
    <div>
      {blockDesign === "first" && (
        <div
          className={`p-[10px] w-full rounded-[15px] bg-white shadow-customblock break-words ${deactivated && "opacity-50"}`}
        >
          <div className="flex space-x-[15px] items-center">
            {image === "" ? (
              <div className="w-[50px] h-[50px] rounded-[10px] bg-[#F7F8F9]"></div>
            ) : (
              <img
                src={image}
                alt=""
                className="w-[50px] h-[50px] object-center object-cover rounded-[10px]"
              />
            )}

            <div className="ml-[10px] flex flex-col justify-start">
              <div className="flex justify-start text-[18px] leading-[22px] text-[#161616] font-bold">
                {title}
              </div>
              <div className="mt-[2px] flex justify-start text-[14px] leading-[18px] text-[#161616]">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}

      {blockDesign === "second" && (
        <div
          className={`p-[10px] w-full rounded-[15px] bg-white shadow-customblock break-words ${deactivated && "opacity-50"}`}
        >
          <div className="flex space-x-[15px] items-center">
            {image === "" ? (
              <div className="w-[50px] h-[50px] rounded-[10px] bg-[#F7F8F9]"></div>
            ) : (
              <img
                src={image}
                alt=""
                className="w-[50px] h-[50px] object-center object-cover rounded-[10px]"
              />
            )}

            <div className="ml-[10px] flex flex-col justify-start">
              <div className="flex justify-start text-[18px] leading-[22px] text-[#161616] font-bold">
                {title}
              </div>
              <div className="mt-[2px] flex justify-start text-[14px] leading-[18px] text-[#161616]">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}

      {blockDesign === "third" && (
        <div
          // className={`p-[10px] w-full rounded-[15px] bg-white shadow-custom break-words ${deactivated && "opacity-50"}`}
          className={`w-full rounded-[7px] bg-white shadow-customblock break-words ${deactivated && "opacity-50"}`}
        >
          <div className="flex space-x-[15px] items-center">
            {image === "" ? (
              <div className="w-[80px] h-[80px] rounded-l-[7px] bg-[#F7F8F9]"></div>
            ) : (
              <img
                src={image}
                alt=""
                className="w-[80px] h-[80px] object-center object-cover rounded-l-[7px]"
              />
            )}

            <div className="flex flex-col justify-start">
              <div className="flex justify-start text-[18px] leading-[22px] text-[#161616] font-bold">
                {title}
              </div>
              <div className="mt-[2px] flex justify-start text-[14px] leading-[18px] text-[#161616]">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
