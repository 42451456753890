import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as utils from "../../utils";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import DigitalProduct from "./DigitalProduct";
import PhysicalProductV1 from "./PhysicalProductV1";
import ProductDetailsThumbnail from "./ProductDetailsThumbnail";
import ProductDetailsCover from "./ProductDetailsCover";
import Bundle from "./Bundle";
import Membership from "./Membership";
import { useTranslation } from "react-i18next";
// import Menu from "../Menu";

export default function Component({
  notify,
  burgerMenuOpen,
  toggleBurgerMenu,
  selectedProductType,
  // productName,
  // handleChangeProductName,
  // productDescription,
  // handleChangeProductDescription,
  productInfo,
  productThumbnail,
  handleChangeProductThumbnail,
  handleChangeProductInfo,
  listOfProductImages,
  handleChangeListProductImage,
  handleAddProductImage,
  handleDeleteProductImage,
  digitalProductContent,
  handleChangeDigitalProductContent,
  handleChangeDigitalProductList,
  handleFileVersionChange,
  handleButtonClickFileVersionUrl,
  physicalProductContent,
  handleChangePhysicalProductContent,
  handleChangePhysicalProductList,
  countryData,
  handleChangeCountryData,
  bundleListOfProducts,
  searchItemInListOfProducts,
  deleteItemInBundleListOfProducts,
  setDescriptionToItemInBundleListOfProducts,
  addItemToBundleListOfProducts,
  setItemToBundleListOfProducts,
  searchIndexBundleListOfProducts,
  bundlePrice,
  handleChangeBundlePrice,
  membershipContent,
  handleChangeMembershipContent,
  handleCheckedMembershipContent,
  status,
  handleChangeStatus,
  handleChangeStage,
  handleSendProductData,
}: any) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [errorProductName, setErrorProductName] = useState("");
  const [errorProductDescription, setErrorProductDescription] = useState("");
  const [isClickInfoButton, setIsClickInfoButton] = useState(false);

  useEffect(() => {
    if (isClickInfoButton) {
      if (productInfo.name === "") {
        setErrorProductName(t("error_product_name"));
      } else {
        setErrorProductName("");
      }

      if (productInfo.description === "") {
        setErrorProductDescription(t("error_product_description"));
      } else {
        setErrorProductDescription("");
      }
    }
  }, [productInfo, isClickInfoButton]);

  const textareaRefProductName = useRef(null);
  const textareaRefProductDescription = useRef(null);

  const handleChangeRefProductName = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    // handleChangeProductName(newValue);
    handleChangeProductInfo("name", newValue);
    // @ts-ignore
    textareaRefProductName.current.style.height = "auto";
    // @ts-ignore
    textareaRefProductName.current.style.height = `${textareaRefProductName.current.scrollHeight}px`;
  };

  const handleChangeRefProductDescription = (event: any) => {
    const value = event.target.value;
    // const newValue = value.replace("\n", "");
    // handleChangeProductDescription(newValue);
    handleChangeProductInfo("description", value);
    // @ts-ignore
    textareaRefProductDescription.current.style.height = "auto";
    // @ts-ignore
    textareaRefProductDescription.current.style.height = `${textareaRefProductDescription.current.scrollHeight}px`;
  };

  return (
    <div>
      {status === "info" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    navigate("/product");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("create_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                  onClick={() => {
                    //   changeStatus("Info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <textarea
                ref={textareaRefProductName}
                maxLength={100}
                placeholder={t("product_name")}
                className="mt-[10px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                value={productInfo.name}
                onChange={handleChangeRefProductName}
                rows={1} // Начнем с одной строки
              />
            </div>

            {errorProductName !== "" && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[18px]">
                {errorProductName}
              </div>
            )}

            <div className="mt-[10px]">
              <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                {t("description")}
              </span>
            </div>

            <div className="mt-[10px]">
              <textarea
                ref={textareaRefProductDescription}
                maxLength={1000}
                placeholder={t("description")}
                className="w-full h-[125px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                value={productInfo.description}
                onChange={handleChangeRefProductDescription}
                rows={3} // Начнем с одной строки
              />
            </div>

            {errorProductDescription !== "" && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[18px]">
                {errorProductDescription}
              </div>
            )}

            <div className="mt-[10px]">
              <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                {t("thumbnail")}
              </span>
            </div>

            <ProductDetailsThumbnail
              notify={notify}
              productThumbnail={productThumbnail}
              handleChangeProductThumbnail={handleChangeProductThumbnail}
              productInfo={productInfo}
              handleChangeProductInfo={handleChangeProductInfo}
            />

            <div className="mt-[10px]">
              <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                {t("product_images_gallery")}
              </span>
            </div>

            <ProductDetailsCover
              notify={notify}
              listOfProductImages={listOfProductImages}
              handleChangeListProductImage={handleChangeListProductImage}
              handleAddProductImage={handleAddProductImage}
              handleDeleteProductImage={handleDeleteProductImage}
            />

            <div className="mt-[14px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  setIsClickInfoButton(true);
                  if (
                    productInfo.description !== "" &&
                    productInfo.name !== ""
                  ) {
                    handleChangeStatus("content");
                  }
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      )}

      {(status === "content" || status === "price") &&
        selectedProductType == "physical_product" && (
          <PhysicalProductV1
            burgerMenuOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
            selectedProductType={selectedProductType}
            physicalProductContent={physicalProductContent}
            handleChangePhysicalProductContent={
              handleChangePhysicalProductContent
            }
            handleChangePhysicalProductList={handleChangePhysicalProductList}
            countryData={countryData}
            handleChangeCountryData={handleChangeCountryData}
            status={status}
            handleChangeStatus={handleChangeStatus}
            handleChangeStage={handleChangeStage}
            handleSendProductData={handleSendProductData}
          />
        )}
    </div>
  );
}
