import * as constants from "./constants";
import axios from "axios";
import * as utils from "./utils";

export async function getMessageList(dispatch: any, queryParams: string) {
  try {
    dispatch({ type: constants.messageList.load });
    const config = { Authorization: "Token=token_auth123" };
    const response = await axios.get(
      `http://127.0.0.1:8000/api/communicator${queryParams}`,
      // @ts-ignore
      config
    );
    if (response.data) {
      dispatch({ type: constants.messageList.success, payload: response.data });
    } else {
      dispatch({
        type: constants.messageList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    dispatch({ type: constants.messageList.fail, payload: error.toString() });
    console.error("error: ", error);
  }
}

export async function getNowMessageList(dispatch: any, queryParams: string) {
  try {
    dispatch({ type: constants.nowMessageList.load });
    const config = { Authorization: "Token=token_auth123" };
    const response = await axios.get(
      `http://127.0.0.1:8000/api/communicator${queryParams}`,
      // @ts-ignore
      config
    );
    if (response.data) {
      dispatch({
        type: constants.nowMessageList.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constants.nowMessageList.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    dispatch({
      type: constants.nowMessageList.fail,
      payload: error.toString(),
    });
    console.error("error: ", error);
  }
}

export function constructorAction1(
  constant: any,
  dispatch: any,
  url: string,
  queryParams: string,
  auth: string = "Token=token_auth123"
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      const config = { Authorization: auth };
      const response = await axios.get(
        `${url}${queryParams}`,
        // @ts-ignore
        config
      );
      if (response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        dispatch({
          type: constant.error,
          payload: response.statusText,
        });
      }
    } catch (error: any) {
      dispatch({
        type: constant.fail,
        payload: error.toString(),
      });
      if (constants.DEBUG) {
        console.error("error: ", error);
      }
    }
  };
}

export async function constructorPostAction(
  dispatch: any,
  constant: any,
  form: any,
  url: string
) {
  try {
    dispatch({ type: constant.load });
    const response = await axios.post(url, { ...form });
    if (response.data) {
      dispatch({
        type: constant.success,
        payload: response.data,
      });
    } else {
      dispatch({
        type: constant.error,
        payload: response.statusText,
      });
    }
  } catch (error: any) {
    dispatch({
      type: constant.fail,
      payload: error.toString(),
    });
    if (constants.DEBUG) {
      console.error("error: ", error);
    }
  }
}

export function refreshToken() {
  return async function func() {
    try {
      let form: any = {};
      const tokenCookie = utils.GetCookieValue("token");
      if (tokenCookie) {
        const tokenRefresh = JSON.parse(
          utils.GetCookieValue("token") ?? "{}"
        ).refresh;
        form["refresh"] = `${tokenRefresh}`;
      }

      const response = await axios.post(
        `${constants.API}api/token/refresh/`,
        form
        // @ts-ignore
        // config,
      );
      // console.log("response Ref", response);
      if (response && response.data) {
        utils.SetCookieValue(
          "token",
          JSON.stringify(response.data)
          // 600
        );
      } else {
        // console.log("error in response", response.statusText);
      }
    } catch (error: any) {
      console.log("Refresh error", error);
      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
    }
  };
}

export function constructorAction(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = "",
  form: object | undefined = undefined,
  auth: boolean = true
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};
      if (auth) {
        const tokenCookie = utils.GetCookieValue("token");
        if (tokenCookie) {
          const tokenAccess = JSON.parse(
            utils.GetCookieValue("token") ?? "{}"
          ).access;
          config["Authorization"] = `Bearer ${tokenAccess}`;
        } else {
          config["Authorization"] = "";
        }
      }

      let response: any = undefined;

      switch (method.toLowerCase()) {
        case "get": {
          try {
            response = await axios.get(
              `${constants.API}${url}${queryParams}`,
              // @ts-ignore
              { headers: config }
            );
          } catch (err: any) {
            console.log("err", err);
            // if (err.response.data.code === "token_not_valid") {

            if (auth) {
              await refreshToken()();
              const tokenCookie = utils.GetCookieValue("token");
              if (tokenCookie) {
                const tokenAccess = JSON.parse(
                  utils.GetCookieValue("token") ?? "{}"
                ).access;
                config["Authorization"] = `Bearer ${tokenAccess}`;
              } else {
                config["Authorization"] = "";
              }
            }
            response = await axios.get(
              `${constants.API}${url}${queryParams}`,
              // @ts-ignore
              { headers: config }
            );
          }
          break;
        }
        case "post": {
          try {
            response = await axios.post(
              `${constants.API}${url}`,
              form,
              // @ts-ignore
              { headers: config }
            );
          } catch (err: any) {
            // if (err.response.data.code === "token_not_valid") {
            if (auth) {
              await refreshToken()();
              const tokenCookie = utils.GetCookieValue("token");
              if (tokenCookie) {
                const tokenAccess = JSON.parse(
                  utils.GetCookieValue("token") ?? "{}"
                ).access;
                config["Authorization"] = `Bearer ${tokenAccess}`;
              } else {
                config["Authorization"] = "";
              }
            }
            response = await axios.post(
              `${constants.API}${url}`,
              form,
              // @ts-ignore
              { headers: config }
            );
          }
          break;
        }
        default: {
          // todo raise exception
          console.error("Unexpected method");
        }
      }
      // if (auth) {
      //   dispatch({
      //     type: constant.error,
      //     payload: response.status,
      //   });
      //   return;
      // }
      if (response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        dispatch({
          type: constant.error,
          payload: response.statusText,
        });
      }
      // if (response && response.data) {
      //   dispatch({
      //     type: constant.success,
      //     payload: response.data,
      //   });
      // } else {
      //   dispatch({
      //     type: constant.error,
      //     payload: response.statusText,
      //   });
      // }
    } catch (error: any) {
      // console.log("error", error);
      // console.log("status_code", error.response.status);
      // if (error.response.data.code === "token_not_valid") {
      //   await refreshToken()();
      //   await func();
      // }

      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
      // dispatch({
      //   type: constant.fail,
      //   payload: error.toString(),
      // });
      // console.error("error: ", error);

      if (error.response && error.response.data) {
        console.error("error: ", error);
        dispatch({
          type: constant.fail,
          payload: error.response.data.error,
        });
      } else {
        dispatch({
          type: constant.fail,
          payload: error.toString(),
        });
      }
    }
  };
}

// TODO Logout: нужно очистить кукисы и reset нужных переменных из redux и сделать reboot страницы

//
// export function getAllTodos() {
//   return constructorAction({
//     url: `https://jsonplaceholder.typicode.com/todos/`,
//     constant: constants.listTodos,
//   });
// }
//
// export function constructorAction(
//   props = {
//     // @ts-ignore
//     url,
//     constant: {
//       // @ts-ignore
//       load: string,
//       // @ts-ignore
//       success: string,
//       // @ts-ignore
//       fail: string,
//       // @ts-ignore
//       error: string,
//       // @ts-ignore
//       reset: string,
//     },
//   }
// ) {
//   return async function (dispatch: any) {
//     try {
//       // TODO load
//       dispatch({ type: props.constant.load });
//       const response = await axios.get(props.url); // todo откуда берём данные
//       if (response.status === 200 || response.status === 201) {
//         // TODO success
//         dispatch({
//           type: props.constant.success, // todo куда ложим успешные данные
//           payload: response.data,
//         });
//       } else {
//         // TODO error
//         dispatch({
//           type: props.constant.error,
//           payload: response.statusText,
//         });
//       }
//     } catch (error) {
//       console.log("error: ", error);
//       // TODO fail
//       dispatch({
//         type: props.constant.fail,
//         // @ts-ignore
//         payload: error.toString(),
//       });
//     }
//   };
// }
