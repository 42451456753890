import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
  list,
} from "@material-tailwind/react";
import * as utils from "../../components/utils";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import { useDispatch, useSelector } from "react-redux";

export async function subscriptionPaymentFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.subscriptionPayment,
    dispatch,
    `api/payment/subscription/`,
    "post",
    "",
    form,
    true
  )();
}

export async function subscriptionDetailsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.subscriptionDetails,
    dispatch,
    `api/subscription/details/`,
    "get",
    "",
    form,
    true
  )();
}

const PricingPlan = () => {
  const { t, i18n } = useTranslation();
  const padding = "10px";
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [plan, setPlan] = useState("yearly");
  const [userLanguage, setUserLanguage] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");

  // SUBSCRIPTOION DETAILS
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [paymentCycle, setPaymentCycle] = useState("");
  const [extensionAmount, setExtensionAmount] = useState("");
  const [endOfPeriodDate, setEndOfPeriodDate] = useState("");
  const [endOfPeriodDay, setEndOfPeriodDay] = useState("");

  const [isExtendButton, setIsExtendButton] = useState(true);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);
  const subscriptionPaymentStore = useSelector(
    (state: any) => state.subscriptionPaymentStore
  );
  const subscriptionDetailsStore = useSelector(
    (state: any) => state.subscriptionDetailsStore
  );

  const tokenCookie = utils.GetCookieValue("token");

  const dispatch = useDispatch();

  useEffect(() => {
    if (endOfPeriodDay === "-" || endOfPeriodDay === "") {
      setIsExtendButton(false);
      //@ts-ignore
    } else if (endOfPeriodDay > 7) {
      setIsExtendButton(false);
    } else {
      setIsExtendButton(true);
    }
  }, [endOfPeriodDay]);

  useEffect(() => {
    dispatch({ type: constants.subscriptionPayment.reset });
    dispatch({ type: constants.subscriptionDetails.reset });

    const tokenCookie = utils.GetCookieValue("token");

    if (tokenCookie) {
      subscriptionDetailsFunc(dispatch, "", {});
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    // console.log("subscriptionDetailsStore", subscriptionDetailsStore);
    if (subscriptionDetailsStore && subscriptionDetailsStore.data) {
      const response = subscriptionDetailsStore.data.response;

      setSubscriptionStatus(response.subscription_status);
      setPaymentCycle(response.payment_cycle);
      setExtensionAmount(response.extension_amount);
      setEndOfPeriodDate(response.end_of_period_date);
      setEndOfPeriodDay(response.end_of_period_day);
    }
  }, [subscriptionDetailsStore]);

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (Object.keys(profileInfoStore).length === 0) {
      setUserLanguage(navigator.language || navigator.languages[0]);
    } else if (profileInfoStore && profileInfoStore.data) {
      const userSetLanguage = profileInfoStore.data.response.language;

      if (userSetLanguage) {
        setUserLanguage(userSetLanguage);
      } else {
        setUserLanguage(navigator.language || navigator.languages[0]);
      }
    }
  }, [profileInfoStore]);

  const handleClickExtendButton = () => {
    if (userLanguage) {
      subscriptionPaymentFunc(dispatch, "", {
        type: paymentCycle.toLowerCase(),
        language: userLanguage.split("-")[0],
      });
    }
  };

  const handleClickSubscriptionButton = () => {
    if (userLanguage) {
      subscriptionPaymentFunc(dispatch, "", {
        type: plan,
        language: userLanguage.split("-")[0],
      });
    }
  };

  useEffect(() => {
    // console.log("subscriptionPaymentStore", subscriptionPaymentStore);
    if (subscriptionPaymentStore && subscriptionPaymentStore.load) {
      setIsPaymentLoading(true);
    }

    if (
      subscriptionPaymentStore &&
      subscriptionPaymentStore.data &&
      subscriptionPaymentStore.data.response
    ) {
      setPaymentUrl(subscriptionPaymentStore.data.response.redirect_url);
    }
  }, [subscriptionPaymentStore]);

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);

  if (isPaymentLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isPaymentLoading} />
      </div>
    );
  }

  if (subscriptionDetailsStore && subscriptionDetailsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={subscriptionDetailsStore.load} />
      </div>
    );
  }

  return (
    <div className="flex justify-center break-words">
      <div className="font-manrope w-screen overflow-hidden min-h-screen max-w-md bg-white pb-[40px]">
        <div className="flex flex-col px-[24px]">
          {/* <div className="mt-[50px]">
            <div className="text-[24px] text-center text-[#161616] font-extrabold leading-[28px]">
              {t("pricing_text1")} 🚀
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="text-[14px] text-center text-[#161616]">
              {t("pricing_text2")}
            </div>
          </div>

          <div className="mt-[30px]">
            <div className="flex justify-end items-center">
              <div className="flex flex-col w-[100px]">
                <div className="text-[20px] font-semibold flex justify-center">
                  {t("basic")}
                </div>
                <div className="text-[12px] font-regular flex justify-center">
                  {t("basic_plan_text")}
                </div>
              </div>

              <div className="flex flex-col w-[100px]">
                <div className="text-[20px] font-semibold flex justify-center">
                  <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                    {t("premium")}
                  </span>
                </div>
                <div className="text-[12px] font-regular flex justify-center">
                  {t("premium_plan_text")}
                </div>
              </div>
            </div>
          </div>

          <table className="text-[#161616] table-fixed">
            <thead>
              <tr className="border-b border-[#E8ECF4]">
                <th className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("general")}
                  </div>
                </th>
                <th className="text-center">
                  <div className="w-[100px] invisible">
                    <div className="text-[12px] font-regular flex justify-center">
                      For starting out
                    </div>
                  </div>
                </th>
                <th className="text-center">
                  <div className="w-[100px] invisible">
                    <div className="text-[20px] font-semibold flex justify-center">
                      Premium
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">{t("monthly")}</div>
                </td>
                <td className="">
                  <div className="text-center text-[16px] font-bold">
                    {t("free")}
                  </div>
                </td>
                <td className="">
                  <div className="text-center text-[16px] font-bold">
                    {t("3990_tg")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("general")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("social_icons")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("profile_picture")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("profile_bio")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("multi_page_navigation")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("share_button_with_QR_code")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("special_blocks")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("product_block")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("youtube_video_block")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("link_to_url_or_pdf_file")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("image_block")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("animation_gif")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("text_block")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("up_to_5_blocks_on_a_page")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("more_than_5_blocks_on_page")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("design")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("blocks_design")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="close.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("social_icons_design")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="close.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("analytics")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("total_page_views")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("total_link_clicks")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("total_link_per_links")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("management_developing")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("create_team")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("blocks_design")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("distribute_roles_among_team_members")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("manage_many_profiles_from_one_account")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td colSpan={3} className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[14px] font-extrabold">
                    {t("monetization")}
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("create_product")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("monetization_of_physical_products")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("monetization_of_digital_products")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("monetization_by_subscription")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/check.svg" alt="check.svg" />
                  </div>
                </td>
              </tr>

              <tr className="border-b border-[#E8ECF4]">
                <td className={`w-full py-[${padding}]`}>
                  <div className="text-start text-[12px]">
                    {t("transaction_fee")}
                  </div>
                </td>
                <td className="">
                  <div className="flex justify-center">
                    <img src="/images/pricing/close.svg" alt="check.svg" />
                  </div>
                </td>
                <td className="">
                  <div className="text-center text-[12px] font-medium">
                    13.5%
                  </div>
                </td>
              </tr>
            </tbody>
          </table> */}

          {subscriptionStatus !== "" ? (
            <div className="mt-[30px]">
              <div className="text-[20px] font-bold">
                {t("susbscription_details")}
              </div>
            </div>
          ) : (
            <div className="mt-[20px]"></div>
          )}

          {subscriptionStatus !== "" && (
            <div className="mt-[15px]">
              <div className="bg-[#F7F8F9] w-full h-[56px] px-[16px] rounded-[10px] flex items-center">
                <div className="flex flex-col">
                  <div className="text-[#8391A1] text-[12px]">
                    {t("subscription_status")}
                  </div>
                  <div
                    className={`text-[16px] font-semibold ${
                      // @ts-ignore
                      subscriptionStatus === "Active"
                        ? "text-[#18C07A]"
                        : "text-[#FF2C20]"
                    }`}
                  >
                    {subscriptionStatus}
                  </div>
                </div>
              </div>
            </div>
          )}

          {paymentCycle !== "" && (
            <div className="mt-[10px]">
              <div className="bg-[#F7F8F9] w-full h-[56px] px-[16px] rounded-[10px] flex items-center">
                <div className="flex flex-col">
                  <div className="text-[#8391A1] text-[12px]">
                    {t("payment_cycle")}
                  </div>
                  <div className={`text-[16px] font-semibold text-[#161616]`}>
                    {t(paymentCycle)}
                  </div>
                </div>
              </div>
            </div>
          )}

          {extensionAmount !== "" && (
            <div className="mt-[10px]">
              <div className="bg-[#F7F8F9] w-full h-[56px] px-[16px] rounded-[10px] flex items-center">
                <div className="flex flex-col">
                  <div className="text-[#8391A1] text-[12px]">
                    {t("extension_amount")}
                  </div>
                  <div className={`text-[16px] font-semibold text-[#161616]`}>
                    {extensionAmount}
                  </div>
                </div>
              </div>
            </div>
          )}

          {endOfPeriodDate !== "" && (
            <div className="mt-[10px]">
              <div className="bg-[#F7F8F9] w-full h-[56px] px-[16px] rounded-[10px] flex items-center">
                <div className="flex flex-col">
                  <div className="text-[#8391A1] text-[12px]">
                    {t("end_of_period")}
                  </div>
                  {
                    //@ts-ignore
                    endOfPeriodDay != "-" ? (
                      <div
                        className={`text-[16px] font-semibold text-[#161616]`}
                      >
                        <span
                          className={
                            //@ts-ignore
                            endOfPeriodDay <= 7 ? "text-[#FF2C20]" : ""
                          }
                        >
                          {endOfPeriodDay}{" "}
                          {
                            //@ts-ignore
                            endOfPeriodDay === 1
                              ? t("days-1")
                              : //@ts-ignore
                                endOfPeriodDay < 5
                                ? t("days-2")
                                : t("days")
                          }
                        </span>{" "}
                        ({endOfPeriodDate})
                      </div>
                    ) : (
                      <div
                        className={`text-[16px] font-semibold text-[#161616]`}
                      >
                        {endOfPeriodDate}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )}

          {isExtendButton && (
            <div className="mt-[15px]">
              <button
                onClick={handleClickExtendButton}
                className="w-full h-[50px] flex justify-center items-center bg-[#161616] text-white rounded-[10px]"
              >
                {t("extend")}
              </button>
            </div>
          )}

          <div className="mt-[20px]">
            <div className="text-[28px] leading-[32px] font-extrabold">
              Hubbbox{" "}
              <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                Premium
              </span>{" "}
              <span className="text-[28px] leading-[32px]">🚀</span>
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="text-[16px]">{t("subscription_window_text3")}</div>
          </div>

          <div className="mt-[20px]">
            <div className="text-[18px] font-bold">
              {t("subscription_window_text4")}
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="flex space-x-[10px] items-start">
              <img
                src="/images/subscription_window/block.svg"
                alt="block.svg"
                className="w-[24px] h-[24px]"
              />
              <div className="">
                <div className="text-[16px] font-bold">
                  {t("subscription_window_text5")}
                </div>
                <div className="mt-[10px]">
                  <span className="text-[16px]">
                    {t("subscription_window_text6")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[15px]">
            <div className="flex space-x-[10px] items-start">
              <img
                src="/images/subscription_window/product.svg"
                alt="product.svg"
                className="w-[24px] h-[24px]"
              />
              <div className="">
                <div className="text-[16px] font-bold">
                  {t("subscription_window_text7")}
                </div>
                <div className="mt-[10px]">
                  <span className="text-[16px]">
                    {t("subscription_window_text8")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[15px]">
            <div className="flex space-x-[10px] items-start">
              <img
                src="/images/subscription_window/pen.svg"
                alt="pen.svg"
                className="w-[24px] h-[24px]"
              />
              <div className="">
                <div className="text-[16px] font-bold">
                  {t("subscription_window_text9")}
                </div>
                <div className="mt-[10px]">
                  <span className="text-[16px]">
                    {t("subscription_window_text10")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[15px]">
            <div className="flex space-x-[10px] items-start">
              <img
                src="/images/subscription_window/palette.svg"
                alt="palette.svg"
                className="w-[24px] h-[24px]"
              />
              <div className="">
                <div className="text-[16px] font-bold">
                  {t("subscription_window_text11")}
                </div>
                <div className="mt-[10px]">
                  <span className="text-[16px]">
                    {t("subscription_window_text12")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[25px]">
            <div
              className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
              onClick={() => {
                setPlan("yearly");
              }}
            >
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-[#161616] text-[20px] font-bold">
                    {t("annual")}{" "}
                    <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                      -30%
                    </span>
                  </div>
                  <div className="text-[#8391A1] text-[14px]">
                    {t("annual_text1")}
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {t("annual_text2")}
                  </div>
                </div>

                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={plan === "yearly"}
                  onChange={() => {
                    setPlan("yearly");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-[10px]">
            <div
              className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
              onClick={() => {
                setPlan("monthly");
              }}
            >
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-[#161616] text-[20px] font-bold">
                    {t("monthly_pricing")}
                  </div>
                  <div className="text-[#8391A1] text-[14px]">
                    {t("monthly_text")}
                  </div>
                </div>

                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={plan === "monthly"}
                  onChange={() => {
                    setPlan("monthly");
                  }}
                />
              </div>
            </div>
          </div>

          {
            !tokenCookie ? (
              <div className="mt-[15px]">
                <button
                  className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
                  onClick={() => {
                    if (tokenCookie) {
                      handleClickSubscriptionButton();
                    } else {
                      navigate("/auth");
                    }
                  }}
                >
                  <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
                  <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
                  <span className="relative">{t("get_premium_plan")}</span>
                </button>
              </div>
            ) : (
              isExtendButton && (
                <div className="mt-[15px]">
                  <button
                    className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
                    onClick={() => {
                      if (tokenCookie) {
                        handleClickSubscriptionButton();
                      } else {
                        navigate("/auth");
                      }
                    }}
                  >
                    <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
                    <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
                    <span className="relative">{t("get_premium_plan")}</span>
                  </button>
                </div>
              )
            )
            // : (
            //   <div className="mt-[15px]">
            //     <button
            //       className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
            //       onClick={() => {
            //         navigate("/home");
            //       }}
            //     >
            //       <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
            //       <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
            //       <span className="relative">{t("home")}</span>
            //     </button>
            //   </div>
            // )
          }
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
