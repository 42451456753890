import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
  list,
} from "@material-tailwind/react";
import StepZero from "../../components/ui/AccountLaunch/StepZero";
import StepOne from "../../components/ui/AccountLaunch/StepOne";
import StepTwo from "../../components/ui/AccountLaunch/StepTwo";
import { useNavigate } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "../../components/utils";
import LoadingScreen from "../../components/ui/LoadingScreen";
// import StepThree from "../../components/AccountLaunch/StepThree";
import SocialLinks from "../../components/ui/SocialLinks";
import ProfileType from "../../components/ui/ProfileType";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import * as constantsSocial from "../../components/ui/ListOfSocialMediaLinks";

export async function sendProfileParametersFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendProfileParameters,
    dispatch,
    `api/auth/profile_parameters/`,
    "post",
    "",
    form,
    true
  )();

  // try {
  //   dispatch({ type: constants.sendProfileParameters.load });
  //   const config = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const response = await axios.post(
  //     `${constants.API}api/auth/profile_parameters/`,
  //     // @ts-ignore
  //     config,
  //     form
  //     // { data: [...form] },
  //   );
  //   if (response.data) {
  //     dispatch({
  //       type: constants.sendProfileParameters.success,
  //       payload: response.data,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.sendProfileParameters.error,
  //       payload: response.statusText,
  //     });
  //   }
  // } catch (error: any) {
  //   if (error.response && error.response.data) {
  //     dispatch({
  //       type: constants.sendProfileParameters.fail,
  //       payload: error.response.data.error,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.sendProfileParameters.fail,
  //       payload: error.toString(),
  //     });
  //   }
  //   console.error("error: ", error);
  // }
}

export async function checkAccountLaunchFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.checkAccountLaunch,
    dispatch,
    `api/auth/check_account_launch/`,
    "get",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [username, setUsername] = useState("");
  const [profileName, setProfileName] = useState("");
  const [status, setStatus] = useState("");
  const [profileStatusLogo, setProfileStatusLogo] = useState("");
  const [bio, setBio] = useState("");
  const [profileType, setProfileType] = useState("Classic");
  const [plan, setPlan] = useState("yearly");
  const [listOfSocialMediaLinks, setListOfSocialMediaLinks] = useState([]);
  const [listOfSocialMediaLinksFinished, setListOfSocialMediaLinksFinished] =
    useState([]);
  const [listOfSelectedSocialMediaLinks, setListOfSelectedSocialMediaLinks] =
    useState([]);
  const [avatar, setAvatar] = useState(null);
  const [profileCover, setProfileCover] = useState(null);
  const [nameProfileCover, setNameProfileCover] = useState("");
  const [messageProfileCover, setMessageProfileCover] = useState("");
  const [messageAvatar, setMessageAvatar] = useState("");

  // SUBSCRIPTION PAYMENT
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const handleChangeLoading = () => {
    setIsLoading(!isLoading);
  };

  const changeListData = (valueName: any, newValue: any) => {
    let tmpList = structuredClone(listOfSocialMediaLinks);

    let lowerNewValue = newValue.toLowerCase();

    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i]["name"] === valueName) {
        // @ts-ignore
        tmpList[i]["value"] = lowerNewValue;
      }
    }
    setListOfSocialMediaLinks(tmpList);
  };

  const changeChecked = (valueName: any) => {
    let tmpList = structuredClone(listOfSocialMediaLinks);

    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i]["name"] === valueName) {
        if (tmpList[i]["value"] !== "") {
          // @ts-ignore
          tmpList[i]["checked"] = !tmpList[i]["checked"];
        }
      }
    }

    setListOfSocialMediaLinks(tmpList);
  };

  const getPlaceholder = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["placeholder"];
      }
    }
    return "";
  };

  const getChecked = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["checked"];
      }
    }
    return false;
  };

  const getValue = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["value"];
      }
    }
    return "";
  };

  const isFirstListElem = (valName: string) => {
    // @ts-ignore
    if (valName === listOfSelectedSocialMediaLinks[0].name) {
      return true;
    } else {
      return false;
    }
  };

  const isLastListElem = (valName: string) => {
    const lastElem =
      listOfSelectedSocialMediaLinks[listOfSelectedSocialMediaLinks.length - 2];

    if (
      //@ts-ignore
      valName === lastElem.name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleMoveListElem = (valName: string, action: string) => {
    const newList = structuredClone(listOfSocialMediaLinks);

    // Находим индекс элемента с block_num
    const index = newList.findIndex(
      //@ts-ignore
      (item) => item.name === valName
    );

    if (action === "move_up") {
      if (index === -1 || index === 0) {
        return;
      }

      let prevElemIndex = 0;

      for (let i = 0; i < listOfSelectedSocialMediaLinks.length; i++) {
        if (i !== 0) {
          //@ts-ignore
          prevElemIndex = listOfSelectedSocialMediaLinks[i - 1].index - 1;
        }

        //@ts-ignore
        if (listOfSelectedSocialMediaLinks[i].index === index + 1) {
          break;
        }
      }

      // Временная переменная для хранения значения элемента с индексом index1
      let temp = newList[index];

      // Присваиваем значение элемента с индексом index2 элементу с индексом index1
      newList[index] = newList[prevElemIndex];

      // Присваиваем значение временной переменной элементу с индексом index2
      newList[prevElemIndex] = temp;
    } else if (action === "move_down") {
      if (index === newList.length - 1) {
        return;
      }

      let lastElemIndex = 0;

      for (let i = listOfSelectedSocialMediaLinks.length - 1; i >= 0; i--) {
        if (i !== listOfSelectedSocialMediaLinks.length - 1) {
          //@ts-ignore
          lastElemIndex = listOfSelectedSocialMediaLinks[i + 1].index - 1;
        }

        //@ts-ignore
        if (listOfSelectedSocialMediaLinks[i].index === index + 1) {
          break;
        }
      }

      // Временная переменная для хранения значения элемента с индексом index1
      let temp = newList[index];

      // Присваиваем значение элемента с индексом index2 элементу с индексом index1
      newList[index] = newList[lastElemIndex];

      // Присваиваем значение временной переменной элементу с индексом index2
      newList[lastElemIndex] = temp;
    } else if (action === "move_to_top") {
      if (index === -1 || index === 0) {
        return;
      }

      // Извлечем объект из списка
      let [objectWithValName] = newList.splice(index, 1);
      // Вставим объект на первое место
      newList.unshift(objectWithValName);
    } else if (action === "move_to_bottom") {
      if (index === newList.length - 1) {
        return;
      }

      // Извлечем объект из списка
      let [objectWithBlockNum] = newList.splice(index, 1);
      // Вставим объект на первое место
      newList.push(objectWithBlockNum);
    }

    setListOfSocialMediaLinks(newList);
  };

  useEffect(() => {
    // console.log("listOfSocialMediaLinks", listOfSocialMediaLinks);
    let tmpList = [];
    //@ts-ignore
    let listOfSocialMediaLinksFinishedTmp = [];

    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["checked"]) {
        //@ts-ignore
        tmpList.push({ ...listOfSocialMediaLinks[i], index: i + 1 });
      }

      if (listOfSocialMediaLinks[i]["value"] !== "") {
        listOfSocialMediaLinksFinishedTmp.push({
          //@ts-ignore
          name: listOfSocialMediaLinks[i].name,
          //@ts-ignore
          checked: listOfSocialMediaLinks[i].checked,
          //@ts-ignore
          value: listOfSocialMediaLinks[i].value,
        });
      }
    }

    const share_obj = {
      icon: "/images/social_media_icons/share.svg",
    };

    if (tmpList.length > 0) {
      tmpList.push({ ...share_obj, index: listOfSocialMediaLinks.length + 1 });
    }

    //@ts-ignore
    setListOfSelectedSocialMediaLinks(tmpList);
    //@ts-ignore
    setListOfSocialMediaLinksFinished(listOfSocialMediaLinksFinishedTmp);
  }, [listOfSocialMediaLinks]);

  const navigate = useNavigate();

  function handleChangeStep() {
    setStep(step + 1);
  }

  function handleChangeStepBack() {
    setStep(step - 1);
  }

  function handleChangeProfile(profileNew: any) {
    setProfileName(profileNew);
  }

  function handleChangeStatus(statusNew: any) {
    setStatus(statusNew);
  }

  function handleChangeBio(bioNew: any) {
    setBio(bioNew);
  }

  function goHome() {
    navigate("/home");
    utils.LocalStorage.remove("AccountLaunchData");
    dispatch({ type: constants.sendProfileParameters.reset });
  }

  useEffect(() => {
    const accountLaunchDataStorage =
      utils.LocalStorage.get("AccountLaunchData");
    if (accountLaunchDataStorage) {
      if (accountLaunchDataStorage.step) {
        setStep(accountLaunchDataStorage.step);
      }
      if (accountLaunchDataStorage.profileName) {
        setProfileName(accountLaunchDataStorage.profileName);
      }
      if (accountLaunchDataStorage.status) {
        setStatus(accountLaunchDataStorage.status);
      }
      if (accountLaunchDataStorage.bio) {
        setBio(accountLaunchDataStorage.bio);
      }
      if (accountLaunchDataStorage.profileType) {
        setProfileType(accountLaunchDataStorage.profileType);
      }
      if (accountLaunchDataStorage.plan) {
        setPlan(accountLaunchDataStorage.plan);
      }
      if (
        accountLaunchDataStorage.listOfSocialMediaLinks &&
        accountLaunchDataStorage.listOfSocialMediaLinks.length > 0
      ) {
        setListOfSocialMediaLinks(
          accountLaunchDataStorage.listOfSocialMediaLinks
        );
      } else {
        //@ts-ignore
        setListOfSocialMediaLinks(constantsSocial.listOfSocialMediaLinks);
      }
      if (accountLaunchDataStorage.avatar) {
        setAvatar(accountLaunchDataStorage.avatar);
      }
      if (accountLaunchDataStorage.profileCover) {
        setProfileCover(accountLaunchDataStorage.profileCover);
      }
      if (accountLaunchDataStorage.nameProfileCover) {
        setNameProfileCover(accountLaunchDataStorage.nameProfileCover);
      }
    }

    checkAccountLaunchFunc(dispatch, "", {});

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    utils.LocalStorage.set("AccountLaunchData", {
      step: step,
      profileName: profileName,
      status: status,
      bio: bio,
      profileType: profileType,
      plan: plan,
      listOfSocialMediaLinks: listOfSocialMediaLinks,
      // avatar: avatar,
      // profileCover: profileCover,
      // nameProfileCover: nameProfileCover,
    });
  }, [
    step,
    profileName,
    status,
    bio,
    profileType,
    plan,
    listOfSocialMediaLinks,
    // avatar,
    // profileCover,
    // nameProfileCover,
  ]);

  const validateFileType = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSize = (file: any) => {
    return file.size <= constants.MaxSizeAvatar * 1024 * 1024;
  };

  const handleAvatarChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageAvatar("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileType(file)) {
    //     setMessageAvatar(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSize(file)) {
    //     setMessageAvatar(
    //       `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // localStorage.setItem("image", base64String);
    //       // @ts-ignore
    //       setAvatar(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageAvatar("");
    //   }
    // }

    setAvatar(fileStr);
  };

  const handleButtonClickAvatar = () => {
    //@ts-ignore
    document.getElementById("avatarInput").click();
  };

  const handleProfileCoverChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageProfileCover("Please select only one file.");
    //   return;
    // }

    // const file = files[0];
    // if (file) {
    //   setNameProfileCover(file.name);
    // }

    // if (file) {
    //   if (!validateFileType(file)) {
    //     notify(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //     // setMessageProfileCover(
    //     //   "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     // );
    //   } else if (!validateFileSize(file)) {
    //     notify(
    //       `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     );
    //     // setMessageProfileCover(
    //     //   `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     // );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // localStorage.setItem("image", base64String);
    //       // @ts-ignore
    //       setProfileCover(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageProfileCover("");
    //   }
    // }

    setProfileCover(fileStr);
  };

  const handleChangeNameProfileCover = (newVal: string) => {
    setNameProfileCover(newVal);
  };

  const handleButtonClickProfileCover = () => {
    //@ts-ignore
    document.getElementById("profileCover").click();
  };

  const handleButtonDeleteCover = () => {
    setProfileCover(null);
    setNameProfileCover("");
  };

  const handleChangeProfileStatusLogo = (fileStr: any) => {
    setProfileStatusLogo(fileStr);
  };

  const handleButtonClickProfileStatusLogo = () => {
    //@ts-ignore
    document.getElementById("profileStatusLogo").click();
  };

  const handleButtonDeleteProfileStatusLogo = () => {
    setProfileStatusLogo("");
  };

  // useEffect(() => {
  //   console.log("step", step);
  // }, [step]);

  const dispatch = useDispatch();

  const sendProfileParametersStore = useSelector(
    (state: any) => state.sendProfileParametersStore
  );

  const checkAccountLaunchStore = useSelector(
    (state: any) => state.checkAccountLaunchStore
  );

  const [iconDesign, setIconDesign] = useState("monochrome");

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      // console.log("profileInfoStore", profileInfoStore);
      setUsername(profileInfoStore.data.response.username);
      setIconDesign(profileInfoStore.data.response.design_icons);
    }
  }, [profileInfoStore]);

  useEffect(() => {
    // console.log("sendProfileParametersStore", sendProfileParametersStore);
    if (
      sendProfileParametersStore &&
      sendProfileParametersStore.data &&
      sendProfileParametersStore.data.response
    ) {
      if (plan === "free") {
        navigate("/home");
      } else {
        window.location.href =
          sendProfileParametersStore.data.response.redirect_url;
      }

      utils.LocalStorage.remove("AccountLaunchData");
    }
  }, [sendProfileParametersStore]);

  useEffect(() => {
    // console.log("sendProfileParametersStore", sendProfileParametersStore);
    if (checkAccountLaunchStore && checkAccountLaunchStore.data) {
      if (!checkAccountLaunchStore.data.response) {
        navigate("/home");
        utils.LocalStorage.remove("AccountLaunchData");
      }
    }
  }, [checkAccountLaunchStore]);

  const handleClickProfileParameters = (zeroPlan = "") => {
    setIsPaymentLoading(true);

    // Создать объект FormData для отправки файла
    const formData = new FormData();

    if (avatar) {
      // @ts-ignore
      const base64String = avatar.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = avatar
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append("avatar", fileBlob, `avatar.${fileType}`);
    }

    if (profileStatusLogo) {
      // @ts-ignore
      const base64String = profileStatusLogo.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = profileStatusLogo
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append(
        "profileStatusLogo",
        fileBlob,
        `profileStatusLogo.${fileType}`
      );
    }

    if (profileCover) {
      // @ts-ignore
      const base64String = profileCover.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = profileCover
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append("profileCover", fileBlob, `profileCover.${fileType}`);
    }

    const dataForm = {
      profileName: profileName,
      bio: bio,
      profileType: profileType,
      // plan: plan,
      listOfSocialMediaLinks: listOfSocialMediaLinksFinished,
      status: status,
      // avatar: avatar,
    };

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    if (zeroPlan !== "") {
      formData.append("plan", zeroPlan);
    } else {
      formData.append("plan", plan);
    }

    let userLanguage = "ru-RU";

    if (Object.keys(profileInfoStore).length === 0) {
      userLanguage = navigator.language || navigator.languages[0];
    } else if (profileInfoStore && profileInfoStore.data) {
      const userSetLanguage = profileInfoStore.data.response.language;

      if (userSetLanguage) {
        userLanguage = userSetLanguage;
      } else {
        userLanguage = navigator.language || navigator.languages[0];
      }
    }

    formData.append("language", userLanguage.split("-")[0]);

    sendProfileParametersFunc(dispatch, "", formData);
  };

  // useEffect(() => {
  //   if (paymentUrl) {
  //     window.location.href = paymentUrl;
  //   }
  // }, [paymentUrl]);

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  if (sendProfileParametersStore && sendProfileParametersStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendProfileParametersStore.load} />
      </div>
    );
  }

  if (checkAccountLaunchStore && checkAccountLaunchStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={checkAccountLaunchStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  if (isPaymentLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isPaymentLoading} />
      </div>
    );
  }

  const padding = "10px";

  function StepThree() {
    // This is a test bio of my profile to evaluate the visual perception of this aspect of customization. This is additional text to fill out the field.

    return (
      <div className="flex justify-center">
        {/* <div className="font-manrope px-[24px] pb-[50px] w-screen max-w-md min-h-screen"> */}
        <div className="font-manrope pb-[50px] w-screen max-w-md">
          <div className="flex flex-col">
            <div className="flex justify-between items-center h-[56px] px-[24px]">
              <button onClick={handleChangeStepBack}>
                {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </button>
              <div className="text-[18px] leading-[28px] text-center text-[#161616]">
                {t("layout")}
              </div>

              <div className="invisible">
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[2px] flex justify-center items-center px-[24px]">
              <img
                src="/images/account_launch/step_three.svg"
                alt="step_three.svg"
                className="w-[260px] h-[32px]"
              />
            </div>

            <div className="mt-[15px]">
              <ProfileType
                page={"AccLaunch"}
                profileName={profileName}
                status={status}
                profileStatusLogo={profileStatusLogo}
                bio={bio}
                avatar={avatar}
                username={username}
                // profileCover={profileCover}
                iconDesign={iconDesign}
                listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
                profileType={profileType}
              />
            </div>

            <div
              className={`${profileType === "Classic" ? "mt-[40px]" : "mt-[150px]"} flex justify-center px-[24px]`}
            >
              <div
                className="w-full p-[10px] rounded-[10px] flex justify-between items-center shadow-custom"
                onClick={() => {
                  setProfileType("Classic");
                }}
              >
                <label className="pl-[10px]">
                  <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                    {t("classic")}
                  </div>
                  <div className="text-[#8391A1] mt-[3px] text-[14px] leading-[20px]">
                    {t("classic_style_text")}
                  </div>
                </label>
                <div className="pr-[5px]">
                  <Radio
                    name="description"
                    crossOrigin={""}
                    checked={profileType === "Classic"}
                    onChange={() => {
                      setProfileType("Classic");
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="mt-[10px] flex justify-center">
            <div className="w-[342px] h-[74px] border-[1px] border-[#8391A1] rounded-[10px] flex items-center">
              <Radio
                name="description"
                label={
                  <div>
                    <div className="text-[20px] leading-[20px] font-semibold text-[#161616]">
                      Minimalist
                    </div>
                    <div className="text-[#8391A1] text-[12px] leading-[20px]">
                      Left aligned small profile picture
                    </div>
                  </div>
                }
                containerProps={{
                  className: "",
                }}
                crossOrigin={""}
                checked={profileType === "Minimalist"}
                onChange={() => {
                  //   handleChangeProfileType("Minimalist");
                  setProfileType("Minimalist");
                }}
              />
            </div>
          </div> */}

            <div className="mt-[10px] flex justify-center px-[24px]">
              <div
                className="w-full p-[10px] rounded-[10px] flex justify-between items-center shadow-custom"
                onClick={() => {
                  setProfileType("Minimalist");
                }}
              >
                <label className="pl-[10px]">
                  <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                    {t("minimalist")}
                  </div>
                  <div className="text-[#8391A1] mt-[3px] text-[14px] leading-[20px]">
                    {t("minimalist_style_text")}
                  </div>
                </label>
                <div className="pr-[5px]">
                  <Radio
                    name="description"
                    crossOrigin={""}
                    checked={profileType === "Minimalist"}
                    onChange={() => {
                      setProfileType("Minimalist");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[62px] flex justify-center px-[24px]">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
                onClick={() => {
                  setStep(step + 1);
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function StepFour() {
    //   useEffect(() => {
    //     console.log("profileName", profileName);
    //   }, [profileName]);

    return (
      <div className="flex justify-center">
        {/* <div className="font-manrope px-[24px] pb-[50px] max-w-md w-screen min-h-screen"> */}
        <div className="font-manrope px-[24px] pb-[20px] max-w-md w-screen bg-[#FAFAFA] min-h-screen">
          <div className="flex flex-col">
            <div className="flex justify-between items-center h-[56px]">
              <button onClick={handleChangeStepBack}>
                {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </button>
              <div className="text-[18px] leading-[20px] font-semibold text-center text-[#161616]">
                {t("select_plan")}
              </div>
              <button className="invisible">
                {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
                <div className="p-[10px]">
                  <img
                    src="/images/back_arrow.svg"
                    alt="back_arrow.svg"
                    className="w-[7px] h-[14px]"
                  />
                </div>
              </button>
            </div>

            <div className="mt-[23px]">
              {/* <div className="text-[24px] text-center text-[#161616] font-extrabold leading-[28px]">
                {t("pricing_text1")} 🚀
              </div>

              <div className="mt-[20px]">
                <div className="text-[14px] text-center text-[#161616]">
                  {t("pricing_text2")}
                </div>
              </div>

              <div className="mt-[30px]">
                <div className="flex justify-end items-center">
                  <div className="flex flex-col w-[100px]">
                    <div className="text-[20px] font-semibold flex justify-center">
                      {t("basic")}
                    </div>
                    <div className="text-[12px] font-regular flex justify-center">
                      {t("basic_plan_text")}
                    </div>
                  </div>

                  <div className="flex flex-col w-[100px]">
                    <div className="text-[20px] font-semibold flex justify-center">
                      <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                        {t("premium")}
                      </span>
                    </div>
                    <div className="text-[12px] font-regular flex justify-center">
                      {t("premium_plan_text")}
                    </div>
                  </div>
                </div>

                <table className="text-[#161616] table-fixed">
                  <thead>
                    <tr className="border-b border-[#E8ECF4]">
                      <th className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("general")}
                        </div>
                      </th>
                      <th className="text-center">
                        <div className="w-[100px] invisible">
                          <div className="text-[12px] font-regular flex justify-center">
                            For starting out
                          </div>
                        </div>
                      </th>
                      <th className="text-center">
                        <div className="w-[100px] invisible">
                          <div className="text-[20px] font-semibold flex justify-center">
                            Premium
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("monthly")}
                        </div>
                      </td>
                      <td className="">
                        <div className="text-center text-[16px] font-bold">
                          {t("free")}
                        </div>
                      </td>
                      <td className="">
                        <div className="text-center text-[16px] font-bold">
                          {t("3990_tg")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("general")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("social_icons")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("profile_picture")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("profile_bio")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("multi_page_navigation")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("share_button_with_QR_code")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("special_blocks")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("product_block")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("youtube_video_block")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("link_to_url_or_pdf_file")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("image_block")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("animation_gif")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("text_block")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("up_to_5_blocks_on_a_page")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("more_than_5_blocks_on_page")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("design")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("blocks_design")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="close.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("social_icons_design")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="close.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("analytics")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("total_page_views")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("total_link_clicks")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("total_link_per_links")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("management_developing")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("create_team")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("blocks_design")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("distribute_roles_among_team_members")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("manage_many_profiles_from_one_account")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td colSpan={3} className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[14px] font-extrabold">
                          {t("monetization")}
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("create_product")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("monetization_of_physical_products")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("monetization_of_digital_products")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("monetization_by_subscription")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/check.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-[#E8ECF4]">
                      <td className={`w-full py-[${padding}]`}>
                        <div className="text-start text-[12px]">
                          {t("transaction_fee")}
                        </div>
                      </td>
                      <td className="">
                        <div className="flex justify-center">
                          <img
                            src="/images/pricing/close.svg"
                            alt="check.svg"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="text-center text-[12px] font-medium">
                          13.5%
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              <div className="">
                <div className="text-[28px] leading-[32px] font-extrabold">
                  {t("pricing_subscription_text1")} Hubbbox{" "}
                  <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                    Premium
                  </span>{" "}
                  <span className="text-[28px] leading-[32px]">🚀</span>
                </div>
              </div>

              <div className="mt-[20px]">
                <div className="text-[16px]">
                  {t("subscription_window_text3")}
                </div>
              </div>

              <div className="mt-[20px]">
                <div className="text-[18px] font-bold">
                  {t("subscription_window_text4")}
                </div>
              </div>

              <div className="mt-[20px]">
                <div className="flex space-x-[10px] items-start">
                  <img
                    src="/images/subscription_window/block.svg"
                    alt="block.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <div className="">
                    <div className="text-[16px] font-bold">
                      {t("subscription_window_text5")}
                    </div>
                    <div className="mt-[10px]">
                      <span className="text-[16px]">
                        {t("subscription_window_text6")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex space-x-[10px] items-start">
                  <img
                    src="/images/subscription_window/product.svg"
                    alt="product.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <div className="">
                    <div className="text-[16px] font-bold">
                      {t("subscription_window_text7")}
                    </div>
                    <div className="mt-[10px]">
                      <span className="text-[16px]">
                        {t("subscription_window_text8")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex space-x-[10px] items-start">
                  <img
                    src="/images/subscription_window/pen.svg"
                    alt="pen.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <div className="">
                    <div className="text-[16px] font-bold">
                      {t("subscription_window_text9")}
                    </div>
                    <div className="mt-[10px]">
                      <span className="text-[16px]">
                        {t("subscription_window_text10")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex space-x-[10px] items-start">
                  <img
                    src="/images/subscription_window/palette.svg"
                    alt="palette.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <div className="">
                    <div className="text-[16px] font-bold">
                      {t("subscription_window_text11")}
                    </div>
                    <div className="mt-[10px]">
                      <span className="text-[16px]">
                        {t("subscription_window_text12")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <div
                  className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
                  onClick={() => {
                    setPlan("yearly");
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <div className="text-[#161616] text-[20px] font-bold">
                        {t("annual")}{" "}
                        <span className="bg-gradient-to-r from-[#FB047B] to-[#130EFF] text-transparent bg-clip-text">
                          -30%
                        </span>
                      </div>
                      <div className="text-[#8391A1] text-[14px]">
                        {t("annual_text1")}
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        {t("annual_text2")}
                      </div>
                    </div>

                    <Radio
                      name="description"
                      crossOrigin={""}
                      checked={plan === "yearly"}
                      onChange={() => {
                        setPlan("yearly");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[10px]">
                <div
                  className="w-full px-[20px] py-[16px] rounded-[10px] shadow-customblock"
                  onClick={() => {
                    setPlan("monthly");
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <div className="text-[#161616] text-[20px] font-bold">
                        {t("monthly_pricing")}
                      </div>
                      <div className="text-[#8391A1] text-[14px]">
                        {t("monthly_text")}
                      </div>
                    </div>

                    <Radio
                      name="description"
                      crossOrigin={""}
                      checked={plan === "monthly"}
                      onChange={() => {
                        setPlan("monthly");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <button
                  className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
                  onClick={() => {
                    handleClickProfileParameters(plan);
                  }}
                >
                  <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
                  <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
                  <span className="relative">{t("get_premium_plan")}</span>
                </button>
              </div>

              <div className="mt-[15px]">
                <div
                  className="text-[16px] text-center font-bold text-[#161616]"
                  onClick={() => {
                    handleClickProfileParameters("free");
                  }}
                >
                  {t("free_plan")}
                </div>
              </div>

              <div className="mt-[30px] flex justify-center">
                <div className="text-[16px] leading-[22px] text-[#8391A1]">
                  {t("plan_text")}{" "}
                  <span
                    className="text-[#161616] underline"
                    onClick={() => {
                      window.open(
                        // `https://www.hubbbox.com/term-of-service`,
                        "https://api.hubbbox.com/static/media/Пользовательское%20соглашение.pdf",
                        "_blank"
                      );
                    }}
                  >
                    {t("term_of_service")}
                  </span>{" "}
                  {t("and")}{" "}
                  <span
                    className="text-[#161616] underline"
                    onClick={() => {
                      window.open(
                        // `https://www.hubbbox.com/privacy-policy`,
                        "https://api.hubbbox.com/static/media/Политика%20конфиденциальности.pdf",
                        "_blank"
                      );
                    }}
                  >
                    {" "}
                    {t("privacy_policy")}.
                  </span>
                </div>
              </div>

              {/* <div className="mt-[20px]">
                  <div
                    className="h-[50px] flex justify-center items-center rounded-[10px] bg-black text-white text-[16px] font-semibold"
                    onClick={() => {
                      handleClickProfileParameters("premium");
                    }}
                  >
                    {t("get_premium_plan")}
                  </div>
                </div> */}
            </div>

            {/* <div className="mt-[24px]">
              <div className="text-[#161616] text-[26px] leading-[28px] font-semibold">
                {t("premium_subscription")}
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="text-[16px] text-[#161616] leading-[18px]">
                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text1")}</div>
                </div>

                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text2")}</div>
                </div>

                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text3")}</div>
                </div>

                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text5")}</div>
                </div>

                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text6")}</div>
                </div>

                <div className="flex space-x-[7px] items-center">
                  <img
                    src="/images/login/check.svg"
                    alt="check.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <div className="">{t("select_plan_text7")}</div>
                </div>
              </div>
            </div>

            <div className="mt-[22px] flex justify-center">
              <div
                className="w-full h-[74px] rounded-[10px] flex justify-between items-center shadow-custom p-[20px]"
                onClick={() => {
                  setPlan("first");
                }}
              >
                <div>
                  <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                    {t("plan1_subscription")}
                  </div>
                  <div className="text-[#8391A1] mt-[3px] text-[14px] leading-[20px]">
                    {t("plan1_subscription_text")}
                  </div>
                </div>
                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={plan === "first"}
                  onChange={() => {
                    setPlan("first");
                  }}
                />
              </div>
            </div>

            <div className="mt-[10px] flex justify-center">
              <div
                className="w-full h-[74px] rounded-[10px] flex justify-between items-center shadow-custom p-[20px]"
                onClick={() => {
                  setPlan("second");
                }}
              >
                <div>
                  <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                    {t("plan2_subscription")}
                  </div>
                  <div className="text-[#8391A1] text-[14px] leading-[20px]">
                    {t("plan2_subscription_text")}
                  </div>
                </div>
                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={plan === "second"}
                  onChange={() => {
                    setPlan("second");
                  }}
                />
              </div>
            </div>

            <div className="mt-[10px] flex justify-center">
              <div
                className="w-full h-[74px] rounded-[10px] flex justify-between items-center shadow-custom p-[20px]"
                onClick={() => {
                  setPlan("third");
                }}
              >
                <div>
                  <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                    {t("plan3_subscription")}
                  </div>
                  <div className="text-[#8391A1] text-[14px] leading-[20px]">
                    {t("plan3_subscription_text")}
                  </div>
                </div>
                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={plan === "third"}
                  onChange={() => {
                    setPlan("third");
                  }}
                />
              </div>
            </div>

            <div className="mt-[20px]">
              <div className="flex justify-center text-[16px] text-[#161616] leading-[18px] font-semibold">
                <button
                  onClick={() => {
                    // setPlan("zero");
                    handleClickProfileParameters("zero");
                  }}
                >
                  {t("free_plan")}
                </button>
              </div>
            </div>

            <div className="mt-[52px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
                onClick={() => {
                  handleClickProfileParameters();
                }}
              >
                {t("continue")}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="break-words overflow-hidden">
        {step === 0 && <StepZero handleChangeStep={handleChangeStep} />}
        {step === 1 && (
          <StepOne
            handleChangeStep={handleChangeStep}
            handleChangeStepBack={handleChangeStepBack}
            handleChangeProfile={handleChangeProfile}
            handleChangeBio={handleChangeBio}
            handleChangeStatus={handleChangeStatus}
            iconDesign={iconDesign}
            username={username}
            profileName={profileName}
            status={status}
            handleChangeProfileStatusLogo={handleChangeProfileStatusLogo}
            handleButtonClickProfileStatusLogo={
              handleButtonClickProfileStatusLogo
            }
            handleButtonDeleteProfileStatusLogo={
              handleButtonDeleteProfileStatusLogo
            }
            profileStatusLogo={profileStatusLogo}
            bio={bio}
            avatar={avatar}
            notify={notify}
            handleAvatarChange={handleAvatarChange}
            handleButtonClickAvatar={handleButtonClickAvatar}
            profileCover={profileCover}
            nameProfileCover={nameProfileCover}
            handleChangeNameProfileCover={handleChangeNameProfileCover}
            handleProfileCoverChange={handleProfileCoverChange}
            handleButtonDeleteCover={handleButtonDeleteCover}
            handleButtonClickProfileCover={handleButtonClickProfileCover}
            messageAvatar={messageAvatar}
            goHome={goHome}
            profileType={profileType}
            listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
          />
        )}
        {step === 2 && (
          <StepTwo
            handleChangeStep={handleChangeStep}
            handleChangeStepBack={handleChangeStepBack}
            listOfSocialMediaLinks={listOfSocialMediaLinks}
            profileType={profileType}
            listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
            changeListData={changeListData}
            changeChecked={changeChecked}
            getChecked={getChecked}
            getValue={getValue}
            getPlaceholder={getPlaceholder}
            iconDesign={iconDesign}
            username={username}
            profileName={profileName}
            status={status}
            profileStatusLogo={profileStatusLogo}
            bio={bio}
            avatar={avatar}
            profileCover={profileCover}
            goHome={goHome}
            handleMoveListElem={handleMoveListElem}
            isLastListElem={isLastListElem}
            isFirstListElem={isFirstListElem}
          />
        )}
        {step === 3 && <StepThree />}
        {step === 4 && <StepFour />}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
