import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Component({ id, value, onChange, onRemove }: any) {
  const { t, i18n } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      //@ts-ignore
      textarea.style.height = "auto";
      //@ts-ignore
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault(); // Останавливаем потерю фокуса перед удалением
    onRemove(id);
  };

  return (
    <div className="relative mb-[10px]">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={t("placeholder_textarea_post")}
        className="w-full p-[10px] rounded-[10px] resize-none overflow-hidden"
        rows={1}
      />
      {isFocused && id !== 1 && (
        <button
          onMouseDown={handleMouseDown}
          className="absolute top-1 right-1 bg-[#161616] text-white px-[5px] rounded-full"
        >
          ✕
        </button>
      )}
    </div>
  );
}
