import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Component({
  product_id,
  product_name,
  description,
  image,
  username,
  buttonName = "Call to action",
  isPreview = false,
  deactivated,
  blockDesign,
  // senderFunc = null,
}: any) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  return (
    <div>
      {blockDesign === "first" && (
        <div className="break-words">
          <div
            className={`w-full rounded-[15px] p-[15px] bg-white ${deactivated && "opacity-50"} shadow-customblock`}
          >
            <div className="flex flex-col">
              {image === "" ? (
                // <div className="bg-gray-200 rounded-[10px] w-full aspect-[4/3]"></div>
                <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-[10px]">
                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-[10px]"></div>
                </div>
              ) : (
                // <img src={image} alt="productImage" className="rounded-[10px]" />
                <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                  <img
                    src={image}
                    alt="productImage"
                    className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                  />
                </div>
              )}
              {/* {image === "" ? (
              <div className="bg-gray-200 w-[312px] h-[234px] rounded-[10px]"></div>
            ) : (
              <img
                src={image}
                alt="productImage"
                className="max-w-[312px] max-h-[234px] rounded-[10px]"
              />
            )} */}
              <div className="text-left mt-[15px] text-[18px] leading-[20.8px] text-[#161616] font-bold">
                {product_name}
              </div>
              <div className="text-left mt-[5px] text-[14px] leading-[19.12px] text-[#161616]">
                {description}
              </div>

              {isPreview ? (
                <div
                  className="mt-[20px] bg-[#161616] text-white text-center rounded-[10px] h-[43px] text-[18px] font-bold leading-[22.5px] flex justify-center items-center"
                  onClick={() => {
                    // senderFunc();
                    navigate(`/product/${product_id}`);
                  }}
                >
                  {getAction(buttonName)}
                </div>
              ) : (
                <div className="mt-[20px] bg-[#161616] text-white text-center rounded-[10px] h-[43px] text-[18px] font-bold leading-[22.5px] flex justify-center items-center">
                  {getAction(buttonName)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {blockDesign === "second" && (
        <div className="break-words">
          <div
            className={`w-full rounded-[15px] p-[15px] bg-white ${deactivated && "opacity-50"} shadow-customblock`}
            onClick={() => {
              if (isPreview) {
                // senderFunc();
                navigate(`/product/${product_id}`);
              }
            }}
          >
            <div className="flex flex-col">
              {image === "" ? (
                <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-[10px]">
                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-[10px]"></div>
                </div>
              ) : (
                <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                  <img
                    src={image}
                    alt="productImage"
                    className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                  />
                </div>
              )}
              <div className="text-left mt-[15px] text-[18px] leading-[20.8px] text-[#161616] font-bold">
                {product_name}
              </div>
              <div className="text-left mt-[5px] text-[14px] leading-[19.12px] text-[#161616]">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}

      {blockDesign === "third" && (
        <div className="break-words">
          <div
            className={`w-full rounded-[7px] bg-white ${deactivated && "opacity-50"} shadow-customblock`}
            onClick={() => {
              if (isPreview) {
                // senderFunc();
                navigate(`/product/${product_id}`);
              }
            }}
          >
            <div className="flex flex-col">
              {image === "" ? (
                <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-t-[7px]">
                  <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-t-[7px]"></div>
                </div>
              ) : (
                <div className="relative w-full pt-[100%] overflow-hidden rounded-t-[7px]">
                  <img
                    src={image}
                    alt="productImage"
                    className="absolute inset-0 w-full h-full object-cover object-center rounded-t-[7px]"
                  />
                </div>
              )}
              <div className="text-left mt-[15px] px-[15px] text-[18px] leading-[22px] text-[#161616] font-bold">
                {product_name}
              </div>
              <div className="text-left mt-[5px] px-[15px] pb-[15px] text-[14px] leading-[18px] text-[#161616]">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
