import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import ProductDetailsThumbnail from "../../components/ui/PostCreate/ProductDetailsThumbnail";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AutoResizeTextarea from "../../components/ui/PostCreate/AutoResizeTextarea";
import ImagePreview from "../../components/ui/PostCreate/ImagePreview";
import heic2any from "heic2any";

export async function sendProfileDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendInfoAboutNewPost,
    dispatch,
    `api/post/create/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  const [status, setStatus] = useState("info");

  const handleChangeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const navigate = useNavigate();

  const [errorPostTitle, setErrorPostTitle] = useState("");
  const [errorReadingTime, setErrorReadingTime] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [isClickInfoButton, setIsClickInfoButton] = useState(false);

  // MAIN Info ---------------------------------------------------------------
  const [postInfo, setPostInfo] = useState({
    title: "",
    readingTime: "",
  });
  const [listCategory, setListCategory] = useState([]);
  const [inputCategory, setInputCategory] = useState("");
  const [postThumbnail, setPostThumbnail] = useState("");

  useEffect(() => {
    if (isClickInfoButton) {
      if (postInfo.title === "") {
        setErrorPostTitle(t("error_post_title"));
      } else {
        setErrorPostTitle("");
      }

      if (postInfo.readingTime === "") {
        setErrorReadingTime(t("error_post_reading_time"));
      } else {
        setErrorReadingTime("");
      }
    }
  }, [postInfo, isClickInfoButton]);

  useEffect(() => {
    if (isClickInfoButton) {
      if (listCategory.length === 0) {
        setErrorCategory(t("error_post_category"));
      } else {
        setErrorCategory("");
      }
    }
  }, [listCategory, isClickInfoButton]);

  const handleRemoveCategory = (categoryName: number) => {
    setListCategory(listCategory.filter((item) => item !== categoryName));
  };

  // useEffect(() => {
  //   console.log("listCategory", listCategory);
  // }, [listCategory]);

  const handleChangePostInfo = (key: string, newValue: any) => {
    let oldPostInfo = structuredClone(postInfo);

    if (key === "title") {
      oldPostInfo.title = newValue;
    }

    if (key === "readingTime") {
      oldPostInfo.readingTime = newValue;
    }

    setPostInfo(oldPostInfo);
  };

  const handleChangePostThumbnail = (newValue: any) => {
    setPostThumbnail(newValue);
  };

  const textareaRefPostTitle = useRef(null);

  const handleChangeRefPostTitle = (event: any) => {
    const value = event.target.value;
    // const newValue = value.replace("\n", "");
    // handleChangeProductName(newValue);
    handleChangePostInfo("title", value);
    // @ts-ignore
    textareaRefPostTitle.current.style.height = "auto";
    // @ts-ignore
    textareaRefPostTitle.current.style.height = `${textareaRefPostTitle.current.scrollHeight}px`;
  };

  const handleChangeRefPostReadingTime = (event: any) => {
    const newValue = event.target.value;
    if (/^[1-9]\d*$/.test(newValue) || newValue === "") {
      handleChangePostInfo("readingTime", newValue);
    }
  };

  // MAIN Content ---------------------------------------------------------------
  const [content, setContent] = useState([
    { id: 1, type: "textarea", text: "" },
  ]);

  const [contentMain, setContentMain] = useState([]);

  const [errorContent, setErrorContent] = useState("");
  const [isClickContentButton, setIsClickContentButton] = useState(false);

  useEffect(() => {
    if (isClickContentButton) {
      if (content.length === 1) {
        if (content[0].text === "") {
          setErrorContent(t("error_post_content"));
        } else {
          setErrorContent("");
        }
      } else {
        setErrorContent("");
      }
    }
  }, [content, isClickContentButton]);

  // useEffect(() => {
  //   console.log("content", content);
  // }, [content]);

  const handleInputChange = (id: number, value: any) => {
    setContent(
      //@ts-ignore
      content.map((item) => (item.id === id ? { ...item, text: value } : item))
    );
  };

  const handleRemoveItem = (id: number) => {
    //@ts-ignore
    if (id !== 1 && id !== content[content.length - 1].id) {
      setContent(content.filter((item) => item.id !== id));

      let contentMainTmp = structuredClone(contentMain);

      for (let i = 0; i < content.length; i++) {
        if (content[i].id === id) {
          //@ts-ignore
          if (content[i].image_id) {
            for (let j = 0; j < contentMain.length; j++) {
              //@ts-ignore
              if (contentMain[j].id === id) {
                //@ts-ignore
                contentMainTmp[j].deleted = true;
              }
            }
          }
        }
      }

      setContentMain(contentMainTmp);
    }
  };

  useEffect(() => {
    console.log("content", content);
  }, [content]);

  useEffect(() => {
    console.log("contentMain", contentMain);
  }, [contentMain]);

  // Upload IMAGE NEW
  const addImage = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (
        content[content.length - 1].text === "" &&
        content[content.length - 1].id !== 1
      ) {
        let newContent = content.filter(
          (item) => item.id !== content[content.length - 1].id
        );
        setContent([
          //@ts-ignore
          ...newContent,
          {
            id: newContent[newContent.length - 1].id + 1,
            type: "image",
            //@ts-ignore
            src: reader.result,
          },
          {
            id: newContent[newContent.length - 1].id + 2,
            type: "textarea",
            //@ts-ignore
            text: "",
          },
        ]);
      } else {
        setContent([
          //@ts-ignore
          ...content,
          {
            id: content[content.length - 1].id + 1,
            type: "image",
            //@ts-ignore
            src: reader.result,
          },
          {
            id: content[content.length - 1].id + 2,
            type: "textarea",
            //@ts-ignore
            text: "",
          },
        ]);
      }
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        if (
          content[content.length - 1].text === "" &&
          content[content.length - 1].id !== 1
        ) {
          let newContent = content.filter(
            (item) => item.id !== content[content.length - 1].id
          );
          setContent([
            //@ts-ignore
            ...newContent,
            {
              id: newContent[newContent.length - 1].id + 1,
              type: "image",
              //@ts-ignore
              src: reader.result,
            },
            {
              id: newContent[newContent.length - 1].id + 2,
              type: "textarea",
              //@ts-ignore
              text: "",
            },
          ]);
        } else {
          setContent([
            //@ts-ignore
            ...content,
            {
              id: content[content.length - 1].id + 1,
              type: "image",
              //@ts-ignore
              src: reader.result,
            },
            {
              id: content[content.length - 1].id + 2,
              type: "textarea",
              //@ts-ignore
              text: "",
            },
          ]);
        }
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("postImage").click();
  };

  // MAIN
  async function getPostInfoFunc() {
    await actions.constructorAction(
      constants.getPostInfo,
      dispatch,
      "api/post/data/",
      "post",
      "",
      {
        id: params.postId,
      },
      true
    )();
  }

  useEffect(() => {
    dispatch({ type: constants.sendInfoAboutEditPost.reset });
    dispatch({ type: constants.getPostInfo.reset });

    const editBlockDataStorage = utils.LocalStorage.get("EditPostData");
    if (editBlockDataStorage) {
      if (editBlockDataStorage.status) {
        setStatus(editBlockDataStorage.status);
      }
    }

    getPostInfoFunc();

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (getPostInfoStore && getPostInfoStore.data) {
      utils.LocalStorage.set("EditPostData", {
        status: status,
        postInfo: postInfo,
        listCategory: listCategory,
      });
    }
  }, [status, postInfo, listCategory]);

  const dispatch = useDispatch();

  const sendInfoAboutNewPostStore = useSelector(
    (state: any) => state.sendInfoAboutNewPostStore
  );

  const getPostInfoStore = useSelector((state: any) => state.getPostInfoStore);

  useEffect(() => {
    if (
      getPostInfoStore &&
      getPostInfoStore.data &&
      getPostInfoStore.data.response
    ) {
      // console.log("getPostInfoStore", getPostInfoStore);
      const response = getPostInfoStore.data.response;

      const editBlockDataStorage = utils.LocalStorage.get("EditPostData");

      if (editBlockDataStorage && editBlockDataStorage.postInfo) {
        setPostInfo(editBlockDataStorage.postInfo);
      } else {
        setPostInfo({
          title: response.post_info.title,
          readingTime: response.post_info.reading_time,
        });
      }

      if (editBlockDataStorage && editBlockDataStorage.listCategory) {
        setListCategory(editBlockDataStorage.listCategory);
      } else {
        setListCategory(response.post_info.category);
      }

      setPostThumbnail(response.post_info.thumbnail);

      setContent(response.post_data);

      let contentMainTmp = [];
      for (let i = 0; i < response.post_data.length; i++) {
        contentMainTmp.push({ ...response.post_data[i], deleted: false });
      }
      //@ts-ignore
      setContentMain(contentMainTmp);
    }
  }, [getPostInfoStore]);

  const handleSendPostData = () => {
    const formData = new FormData();

    if (postThumbnail) {
      const base64String = postThumbnail.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = postThumbnail
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      // const fileBlob = new Blob([byteArray], { type: "image/jpeg" });
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append("post_thumbnail", fileBlob, `post_thumbnail.${fileType}`);
    }

    if (content && content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        //@ts-ignore
        if (content[i].type === "image") {
          //@ts-ignore
          const base64String = content[i].src.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
          //@ts-ignore
          const fileType = content[i].src
            .split(",")[0]
            .split("data:")[1]
            .split(";base64")[0];

          // Преобразовать строку base64 в бинарные данные
          // @ts-ignore
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Создать объект Blob из бинарных данных
          const fileBlob = new Blob([byteArray], { type: fileType });

          // @ts-ignore
          formData.append(
            //@ts-ignore
            `post_image${content[i].id}`,
            fileBlob,
            //@ts-ignore
            `post_image${content[i].id}.${fileType}`
          );
        }
      }
    }

    let newContent = [];

    for (let i = 0; i < content.length; i++) {
      //@ts-ignore
      if (content[i].type === "image") {
        newContent.push({
          //@ts-ignore
          id: content[i].id,
          //@ts-ignore
          type: content[i].type,
          //@ts-ignore
          src: `post_image${content[i].id}`,
        });
      } else {
        //@ts-ignore
        if (content[i].text !== "") {
          newContent.push({
            //@ts-ignore
            id: content[i].id,
            //@ts-ignore
            type: content[i].type,
            //@ts-ignore
            src: content[i].text,
          });
        }
      }
    }

    const dataForm = {
      info: {
        title: postInfo.title,
        readingTime: postInfo.readingTime,
        category: listCategory,
      },
      content: newContent,
    };

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    sendProfileDataFunc(dispatch, "", formData);
  };

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    // console.log("sendInfoAboutNewPostStore", sendInfoAboutNewPostStore);
    if (sendInfoAboutNewPostStore && sendInfoAboutNewPostStore.data) {
      navigate("/post");
      utils.LocalStorage.remove("EditPostData");
    }
  }, [sendInfoAboutNewPostStore]);

  if (sendInfoAboutNewPostStore && sendInfoAboutNewPostStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendInfoAboutNewPostStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        {status === "info" && (
          <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
            <BurgerMenu
              isOpen={burgerMenuOpen}
              profileName={"profileName"}
              toggleBurgerMenu={toggleBurgerMenu}
            />
            <div className="flex flex-col">
              <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                <div className="h-[56px] flex justify-between items-center">
                  <button
                    onClick={() => {
                      utils.LocalStorage.remove("EditPostData");
                      navigate("/post");
                    }}
                  >
                    {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                    <div className="p-[10px]">
                      <img
                        src="/images/back_arrow.svg"
                        alt="back_arrow.svg"
                        className="w-[7px] h-[14px]"
                      />
                    </div>
                  </button>
                  <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                    {t("create_post")}
                  </div>
                  <button onClick={toggleBurgerMenu}>
                    {!burgerMenuOpen ? (
                      <Icon
                        icon="iconamoon:menu-burger-horizontal-light"
                        className="w-[24px] h-[26px]"
                      />
                    ) : (
                      <span> </span>
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-[61px]">
                <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                  <div
                    className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                    onClick={() => {
                      handleChangeStatus("info");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("info_product")}
                    </div>
                  </div>
                  <div
                    className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                    onClick={() => {
                      handleChangeStatus("content");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("content")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <textarea
                  ref={textareaRefPostTitle}
                  maxLength={100}
                  placeholder={t("post_title")}
                  className="mt-[10px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                  value={postInfo.title}
                  onChange={handleChangeRefPostTitle}
                  rows={1} // Начнем с одной строки
                />
              </div>

              {errorPostTitle !== "" && (
                <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                  {errorPostTitle}
                </div>
              )}

              <div className="mt-[10px]">
                <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                  {t("thumbnail")}
                </span>
              </div>

              <ProductDetailsThumbnail
                notify={notify}
                productThumbnail={postThumbnail}
                handleChangeProductThumbnail={handleChangePostThumbnail}
              />

              <div className="mt-[10px]">
                <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                  {t("reading_time")}
                </span>
              </div>

              <div className="mt-[10px]">
                <input
                  type="number"
                  maxLength={5}
                  placeholder={t("set_time_in_minutes")}
                  className="w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                  value={postInfo.readingTime}
                  onChange={handleChangeRefPostReadingTime}
                  onKeyDown={(e) => {
                    // Ограничение ввода только цифр и клавиш управления
                    if (
                      e.key !== "Backspace" &&
                      e.key !== "Tab" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      !/^\d$/.test(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>

              {errorReadingTime !== "" && (
                <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                  {errorReadingTime}
                </div>
              )}

              <div className="mt-[10px]">
                <span className="text-start text-[#161616] text-[16px] leading-[20px] font-medium">
                  {t("category")}
                </span>
              </div>

              {/* <div className="mt-[10px] grid grid-cols-2 gap-2.5">
                <div
                  className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                  onClick={() => {
                    handleChangePostInfo("category", "IT");
                  }}
                >
                  <div className="pl-[15px]">
                    <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                      IT
                    </div>
                  </div>
                  <Radio
                    name="block_type"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={postInfo.category === "IT"}
                    onChange={(e) => {
                      handleChangePostInfo("category", "IT");
                    }}
                  />
                </div>

                <div
                  className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                  onClick={() => {
                    handleChangePostInfo("category", "Новости");
                  }}
                >
                  <div className="pl-[15px]">
                    <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                      Новости
                    </div>
                  </div>
                  <Radio
                    name="block_type"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={postInfo.category === "Новости"}
                    onChange={(e) => {
                      handleChangePostInfo("category", "Новости");
                    }}
                  />
                </div>
              </div> */}

              {listCategory.length > 0 && (
                <div className="mt-[10px] grid grid-cols-2 gap-2.5">
                  {listCategory.map((category: any, indexCategory: number) => (
                    <div
                      key={`category-${indexCategory}`}
                      className="relative w-full h-[44px] shadow-custom rounded-[10px] flex justify-center items-center px-[15px]"
                    >
                      <div className="text-[16px] leading-[20px] text-center font-medium text-[#161616]">
                        {category}
                      </div>
                      <button
                        onClick={() => {
                          handleRemoveCategory(category);
                        }}
                        className="absolute top-1 right-1 bg-[#161616] text-white px-[5px] rounded-full"
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="mt-[10px]">
                <textarea
                  // ref={textareaRefPostTitle}
                  maxLength={20}
                  placeholder={t("category_placeholder")}
                  className="w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                  value={inputCategory}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInputCategory(value);
                  }}
                  rows={1} // Начнем с одной строки
                />
              </div>

              {errorCategory !== "" && (
                <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                  {errorCategory}
                </div>
              )}

              <div className="mt-[10px]">
                <button
                  onClick={() => {
                    if (inputCategory !== "") {
                      let oldListCategory = structuredClone(listCategory);
                      //@ts-ignore
                      oldListCategory.push(inputCategory);
                      setListCategory(oldListCategory);
                      setInputCategory("");
                    }
                  }}
                  className="w-full h-[47px] flex justify-center items-center text-[16px] text-[#161616] border-[#161616] border-[1px] rounded-[8px]"
                >
                  {t("create_category")}
                </button>
              </div>

              <div className="mt-[15px] flex justify-center">
                <button
                  className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                  onClick={() => {
                    setIsClickInfoButton(true);
                    if (
                      postInfo.title !== "" &&
                      postInfo.readingTime !== "" &&
                      listCategory.length > 0
                    ) {
                      handleChangeStatus("content");
                    }
                  }}
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          </div>
        )}

        {isConverting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="text-center">
              {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
              <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                Идет конвертация ...
              </div>
            </div>
          </div>
        )}

        {status === "content" && (
          <div className="font-manrope pb-[20px] w-screen max-w-md bg-white min-h-screen">
            <BurgerMenu
              isOpen={burgerMenuOpen}
              profileName={"profileName"}
              toggleBurgerMenu={toggleBurgerMenu}
            />
            <div className="flex flex-col">
              <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                <div className="h-[56px] flex justify-between items-center">
                  <button
                    onClick={() => {
                      handleChangeStatus("info");
                    }}
                  >
                    {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                    <div className="p-[10px]">
                      <img
                        src="/images/back_arrow.svg"
                        alt="back_arrow.svg"
                        className="w-[7px] h-[14px]"
                      />
                    </div>
                  </button>
                  <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                    {t("create_post")}
                  </div>
                  <button onClick={toggleBurgerMenu}>
                    {!burgerMenuOpen ? (
                      <Icon
                        icon="iconamoon:menu-burger-horizontal-light"
                        className="w-[24px] h-[26px]"
                      />
                    ) : (
                      <span> </span>
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-[61px] px-[24px]">
                <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                  <div
                    className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                    onClick={() => {
                      handleChangeStatus("info");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("info_product")}
                    </div>
                  </div>
                  <div
                    className="mr-[2px] w-full h-[26px] bg-white shadow-custom flex justify-center items-center rounded-[40px]"
                    onClick={() => {
                      handleChangeStatus("content");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("content")}
                    </div>
                  </div>
                </div>
              </div>

              <input
                id="postImage"
                type="file"
                accept=".jpg,.jpeg,.png,.heic,.heif"
                onChange={addImage}
                style={{ display: "none" }}
              />

              <div className="mt-[25px]">
                {content.map((item) =>
                  //@ts-ignore
                  item.type === "textarea" ? (
                    <div
                      key={
                        //@ts-ignore
                        `textarea-${item.id}`
                      }
                      className="px-[24px]"
                    >
                      <AutoResizeTextarea
                        id={
                          //@ts-ignore
                          item.id
                        }
                        value={
                          //@ts-ignore
                          item.text
                        }
                        onChange={handleInputChange}
                        onRemove={handleRemoveItem}
                      />
                    </div>
                  ) : (
                    <div
                      key={
                        //@ts-ignore
                        `image-${item.id}`
                      }
                    >
                      <ImagePreview
                        src={
                          //@ts-ignore
                          item.src
                        }
                        onRemove={
                          //@ts-ignore
                          () => handleRemoveItem(item.id)
                        }
                      />
                    </div>
                  )
                )}
              </div>

              {errorContent !== "" && (
                <div className="px-[24px] mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                  {errorContent}
                </div>
              )}

              <div className="mt-[5px] px-[24px]">
                <button
                  onClick={handleButtonImageInputClick}
                  className="w-full h-[50px] flex justify-center items-center rounded-[8px] bg-white text-[#161616] border-[1px] border-[#161616]"
                >
                  {t("upload_image_post")}
                </button>
              </div>

              <div
                className={`${content.length === 0 ? "mt-[300px]" : "mt-[15px]"} flex justify-center px-[24px]`}
              >
                <button
                  className={`w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]`}
                  onClick={() => {
                    setIsClickContentButton(true);
                    if (content.length > 0 && content[0].text !== "") {
                      handleSendPostData();
                    }
                  }}
                >
                  {t("publish")}
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
