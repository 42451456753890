import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

export default function Page({ handleChangeStep }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex justify-center break-words">
      {/* <div className="font-manrope bg-[#F5F5F5] px-[24px] pb-[50px] w-screen max-w-md min-h-screen"> */}
      <div className="font-manrope pb-[20px] px-[24px] w-screen max-w-md">
        <div className="flex flex-col">
          <div className="mt-[50px] flex justify-center">
            <img
              src="/images/account_launch/welcome_page.svg"
              alt="welcome_page.svg"
              className="w-[342px] h-[342px]"
            />
          </div>
          <div className="mt-[20px]">
            <div className="text-[30px] flex justify-start text-[#161616] leading-[28px] font-semibold">
              {t("start_page_text1")}
            </div>
          </div>
          <div className="mt-[25px] flex justify-start">
            <div className="text-[16px] leading-[18px] text-[#161616]">
              {t("start_page_text2")}{" "}
              <span className="font-semibold">hubbbox</span>{" "}
              {t("start_page_text3")}
            </div>
          </div>

          <div className="mt-[25px] flex justify-center">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
              onClick={handleChangeStep}
            >
              {t("lets_start")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
