import React from "react";
// import react router dom
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import pages
import Registration from "../pages/auth/Registration";
import VerifyCodeEmail from "../pages/auth/VerifyCodeEmail";
import VerifyCodeEmailCompleted from "../pages/auth/VerifyCodeEmailCompleted";
import Login from "../pages/auth/Login";
import ChangePassword from "../pages/auth/ChangePassword";
import ChangePasswordCompleted from "../pages/auth/ChangePasswordCompleted";
import HomePage from "../pages/home/HomePage";
import HomePageV1 from "../pages/home/HomePageV1";
import ProfilePage from "../pages/home/ProfilePage";
// import Profile_old_with_section from "../pages/profile/Profile_old_with_section";
import Profile from "../pages/profile/Profile";
import CreateBlock from "../pages/profile/CreateBlock";
import EditBlock from "../pages/profile/EditBlock";
import Main from "../pages/account_launch/Main";
import ProfilePreview from "../pages/profile/ProfilePreview";
import Test from "../components/ui/Test";
import Carousel from "../components/ui/Carousel";
import ScrollToTop from "../components/ui/ScrollToTop";
import ErrorPage from "../components/ui/ErrorPage";
import Share from "../components/ui/Share";
import ProductSlider from "./ui/ProductSlider";
import PersonalInformation from "../pages/personal_info/PersonalInformation";
import ProductDetail from "../components/ui/Product/ProductDetail";
import ImageCropper from "../components/ui/ImageCropper";
import LinksUrlNew from "../components/ui/LinksUrlNew";
import YouTubeVideoTest from "../components/ui/YouTubeVideoTest";
import TextEditorTest from "../components/ui/TextEditorTest";
import SlickSlider from "../components/ui/SlickSlider";
import SlickSliderNew from "../components/ui/SlickSliderNew";
import Notification from "../components/notification/Notificaiton";
import CreateProduct from "../pages/create_product/CreateProduct";
import CreateProductV1 from "../pages/create_product/CreateProductV1";
import AllProducts from "../pages/all_products/AllProducts";
import AllProductsV1 from "../pages/all_products/AllProductsV1";
import ProductDetails from "../pages/product_details/ProductDetails";
import FileUpload from "../components/ui/FileUpload";
import TestLanguage from "../components/ui/TestLanguage";
import Settings from "../pages/settings/Settings";
import EditProduct from "../pages/create_product/EditProduct";
import CropImageTest from "./ui/CropImageTest";
import ProfileGetUpdates from "./ui/ProfileGetUpdates";
import GalleryNew from "../components/ui/GalleryNew";
import UploadGif from "../components/ui/UploadGif";
import RegistrationCompleted from "../pages/auth/RegistrationCompleted";
import ChangePasswordSettings from "../pages/settings/ChangePassword";
import MakeCollaboration from "../pages/make_collaboration/MakeCollaboration";
import NewCollaboration from "../pages/make_collaboration/NewCollaboration";
import CollaborationDetails from "../pages/make_collaboration/CollaborationDetails";
import Design from "../pages/design/Design";
import TermOfService from "../pages/term_of_service/TermOfService";
import PrivacyPolicy from "../pages/privacy_policy/PrivacyPolicy";
import Pricing from "../pages/pricing/Pricing";
import QRCodeGenerator from "../components/ui/QRCodeGenerator";
import ShareQR from "../components/ui/ShareQR";
import Analytics from "../pages/analytics/Analytics";
import PaymentFinish from "../pages/payment/PaymentFinish";
import CreatePost from "../pages/post/CreatePost";
import PostCreate from "./ui/PostCreate/PostCreate";
import AllPosts from "../pages/all_posts/AllPosts";
import PostDetails from "../pages/post_details/PostDetails";
import EditPost from "../pages/post/EditPost";

const App = () => {
  // const [language, setLanguage] = React.useState("en"); // по умолчанию английский

  // React.useEffect(() => {
  //   const userLanguage = navigator.language || navigator.languages[0];
  //   // console.log("userLanguage", userLanguage);
  //   setLanguage(userLanguage);
  // }, []);

  return (
    <div className="overflow-hidden">
      <Router>
        {/*<Header />*/}
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="auth/registration" element={<Registration />} />
          <Route
            path="auth/registration/:token/verify"
            element={<VerifyCodeEmail />}
          />
          <Route
            path="auth/registration/completed"
            element={<VerifyCodeEmailCompleted />}
          />
          <Route path="auth/" element={<Login />} />
          <Route path="auth/password/change" element={<ChangePassword />} />
          <Route
            path="auth/password/completed"
            element={<ChangePasswordCompleted />}
          />
          <Route path="auth/launch" element={<Main />} />

          {/* <Route path="home" element={<HomePage />} />
          <Route path="home/profile" element={<ProfilePage />} /> */}
          <Route path="home" element={<HomePageV1 />} />
          <Route path="home/profile" element={<Navigate to="/home" />} />

          <Route path="profile" element={<Profile />} />

          <Route path=":userUsername" element={<ProfilePreview />} />
          <Route path=":userUsername/updates" element={<ProfileGetUpdates />} />
          <Route path="profile/create" element={<CreateBlock />} />
          <Route path="profile/edit/:blockNum" element={<EditBlock />} />

          {/* <Route path="test" element={<Test />} /> */}

          {/* <Route path="error" element={<ErrorPage />} /> */}

          {/* <Route path="carousel" element={<Carousel />} />
          <Route path="share" element={<Share />} />
          <Route path="slider" element={<ProductSlider />} /> */}

          <Route path="profile/info" element={<PersonalInformation />} />

          <Route path="product/old/:userUsername" element={<ProductDetail />} />

          <Route path="notification" element={<Notification />} />

          {/* <Route path="product" element={<AllProducts />} /> */}
          <Route path="product" element={<AllProductsV1 />} />

          {/* <Route path="product/create" element={<CreateProduct />} /> */}
          <Route path="product/create" element={<CreateProductV1 />} />

          <Route path="product/:productId" element={<ProductDetails />} />

          <Route path="profile/settings" element={<Settings />} />

          <Route
            path="profile/settings/password"
            element={<ChangePasswordSettings />}
          />

          <Route path="" element={<Navigate to="/auth" />} />

          <Route path="product/edit/:productId" element={<EditProduct />} />

          <Route path="collaboration/" element={<MakeCollaboration />} />

          <Route path="collaboration/new/" element={<NewCollaboration />} />

          <Route
            path="collaboration/:collaborationId"
            element={<CollaborationDetails />}
          />

          {/* <Route path="crop/test" element={<CropImageTest />} /> */}

          {/* <Route path="gallery" element={<GalleryNew />} /> */}

          {/* <Route path="upload/gif" element={<UploadGif />} /> */}

          <Route
            path="auth/registration/withoutcode/completed"
            element={<RegistrationCompleted />}
          />

          <Route path="design" element={<Design />} />

          {/* <Route path="term-of-service" element={<TermOfService />} /> */}

          {/* <Route path="privacy-policy" element={<PrivacyPolicy />} /> */}

          <Route path="pricing" element={<Pricing />} />

          {/* <Route path="generate_qr" element={<QRCodeGenerator />} /> */}

          {/* <Route path="share_qr" element={<ShareQR />} /> */}

          <Route path="analytics" element={<Analytics />} />
          <Route path="payment/status" element={<PaymentFinish />} />
          <Route path="post/create" element={<CreatePost />} />
          <Route path="post" element={<AllPosts />} />
          <Route path="post/example" element={<PostCreate />} />
          <Route path="post/:postId" element={<PostDetails />} />
          <Route path="post/edit/:postId" element={<EditPost />} />

          {/* <Route path="language" element={<TestLanguage />} /> */}

          {/* <Route path="file/upload" element={<FileUpload />} /> */}

          {/* <Route path="image/" element={<ImageCropper />} /> */}

          {/* <Route path="linksurlnew/" element={<LinksUrlNew />} /> */}

          {/* <Route path="youtube/" element={<YouTubeVideoTest />} /> */}

          {/* <Route path="texteditor/" element={<TextEditorTest />} /> */}

          {/* <Route path="slickslider/" element={<SlickSlider />} /> */}
          {/* <Route path="slickslidernew/" element={<SlickSliderNew />} /> */}

          {/* <Route path="*" element={<Login />} /> */}
        </Routes>
        {/*<Sidebar />*/}
        {/*<Footer />*/}
      </Router>
    </div>
  );
};

export default App;
