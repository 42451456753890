import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import { useTranslation } from "react-i18next";

export default function Page({
  profileName,
  changeStatus,
  handleChangeYoutubeUrl,
  youtubeVideoUrl,
  handleSendProfileData,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  //   useEffect(() => {
  //     console.log("profileName", profileName);
  //   }, [profileName]);

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    // "Side slider", "Big block"
    if (youtubeVideoUrl === "") {
      setErrorCreate(t("create_block_youtube_error"));
    } else {
      setErrorCreate("");
    }
  }, [youtubeVideoUrl]);

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] w-screen max-w-md">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("create_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-[10px]">
          <div className="text-[16px] text-[#161616] leading-[20px] font-semibold">
            List of YouTube video URLs
          </div>
        </div> */}

        <div className="mt-[25px]">
          <input
            type="text"
            value={youtubeVideoUrl}
            placeholder={t("youtube_video_url")}
            className="w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              // const value = e.target.value;

              // // if (!value.startsWith(" ")) {
              // if (value !== " ") {
              //   handleChangeYoutubeUrl(value);
              // }

              const value = e.target.value.replace(/\s/g, "");
              handleChangeYoutubeUrl(value);
            }}
          />
        </div>

        {errorCreate !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {errorCreate}
          </div>
        )}

        {/* <div className="mt-[10px] flex justify-between items-center">
          <input
            type="text"
            value={youtubeLink}
            placeholder="Youtube video URL"
            className="w-[276px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              setYoutubeLink(e.target.value);
            }}
          />
          <div className="w-[56px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] flex justify-center items-center">
            <Icon
              icon="fluent:delete-48-regular"
              className="text-[#FF2C20] w-[24px] h-[24px]"
            />
          </div>
        </div> */}

        {/* <div className="mt-[10px]">
          <div className="w-[342px] h-[50px] rounded-[10px] border-[1px] border-[#161616] text-[#161616] flex justify-center items-center">
            Add URL
          </div>
        </div> */}

        <div className="mt-[400px]">
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={(event) => {
              if (errorCreate === "") {
                event.preventDefault();
                handleSendProfileData("YouTube video");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
