import { Icon } from "@iconify/react";
import * as utils from "../../utils";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
  list,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import ProfileType from "../ProfileType";
import BurgerMenu from "../BurgerMenu";
import { useState, useCallback, useEffect, useRef } from "react";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
// import { openSync } from "fs";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Page({
  state,
  changeState,
  avatar,
  notify,
  iconDesign,
  profileName,
  handleChangeProfile,
  profileStatus,
  handleChangeStatus,
  handleChangeProfileStatusLogo,
  handleButtonClickProfileStatusLogo,
  handleButtonDeleteProfileStatusLogo,
  profileStatusLogo,
  bio,
  handleChangeBio,
  messageAvatar,
  goHome,
  handleAvatarChange,
  handleButtonClickAvatar,
  profileType,
  handleProfileCoverChange,
  handleButtonDeleteCover,
  profileCover,
  nameProfileCover,
  handleChangeNameProfileCover,
  handleButtonClickCover,
  messageCover,
  listOfSelectedSocialMediaLinks,
  username,
}: any) {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);
  const [imageType, setImageType] = useState(null);

  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => {
  //   setImage(null); // Сбрасываем выбранное изображение при закрытии модального окна
  //   setModalIsOpen(false);
  // };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];
  //   const isValidImage = file.type.startsWith("image/");

  //   if (!isValidImage) {
  //     notify(
  //       //@ts-ignore
  //       t("image_upload_error1")
  //     );
  //     return;
  //   }

  //   if (file.size > constants.MaxSizeAvatar * 1024 * 1024) {
  //     notify(
  //       //@ts-ignore
  //       t("image_upload_error2")
  //     );
  //     return;
  //   }

  //   if (file) {
  //     if (file.type === "image/heic" || file.type === "image/heif") {
  //       // Обработка HEIC или HEIF файла
  //       convertHeicOrHeifToBase64(file);
  //     } else if (file.size <= 5 * 1024 * 1024) {
  //       // Обработка обычного изображения (до 5 МБ)
  //       convertImageToBase64(file);
  //     } else {
  //       // @ts-ignore
  //       notify("Размер файла превышает 5 МБ.");
  //     }
  //   }

  //   // const reader = new FileReader();
  //   // reader.onload = () => {
  //   //   //@ts-ignore
  //   //   setImage(reader.result);
  //   //   setError(null);
  //   //   openModal();
  //   // };
  //   // reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   onDrop,
  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: false, // Разрешаем выбор только одного файла
  //   //@ts-ignore
  //   accept: "image/*", // Разрешаем только изображения
  //   // accept: {
  //   //   "image/*": [".jpeg", ".jpg", ".png", ".svg"],
  //   // },
  // });

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperRef.current.cropper;
  //   const canvas = cropper.getCroppedCanvas({ width: 400, height: 400 }); // 4:3 aspect ratio
  //   // setCroppedImage(canvas.toDataURL("image/jpeg"));
  //   setCroppedImage(canvas.toDataURL(imageType));
  //   closeModal();
  // };

  // useEffect(() => {
  //   if (croppedImage) {
  //     // console.log("croppedImage", croppedImage);
  //     handleAvatarChange(croppedImage);
  //   }
  // }, [croppedImage]);

  const handleFileAvatarChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        //@ts-ignore
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        //@ts-ignore
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      // setImageType(file.type);
      // //@ts-ignore
      // setImage(result); // Устанавливаем изображение для обрезки
      // setError(null);
      // openModal();
      handleAvatarChange(result);
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleAvatarChange(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  //Profile Cover NEW
  const handleFileCoverChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        //@ts-ignore
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        //@ts-ignore
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64Cover(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64Cover(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64Cover = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleProfileCoverChange(result);
      handleChangeNameProfileCover(file.name);
    };
    reader.readAsDataURL(file);
  };

  const convertHeicOrHeifToBase64Cover = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleProfileCoverChange(result);
        handleChangeNameProfileCover(file.name);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  //Profile Status LOGO NEW
  const handleFileStatusLogoChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        //@ts-ignore
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        //@ts-ignore
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64StatusLogo(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64StatusLogo(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64StatusLogo = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleChangeProfileStatusLogo(result);
    };
    reader.readAsDataURL(file);
  };

  const convertHeicOrHeifToBase64StatusLogo = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleChangeProfileStatusLogo(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const [profileNameLength, setProfileNameLength] = useState(
    t("start_page_error2")
  );

  const [clickButton, setClickButton] = useState(false);

  useEffect(() => {
    if (profileName.length >= 3) {
      setProfileNameLength("");
    } else {
      setProfileNameLength(t("start_page_error2"));
    }
  }, [profileName]);

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[50px] w-screen max-w-md">
        <BurgerMenu
          isOpen={burgerMenuOpen}
          profileName={profileName}
          username={username}
          toggleBurgerMenu={toggleBurgerMenu}
        />
        <div className="flex flex-col">
          <div className="flex justify-between items-center h-[56px] px-[24px]">
            <button
              onClick={() => {
                // navigate("/home/profile");
                goHome();
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="text-[18px] leading-[24.59px] text-center text-[#161616] font-semibold">
              {t("title")}
            </div>
            {/* <div
              className="text-[14px] leading-[18px] text-[#8391A1]"
              //   onClick={goHome}
            >
              Skip
            </div> */}
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                // <Icon
                //   icon="iconamoon:menu-burger-horizontal-light"
                //   className="w-[24px] h-[26px]"
                // />
                <img
                  src="/images/hamburger_menu/hamburger_menu.svg"
                  alt="hamburger_menu.svg"
                  className="w-[24px] h-[26.88px]"
                />
              ) : (
                <span className="text-white text-[12px]"> </span>
              )}
            </button>
          </div>

          <div className="mt-[5px] px-[24px]">
            <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
              <div
                className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                onClick={() => {
                  //   changeStatus("Info");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("info")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Links");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("links")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Layout");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("layout")}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[14px]">
            <ProfileType
              page={"PersonalInformation"}
              profileName={profileName}
              // profileCover={profileCover}
              status={profileStatus}
              profileStatusLogo={profileStatusLogo}
              username={username}
              bio={bio}
              avatar={avatar}
              iconDesign={iconDesign}
              listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
              profileType={profileType}
              isEdit={true}
              openFunc={handleButtonClickAvatar}
            />
          </div>

          <input
            id="avatarInput"
            type="file"
            accept=".jpg,.jpeg,.png,.heic,.heif"
            onChange={handleFileAvatarChange}
            style={{ display: "none" }}
          />

          {isConverting && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="text-center">
                {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
                <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                  Идет конвертация ...
                </div>
              </div>
            </div>
          )}

          <div
            className={`${profileType === "Classic" ? "mt-[40px] px-[24px]" : "mt-[150px] px-[24px]"}`}
          >
            <div className="w-full h-[48px] shadow-custom flex items-center rounded-[8px]">
              <div
                // className={`bg-[#E8ECF4] flex-shrink-0 ${i18n.language === "ru-RU" ? "w-[160px]" : "w-[115px]"} py-[12px] px-2 text-[16px] font-medium text-[#8391A1] rounded-l-[8px] text-center`}
                className={`bg-[#E8ECF4] flex-shrink-0 py-[12px] px-2 text-[16px] font-medium text-[#8391A1] rounded-l-[8px] text-center`}
                onClick={handleButtonClickCover}
              >
                <span className="whitespace-nowrap">{t("profile_cover")}</span>
              </div>
              <div
                className="text-[17px] pl-[15px] truncate flex-grow text-start"
                onClick={handleButtonClickCover}
              >
                {nameProfileCover === ""
                  ? t("start_page_error1")
                  : nameProfileCover}
              </div>
              {nameProfileCover !== "" && (
                <button
                  className="flex-shrink-0 px-[5px]"
                  onClick={handleButtonDeleteCover}
                >
                  <img
                    src="/images/profile/delete.svg"
                    alt="delete.svg"
                    className="w-[18.5px] h-[19.5px]"
                  />
                </button>
              )}
            </div>

            <input
              id="profileCover"
              type="file"
              accept=".jpg,.jpeg,.png,.heic,.heif,.gif"
              onChange={handleFileCoverChange}
              style={{ display: "none" }}
            />
          </div>

          {/* <div
            className={`${profileType === "Classic" ? "mt-[40px]" : "mt-[150px]"}`}
          >
            <input
              id="coverInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleAvatarChange}
              style={{ display: "none" }}
            />
            <button
              className="w-full h-[47px] rounded-[10px] text-[#161616] text-center text-[18px] leading-[22px] border-[1px] border-[#161616]"
              onClick={handleButtonClickCover}
            >
              {cover ? "Add Profile Cover (Downloaded)" : "Add Profile Cover"}
            </button>
          </div>

          {messageCover !== "" && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[12px] leading-[24px]">
              {messageCover}
            </div>
          )} */}

          {/* <div {...getRootProps()} className="hidden">
            <input {...getInputProps()} />
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="flex justify-center items-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            appElement={document.getElementById("root")}
            ariaHideApp={false}
          >
            <div className="bg-white p-4 rounded shadow-lg">
              {image && (
                <div>
                  <Cropper
                    src={image}
                    style={{ height: 400, width: 400 }}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    viewMode={1}
                    guides={false}
                    cropBoxResizable={false}
                    dragMode="move"
                    cropBoxMovable={false}
                    ref={cropperRef}
                  />
                  <div className="flex justify-between mt-4 px-[30px]">
                    <button
                      className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                      onClick={getCroppedImage}
                    >
                      <div className="text-white text-[18px] leading-[22px] font-semibold">
                        {t("crop_image")}
                      </div>
                    </button>
                    <button
                      className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                      onClick={closeModal}
                    >
                      <div className="text-white text-[18px] leading-[22px] font-semibold">
                        {t("cancel")}
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal> */}

          {/* <div
            className={`${profileType === "Classic" ? "mt-[40px]" : "mt-[150px]"}`}
          >
            <input
              id="avatarInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleAvatarChange}
              style={{ display: "none" }}
            />
            <button
              className="w-full h-[47px] rounded-[10px] text-[#161616] text-center text-[16px] leading-[22px] border-[1px] border-[#161616]"
              onClick={handleButtonClickAvatar}
            >
              Add Profile Picture
            </button>
          </div> */}

          {error !== "" && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px] px-[24px]">
              {error}
            </div>
          )}

          {/* {messageAvatar !== "" && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[12px] leading-[24px]">
              {messageAvatar}
            </div>
          )} */}

          <div className="mt-[10px] px-[24px]">
            <input
              type="text"
              value={profileName}
              minLength={3}
              maxLength={17}
              className="w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] px-6 py-4 placeholder:text-[#8391A1] text-[#161616] text-[16px] leading-[17.5px]"
              placeholder={t("profile_name")}
              onChange={(e) => {
                handleChangeProfile(e.target.value);
              }}
            />
          </div>

          {profileNameLength !== "" &&
            (clickButton === true || profileName !== "") && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px] px-[24px]">
                {profileNameLength}
              </div>
            )}

          <input
            id="profileStatusLogo"
            type="file"
            accept=".gif,.jpg,.jpeg,.png,.heic,.heif"
            onChange={handleFileStatusLogoChange}
            style={{ display: "none" }}
          />

          <div className="mt-[10px] px-[24px] flex space-x-[10px]">
            {profileStatusLogo === "" ? (
              <div
                onClick={handleButtonClickProfileStatusLogo}
                className={`flex justify-center items-center w-[56px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] flex-shrink-0`}
              >
                <img
                  src="/images/profile_page/upload.svg"
                  alt="upload.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>
            ) : (
              <div
                // onClick={handleButtonClickProfileStatusLogo}
                className={`relative flex justify-center items-center w-[56px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] flex-shrink-0`}
              >
                <img
                  src={profileStatusLogo}
                  alt="profileStatusLogo"
                  className="w-[56px] h-[56px] rounded-[10px]"
                />

                <button
                  onClick={handleButtonDeleteProfileStatusLogo}
                  className="absolute top-1 right-1 text-[#161616] px-[5px] bg-white text-[14px] rounded-full"
                >
                  ✕
                </button>
              </div>
            )}
            <input
              type="text"
              value={profileStatus}
              className="w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] px-6 py-4 placeholder:text-[#8391A1] text-[#161616] text-[16px] leading-[17.5px]"
              placeholder={t("status")}
              maxLength={40}
              onChange={(e) => {
                handleChangeStatus(e.target.value);
              }}
            />
          </div>

          <div className="mt-[10px] px-[24px]">
            <textarea
              maxLength={300}
              value={bio}
              className="w-full h-[73px] text-start rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] px-6 py-4 placeholder:text-[#8391A1] text-[#161616] text-[16px] leading-[17.5px]"
              placeholder={t("bio")}
              onChange={(e) => {
                handleChangeBio(e.target.value);
              }}
            />
          </div>

          <div className="mt-[5px] flex justify-end items-center px-[24px]">
            <div className="text-[12px] leading-[12px]">{bio.length}/300</div>
          </div>

          {profileNameLength === "" ? (
            <div className="mt-[5px] flex justify-center px-[24px]">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
                onClick={() => {
                  changeState("Links");
                  setClickButton(true);
                }}
              >
                {t("continue")}
              </button>
            </div>
          ) : (
            <div className="mt-[5px] flex justify-center px-[24px]">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
                onClick={() => {
                  setClickButton(true);
                }}
              >
                {t("continue")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
