import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import ErrorPage from "../../components/ui/ErrorPage";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import CustomInput from "../../components/ui/CustomInput";
import NumberInput from "../../components/ui/NumberInput";

export async function getCollaborationDetailsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getCollaborationDetails,
    dispatch,
    `api/collaboration/get_by_id/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    getCollaborationDetailsFunc(dispatch, "", {
      collaborationId: params.collaborationId,
    });
  }, []);

  const getCollaborationDetailsStore = useSelector(
    (state: any) => state.getCollaborationDetailsStore
  );

  const [authorType, setAuthorType] = useState("");
  const [collaborationType, setCollaborationType] = useState("");
  const [product, setProduct] = useState({});
  const [senderUsername, setSenderUsername] = useState("");

  const [distributorUsername, setDistributorUsername] = useState("");

  const [productLink, setProductLink] = useState("");
  const [recipientUsername, setRecipientUsername] = useState("");

  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [fee, setFee] = useState("");
  const [duration, setDuration] = useState("");
  const [quantity, setQuantity] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    // console.log("getCollaborationDetailsStore", getCollaborationDetailsStore);
    if (getCollaborationDetailsStore && getCollaborationDetailsStore.data) {
      const response = getCollaborationDetailsStore.data.response;

      if (response.type === "received") {
        setAuthorType(response.type);
        setSenderUsername(response.sender);
        setProduct(response.product);
        setCollaborationType(response.collaboration_type);
      }

      if (response.type === "send") {
        if (response.collaboration_type === "select") {
          setAuthorType(response.type);
          setDistributorUsername(response.username);
          setCollaborationType(response.collaboration_type);
        } else if (response.collaboration_type === "become") {
          setAuthorType(response.type);
          setProductLink(response.product_link);
          setCollaborationType(response.collaboration_type);
          setRecipientUsername(response.username);
        }
      }

      setPrice(response.price);
      setCurrency(response.currency);
      if (response.currency === "dollar") {
        setCurrencySymbol("$");
      } else if (response.currency === "tenge") {
        setCurrencySymbol("₸");
      } else if (response.currency === "ruble") {
        setCurrencySymbol("₽");
      }
      setFee(response.fee);

      if (response.duration === null) {
        setDuration("");
      } else {
        setDuration(response.duration);
      }

      if (response.quantity === null) {
        setQuantity("");
      } else {
        setQuantity(response.quantity);
      }

      setComments(response.comments);
    }
  }, [getCollaborationDetailsStore]);

  if (getCollaborationDetailsStore && getCollaborationDetailsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getCollaborationDetailsStore.load} />
      </div>
    );
  }

  if (getCollaborationDetailsStore && getCollaborationDetailsStore.error) {
    return (
      <div>
        <ErrorPage error={getCollaborationDetailsStore.error} />
      </div>
    );
  }

  if (getCollaborationDetailsStore && getCollaborationDetailsStore.fail) {
    return (
      <div>
        <ErrorPage error={getCollaborationDetailsStore.fail} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    navigate("/collaboration");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("collaboration")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            {authorType === "received" && (
              <div>
                <div className="mt-[66px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("sender")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={senderUsername}
                    placeholder={"@username"}
                    rows={1}
                    maxLength={100}
                    disabled={true}
                  />
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    {t("product")}
                  </div>
                </div>

                {Object.keys(product).length > 0 && (
                  <div
                    className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                    // onClick={() => {
                    //   setItemToListOfProducts(elem.id);
                    // }}
                  >
                    <div className="flex space-x-[10px] items-center">
                      {
                        //@ts-ignore
                        product.thumbnail === "" ? (
                          <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                        ) : (
                          <img
                            src={
                              //@ts-ignore
                              product.thumbnail
                            }
                            alt="image"
                            className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                          />
                        )
                      }
                      <div className="ml-[10px]">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {
                            //@ts-ignore
                            product.name
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {
                            //@ts-ignore
                            t(product.type)
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {t("price")}:{" "}
                          {
                            //@ts-ignore
                            product.price
                              ? //@ts-ignore
                                `${product.currencySymbol}${product.price}`
                              : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("collaboration_type")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={t(collaborationType)}
                    placeholder={"@username"}
                    rows={1}
                    maxLength={100}
                    disabled={true}
                  />
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">{t("price")}</span> ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div className="w-full h-[56px]">
                      <NumberInput
                        value={price}
                        placeholder={t("set_price")}
                        disabled={true}
                      />
                    </div>
                    <div className="w-[76px] h-[56px] flex-shrink-0">
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("fee")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={fee}
                      placeholder={t("your_fee_placeholder")}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">
                      {t("duration_of_the_collaboration")}
                    </span>{" "}
                    ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("time_days")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      defaultChecked={duration !== ""}
                      disabled
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      //   duration
                      value={duration}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("product_quantity")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      checked={quantity !== ""}
                      disabled
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={quantity}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("сomments")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={comments}
                    placeholder={t("сomments_placeholder")}
                    rows={3}
                    maxLength={240}
                    delimiter={false}
                    disabled={true}
                  />
                </div>
              </div>
            )}

            {authorType === "send" && collaborationType === "select" && (
              <div>
                <div className="mt-[66px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("distributor_username")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={distributorUsername}
                    placeholder={t("distributor_username_placeholder")}
                    rows={1}
                    maxLength={100}
                    disabled={true}
                  />
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    {t("product")}
                  </div>
                </div>

                {Object.keys(product).length > 0 && (
                  <div
                    className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                    // onClick={() => {
                    //   setIsOpen(true);
                    // }}
                  >
                    <div className="flex space-x-[10px] items-center">
                      {
                        //@ts-ignore
                        product.thumbnail === "" ? (
                          <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                        ) : (
                          <img
                            src={
                              //@ts-ignore
                              product.thumbnail
                            }
                            alt="image"
                            className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                          />
                        )
                      }
                      <div className="ml-[10px]">
                        <div className="text-[16px] leading-[27px] text-[#161616] font-semibold">
                          {
                            //@ts-ignore
                            product.name
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {
                            //@ts-ignore
                            t(product.type)
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {t("price")}:{" "}
                          {
                            //@ts-ignore
                            product.price
                              ? //@ts-ignore
                                `${product.currencySymbol}${product.price}`
                              : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">{t("your_price")}</span> ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div className="w-full h-[56px]">
                      <NumberInput
                        value={price}
                        placeholder={t("set_price")}
                        disabled={true}
                      />
                    </div>
                    <div className="w-[76px] h-[56px] flex-shrink-0">
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("your_fee")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={fee}
                      placeholder={t("your_fee_placeholder")}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">
                      {t("duration_of_the_collaboration")}
                    </span>{" "}
                    ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("time_days")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      checked={duration !== ""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={duration}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("product_quantity")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      checked={quantity !== ""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={quantity}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("сomments")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={comments}
                    placeholder={t("сomments_placeholder")}
                    rows={3}
                    maxLength={240}
                    delimiter={false}
                    disabled={true}
                  />
                </div>
              </div>
            )}

            {authorType === "send" && collaborationType === "become" && (
              <div>
                <div className="mt-[66px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("product_link")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={productLink}
                    placeholder={t("product_link_placeholder")}
                    rows={1}
                    maxLength={100}
                    disabled={true}
                  />
                </div>

                {Object.keys(product).length > 0 && (
                  <div className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom">
                    <div className="flex space-x-[10px] items-center">
                      {
                        //@ts-ignore
                        product.thumbnail === "" ? (
                          <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                        ) : (
                          <img
                            src={
                              //@ts-ignore
                              product.thumbnail
                            }
                            alt="image"
                            className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                          />
                        )
                      }
                      <div className="ml-[10px]">
                        <div className="text-[16px] leading-[27px] text-[#161616] font-semibold">
                          {
                            //@ts-ignore
                            product.name
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {
                            //@ts-ignore
                            t(product.type)
                          }
                        </div>
                        <div className="text-[14px] leading-[22.5px] text-[#161616]">
                          {t("price")}:{" "}
                          {
                            //@ts-ignore
                            product.price
                              ? //@ts-ignore
                                `${product.currencySymbol}${product.price}`
                              : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("recipient")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={recipientUsername}
                    placeholder={"@username"}
                    rows={1}
                    maxLength={100}
                    disabled={true}
                  />
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">{t("your_price")}</span> ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div className="w-full h-[56px]">
                      <NumberInput
                        value={price}
                        placeholder={t("set_price")}
                        disabled={true}
                      />
                    </div>
                    <div className="w-[76px] h-[56px] flex-shrink-0">
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("your_fee")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={fee}
                      placeholder={t("your_fee_placeholder")}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px]">
                    <span className="font-medium">
                      {t("duration_of_the_collaboration")}
                    </span>{" "}
                    ⓘ
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("time_days")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      checked={duration !== ""}
                      disabled
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={duration}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                      {t("product_quantity")}
                    </span>
                    <Switch
                      crossOrigin={""}
                      checked={quantity !== ""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="w-full h-[56px]">
                    <NumberInput
                      value={quantity}
                      placeholder={""}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                    {t("сomments")}
                  </div>
                </div>

                <div className="mt-[10px]">
                  <CustomInput
                    value={comments}
                    placeholder={t("сomments_placeholder")}
                    rows={3}
                    maxLength={240}
                    delimiter={false}
                    disabled={true}
                  />
                </div>
              </div>
            )}

            <div className="mt-[15px]">
              <div
                className="bg-[#161616] h-[50px] rounded-[10px] text-white flex justify-center items-center font-semibold text-[18px]"
                onClick={() => {
                  navigate("/collaboration");
                }}
              >
                {t("return")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
