import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import * as utils from "../../utils";
import SocialLinks from "../SocialLinks";
import TabLinksDotsMenu from "../PersonalInformation/components/TabLinksDotsMenu";
import ProfileType from "../ProfileType";
import BurgerMenu from "../BurgerMenu";
import { useTranslation } from "react-i18next";

export default function Page({
  state,
  changeState,
  iconDesign,
  profileName,
  bio,
  profileStatus,
  profileStatusLogo,
  avatar,
  profileCover,
  listOfSocialMediaLinks,
  changeListData,
  changeChecked,
  getPlaceholder,
  getChecked,
  getValue,
  listOfSelectedSocialMediaLinks,
  profileType,
  username,
  handleMoveListElem,
  isFirstListElem,
  isLastListElem,
}: any) {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const openWindow = () => {
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const handleKeyPress = (e: any) => {
    // Разрешаем только цифры (ключи 0-9)
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   console.log(
  //     "listOfSelectedSocialMediaLinks",
  //     listOfSelectedSocialMediaLinks
  //   );
  // }, [listOfSelectedSocialMediaLinks]);

  // useEffect(() => {
  //   console.log("listOfSocialMediaLinks", listOfSocialMediaLinks);
  // }, [listOfSocialMediaLinks]);

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[50px] w-screen max-w-md">
        <BurgerMenu
          isOpen={burgerMenuOpen}
          profileName={profileName}
          username={username}
          toggleBurgerMenu={toggleBurgerMenu}
        />
        <div className="flex flex-col">
          <div className="flex justify-between items-center h-[56px] px-[24px]">
            <button
              onClick={() => {
                changeState("Info");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="text-[18px] leading-[24.59px] text-center text-[#161616] font-semibold">
              {t("links")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                // <Icon
                //   icon="iconamoon:menu-burger-horizontal-light"
                //   className="w-[24px] h-[26px]"
                // />
                <img
                  src="/images/hamburger_menu/hamburger_menu.svg"
                  alt="hamburger_menu.svg"
                  className="w-[24px] h-[26.88px]"
                />
              ) : (
                <span className="text-white text-[12px]"> </span>
              )}
            </button>
          </div>

          <div className="mt-[5px] px-[24px]">
            <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
              <div
                className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Info");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("info")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                onClick={() => {
                  //   changeStatus("Links");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("links")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Layout");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("layout")}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-[14px] flex justify-center">
            <div className="flex flex-col items-center">
              {!avatar ? (
                <Icon
                  icon="carbon:user-avatar-filled"
                  className="w-[110px] h-[110px] text-[#F7F8F9]"
                />
              ) : (
                <Avatar
                  src={avatar}
                  alt="avatar"
                  className="w-[110px] h-[110px]"
                />
              )}

              {profileName == "" ? (
                <div className="mt-[15px] bg-[#F7F8F9] w-[260px] h-[34px] rounded-[20px]"></div>
              ) : (
                <div className="mt-[15px] text-[#161616] text-[28px] leading-[33.6px] text-center font-semibold break-all">
                  {profileName}
                </div>
              )}

              {bio == "" ? (
                <div className="mt-[15px] bg-[#F7F8F9] w-[140px] h-[17px] rounded-[20px]"></div>
              ) : (
                <div className="mt-[15px] text-[#161616] text-[14px] leading-[16.8px] text-center break-all">
                  {utils.splitText(bio)}
                </div>
              )}

              <div className="mt-[20px]">
                <SocialLinks
                  listOfSocialLinks={listOfSelectedSocialMediaLinks}
                  accType={"classical"}
                />
              </div>
            </div>
          </div> */}

          <div className="mt-[14px]">
            <ProfileType
              page={"PersonalInformation"}
              profileName={profileName}
              bio={bio}
              username={username}
              // profileCover={profileCover}
              status={profileStatus}
              profileStatusLogo={profileStatusLogo}
              avatar={avatar}
              iconDesign={iconDesign}
              listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
              profileType={profileType}
            />
          </div>

          <div
            className={`${profileType === "Classic" ? "mt-[40px] px-[24px]" : "mt-[150px] px-[24px]"}`}
          >
            {listOfSelectedSocialMediaLinks.length > 0 &&
              listOfSelectedSocialMediaLinks.map(
                (item: any) =>
                  item.name && (
                    <div className="mt-[5px]" key={item.index}>
                      <div className="flex justify-between items-center w-full">
                        <label className="text-[16px] leading-[16.8px] font-medium text-[#161616]">
                          {item.name}
                        </label>
                        <TabLinksDotsMenu
                          name={item.name}
                          handleMoveListElem={handleMoveListElem}
                          isFirstListElem={isFirstListElem}
                          isLastListElem={isLastListElem}
                          deleteFunc={changeChecked}
                        />
                      </div>
                      <div className="mt-[5px]">
                        {item.name !== "WhatsApp" ? (
                          <input
                            type="text"
                            className="w-full h-[47px] py-[15px] px-[20px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] placeholder:text-[#8391A1] placeholder:font-light text-[#161616] text-[16px] leading-[17.5px]"
                            // placeholder={getPlaceholder(elem.name)}
                            placeholder={
                              item.name === "Email"
                                ? t("placeholder_social_link_email")
                                : t("placeholder_social_link")
                            }
                            value={getValue(item.name)}
                            onChange={(event) => {
                              changeListData(item.name, event.target.value);
                            }}
                          />
                        ) : (
                          <div className="flex justify-center">
                            <div className="flex flex-row items-center w-full relative">
                              <span className="absolute top-0 left-0 m-4 text-[#161616] text-[16px]">
                                +
                              </span>
                              <input
                                type="text"
                                className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-[30px] pr-[15px] text-[#161616] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]"
                                value={getValue(item.name)}
                                onChange={(event: any) => {
                                  const newValue = event.target.value;
                                  if (/^\d*$/.test(newValue)) {
                                    if (
                                      newValue === "" ||
                                      (parseInt(newValue, 10) > 0 &&
                                        newValue[0] !== "0")
                                    ) {
                                      changeListData(
                                        item.name,
                                        event.target.value
                                      );
                                    }
                                  }
                                }}
                                onKeyPress={handleKeyPress}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
          </div>

          <div className="mt-[10px] flex justify-center px-[24px]">
            <button
              className="text-[16px] w-[161px] h-[35px] rounded-[40px] border-[#161616] border-[1px] text-[#161616]"
              onClick={openWindow}
            >
              {t("show_all")}
            </button>
          </div>

          <div className="mt-[14px] flex justify-center px-[24px]">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
              onClick={() => {
                changeState("Layout");
              }}
            >
              {t("continue")}
            </button>
          </div>
        </div>
      </div>

      {/* ============================================== BOTTOM WINDOW ============================================== */}
      <div
        className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
      >
        <div
          className="fixed inset-0 bg-gray-500 opacity-75"
          onClick={closeWindow}
        ></div>

        <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px] font-urbanist">
          <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
            <div className="flex justify-center mt-[10px]">
              <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
            </div>
            <div className="flex justify-between items-center mt-[23px] px-[24px]">
              <div className="text-white text-[1px]">
                hiiiiiiadfadgasfgafafasfgsdfhsghdfghdfghdfghdfghdfghdfghdfghdfghasdfasfgs
              </div>
              <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
                {t("select_links")}
              </div>
              <button
                className="text-[16px] leading-[18px] text-[#8391A1]"
                onClick={closeWindow}
              >
                {t("done")}
              </button>
            </div>
            <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
          </div>

          <div className="px-[24px] pb-[40px] mt-[80px] items-center">
            <div className="flex flex-col">
              <div className="mt-[10px]">
                {listOfSocialMediaLinks.map((elem: any, index: number) => (
                  <div className="mt-[5px]" key={index}>
                    <div className="flex justify-between items-center">
                      <label className="text-[16px] leading-[16.8px] font-medium text-[#161616]">
                        {elem.name}
                      </label>
                      <Switch
                        crossOrigin={""}
                        checked={getChecked(elem.name)}
                        onChange={(event) => {
                          if (elem.value !== "") {
                            changeChecked(elem.name);
                          }
                        }}
                      />
                    </div>
                    <div className="mt-[5px]">
                      {elem.name !== "WhatsApp" ? (
                        <input
                          type="text"
                          className="w-full h-[47px] py-[15px] px-[20px] rounded-[10px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] placeholder:text-[#8391A1] placeholder:font-light text-[#161616] text-[16px] leading-[17.5px]"
                          // placeholder={getPlaceholder(elem.name)}
                          placeholder={
                            elem.name === "Email"
                              ? t("placeholder_social_link_email")
                              : t("placeholder_social_link")
                          }
                          value={getValue(elem.name)}
                          onChange={(event) => {
                            changeListData(elem.name, event.target.value);
                          }}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <div className="flex flex-row items-center w-full relative">
                            <span className="absolute top-0 left-0 m-4 text-[#161616] text-[16px]">
                              +
                            </span>
                            <input
                              type="text"
                              className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-[30px] pr-[15px] text-[#161616] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]"
                              value={getValue(elem.name)}
                              onChange={(event: any) => {
                                const newValue = event.target.value;
                                if (/^\d*$/.test(newValue)) {
                                  if (
                                    newValue === "" ||
                                    (parseInt(newValue, 10) > 0 &&
                                      newValue[0] !== "0")
                                  ) {
                                    changeListData(
                                      elem.name,
                                      event.target.value
                                    );
                                  }
                                }
                              }}
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
