import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/CreateBlock/Products";
import YouTube from "../../components/ui/CreateBlock/YouTube";
import LinksUrl from "../../components/ui/CreateBlock/LinksUrl";
import PDFFile from "../../components/ui/CreateBlock/PDFFile";
import Newsletter from "../../components/ui/CreateBlock/Newsletter";
import Gallery from "../../components/ui/CreateBlock/Gallery";
import Text from "../../components/ui/CreateBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import SelectProductType from "../../components/ui/CreateProduct/SelectProductType";
import ProductDetailsV1 from "../../components/ui/CreateProduct/ProductDetailsV1";
import { useTranslation } from "react-i18next";

export async function sendProductDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendInfoAboutNewProduct,
    dispatch,
    `api/product/create/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [selectedProductType, setSelectedProductType] =
    useState("physical_product");

  const [status, setStatus] = useState("info");

  const handleChangeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  // PRODUCT ---------------------------------------------------------------
  const [productInfo, setProductInfo] = useState({
    name: "",
    description: "",
  });

  const [productThumbnail, setProductThumbnail] = useState("");

  const handleChangeProductInfo = (key: string, newValue: any) => {
    let oldProductInfo = structuredClone(productInfo);

    if (key === "name") {
      oldProductInfo.name = newValue;
    }

    if (key === "description") {
      oldProductInfo.description = newValue;
    }

    setProductInfo(oldProductInfo);
  };

  const handleChangeProductThumbnail = (newValue: any) => {
    setProductThumbnail(newValue);
  };

  const [listOfProductImages, setListOfProductImages] = useState([]);

  const handleAddProductImage = (fileStr: any) => {
    // setNewsletterTitle(titleNew);
    // let listOfProductImagesTmp = structuredClone(listOfProductImages);
    let listOfProductImagesTmp = listOfProductImages;
    // @ts-ignore
    listOfProductImagesTmp.push({
      id: listOfProductImages.length + 1,
      file: fileStr,
    });

    setListOfProductImages(listOfProductImagesTmp);
  };

  const handleChangeListProductImage = (listNew: any) => {
    setListOfProductImages(listNew);
  };

  // useEffect(() => {
  //   console.log("listOfProductImages", listOfProductImages);
  // }, [listOfProductImages]);

  const handleDeleteProductImage = (imageId: number) => {
    let listOfProductImagesTmp = [];
    let j = 0;

    for (let i = 0; i < listOfProductImages.length; i++) {
      // @ts-ignore
      if (listOfProductImages[i].id !== imageId) {
        j += 1;
        listOfProductImagesTmp.push({
          id: j,
          // @ts-ignore
          file: listOfProductImages[i].file,
        });
      }
    }
    // @ts-ignore
    setListOfProductImages(listOfProductImagesTmp);
  };

  // Physical product ---------------------------------------------------------------
  const [physicalProductContent, setPhysicalProductContent] = useState({
    versions: [
      {
        name: "",
        options: [{ name: "", details: "", price: "" }],
      },
    ],
    currency: "dollar",
    currencySymbol: "$",
    action: "I want this",
    phone: "",
    deliveryArea: [],
  });

  const handleChangePhysicalProductContent = (
    key: string,
    newValue: string
  ) => {
    let oldPhysicalProductContent = structuredClone(physicalProductContent);

    if (key === "action") {
      oldPhysicalProductContent.action = newValue;
    }

    if (key === "currency") {
      oldPhysicalProductContent.currency = newValue;

      if (newValue === "dollar") {
        oldPhysicalProductContent.currencySymbol = "$";
      }

      if (newValue === "tenge") {
        oldPhysicalProductContent.currencySymbol = "₸";
      }

      if (newValue === "ruble") {
        oldPhysicalProductContent.currencySymbol = "₽";
      }
    }

    if (key === "phone") {
      oldPhysicalProductContent.phone = newValue;
    }

    setPhysicalProductContent(oldPhysicalProductContent);
  };

  const handleChangePhysicalProductList = (
    indexVersion: number,
    indexOption: number,
    key: string,
    newValue: string,
    action = ""
  ) => {
    let oldPhysicalProductContent = structuredClone(physicalProductContent);

    if (action === "version") {
      let listTmp = oldPhysicalProductContent.versions;

      if (key === "name") {
        listTmp[indexVersion].name = newValue;
      }

      oldPhysicalProductContent.versions = listTmp;
    } else if (action === "option") {
      let listTmp = oldPhysicalProductContent.versions[indexVersion].options;

      if (key === "name") {
        listTmp[indexOption].name = newValue;
      }

      if (key === "details") {
        listTmp[indexOption].details = newValue;
      }

      if (key === "price") {
        listTmp[indexOption].price = newValue;
      }

      oldPhysicalProductContent.versions[indexVersion].options = listTmp;
    } else if (action === "add_version") {
      oldPhysicalProductContent.versions.push({
        name: "",
        options: [{ name: "", details: "", price: "" }],
      });
    } else if (action === "add_option") {
      oldPhysicalProductContent.versions[indexVersion].options.push({
        name: "",
        details: "",
        price: "",
      });
    } else if (action === "delete_version") {
      oldPhysicalProductContent.versions.splice(indexVersion, 1);
    } else if (action === "delete_option") {
      oldPhysicalProductContent.versions[indexVersion].options.splice(
        indexOption,
        1
      );
    }

    setPhysicalProductContent(oldPhysicalProductContent);
  };

  // ALL Settings
  useEffect(() => {
    const createProductDataStorage =
      utils.LocalStorage.get("CreateProductData");
    if (createProductDataStorage) {
      if (createProductDataStorage.selectedProductType) {
        setSelectedProductType(createProductDataStorage.selectedProductType);
      }
      if (createProductDataStorage.status) {
        setStatus(createProductDataStorage.status);
      }
      if (createProductDataStorage.productInfo) {
        setProductInfo(createProductDataStorage.productInfo);
      }
      if (createProductDataStorage.listOfProductImages) {
        setListOfProductImages(createProductDataStorage.listOfProductImages);
      }

      if (createProductDataStorage.physicalProductContent) {
        setPhysicalProductContent(
          createProductDataStorage.physicalProductContent
        );
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    utils.LocalStorage.set("CreateProductData", {
      selectedProductType: selectedProductType,
      status: status,
      productInfo: productInfo,
      // listOfProductImages: listOfProductImages,
      physicalProductContent: physicalProductContent,
    });
  }, [
    selectedProductType,
    status,
    productInfo,
    // listOfProductImages,
    physicalProductContent,
  ]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sendInfoAboutNewProductStore = useSelector(
    (state: any) => state.sendInfoAboutNewProductStore
  );

  // SEND DATA
  const handleSendProductData = (blockType: string) => {
    const formData = new FormData();

    if (productThumbnail) {
      // @ts-ignore
      const base64String = productThumbnail.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      // @ts-ignore
      const fileType = productThumbnail
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      // const fileBlob = new Blob([byteArray], { type: "image/jpeg" });
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append("thumbnail", fileBlob, `thumbnail.${fileType}`);
    }

    if (listOfProductImages && listOfProductImages.length > 0) {
      for (let i = 0; i < listOfProductImages.length; i++) {
        // @ts-ignore
        const base64String = listOfProductImages[i].file.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = listOfProductImages[i].file
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          //@ts-ignore
          `product_image${listOfProductImages[i].id}`,
          fileBlob,
          //@ts-ignore
          `product_image${listOfProductImages[i].id}.${fileType}`
        );
      }
    }

    let dataForm = {
      product_type: selectedProductType,
      product_info: {
        name: productInfo.name,
        description: productInfo.description,
        image_length: listOfProductImages.length,
      },
      product_data: {},
    };

    if (selectedProductType == "physical_product") {
      dataForm.product_data = physicalProductContent;
    }

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    sendProductDataFunc(dispatch, "", formData);
  };

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    // console.log("sendInfoAboutNewProductStore", sendInfoAboutNewProductStore);
    if (sendInfoAboutNewProductStore && sendInfoAboutNewProductStore.data) {
      navigate("/product");
      utils.LocalStorage.remove("CreateProductData");
      dispatch({ type: constants.sendInfoAboutNewProduct.reset });
    }
  }, [sendInfoAboutNewProductStore]);

  if (sendInfoAboutNewProductStore && sendInfoAboutNewProductStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendInfoAboutNewProductStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        <ProductDetailsV1
          notify={notify}
          burgerMenuOpen={burgerMenuOpen}
          toggleBurgerMenu={toggleBurgerMenu}
          selectedProductType={selectedProductType}
          productInfo={productInfo}
          productThumbnail={productThumbnail}
          handleChangeProductThumbnail={handleChangeProductThumbnail}
          handleChangeProductInfo={handleChangeProductInfo}
          listOfProductImages={listOfProductImages}
          handleChangeListProductImage={handleChangeListProductImage}
          handleAddProductImage={handleAddProductImage}
          handleDeleteProductImage={handleDeleteProductImage}
          // Physical product
          physicalProductContent={physicalProductContent}
          handleChangePhysicalProductContent={
            handleChangePhysicalProductContent
          }
          handleChangePhysicalProductList={handleChangePhysicalProductList}
          status={status}
          handleChangeStatus={handleChangeStatus}
          handleSendProductData={handleSendProductData}
        />

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
