import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Radio,
} from "@material-tailwind/react";
import SocialLinks from "../SocialLinks";
import ProfileType from "../ProfileType";
import * as utils from "../../utils";
import BurgerMenu from "../BurgerMenu";
import { useTranslation } from "react-i18next";

export default function Page({
  state,
  changeState,
  profileName,
  iconDesign,
  username,
  profileStatus,
  profileStatusLogo,
  profileCover,
  bio,
  avatar,
  listOfSelectedSocialMediaLinks,
  profileType,
  changeProfileType,
  handleClickProfileInfoEdit,
}: any) {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[50px] w-screen max-w-md">
        <BurgerMenu
          isOpen={burgerMenuOpen}
          profileName={profileName}
          username={username}
          toggleBurgerMenu={toggleBurgerMenu}
        />
        <div className="flex flex-col">
          <div className="flex justify-between items-center h-[56px] px-[24px]">
            <button
              onClick={() => {
                changeState("Links");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[20px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="text-[18px] leading-[24.59px] text-center text-[#161616] font-semibold">
              {t("layout")}
            </div>
            {/* <div
              className="text-[14px] leading-[18px] text-[#8391A1]"
              //   onClick={goHome}
            >
              Skip
            </div> */}
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                // <Icon
                //   icon="iconamoon:menu-burger-horizontal-light"
                //   className="w-[24px] h-[26px]"
                // />
                <img
                  src="/images/hamburger_menu/hamburger_menu.svg"
                  alt="hamburger_menu.svg"
                  className="w-[24px] h-[26.88px]"
                />
              ) : (
                <span className="text-white text-[12px]"> </span>
              )}
            </button>
          </div>

          <div className="mt-[5px] px-[24px]">
            <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
              <div
                className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Info");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("info")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                onClick={() => {
                  changeState("Links");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("links")}
                </div>
              </div>
              <div
                className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                onClick={() => {
                  //   changeState("Layout");
                }}
              >
                <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                  {t("layout")}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[14px]">
            <ProfileType
              page={"PersonalInformation"}
              profileName={profileName}
              bio={bio}
              avatar={avatar}
              // profileCover={profileCover}
              status={profileStatus}
              profileStatusLogo={profileStatusLogo}
              iconDesign={iconDesign}
              listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
              profileType={profileType}
              username={username}
            />
          </div>

          <div
            className={`${profileType === "Classic" ? "mt-[40px]" : "mt-[150px]"} flex justify-center px-[24px]`}
          >
            <div
              className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
              onClick={() => {
                changeProfileType("Classic");
              }}
            >
              <label className="pl-[10px]">
                <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                  {t("classic")}
                </div>
                <div className="text-[#8391A1] mt-[3px] text-[14px] leading-[20px]">
                  {t("classic_style_text")}
                </div>
              </label>
              <div className="pr-[5px]">
                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={profileType === "Classic"}
                  onChange={() => {
                    changeProfileType("Classic");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-[10px] flex justify-center px-[24px]">
            <div
              className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
              onClick={() => {
                changeProfileType("Minimalist");
              }}
            >
              <label className="pl-[10px]">
                <div className="text-[22px] leading-[20px] font-semibold text-[#161616]">
                  {t("minimalist")}
                </div>
                <div className="text-[#8391A1] mt-[3px] text-[14px] leading-[20px]">
                  {t("minimalist_style_text")}
                </div>
              </label>
              <div className="pr-[5px]">
                <Radio
                  name="description"
                  crossOrigin={""}
                  checked={profileType === "Minimalist"}
                  onChange={() => {
                    changeProfileType("Minimalist");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-[62px] flex justify-center px-[24px]">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
              onClick={handleClickProfileInfoEdit}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
