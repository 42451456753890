import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
  list,
} from "@material-tailwind/react";
import StepZero from "../../components/ui/AccountLaunch/StepZero";
import StepOne from "../../components/ui/AccountLaunch/StepOne";
import StepTwo from "../../components/ui/AccountLaunch/StepTwo";
import { useNavigate } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "../../components/utils";
import LoadingScreen from "../../components/ui/LoadingScreen";
import TabInfo from "../../components/ui/PersonalInformation/TabInfo";
import TabLinks from "../../components/ui/PersonalInformation/TabLinks";
import TabLayout from "../../components/ui/PersonalInformation/TabLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import * as constantsSocial from "../../components/ui/ListOfSocialMediaLinks";

export async function editProfileInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.editProfileInfo,
    dispatch,
    `api/profile/edit/`,
    "post",
    "",
    form,
    true
  )();
}

export async function profileInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.profileInfo,
    dispatch,
    `api/profile/info/`,
    "get",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState("Info");
  const [profileName, setProfileName] = useState("");
  const [bio, setBio] = useState("");
  const [profileStatus, setProfileStatus] = useState("");
  const [profileStatusLogo, setProfileStatusLogo] = useState("");
  const [avatar, setAvatar] = useState("");
  const [profileCover, setProfileCover] = useState("");
  const [nameProfileCover, setNameProfileCover] = useState("");
  const [cover, setCover] = useState("");
  const [messageCover, setMessageCover] = useState("");
  const [listOfSocialMediaLinks, setListOfSocialMediaLinks] = useState([]);
  const [listOfSocialMediaLinksFinished, setListOfSocialMediaLinksFinished] =
    useState([]);
  const [listOfSelectedSocialMediaLinks, setListOfSelectedSocialMediaLinks] =
    useState([]);
  const [messageAvatar, setMessageAvatar] = useState("");
  const [profileType, setProfileType] = useState("Classic");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [iconDesign, setIconDesign] = useState("monochrome");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const editProfileInfoStore = useSelector(
    (state: any) => state.editProfileInfoStore
  );

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  function goHome() {
    navigate("/home/profile");
    utils.LocalStorage.remove("PersonalInformationEditData");
    dispatch({ type: constants.editProfileInfo.reset });
    dispatch({ type: constants.profileInfo.reset });
  }

  function changeState(newVal: string) {
    setState(newVal);
  }

  function changeProfileType(newVal: string) {
    setProfileType(newVal);
  }

  function handleChangeProfile(profileNew: string) {
    setProfileName(profileNew);
  }

  function handleChangeBio(bioNew: string) {
    setBio(bioNew);
  }

  function handleChangeStatus(bioStatus: string) {
    setProfileStatus(bioStatus);
  }

  // Social Links
  const changeListData = (valueName: any, newValue: any) => {
    let tmpList = structuredClone(listOfSocialMediaLinks);

    let lowerNewValue = newValue.toLowerCase();

    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i]["name"] === valueName) {
        //@ts-ignore
        tmpList[i]["value"] = lowerNewValue;
      }
    }

    setListOfSocialMediaLinks(tmpList);
  };

  const changeChecked = (valueName: any) => {
    let tmpList = structuredClone(listOfSocialMediaLinks);

    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i]["name"] === valueName) {
        // if (tmpList[i]["value"] !== "") {
        //@ts-ignore
        tmpList[i]["checked"] = !tmpList[i]["checked"];
        // }
      }
    }

    setListOfSocialMediaLinks(tmpList);
  };

  const getPlaceholder = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["placeholder"];
      }
    }
    return "";
  };

  const getChecked = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["checked"];
      }
    }
    return false;
  };

  const getValue = (valueName: any) => {
    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["name"] === valueName) {
        return listOfSocialMediaLinks[i]["value"];
      }
    }
    return "";
  };

  const isFirstListElem = (valName: string) => {
    // @ts-ignore
    if (valName === listOfSelectedSocialMediaLinks[0].name) {
      return true;
    } else {
      return false;
    }
  };

  const isLastListElem = (valName: string) => {
    const lastElem =
      listOfSelectedSocialMediaLinks[listOfSelectedSocialMediaLinks.length - 2];

    if (
      //@ts-ignore
      valName === lastElem.name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleMoveListElem = (valName: string, action: string) => {
    const newList = structuredClone(listOfSocialMediaLinks);

    // Находим индекс элемента с block_num
    const index = newList.findIndex(
      //@ts-ignore
      (item) => item.name === valName
    );

    if (action === "move_up") {
      if (index === -1 || index === 0) {
        return;
      }

      let prevElemIndex = 0;

      for (let i = 0; i < listOfSelectedSocialMediaLinks.length; i++) {
        if (i !== 0) {
          //@ts-ignore
          prevElemIndex = listOfSelectedSocialMediaLinks[i - 1].index - 1;
        }

        //@ts-ignore
        if (listOfSelectedSocialMediaLinks[i].index === index + 1) {
          break;
        }
      }

      // Временная переменная для хранения значения элемента с индексом index1
      let temp = newList[index];

      // Присваиваем значение элемента с индексом index2 элементу с индексом index1
      newList[index] = newList[prevElemIndex];

      // Присваиваем значение временной переменной элементу с индексом index2
      newList[prevElemIndex] = temp;
    } else if (action === "move_down") {
      if (index === newList.length - 1) {
        return;
      }

      let lastElemIndex = 0;

      for (let i = listOfSelectedSocialMediaLinks.length - 1; i >= 0; i--) {
        if (i !== listOfSelectedSocialMediaLinks.length - 1) {
          //@ts-ignore
          lastElemIndex = listOfSelectedSocialMediaLinks[i + 1].index - 1;
        }

        //@ts-ignore
        if (listOfSelectedSocialMediaLinks[i].index === index + 1) {
          break;
        }
      }

      // Временная переменная для хранения значения элемента с индексом index1
      let temp = newList[index];

      // Присваиваем значение элемента с индексом index2 элементу с индексом index1
      newList[index] = newList[lastElemIndex];

      // Присваиваем значение временной переменной элементу с индексом index2
      newList[lastElemIndex] = temp;
    } else if (action === "move_to_top") {
      if (index === -1 || index === 0) {
        return;
      }

      // Извлечем объект из списка
      let [objectWithValName] = newList.splice(index, 1);
      // Вставим объект на первое место
      newList.unshift(objectWithValName);
    } else if (action === "move_to_bottom") {
      if (index === newList.length - 1) {
        return;
      }

      // Извлечем объект из списка
      let [objectWithBlockNum] = newList.splice(index, 1);
      // Вставим объект на первое место
      newList.push(objectWithBlockNum);
    }

    setListOfSocialMediaLinks(newList);
  };

  useEffect(() => {
    // console.log("status", status);
    let listOfCheckedSocialMediaLinks = [];
    //@ts-ignore
    let listOfSocialMediaLinksFinishedTmp = [];

    for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
      if (listOfSocialMediaLinks[i]["checked"]) {
        listOfCheckedSocialMediaLinks.push({
          //@ts-ignore
          ...listOfSocialMediaLinks[i],
          index: i + 1,
        });
      }

      if (listOfSocialMediaLinks[i]["value"] !== "") {
        listOfSocialMediaLinksFinishedTmp.push({
          //@ts-ignore
          name: listOfSocialMediaLinks[i].name,
          //@ts-ignore
          checked: listOfSocialMediaLinks[i].checked,
          //@ts-ignore
          value: listOfSocialMediaLinks[i].value,
        });
      }
    }

    const share_obj = {
      icon: "/images/social_media_icons/share.svg",
    };

    if (listOfCheckedSocialMediaLinks.length > 0) {
      listOfCheckedSocialMediaLinks.push({
        ...share_obj,
        index: listOfSocialMediaLinks.length + 1,
      });
    }

    //@ts-ignore
    setListOfSelectedSocialMediaLinks(listOfCheckedSocialMediaLinks);
    //@ts-ignore
    setListOfSocialMediaLinksFinished(listOfSocialMediaLinksFinishedTmp);
  }, [listOfSocialMediaLinks]);

  // useEffect(() => {
  //   profileInfoFunc(dispatch, "", {});
  // }, []);

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      const personalInformationDataStorage = utils.LocalStorage.get(
        "PersonalInformationEditData"
      );

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.profileName
      ) {
        setProfileName(personalInformationDataStorage.profileName);
      } else {
        setProfileName(profileInfoStore.data.response.profile_name);
      }

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.profileStatus
      ) {
        setProfileStatus(personalInformationDataStorage.profileStatus);
      } else {
        setProfileStatus(profileInfoStore.data.response.status);
      }

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.profileStatusLogo
      ) {
        setProfileStatusLogo(personalInformationDataStorage.profileStatusLogo);
      } else {
        setProfileStatusLogo(
          profileInfoStore.data.response.profile_status_logo
        );
      }

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.bio
      ) {
        setBio(personalInformationDataStorage.bio);
      } else {
        setBio(profileInfoStore.data.response.bio);
      }

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.profileType
      ) {
        setProfileType(personalInformationDataStorage.profileType);
      } else {
        if (profileInfoStore.data.response.profile_type !== "") {
          setProfileType(profileInfoStore.data.response.profile_type);
        }
      }

      if (
        personalInformationDataStorage &&
        personalInformationDataStorage.listOfSocialMediaLinks
      ) {
        setListOfSocialMediaLinks(
          personalInformationDataStorage.listOfSocialMediaLinks
        );
      } else {
        const tmpListOfSocialMediaLinks =
          profileInfoStore.data.response.list_of_social_media_links;

        // let tmpList = structuredClone(listOfSocialMediaLinks);
        let tmpList = constantsSocial.listOfSocialMediaLinks;

        for (let i = 0; i < tmpListOfSocialMediaLinks.length; i++) {
          if (tmpListOfSocialMediaLinks[i].value) {
            for (let j = 0; j < tmpList.length; j++) {
              if (tmpList[j]["name"] === tmpListOfSocialMediaLinks[i].name) {
                //@ts-ignore
                tmpList[j]["value"] = tmpListOfSocialMediaLinks[i].value;
                //@ts-ignore
                tmpList[j]["checked"] = tmpListOfSocialMediaLinks[i].checked;
              }
            }
          }
        }
        //@ts-ignore
        setListOfSocialMediaLinks(tmpList);
      }

      setAvatar(profileInfoStore.data.response.avatar);
      setProfileCover(profileInfoStore.data.response.profile_cover);
      setNameProfileCover(profileInfoStore.data.response.profile_cover_name);
      setUsername(profileInfoStore.data.response.username);
      setIconDesign(profileInfoStore.data.response.design_icons);
    }
  }, [profileInfoStore]);

  // useEffect(() => {
  //   console.log("profileStatusLogo", profileStatusLogo);
  // }, [profileStatusLogo]);

  useEffect(() => {
    const personalInformationDataStorage = utils.LocalStorage.get(
      "PersonalInformationEditData"
    );

    if (
      personalInformationDataStorage &&
      personalInformationDataStorage.state
    ) {
      setState(personalInformationDataStorage.state);
    }

    // if (personalInformationDataStorage) {
    //   if (personalInformationDataStorage.state) {
    //     setState(personalInformationDataStorage.state);
    //   }
    //   if (personalInformationDataStorage.username) {
    //     setUsername(personalInformationDataStorage.username);
    //   }
    //   if (personalInformationDataStorage.profileName) {
    //     setProfileName(personalInformationDataStorage.profileName);
    //   }
    //   if (personalInformationDataStorage.bio) {
    //     setBio(personalInformationDataStorage.bio);
    //   }
    //   if (personalInformationDataStorage.profileStatus) {
    //     setProfileStatus(personalInformationDataStorage.profileStatus);
    //   }
    //   if (personalInformationDataStorage.profileType) {
    //     setProfileType(personalInformationDataStorage.profileType);
    //   }
    //   if (personalInformationDataStorage.listOfSocialMediaLinks) {
    //     setListOfSocialMediaLinks(
    //       personalInformationDataStorage.listOfSocialMediaLinks
    //     );
    //   }
    //   if (personalInformationDataStorage.avatar) {
    //     setAvatar(personalInformationDataStorage.avatar);
    //   }
    //   if (personalInformationDataStorage.profileCover) {
    //     setProfileCover(personalInformationDataStorage.profileCover);
    //   }
    //   if (personalInformationDataStorage.nameProfileCover) {
    //     setNameProfileCover(personalInformationDataStorage.nameProfileCover);
    //   }
    // }
    // else {
    //   profileInfoFunc(dispatch, "", {});
    // }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      utils.LocalStorage.set("PersonalInformationEditData", {
        state: state,
        // username: username,
        profileName: profileName,
        profileStatus: profileStatus,
        bio: bio,
        profileType: profileType,
        listOfSocialMediaLinks: listOfSocialMediaLinks,
        // avatar: avatar,
        // profileCover: profileCover,
        // nameProfileCover: nameProfileCover,
      });
    }
  }, [
    state,
    profileName,
    profileStatus,
    bio,
    profileType,
    listOfSocialMediaLinks,
    // avatar,
    // profileCover,
    // nameProfileCover,
    // username,
  ]);

  const validateFileType = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSize = (file: any) => {
    return file.size <= constants.MaxSizeAvatar * 1024 * 1024;
  };

  const handleAvatarChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageAvatar("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileType(file)) {
    //     setMessageAvatar(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSize(file)) {
    //     setMessageAvatar(
    //       `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // localStorage.setItem("image", base64String);
    //       // @ts-ignore
    //       setAvatar(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageAvatar("");
    //   }
    // }

    setAvatar(fileStr);
  };

  const handleButtonClickAvatar = () => {
    //@ts-ignore
    document.getElementById("avatarInput").click();
  };

  const handleProfileCoverChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   // setMessageCover("Please select only one file.");
    //   setMessageCover(t("pdf_file_upload_error1"));
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   setNameProfileCover(file.name);
    // }

    // if (file) {
    //   if (!validateFileType(file)) {
    //     notify(
    //       t("image_upload_error1")
    //       // "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //     // setMessageCover(
    //     //   "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     // );
    //   } else if (!validateFileSize(file)) {
    //     notify(
    //       t("image_upload_error2")
    //       // `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     );
    //     // setMessageCover(
    //     //   `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
    //     // );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // localStorage.setItem("image", base64String);
    //       // @ts-ignore
    //       setProfileCover(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageCover("");
    //   }
    // }
    setProfileCover(fileStr);
  };

  const handleChangeNameProfileCover = (newVal: string) => {
    setNameProfileCover(newVal);
  };

  const handleButtonClickCover = () => {
    //@ts-ignore
    document.getElementById("profileCover").click();
  };

  const handleButtonDeleteCover = () => {
    setProfileCover("");
    setNameProfileCover("");
  };

  const handleChangeProfileStatusLogo = (fileStr: any) => {
    setProfileStatusLogo(fileStr);
  };

  const handleButtonClickProfileStatusLogo = () => {
    //@ts-ignore
    document.getElementById("profileStatusLogo").click();
  };

  const handleButtonDeleteProfileStatusLogo = () => {
    setProfileStatusLogo("");
  };

  // useEffect(() => {
  //   console.log("profileCover", profileCover);
  // }, [profileCover]);

  const handleClickProfileInfoEdit = () => {
    // Создать объект FormData для отправки файла
    const formData = new FormData();

    if (avatar) {
      if (avatar.includes("https://api.hubbbox.com/static/media")) {
        formData.append("avatar", avatar);
      } else {
        // @ts-ignore
        const base64String = avatar.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = avatar
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append("avatar", fileBlob, `avatar.${fileType}`);
      }
    }

    if (profileStatusLogo) {
      if (profileStatusLogo.includes("https://api.hubbbox.com/static/media")) {
        formData.append("profileStatusLogo", profileStatusLogo);
      } else {
        // @ts-ignore
        const base64String = profileStatusLogo.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = profileStatusLogo
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          "profileStatusLogo",
          fileBlob,
          `profileStatusLogo.${fileType}`
        );
      }
    }

    if (profileCover) {
      if (profileCover.includes("https://api.hubbbox.com/static/media")) {
        formData.append("profileCover", profileCover);
      } else {
        // @ts-ignore
        const base64String = profileCover.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = profileCover
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          "profileCover",
          fileBlob,
          `${nameProfileCover.split(".").slice(0, -1).join(".")}.${fileType}`
        );
      }
    }

    const dataForm = {
      profileName: profileName,
      bio: bio,
      profileType: profileType,
      listOfSocialMediaLinks: listOfSocialMediaLinksFinished,
      status: profileStatus,
      // avatar: avatar,
    };

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    editProfileInfoFunc(dispatch, "", formData);
  };

  useEffect(() => {
    // console.log("editProfileInfoStore", editProfileInfoStore);
    if (editProfileInfoStore && editProfileInfoStore.data) {
      goHome();
    }
  }, [editProfileInfoStore]);

  // useEffect(() => {
  //   console.log("profileCover", profileCover);
  // }, [profileCover]);

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  if (editProfileInfoStore && editProfileInfoStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={editProfileInfoStore.load} />
      </div>
    );
  }

  // if (profileInfoStore && profileInfoStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={profileInfoStore.load} />
  //     </div>
  //   );
  // }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="break-words overflow-hidden">
        {state === "Info" && (
          <TabInfo
            state={state}
            changeState={changeState}
            profileName={profileName}
            iconDesign={iconDesign}
            username={username}
            handleChangeProfile={handleChangeProfile}
            handleChangeStatus={handleChangeStatus}
            handleChangeProfileStatusLogo={handleChangeProfileStatusLogo}
            handleButtonClickProfileStatusLogo={
              handleButtonClickProfileStatusLogo
            }
            handleButtonDeleteProfileStatusLogo={
              handleButtonDeleteProfileStatusLogo
            }
            profileStatusLogo={profileStatusLogo}
            bio={bio}
            profileStatus={profileStatus}
            handleChangeBio={handleChangeBio}
            avatar={avatar}
            notify={notify}
            goHome={goHome}
            handleAvatarChange={handleAvatarChange}
            handleButtonClickAvatar={handleButtonClickAvatar}
            messageAvatar={messageAvatar}
            profileType={profileType}
            handleProfileCoverChange={handleProfileCoverChange}
            handleButtonDeleteCover={handleButtonDeleteCover}
            profileCover={profileCover}
            nameProfileCover={nameProfileCover}
            handleChangeNameProfileCover={handleChangeNameProfileCover}
            handleButtonClickCover={handleButtonClickCover}
            messageCover={messageCover}
            listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
          />
        )}

        {state === "Links" && (
          <TabLinks
            state={state}
            changeState={changeState}
            profileName={profileName}
            iconDesign={iconDesign}
            username={username}
            bio={bio}
            avatar={avatar}
            profileStatus={profileStatus}
            profileStatusLogo={profileStatusLogo}
            profileCover={profileCover}
            listOfSocialMediaLinks={listOfSocialMediaLinks}
            changeListData={changeListData}
            changeChecked={changeChecked}
            getPlaceholder={getPlaceholder}
            getChecked={getChecked}
            getValue={getValue}
            listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
            profileType={profileType}
            handleMoveListElem={handleMoveListElem}
            isFirstListElem={isFirstListElem}
            isLastListElem={isLastListElem}
          />
        )}

        {state === "Layout" && (
          <TabLayout
            state={state}
            changeState={changeState}
            iconDesign={iconDesign}
            profileName={profileName}
            username={username}
            bio={bio}
            avatar={avatar}
            profileStatus={profileStatus}
            profileStatusLogo={profileStatusLogo}
            profileCover={profileCover}
            listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
            profileType={profileType}
            changeProfileType={changeProfileType}
            handleClickProfileInfoEdit={handleClickProfileInfoEdit}
          />
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
