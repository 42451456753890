import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import { useNavigate, Link } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "../../components/utils";
import LoadingScreen from "../../components/ui/LoadingScreen";
import ErrorPage from "../../components/ui/ErrorPage";
import ProductDetailImageSlider from "../../components/ui/Product/ProductDetailImageSlider";
import { useTranslation } from "react-i18next";

export async function getProductDetailsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.productDetails,
    dispatch,
    `api/product/details/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // User Data
  const [username, setUsername] = useState("");
  const [profileName, setProfileName] = useState("");
  const [avatar, setAvatar] = useState("");

  //   Product Details
  const [listOfImages, setListOfImages] = useState([]);
  const [productType, setProductType] = useState("");
  const [productInfo, setProductInfo] = useState({});
  const [productData, setProductData] = useState({});

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // console.log("productData", productData);

    let newSelectedOptions = [];

    if (productType === "physical_product") {
      // @ts-ignore
      if (productData && productData.versions) {
        //@ts-ignore
        for (let i = 0; i < productData.versions.length; i++) {
          //@ts-ignore
          newSelectedOptions.push({ version: i + 1, option: 1 });
        }
      }
    } else if (productType === "digital_product") {
      //@ts-ignore
      newSelectedOptions.push({ version: 1, option: 1 });
    } else if (productType === "membership") {
      // @ts-ignore
      if (productData && productData.intervals) {
        //@ts-ignore
        newSelectedOptions.push({ version: 1, option: 1 });
      }
    }

    //@ts-ignore
    setSelectedOptions(newSelectedOptions);
  }, [productData]);

  const productDetailsStore = useSelector(
    (state: any) => state.productDetailsStore
  );

  useEffect(() => {
    // console.log("productDetailsStore", productDetailsStore);
    if (productDetailsStore && productDetailsStore.data) {
      setUsername(productDetailsStore.data.response.user_data.username);
      setProfileName(productDetailsStore.data.response.user_data.profile_name);
      setAvatar(productDetailsStore.data.response.user_data.avatar);
      setListOfImages(
        productDetailsStore.data.response.product_details.cover_images
      );
      setProductType(
        productDetailsStore.data.response.product_details.product_type
      );
      setProductInfo(
        productDetailsStore.data.response.product_details.product_info
      );
      setProductData(
        productDetailsStore.data.response.product_details.product_data
      );
    }
  }, [productDetailsStore]);

  useEffect(() => {
    const productDetailStorage = utils.LocalStorage.get(`ProductDetail`);

    if (productDetailStorage) {
      if (productDetailStorage.selectedOptions) {
        setSelectedOptions(productDetailStorage.selectedOptions);
      }
    }

    getProductDetailsFunc(dispatch, "", { id: params.productId });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  useEffect(() => {
    utils.LocalStorage.set(`ProductDetail`, {
      selectedOptions: selectedOptions,
    });
  }, [selectedOptions]);

  function splitText(text: string, key: string) {
    if (text !== "") {
      const words = text.split("\n"); // Split the text into an array of words using space as a separator
      // console.log("words", words);
      return (
        <div>
          {words.map((elem: any, index: number) =>
            elem !== "" ? (
              <div className="leading-[20px]" key={`${key}-${index}`}>
                {elem}
              </div>
            ) : (
              <br key={`${key}-${index}`} />
            )
          )}
          {/* <div className="-mb-[4px]"></div> */}
        </div>
      );
    }
  }

  function handleChangeOptionPhysicalProduct(
    indexVersion: number,
    indexOption: any
  ) {
    let newSelectedOptions = structuredClone(selectedOptions);

    if (newSelectedOptions.length > 0) {
      for (let i = 0; i < newSelectedOptions.length; i++) {
        //@ts-ignore
        if (newSelectedOptions[i].version === indexVersion) {
          //@ts-ignore
          newSelectedOptions[i].option = indexOption;
        }
      }
    }

    setSelectedOptions(newSelectedOptions);
  }

  function handleCheckOptionPhysicalProduct(
    indexVersion: number,
    indexOption: any
  ) {
    if (selectedOptions.length > 0) {
      for (let i = 0; i < selectedOptions.length; i++) {
        if (
          //@ts-ignore
          selectedOptions[i].version &&
          //@ts-ignore
          selectedOptions[i].version === indexVersion
        ) {
          return (
            //@ts-ignore
            selectedOptions[i].option &&
            //@ts-ignore
            selectedOptions[i].option === indexOption
          );
        }
      }
    }
    return false;
  }

  function handleChangeOption(indexOption: number) {
    let newSelectedOptions = structuredClone(selectedOptions);

    if (newSelectedOptions.length > 0) {
      if (selectedOptions && selectedOptions.length > 0) {
        //@ts-ignore
        newSelectedOptions[0].option = indexOption;
      }
    }
    setSelectedOptions(newSelectedOptions);
  }

  function handleCheckOption(indexOption: number) {
    if (selectedOptions.length > 0) {
      if (
        //@ts-ignore
        selectedOptions &&
        //@ts-ignore
        selectedOptions[0].option
      ) {
        //@ts-ignore
        return selectedOptions[0].option === indexOption;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const NumberFormatter = (formatterNumber: number) => {
    const formatNumber = (num: number) => {
      return new Intl.NumberFormat("ru-RU").format(num);
    };

    return formatNumber(formatterNumber);
  };

  if (productDetailsStore && productDetailsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={productDetailsStore.load} />
      </div>
    );
  }

  if (
    productDetailsStore &&
    (productDetailsStore.fail || productDetailsStore.error)
  ) {
    return (
      <div>
        <ErrorPage error={productDetailsStore.fail} />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="font-manrope pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
        <div className="w-full h-[70px] flex justify-center items-center fixed inset-x-0 z-10 bg-white shadow-custom">
          <div className="w-full bg-white py-[10px] px-[15px]">
            <div className="flex space-x-[15px] items-center">
              {!avatar ? (
                <img
                  src="/images/avatar.svg"
                  alt="avatar.svg"
                  className="w-[50px] h-[50px]"
                />
              ) : (
                <Avatar
                  src={avatar}
                  alt="avatar"
                  className="w-[50px] h-[50px]"
                />
              )}
              <div className="">
                <div className="text-[18px] leading-[19.2px] font-semibold tracking-[-1%]">
                  {profileName}
                </div>
                <div className="mt-[5px] text-[14px] font-medium text-[#161616] leading-[14.4px]">
                  @{username}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[70px] pb-[40px]">
          <div className="flex flex-col">
            <ProductDetailImageSlider
              listOfImages={listOfImages}
              productId={params.productId}
            />

            <div className="px-[24px]">
              <div className="mt-[15px]">
                <span className="text-[24px] text-[#161616] font-semibold leading-[26px]">
                  {
                    //@ts-ignore
                    productInfo.name
                  }
                </span>
              </div>

              <div className="mt-[15px]">
                {
                  //@ts-ignore
                  productInfo.description &&
                    //@ts-ignore
                    splitText(productInfo.description, "description-product")
                }
              </div>

              {productType === "physical_product" && (
                <div className="">
                  {productData &&
                    // @ts-ignore
                    productData.versions &&
                    // @ts-ignore
                    productData.versions.map(
                      (versionElem: any, indexVersion: number) => (
                        <div
                          className="mt-[30px]"
                          key={`version-${indexVersion}`}
                        >
                          <span className="text-[16px] leading-[18x] font-semibold text-[#161616]">
                            {versionElem.name}
                          </span>

                          {versionElem.options &&
                            versionElem.options.length > 0 && (
                              <div className="mt-[5px]"></div>
                            )}

                          {versionElem.options.map(
                            (optionElem: any, indexOption: number) => (
                              <div
                                className="mt-[10px]"
                                key={`option-${indexOption}`}
                              >
                                <div
                                  className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                                  onClick={() => {
                                    handleChangeOptionPhysicalProduct(
                                      indexVersion + 1,
                                      indexOption + 1
                                    );
                                  }}
                                >
                                  <div className="">
                                    <div className="flex space-x-[15px] items-center">
                                      {optionElem.price && (
                                        <div className="flex-shrink-0 rounded-[40px] bg-[#161616] text-[16px] leading-[18px] text-white px-[16px] py-[8px] flex items-center">
                                          {NumberFormatter(optionElem.price)}{" "}
                                          {
                                            //@ts-ignore
                                            productData.currencySymbol &&
                                              //@ts-ignore
                                              productData.currencySymbol
                                          }
                                        </div>
                                      )}
                                      <div className="">
                                        {optionElem.name && (
                                          <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                                            {optionElem.name}
                                          </div>
                                        )}
                                        {optionElem.details && (
                                          <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                                            {splitText(
                                              optionElem.details,
                                              `option-details-${indexOption}`
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <Radio
                                    name={`version-${indexVersion}`}
                                    containerProps={{
                                      className: "",
                                    }}
                                    crossOrigin={""}
                                    checked={handleCheckOptionPhysicalProduct(
                                      indexVersion + 1,
                                      indexOption + 1
                                    )}
                                    onChange={() => {
                                      handleChangeOptionPhysicalProduct(
                                        indexVersion + 1,
                                        indexOption + 1
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                </div>
              )}

              {productType === "digital_product" &&
                // @ts-ignore
                productData.type === "file" && (
                  <div className="">
                    {
                      //@ts-ignore
                      productData.fileVersions &&
                        //@ts-ignore
                        productData.fileVersions.length > 0 && (
                          <div className="mt-[20px]"></div>
                        )
                    }
                    {productData &&
                      //@ts-ignore
                      productData.fileVersions &&
                      //@ts-ignore
                      productData.fileVersions.map(
                        (optionElem: any, indexOption: number) => (
                          <div
                            className="mt-[10px]"
                            key={`option-${indexOption}`}
                          >
                            <div
                              className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                              onClick={() => {
                                handleChangeOption(indexOption + 1);
                              }}
                            >
                              <div className="">
                                <div className="flex space-x-[15px] items-center">
                                  {optionElem.price && (
                                    <div className="flex-shrink-0 rounded-[40px] bg-[#161616] text-[16px] leading-[18px] text-white px-[16px] py-[8px] flex items-center">
                                      {NumberFormatter(optionElem.price)}{" "}
                                      {
                                        //@ts-ignore
                                        productData.currencySymbol &&
                                          //@ts-ignore
                                          productData.currencySymbol
                                      }
                                    </div>
                                  )}
                                  <div className="">
                                    {optionElem.name && (
                                      <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                                        {optionElem.name}
                                      </div>
                                    )}
                                    {optionElem.details && (
                                      <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                                        {splitText(
                                          optionElem.details,
                                          `option-details-${indexOption}`
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Radio
                                name={`digital-product`}
                                containerProps={{
                                  className: "",
                                }}
                                crossOrigin={""}
                                checked={handleCheckOption(indexOption + 1)}
                                onChange={() => {
                                  handleChangeOption(indexOption + 1);
                                }}
                              />
                            </div>
                          </div>
                        )
                      )}
                  </div>
                )}

              {productType === "digital_product" &&
                // @ts-ignore
                productData.type === "url" && (
                  <div className="">
                    {
                      //@ts-ignore
                      productData.urlVersions &&
                        //@ts-ignore
                        productData.urlVersions.length > 0 && (
                          <div className="mt-[20px]"></div>
                        )
                    }
                    {productData &&
                      //@ts-ignore
                      productData.urlVersions &&
                      //@ts-ignore
                      productData.urlVersions.map(
                        (optionElem: any, indexOption: number) => (
                          <div
                            className="mt-[10px]"
                            key={`option-${indexOption}`}
                          >
                            <div
                              className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                              onClick={() => {
                                handleChangeOption(indexOption + 1);
                              }}
                            >
                              <div className="">
                                <div className="flex space-x-[15px] items-center">
                                  {optionElem.price && (
                                    <div className="flex-shrink-0 rounded-[40px] bg-[#161616] text-[16px] leading-[18px] text-white px-[16px] py-[8px] flex items-center">
                                      {NumberFormatter(optionElem.price)}{" "}
                                      {
                                        //@ts-ignore
                                        productData.currencySymbol &&
                                          //@ts-ignore
                                          productData.currencySymbol
                                      }
                                    </div>
                                  )}
                                  <div className="">
                                    {optionElem.name && (
                                      <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                                        {optionElem.name}
                                      </div>
                                    )}
                                    {optionElem.details && (
                                      <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                                        {splitText(
                                          optionElem.details,
                                          `option-details-${indexOption}`
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Radio
                                name={`digital-product`}
                                containerProps={{
                                  className: "",
                                }}
                                crossOrigin={""}
                                checked={handleCheckOption(indexOption + 1)}
                                onChange={() => {
                                  handleChangeOption(indexOption + 1);
                                }}
                              />
                            </div>
                          </div>
                        )
                      )}
                  </div>
                )}

              {productType === "membership" && (
                <div className="">
                  <div className="mt-[30px]">Select your plan</div>

                  {
                    //@ts-ignore
                    productData.intervals &&
                      //@ts-ignore
                      productData.intervals.length > 0 && (
                        <div className="mt-[5px]"></div>
                      )
                  }
                  {productData &&
                    //@ts-ignore
                    productData.intervals &&
                    //@ts-ignore
                    productData.intervals.map(
                      (optionElem: any, indexOption: number) => (
                        <div
                          className="mt-[10px]"
                          key={`option-${indexOption}`}
                        >
                          <div
                            className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                            onClick={() => {
                              handleChangeOption(indexOption + 1);
                            }}
                          >
                            <div className="">
                              <div className="flex space-x-[5px] items-center">
                                {optionElem.price && (
                                  <div className="flex-shrink-0 rounded-[40px] bg-[#161616] text-[16px] leading-[18px] text-white px-[16px] py-[8px] flex items-center">
                                    {NumberFormatter(optionElem.price)}{" "}
                                    {
                                      //@ts-ignore
                                      productData.currencySymbol &&
                                        //@ts-ignore›
                                        productData.currencySymbol
                                    }
                                  </div>
                                )}
                                <div className="">
                                  {optionElem.interval && (
                                    <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                                      / {optionElem.interval}
                                    </div>
                                  )}
                                  {optionElem.details && (
                                    <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                                      {splitText(
                                        optionElem.details,
                                        `option-details-${indexOption}`
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <Radio
                              name={`digital-product`}
                              containerProps={{
                                className: "",
                              }}
                              crossOrigin={""}
                              checked={handleCheckOption(indexOption + 1)}
                              onChange={() => {
                                handleChangeOption(indexOption + 1);
                              }}
                            />
                          </div>
                        </div>
                      )
                    )}
                </div>
              )}

              {/* {productType === "bundle" && (
                <div className="">
                  {
                    //@ts-ignore
                    productData.intervals &&
                      //@ts-ignore
                      productData.intervals.length > 0 && (
                        <div className="mt-[20px]"></div>
                      )
                  }
                  {productData &&
                    //@ts-ignore
                    productData.intervals &&
                    //@ts-ignore
                    productData.intervals.map(
                      (optionElem: any, indexOption: number) => (
                        <div
                          className="mt-[10px]"
                          key={`option-${indexOption}`}
                        >
                          <div
                            className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                            onClick={() => {
                              handleChangeOption(indexOption + 1);
                            }}
                          >
                            <div className="">
                              <div className="flex space-x-[15px] items-center">
                                {optionElem.price && (
                                  <div className="flex-shrink-0 rounded-[40px] bg-[#161616] text-[16px] leading-[18px] text-white px-[16px] py-[8px] flex items-center">
                                    {optionElem.price}{" "}
                                    {
                                      //@ts-ignore
                                      productData.currencySymbol &&
                                        //@ts-ignore
                                        productData.currencySymbol
                                    }
                                  </div>
                                )}
                                <div className="">
                                  {optionElem.interval && (
                                    <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                                      {optionElem.interval}
                                    </div>
                                  )}
                                  {optionElem.details && (
                                    <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                                      {optionElem.details}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <Radio
                              name={`digital-product`}
                              containerProps={{
                                className: "",
                              }}
                              crossOrigin={""}
                              checked={handleCheckOption(indexOption + 1)}
                              onChange={() => {
                                handleChangeOption(indexOption + 1);
                              }}
                            />
                          </div>
                        </div>
                      )
                    )}
                </div>
              )} */}

              {params.productId === "37" || params.productId === "38" ? (
                <div className="mt-[30px]">
                  <div className="flex justify-center">
                    <button
                      className="w-full h-[50px] rounded-xl bg-black text-white text-[18px]"
                      onClick={() => {
                        window.open(
                          `https://wa.me/77713809092?text=Здравствуйте,%20пишу%20с%20сайта%20hubbbox.com%20по%20поводу%20курсов%20по%20привлечению%20инвестиций%20от%20Аяна%20Биримжана%0A`,
                          "_blank"
                        );
                      }}
                    >
                      {t("contact_us")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-[30px]">
                  <div className="flex justify-center">
                    <button
                      className="w-full h-[50px] rounded-xl bg-black text-white text-[18px]"
                      onClick={() => {
                        window.open(
                          //@ts-ignore
                          `https://wa.me/${productData.phone}?text=Здравствуйте,%20пишу%20с%20сайта%20hubbbox.com%20по%20поводу%20продукта%20"${productInfo.name}"`,
                          "_blank"
                        );
                      }}
                    >
                      {
                        //   @ts-ignore
                        getAction(productData.action)
                      }
                    </button>
                  </div>
                </div>

                // <div className="mt-[30px]">
                //   <button
                //     className="w-full h-[47px] rounded-[10px] text-[#161616] text-center text-[18px] leading-[22px] border-[1px] border-[#161616]"
                //     // onClick={handleButtonClickAvatar}
                //   >
                //     {t("add_to_cart")}
                //   </button>

                //   <div className="mt-[10px] flex justify-center">
                //     <button
                //       className="w-full h-[50px] rounded-xl bg-black text-white text-[18px]"
                //       // onClick={handleChangeStep}
                //     >
                //       {
                //         //   @ts-ignore
                //         getAction(productData.action)
                //       }
                //     </button>
                //   </div>
                // </div>
              )}

              {/* {!(params.productId === "37" || params.productId === "38") && (
                <div>
                  <div className="mt-[30px]">
                    <div className="flex justify-between items-center">
                      <div className="font-medium leading-[19.12px] text-[16px] text-[#161616]">
                        {t("review")}
                      </div>
                      <div className="flex space-x-1 items-end">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                          4.9 (241 {t("ratings")})
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-[15px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                      </div>
                      <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                        225 {t("ratings")}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[15px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                      </div>
                      <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                        10 {t("ratings")}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[15px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                      </div>
                      <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                        2 {t("ratings")}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[15px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                      </div>
                      <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                        2 {t("ratings")}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[15px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <img
                          src="/images/product_detail/star_yellow.svg"
                          alt="star_yellow.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                        <img
                          src="/images/product_detail/star_gray.svg"
                          alt="star_gray.svg"
                          className="w-[17.07px] h-[16px]"
                        />
                      </div>
                      <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                        2 {t("ratings")}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
