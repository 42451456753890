import React, { useEffect, useState, useRef, useCallback } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Avatar,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Page({
  profileName,
  selectedBlockLayout,
  changeStatus,
  urlGalleryImage,
  handleChangeUrlGalleryImage,
  notify,
  listOfGalleryImages,
  handleSetUrlGalleryImage,
  handleDeleteUrlGalleryImage,
  handleSendProfileData,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const textareaRefUrl = useRef(null);

  const handleChangeRefUrl = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    handleChangeUrlGalleryImage(newValue, "", "", "url");
    // Устанавливаем высоту textarea так, чтобы она соответствовала высоте содержимого
    // @ts-ignore
    textareaRefUrl.current.style.height = "auto";
    // @ts-ignore
    textareaRefUrl.current.style.height = `${textareaRefUrl.current.scrollHeight}px`;
  };

  // useEffect(() => {
  //   console.log("text", text);
  // }, [text]);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);
  const [imageType, setImageType] = useState(null);

  // Image NEW
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleSetUrlGalleryImage(result);
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleSetUrlGalleryImage(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("urlGalleryImage").click();
  };

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    // "Side slider", "Big block"

    // if (urlGalleryImage === "") {
    //   setErrorCreate(t("create_block_url_images_error1"));
    // } else

    if (listOfGalleryImages.length === 0) {
      setErrorCreate(t("create_block_url_images_error2"));
    } else {
      setErrorCreate("");
    }
  }, [urlGalleryImage, listOfGalleryImages]);

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] pb-[40px] w-screen max-w-md">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("create_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        <textarea
          ref={textareaRefUrl}
          placeholder={t("url")}
          className="mt-[25px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
          value={urlGalleryImage}
          onChange={handleChangeRefUrl}
          rows={1} // Начнем с одной строки
        />

        {isConverting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="text-center">
              {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
              <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                Идет конвертация ...
              </div>
            </div>
          </div>
        )}

        <div className="mt-[15px]">
          <span className="font-semibold text-[18px] leading-[21.86px] text-[#161616]">
            {t("upload_image")}
          </span>
        </div>

        {selectedBlockLayout === "Big block" && (
          <div className="mt-[15px]">
            {listOfGalleryImages.length === 0 ? (
              // <div
              //   className="w-full h-full aspect-video rounded-[10px] border-[1px] bg-[#F7F8F9] border-[#8391A1] border-dashed flex justify-center items-center"
              //   onClick={handleButtonImageInputClick}
              // >
              //   <div className="flex flex-col justify-center items-center">
              //     <img
              //       src="/images/create_block/upload_image.svg"
              //       alt="upload_image.svg"
              //       className="w-[40px] h-[40px]"
              //     />

              //     <div className="mt-[20px] px-[20px] text-center text-[#8391A1] text-[12px]">
              //       {t("create_block_url_images_text1")}
              //     </div>
              //   </div>
              // </div>

              <div className="flex justify-between">
                <div
                  className="w-[100px] h-[100px] flex-shrink-0 border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dashed rounded-[10px] flex justify-center items-center"
                  onClick={handleButtonImageInputClick}
                >
                  <img
                    src="/images/create_block/upload_image.svg"
                    alt="upload_image.svg"
                    className="w-[40px] h-[40px]"
                  />
                </div>
                <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[18px]">
                  {t("create_block_url_images_text1")}
                </div>
              </div>
            ) : (
              // <div
              //   className="relative w-full pt-[75%] overflow-hidden rounded-[10px]"
              //   onClick={handleButtonImageInputClick}
              // >
              //   <img
              //     src={listOfGalleryImages[0].file}
              //     alt="urlGalleryImage"
              //     className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
              //   />
              // </div>

              <div
                className="relative rounded-[10px]"
                // onClick={handleButtonImageInputClick}
              >
                <img
                  src={listOfGalleryImages[0].file}
                  alt="urlGalleryImage"
                  className="w-full h-full rounded-[10px]"
                />

                <button
                  onClick={handleDeleteUrlGalleryImage}
                  className="absolute top-1 right-1 bg-[#161616] text-white px-[5px] text-center rounded-full"
                >
                  ✕
                </button>
              </div>
            )}
          </div>
        )}

        {selectedBlockLayout === "Tile" && (
          <div className="mt-[15px]">
            {listOfGalleryImages.length === 0 ? (
              <div
                className="w-full h-[70px] px-[25px] border-[1px] border-[#8391A1] border-dashed bg-[#F7F8F9] rounded-[10px] flex justify-center items-center"
                onClick={handleButtonImageInputClick}
              >
                <div className="flex space-x-[15px] items-center">
                  <img
                    src="/images/create_block/upload_image.svg"
                    alt="upload_image.svg"
                    className="w-[40px] h-[40px]"
                  />

                  <div className="text-start text-[#8391A1] text-[12px]">
                    {t("create_block_url_images_text1")}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-full h-[70px] rounded-[10px] flex justify-center items-center"
                onClick={handleButtonImageInputClick}
              >
                <img
                  src={listOfGalleryImages[0].file}
                  alt="urlGalleryImage"
                  className="w-full h-full object-cover object-center rounded-[10px]"
                />
              </div>
            )}
          </div>
        )}

        <input
          id="urlGalleryImage"
          type="file"
          accept=".gif,.jpg,.jpeg,.png,.heic,.heif"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {error !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {error}
          </div>
        )}

        {errorCreate !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {errorCreate}
          </div>
        )}

        <div className="mt-[80px]">
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={() => {
              if (errorCreate === "") {
                handleSendProfileData("Gallery");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
