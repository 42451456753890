import React, { useEffect, useState, useRef, useCallback } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Page({
  profileName,
  changeStatus,
  pdfFile,
  notify,
  handleChangePdfFile,
  handleSendProfileData,
  handleButtonClickImagePdfFileUrl,
  handleImagePdfFileChange,
  messageImagePdfFile,
  imagePdfFile,
  handleFilePdfFileChange,
  handleButtonClickFilePdfFileUrl,
  messageFilePdfFile,
  filePdfFile,
  filePdfFileName,
  changeMessageFilePdfFile,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  //   const [title, setTitle] = useState("");
  //   const [description, setDescription] = useState("");

  //   useEffect(() => {
  //     console.log("profileName", profileName);
  //   }, [profileName]);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);

  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => {
  //   setImage(null); // Сбрасываем выбранное изображение при закрытии модального окна
  //   setModalIsOpen(false);
  // };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];
  //   const isValidImage = file.type.startsWith("image/");

  //   if (!isValidImage) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error1")
  //       // "Unsupported file format. Please select an image (jpg, jpeg, png)."
  //     );
  //     return;
  //   }

  //   if (file.size > constants.MaxSizeImagePdfFile * 1024 * 1024) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error2")
  //       // `File size exceeds ${constants.MaxSizeImagePdfFile}MB limit. Please select a smaller file.`
  //     );
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     //@ts-ignore
  //     setImage(reader.result);
  //     setError(null);
  //     openModal();
  //   };
  //   reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   onDrop,
  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: false, // Разрешаем выбор только одного файла
  //   //@ts-ignore
  //   accept: "image/*", // Разрешаем только изображения
  // });

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperRef.current.cropper;
  //   const canvas = cropper.getCroppedCanvas({ width: 400, height: 300 }); // 4:3 aspect ratio
  //   setCroppedImage(canvas.toDataURL("image/jpeg"));
  //   closeModal();
  // };

  // useEffect(() => {
  //   if (croppedImage) {
  //     // console.log("croppedImage", croppedImage);
  //     handleImagePdfFileChange(croppedImage);
  //   }
  // }, [croppedImage]);

  // Image NEW

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleImagePdfFileChange(result);
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleImagePdfFileChange(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("pdfFileImage").click();
  };

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    // "Side slider", "Big block"
    if (pdfFile.title === "") {
      setErrorCreate(t("create_block_pdffile_error1"));
    } else if (!imagePdfFile) {
      setErrorCreate(t("create_block_pdffile_error2"));
    } else if (!filePdfFile) {
      setErrorCreate(t("create_block_pdffile_error3"));
    } else {
      setErrorCreate("");
    }
  }, [pdfFile, imagePdfFile, filePdfFile]);

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("edit_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        <input
          type="text"
          value={pdfFile.title}
          maxLength={30}
          placeholder={t("tile_title")}
          className="mt-[25px] w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] px-[15px]"
          onChange={(e) => {
            handleChangePdfFile(e.target.value, "", "title");
          }}
        />

        <div className="mt-[5px] flex justify-end">
          <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
            {pdfFile.title.length}/30
          </span>
        </div>

        <div className="mt-[5px]">
          <input
            type="text"
            value={pdfFile.description}
            maxLength={30}
            placeholder={t("tile_description_optional")}
            className="w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              handleChangePdfFile("", e.target.value, "description");
            }}
          />
        </div>
        <div className="mt-[5px] flex justify-end">
          <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
            {pdfFile.description.length}/30
          </span>
        </div>

        {!imagePdfFile ? (
          <div className="mt-[10px] flex justify-between">
            <div
              className="w-[150px] h-[100px] border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dashed rounded-[10px] flex justify-center items-center"
              onClick={handleButtonImageInputClick}
            >
              {/* <Icon
                icon="uil:image-upload"
                className="w-[40px] h-[40px] text-[#8391A1]"
              /> */}
              <img
                src="/images/create_block/upload_image.svg"
                alt="upload_image.svg"
                className="w-[40px] h-[40px]"
              />
            </div>
            <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[16.39px]">
              {t("upload_image_links_url_text")}
            </div>
          </div>
        ) : (
          <div className="mt-[10px] flex justify-between">
            <div
              className="w-[150px] h-[100px] bg-[#F7F8F9] rounded-[10px] flex justify-center items-center"
              onClick={handleButtonImageInputClick}
            >
              <img
                src={imagePdfFile}
                alt="image"
                className="h-[100px] w-[150px] object-center object-cover rounded-[10px]"
              />
            </div>
            <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[16.39px]">
              {t("upload_image_links_url_text")}
            </div>
          </div>
        )}

        <input
          id="pdfFileImage"
          type="file"
          accept=".jpg,.jpeg,.png,.heic,.heif"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {isConverting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="text-center">
              {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
              <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                Идет конвертация ...
              </div>
            </div>
          </div>
        )}

        {/* <div {...getRootProps()} className="hidden">
          <input {...getInputProps()} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          appElement={document.getElementById("root")}
          ariaHideApp={false}
        >
          <div className="bg-white p-4 rounded shadow-lg">
            {image && (
              <div>
                <Cropper
                  src={image}
                  style={{ height: 400, width: 400 }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  viewMode={1}
                  guides={false}
                  cropBoxResizable={false}
                  dragMode="move"
                  cropBoxMovable={false}
                  ref={cropperRef}
                />
                <div className="flex justify-between mt-4 px-[30px]">
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={getCroppedImage}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("crop_image")}
                    </div>
                  </button>
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={closeModal}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("cancel")}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal> */}

        {/* <div className="mt-[10px] flex justify-between">
          <div
            className="w-[150px] h-[100px] border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dotted rounded-[10px] flex justify-center items-center"
            onClick={handleButtonClickImagePdfFileUrl}
          >
            <input
              id="imagePdfFileInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleImagePdfFileChange}
              style={{ display: "none" }}
            />
            {!imagePdfFile ? (
              <Icon
                icon="uil:image-upload"
                className="w-[40px] h-[40px] text-[#8391A1]"
              />
            ) : (
              <img
                src={imagePdfFile}
                alt="image"
                className="max-h-[100px] max-w-[150px] rounded-[10px]"
              />
            )}
          </div>
          <div className="ml-[10px] w-full text-[12px] text-[#8391A1] leading-[16.39px]">
            Upload Thumbnail. Your image should be at least 400x400px and must
            be in JPG or PNG format.
          </div>
        </div> */}

        {error !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {error}
          </div>
        )}

        {/* {messageImagePdfFile !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[12px] leading-[24px]">
            {messageImagePdfFile}
          </div>
        )} */}

        <div className="mt-[15px]">
          <span className="font-semibold text-[18px] leading-[21.86px] text-[#161616]">
            {t("upload_pdf_file")}
          </span>
        </div>

        <div className="mt-[15px]">
          <div
            className="w-full border-[1px] border-[#8391A1] border-dashed bg-[#F7F8F9] rounded-[10px]"
            onClick={handleButtonClickFilePdfFileUrl}
          >
            <input
              id="filePdfFileInput"
              type="file"
              // accept=".jpg,.jpeg,.png"
              onChange={handleFilePdfFileChange}
              style={{ display: "none" }}
            />
            <div className="mt-[15px] flex justify-center">
              {/* <Icon
                icon="solar:upload-linear"
                className="w-[40px] h-[40px] text-[#8391A1]"
              /> */}
              <img
                src="/images/create_block/upload_file.svg"
                alt="upload_file.svg"
                className="w-[40px] h-[40px]"
              />
            </div>
            <div className="px-[24px] pb-[15px]">
              <div className="mt-[10px] text-[14px] leading-[16.39px] text-[#8391A1] flex justify-center">
                {filePdfFileName === "" ? (
                  <span>{t("upload_pdf_file_text")}</span>
                ) : (
                  <span className="">{filePdfFileName}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {messageFilePdfFile !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {messageFilePdfFile}
          </div>
        )}

        {errorCreate !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {errorCreate}
          </div>
        )}

        <div className="mt-[86px]">
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={() => {
              // if (!filePdfFile) {
              //   changeMessageFilePdfFile("PDF file is not uploaded");
              // } else {
              //   handleSendProfileData("PDF file");
              // }

              if (errorCreate === "") {
                handleSendProfileData("PDF file");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
