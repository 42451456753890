import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/CreateBlock/Products";
import YouTube from "../../components/ui/CreateBlock/YouTube";
import LinksUrl from "../../components/ui/CreateBlock/LinksUrl";
import PDFFile from "../../components/ui/CreateBlock/PDFFile";
import Newsletter from "../../components/ui/CreateBlock/Newsletter";
import Gallery from "../../components/ui/CreateBlock/Gallery";
import Text from "../../components/ui/CreateBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import SelectProductType from "../../components/ui/CreateProduct/SelectProductType";
import ProductDetails from "../../components/ui/CreateProduct/ProductDetails";
import { useTranslation } from "react-i18next";

export async function postDeleteFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.postDelete,
    dispatch,
    `api/post/delete/`,
    "post",
    "",
    form,
    true
  )();
}

export async function postDeactivateFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.postDeactivate,
    dispatch,
    `api/post/deactivate/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [deletedPostId, setDeletedPostId] = useState(null);
  const [deactivatedPostId, setDeactivatedPostId] = useState(null);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [litOfPosts, setLitOfPosts] = useState([]);

  // useEffect(() => {
  //   console.log("litOfPosts", litOfPosts);
  // }, [litOfPosts]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const allListOfPostsStore = useSelector(
    (state: any) => state.allListOfPostsStore
  );

  const postDeleteStore = useSelector((state: any) => state.postDeleteStore);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const postDeactivateStore = useSelector(
    (state: any) => state.postDeactivateStore
  );

  const [blockDesign, setBlockDesign] = useState("first");

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
    }
  }, [profileInfoStore]);

  const handlePostDelete = (productId: number) => {
    postDeleteFunc(dispatch, "", {
      id: productId,
    });

    // @ts-ignore
    setDeletedPostId(productId);
  };

  useEffect(() => {
    // console.log("sectionDeleteStore", sectionDeleteStore);
    if (postDeleteStore && postDeleteStore.data) {
      const newData = litOfPosts.filter(
        // @ts-ignore
        (obj) => obj.id !== deletedPostId
      );

      setLitOfPosts(newData);
    }
  }, [postDeleteStore]);

  async function getAllPostsFunc() {
    await actions.constructorAction(
      constants.allListOfPosts,
      dispatch,
      "api/post/all/",
      "get",
      "",
      {},
      true
    )();
  }

  const handlePostDeactivate = (id: number) => {
    postDeactivateFunc(dispatch, "", {
      id: id,
    });

    //@ts-ignore
    setDeactivatedPostId(id);
  };

  useEffect(() => {
    dispatch({ type: constants.getProductData.reset });
    dispatch({ type: constants.sendInfoAboutNewPost.reset });
    dispatch({ type: constants.allListOfPosts.reset });

    utils.LocalStorage.remove("CreateProductData");
    utils.LocalStorage.remove("CreatePostData");
    utils.LocalStorage.remove("EditPostData");
    utils.LocalStorage.remove("EditProductData");
    utils.LocalStorage.remove("PersonalInformationEditData");
    utils.LocalStorage.remove("CreateBlockData");
    utils.LocalStorage.remove("EditBlockData");
    utils.LocalStorage.remove("AccountLaunchData");

    getAllPostsFunc();

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //   useEffect(() => {
  //     utils.LocalStorage.set("AllProductsData", {
  //       litOfProducts: litOfProducts,
  //     });
  //   }, [litOfProducts]);

  useEffect(() => {
    // console.log("allListOfPostsStore", allListOfPostsStore);
    if (
      allListOfPostsStore &&
      allListOfPostsStore.data &&
      allListOfPostsStore.data.response
    ) {
      setLitOfPosts(allListOfPostsStore.data.response);
    }
  }, [allListOfPostsStore]);

  useEffect(() => {
    // console.log("postDeactivateStore", postDeactivateStore);
    if (
      postDeactivateStore &&
      postDeactivateStore.data &&
      postDeactivateStore.data.response
    ) {
      getAllPostsFunc();
    }
  }, [postDeactivateStore]);

  if (postDeactivateStore && postDeactivateStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={postDeactivateStore.load} />
      </div>
    );
  }

  if (allListOfPostsStore && allListOfPostsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={allListOfPostsStore.load} />
      </div>
    );
  }

  if (postDeleteStore && postDeleteStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={postDeleteStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        <div className="font-manrope px-[24px] pb-[40px] w-screen max-w-md bg-[#FFFFFF] min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    utils.LocalStorage.remove("AllProductsData");
                    navigate("/home");
                  }}
                >
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("personal_blog")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div
              className="mt-[66px]"
              onClick={() => {
                navigate("create");
              }}
            >
              <div className="w-full h-[50px] rounded-[10px] border-[1px] border-[#161616] text-[18px] text-[#161616] flex justify-center items-center">
                {t("create_post")}
              </div>
            </div>

            <div className="mt-[15px]">
              <span className="text-[#161616] text-[18px] leading-[20px] font-semibold">
                {t("all_posts")}
              </span>
            </div>

            <div>
              {litOfPosts.map((post: any) => (
                <div className="" key={post.id}>
                  <Menu>
                    <MenuHandler>
                      <div className="mt-[20px] flex justify-between items-center">
                        <div className="pb-[10px] px-[10px]">
                          <img
                            src="/images/profile/dots.svg"
                            alt="menu_dots"
                            className="w-[18px] h-[4px] invisible"
                          />
                        </div>

                        <div className="pb-[10px] px-[10px]">
                          <img
                            src="/images/profile/dots.svg"
                            alt="menu_dots"
                            className="w-[18px] h-[4px]"
                          />
                        </div>
                      </div>
                    </MenuHandler>
                    <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                      <MenuItem
                        onClick={() => {
                          window.open(
                            `https://www.hubbbox.com/post/${post.id}`,
                            "_blank"
                          );
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("preview")}
                          </div>

                          <img
                            src="/images/profile/preview.svg"
                            alt="preview.svg"
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center opacity-30"
                          onClick={(event) => {
                            event.preventDefault();
                            // navigate(`/post/edit/${post.id}`);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("edit")}
                          </div>
                          <img
                            src="/images/profile/edit.svg"
                            alt=""
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            handlePostDeactivate(post.id);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("activate")}
                          </div>
                          <Switch
                            crossOrigin={""}
                            checked={!post.deactivated}
                            onChange={(event) => {
                              handlePostDeactivate(post.id);
                            }}
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={(event) => {
                            event.preventDefault();
                            handlePostDelete(post.id);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("delete")}
                          </div>
                          <img
                            src="/images/profile/delete.svg"
                            alt=""
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <div>
                    {blockDesign === "first" && (
                      <div
                        className={`w-full rounded-[15px] p-[15px] shadow-custom ${post.deactivated && "opacity-30"}`}
                      >
                        <div className="flex flex-col">
                          {post.thumbnail === "" ? (
                            <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-[10px]">
                              <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-[10px]"></div>
                            </div>
                          ) : (
                            <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                              <img
                                src={post.thumbnail}
                                alt="productImage"
                                className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                              />
                            </div>
                          )}

                          <div className="mt-[15px]">
                            <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                              {post.title}
                            </div>
                          </div>

                          <div className="mt-[5px]">
                            <span className="text-[14px] text-[#8391A1]">
                              {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                              {t("reading_time")}: {post.reading_time}{" "}
                              {t("minutes")}
                            </span>
                          </div>

                          <div className="mt-[5px]">
                            <span className="text-[16px] text-[#161616]">
                              {post.description}...
                            </span>
                          </div>

                          <div className="mt-[15px]">
                            <button className="w-full h-[47px] flex justify-center items-center bg-[#161616] text-white rounded-[10px]">
                              {t("read_more")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {blockDesign === "third" && (
                      <div
                        className={`w-full rounded-[7px] shadow-custom ${post.deactivated && "opacity-30"}`}
                      >
                        <div className="flex flex-col">
                          {post.thumbnail === "" ? (
                            <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-t-[7px]">
                              <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-t-[7px]"></div>
                            </div>
                          ) : (
                            <div className="relative w-full pt-[100%] overflow-hidden rounded-t-[7px]">
                              <img
                                src={post.thumbnail}
                                alt="productImage"
                                className="absolute inset-0 w-full h-full object-cover object-center rounded-t-[7px]"
                              />
                            </div>
                          )}

                          <div className="my-[15px] px-[24px]">
                            <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                              {post.title}
                            </div>

                            <div className="mt-[5px]">
                              <span className="text-[14px] text-[#8391A1]">
                                {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                                {t("reading_time")}: {post.reading_time}{" "}
                                {t("minutes")}
                              </span>
                            </div>

                            <div className="mt-[5px]">
                              <span className="text-[16px] text-[#161616]">
                                {post.description}...
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
