import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import QRCodeCanvas from "qrcode.react";
import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Radio,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

// const Modal = ({ onClose }: any) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 font-manrope">
//       <div className="bg-white p-6 rounded-[15px] w-[342px] max-w-full relative">
//         <div className="absolute top-[-55px] left-1/2 transform -translate-x-1/2">
//           <img
//             src="https://via.placeholder.com/100" // Замените на URL изображения пользователя
//             alt="User Avatar"
//             className="w-28 h-28 rounded-full border-4 border-white"
//           />
//         </div>

//         <div className="flex flex-col items-center">
//           <div className="mt-[35px]">
//             <h2 className="text-[13px] text-[#8391A1] font-medium">@aaaae</h2>
//           </div>

//           <div className="mt-[15px]">
//             <img
//               src="https://via.placeholder.com/150" // Замените на URL вашего QR-кода
//               alt="QR Code"
//               className="w-48 h-48"
//             />
//           </div>

//           <div className="flex justify-around w-full mt-4">
//             <button className="bg-black text-white py-2 px-4 rounded-md">
//               Share profile
//             </button>
//             <button className="bg-black text-white py-2 px-4 rounded-md">
//               Copy link
//             </button>
//           </div>
//           <button className="mt-4 text-gray-500" onClick={onClose}>
//             &larr; Return back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

const Modal = ({ onClose, avatar, username = "username" }: any) => {
  const { t, i18n } = useTranslation();

  const handleCopy = (url: string) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("Ссылка успешно скопирована в буфер обмена.");
      })
      .catch((error) => {
        console.error("Ошибка при копировании ссылки:", error);
      });
  };

  const handleShare = async (url: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: url,
        });
        console.log("Ссылка успешно поделена!");
      } else {
        throw new Error("Web Share API не поддерживается в вашем браузере.");
      }
    } catch (error) {
      console.error("Ошибка при попытке поделиться ссылкой:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50 font-manrope backdrop-blur-md">
      <div className="flex flex-col items-center">
        <div className="bg-white pt-6 px-6 rounded-[15px] w-[342px] max-w-full relative shadow-custom">
          <div className="absolute top-[-55px] left-1/2 transform -translate-x-1/2">
            {!avatar ? (
              <div className="rounded-full w-[110px] h-[110px] border-4 bg-white border-white">
                <img
                  src="/images/avatar.svg"
                  alt="avatar.svg"
                  className="w-full h-full"
                />
              </div>
            ) : (
              <div className="rounded-full border-4 border-white">
                <Avatar
                  src={avatar}
                  alt="avatar"
                  className="w-[110px] h-[110px]"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col items-center">
            <div className="mt-[45px]">
              <h2 className="text-[13px] text-[#8391A1] font-medium">
                @{username}
              </h2>
            </div>

            <div className="mt-[15px] mb-[50px]">
              <div className="flex justify-center items-center">
                <div className="">
                  <QRCodeCanvas
                    value={`https://www.hubbbox.com/${username}`}
                    size={242}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"M"}
                    includeMargin={false}
                    // style={{ borderRadius: "16px" }}
                    // imageSettings={{
                    //   //   src: "https://hubbbox.com/logo_qr.png",
                    //   src: avatar,
                    //   x: undefined,
                    //   y: undefined,
                    //   height: 50,
                    //   width: 50,
                    //   excavate: true,
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[20px]">
          <div className="flex flex-col">
            <div className="flex space-x-[10px] items-center">
              <button
                className="h-[50px] w-[166px] bg-black rounded-[10px] flex justify-center items-center"
                onClick={() => {
                  handleShare(`https://www.hubbbox.com/${username}`);
                }}
              >
                <div className="flex space-x-[10px] items-center">
                  <img
                    src="/images/profile_preview/share_profile.svg"
                    alt="share_profile.svg"
                  />
                  <span className="text-white text-[14px]">
                    {t("share_profile")}
                  </span>
                </div>
              </button>

              <button
                className="h-[50px] w-[166px] bg-black rounded-[10px] flex justify-center items-center"
                onClick={() => {
                  handleCopy(`https://www.hubbbox.com/${username}`);
                }}
              >
                <div className="flex space-x-[10px] items-center">
                  <img
                    src="/images/profile_preview/copy_link.svg"
                    alt="copy_link.svg"
                  />
                  <span className="text-white text-[14px]">
                    {t("copy_link_share_qr")}
                  </span>
                </div>
              </button>
            </div>
            <div
              className="mt-[20px] flex justify-center items-center"
              onClick={onClose}
            >
              <div className="rounded-full bg-white w-[50px] h-[50px] shadow-custom flex justify-center items-center">
                <img
                  src="/images/profile_preview/close_sign.svg"
                  alt="close.svg"
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
