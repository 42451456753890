import React from "react";
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductSlider = ({
  products,
  isPreview,
  username,
  deactivated,
  blockDesign,
  // senderFunc = null,
}: any) => {
  //   const settings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //         },
  //       },
  //     ],
  //   };

  const settings = {
    className: "slider",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    arrows: false,
    initialSlide: 0,
  };

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  return (
    <div className="product-slider custom-slider">
      <Slider {...settings}>
        {products.map((product: any, index: any) => (
          <div key={index} className="px-[10px] break-words">
            {/* <div
              key={index}
              className={`max-w-[285px] bg-red-500 shadow-md rounded-lg`}
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h2 className="text-lg font-bold">{product.name}</h2>
                <p className="text-gray-600">{product.description}</p>
              </div>
            </div> */}
            {blockDesign === "first" && (
              <div
                // className={`max-w-[285px] rounded-[15px] p-[15px] bg-white shadow-custom ${deactivated && "opacity-50"}`}
                className={`w-full rounded-[15px] p-[15px] bg-white shadow-customblock ${deactivated && "opacity-50"}`}
              >
                {product.image === "" ? (
                  // <div className="bg-gray-200 rounded-[10px] w-full aspect-[4/3]"></div>
                  <div className="w-full pt-[100%] bg-gray-200 rounded-[10px]"></div>
                ) : (
                  <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                    <img
                      src={product.image}
                      alt="productImage"
                      className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                    />
                    {/* <img
                  src={product.image}
                  alt="productImage"
                  className="rounded-[10px]"
                /> */}
                  </div>
                )}
                <div className="flex flex-col">
                  <div className="mt-[15px] text-[18px] leading-[20.8px] text-[#161616] font-bold flex justify-start">
                    {product.name}
                  </div>
                  <div className="mt-[5px] text-[14px] leading-[19.12px] text-[#161616] flex justify-start">
                    {product.description}
                  </div>

                  {isPreview ? (
                    <div
                      className="mt-[20px] bg-[#161616] text-white text-center rounded-[10px] h-[43px] text-[18px] font-bold leading-[22.5px] flex justify-center items-center"
                      onClick={() => {
                        // senderFunc();
                        navigate(`/product/${product.id}`);
                      }}
                    >
                      {/* {product.buttonName ? product.buttonName : "Call to action"} */}
                      {getAction(product.buttonName)}
                    </div>
                  ) : (
                    <div className="mt-[20px] bg-[#161616] text-white text-center rounded-[10px] h-[43px] text-[18px] font-bold leading-[22.5px] flex justify-center items-center">
                      {/* {product.buttonName ? product.buttonName : "Call to action"} */}
                      {getAction(product.buttonName)}
                    </div>
                  )}
                </div>
              </div>
            )}

            {blockDesign === "second" && (
              <div
                className={`w-full rounded-[15px] p-[15px] bg-white shadow-customblock ${deactivated && "opacity-50"}`}
                onClick={() => {
                  if (isPreview) {
                    // senderFunc();
                    navigate(`/product/${product.id}`);
                  }
                }}
              >
                {product.image === "" ? (
                  <div className="w-full pt-[100%] bg-gray-200 rounded-[10px]"></div>
                ) : (
                  <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                    <img
                      src={product.image}
                      alt="productImage"
                      className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                    />
                  </div>
                )}
                <div className="flex flex-col">
                  <div className="mt-[15px] text-[18px] leading-[20.8px] text-[#161616] font-bold flex justify-start">
                    {product.name}
                  </div>
                  <div className="mt-[5px] text-[14px] leading-[19.12px] text-[#161616] flex justify-start">
                    {product.description}
                  </div>
                </div>
              </div>
            )}

            {blockDesign === "third" && (
              <div
                className={`w-full rounded-[7px] bg-white shadow-customblock ${deactivated && "opacity-50"}`}
                onClick={() => {
                  if (isPreview) {
                    // senderFunc();
                    navigate(`/product/${product.id}`);
                  }
                }}
              >
                {product.image === "" ? (
                  <div className="w-full pt-[100%] bg-gray-200 rounded-t-[7px]"></div>
                ) : (
                  <div className="relative w-full pt-[100%] overflow-hidden rounded-t-[7px]">
                    <img
                      src={product.image}
                      alt="productImage"
                      className="absolute inset-0 w-full h-full object-cover object-center rounded-t-[7px]"
                    />
                  </div>
                )}
                <div className="flex flex-col">
                  <div className="mt-[15px] text-[18px] px-[15px] leading-[22px] text-[#161616] font-bold flex justify-start">
                    {product.name}
                  </div>
                  <div className="mt-[5px] text-[14px] px-[15px] pb-[15px] leading-[18px] text-[#161616] flex justify-start">
                    {product.description}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const products = [
  {
    image: "https://via.placeholder.com/150",
    name: "Product 1",
    description: "Description for product 1",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 2",
    description: "Description for product 2",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 3",
    description: "Description for product 3",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 4",
    description: "Description for product 4",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 5",
    description: "Description for product 5",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 6",
    description: "Description for product 6",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 7",
    description: "Description for product 7",
  },
  {
    image: "https://via.placeholder.com/150",
    name: "Product 8",
    description: "Description for product 8",
  },
  // Добавьте больше продуктов по необходимости
];

export default function Component({
  listOfSmallBlock,
  username,
  isPreview = false,
  deactivated,
  blockDesign,
  // senderFunc = null,
}: any) {
  return (
    <div className="container">
      <ProductSlider
        products={listOfSmallBlock}
        username={username}
        isPreview={isPreview}
        deactivated={deactivated}
        blockDesign={blockDesign}
        // senderFunc={senderFunc}
      />
    </div>
  );
}
