import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import SubscriptionBottomWindow from "../../components/ui/SubscriptionBottomWindow";

export async function changeDesignSettingsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.changeDesignSettings,
    dispatch,
    `api/profile/design/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("blocks");
  const [designBlocks, setDesignBlocks] = useState("first");
  const [designIcons, setDesignIcons] = useState("round_bw");
  const [planStatus, setPlanStatus] = useState("");

  const [isOpenSubscriptionBottomWindow, setIsOpenSubscriptionBottomWindow] =
    useState(false);

  const toggleSubscriptionBottomWindow = () => {
    setIsOpenSubscriptionBottomWindow(!isOpenSubscriptionBottomWindow);
  };

  const handleChangeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      const designEditDataStorage = utils.LocalStorage.get("DesignEditData");

      if (designEditDataStorage && designEditDataStorage.designBlocks) {
        setDesignBlocks(designEditDataStorage.designBlocks);
      } else {
        setDesignBlocks(profileInfoStore.data.response.design_blocks);
      }

      if (designEditDataStorage && designEditDataStorage.designIcons) {
        setDesignIcons(designEditDataStorage.designIcons);
      } else {
        setDesignIcons(profileInfoStore.data.response.design_icons);
      }

      if (profileInfoStore.data.response) {
        setPlanStatus(profileInfoStore.data.response.plan);
      }
    }
  }, [profileInfoStore]);

  useEffect(() => {
    const designEditDataStorage = utils.LocalStorage.get("DesignEditData");

    if (designEditDataStorage && designEditDataStorage.status) {
      setStatus(designEditDataStorage.status);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      utils.LocalStorage.set("DesignEditData", {
        status: status,
        designBlocks: designBlocks,
        designIcons: designIcons,
      });
    }
  }, [status, designBlocks, designIcons]);

  function handleChangeDesign() {
    changeDesignSettingsFunc(dispatch, "", {
      designBlocks: designBlocks,
      designIcons: designIcons,
    });
    //   dispatch({ type: constants.profileInfo.reset });
  }

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeDesignSettingsStore = useSelector(
    (state: any) => state.changeDesignSettingsStore
  );

  useEffect(() => {
    console.log("changeDesignSettingsStore", changeDesignSettingsStore);
    if (changeDesignSettingsStore && changeDesignSettingsStore.data) {
      navigate("/home/profile");
      //   utils.profileInfoFunc(dispatch, "", {});
    }
  }, [changeDesignSettingsStore]);

  if (changeDesignSettingsStore && changeDesignSettingsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={changeDesignSettingsStore.load} />
      </div>
    );
  }
  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <SubscriptionBottomWindow
            isOpen={isOpenSubscriptionBottomWindow}
            closeWindow={toggleSubscriptionBottomWindow}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    if (status === "blocks") {
                      navigate("/home/profile");
                    } else if (status === "icons") {
                      handleChangeStatus("blocks");
                    }
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("design")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className={`ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] ${status === "blocks" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    handleChangeStatus("blocks");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("blocks")}
                  </div>
                </div>
                <div
                  className={`mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]  ${status === "icons" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    handleChangeStatus("icons");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("icons")}
                  </div>
                </div>
              </div>
            </div>

            {status === "blocks" && (
              <div>
                {designBlocks === "first" && (
                  <div className="mt-[14px]">
                    <div className="w-full rounded-[15px] p-[15px] bg-white shadow-custom">
                      <div className="flex flex-col">
                        <div className="relative w-full pt-[75%] overflow-hidden bg-[#E8ECF4] rounded-[10px]">
                          <div className="absolute inset-0 w-full h-full object-cover object-center bg-[#E8ECF4] rounded-[10px]"></div>
                        </div>

                        <div className="mt-[19px]">
                          <div className="h-[17px] w-[140px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>

                        <div className="mt-[10px]">
                          <div className="h-[17px] w-[210px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>

                        <div className="mt-[10px]">
                          <div className="h-[43px] w-full rounded-[10px] bg-[#E8ECF4] flex justify-center items-center text-white text-[16px] font-bold">
                            {t("button")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-[10px]">
                      <div className="w-full rounded-[15px] p-[15px] bg-white shadow-custom">
                        <div className="flex space-x-[10px] items-center">
                          <div className="w-[50px] h-[50px] rounded-[10px] bg-[#E8ECF4]"></div>
                          <div className="flex flex-col items-start">
                            <div className="h-[17px] w-[213px] rounded-[10px] bg-[#E8ECF4]"></div>
                            <div className="mt-[10px] h-[17px] w-[95px] rounded-[10px] bg-[#E8ECF4]"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* {designBlocks === "second" && (
                  <div className="mt-[14px] mb-[53px]">
                    <div className="w-full rounded-[15px] p-[15px] bg-white shadow-custom">
                      <div className="flex flex-col">
                        <div className="relative w-full pt-[75%] overflow-hidden bg-[#E8ECF4] rounded-[10px]">
                          <div className="absolute inset-0 w-full h-full object-cover object-center bg-[#E8ECF4] rounded-[10px]"></div>
                        </div>

                        <div className="mt-[19px]">
                          <div className="h-[17px] w-[140px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>

                        <div className="mt-[10px]">
                          <div className="h-[17px] w-[210px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-[10px]">
                      <div className="w-full rounded-[15px] p-[15px] bg-white shadow-custom">
                        <div className="flex space-x-[10px] items-center">
                          <div className="w-[50px] h-[50px] rounded-[10px] bg-[#E8ECF4]"></div>
                          <div className="flex flex-col items-start">
                            <div className="h-[17px] w-[213px] rounded-[10px] bg-[#E8ECF4]"></div>
                            <div className="mt-[10px] h-[17px] w-[95px] rounded-[10px] bg-[#E8ECF4]"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {designBlocks === "third" && (
                  <div className="mt-[14px] mb-[12px]">
                    <div className="w-full rounded-[7px] bg-white shadow-custom">
                      <div className="flex flex-col">
                        <div className="relative w-full pt-[75%] overflow-hidden bg-[#E8ECF4] rounded-t-[7px]">
                          <div className="absolute inset-0 w-full h-full object-cover object-center bg-[#E8ECF4] rounded-t-[7px]"></div>
                        </div>

                        <div className="mt-[19px] px-[15px]">
                          <div className="h-[17px] w-[140px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>

                        <div className="mt-[10px] px-[15px] pb-[15px]">
                          <div className="h-[17px] w-[210px] rounded-[10px] bg-[#E8ECF4]"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-[10px]">
                      <div className="w-full rounded-[7px] bg-white shadow-custom">
                        <div className="flex space-x-[10px] items-center">
                          <div className="relative w-[114px] h-[114px] overflow-hidden bg-[#E8ECF4] rounded-l-[7px]">
                            <div className="absolute inset-0 w-full h-full object-cover object-center bg-[#E8ECF4] rounded-l-[7px]"></div>
                          </div>
                          <div className="flex flex-col items-start py-[15px] pr-[15px]">
                            <div className="h-[17px] w-[176px] rounded-[10px] bg-[#E8ECF4]"></div>
                            <div className="mt-[10px] h-[17px] w-[115px] rounded-[10px] bg-[#E8ECF4]"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
                      onClick={() => {
                        setDesignBlocks("first");
                      }}
                    >
                      <div className="text-[16px] leading-[20px] text-[$161616]">
                        Ver I
                      </div>
                      <Radio
                        name="design"
                        crossOrigin={""}
                        checked={designBlocks === "first"}
                        onChange={() => {
                          setDesignBlocks("first");
                        }}
                      />
                    </div>

                    {/* <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
                      onClick={() => {
                        setDesignBlocks("second");
                      }}
                    >
                      <div className="text-[16px] leading-[20px] text-[$161616]">
                        Ver II
                      </div>
                      <Radio
                        name="design"
                        crossOrigin={""}
                        checked={designBlocks === "second"}
                        onChange={() => {
                          setDesignBlocks("second");
                        }}
                      />
                    </div> */}

                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom p-[10px]"
                      onClick={() => {
                        setDesignBlocks("third");
                      }}
                    >
                      <div className="text-[16px] leading-[20px] text-[$161616]">
                        Ver II
                      </div>
                      <Radio
                        name="design"
                        crossOrigin={""}
                        checked={designBlocks === "third"}
                        onChange={() => {
                          setDesignBlocks("third");
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div
                    className="w-full h-[50px] rounded-[10px] bg-[#161616] flex justify-center items-center text-[16px] text-white font-semibold"
                    onClick={() => {
                      handleChangeStatus("icons");
                    }}
                  >
                    {t("continue")}
                  </div>
                </div>
              </div>
            )}

            {status === "icons" && (
              <div className="mt-[90px]">
                <div className="mt-[10px] flex justify-center">
                  {designIcons == "round_bw" || designIcons == "round" ? (
                    <div className="flex flex-col">
                      <div className="flex space-x-[7px] items-center">
                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Behance/Behance${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Facebook/Facebook${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Instagram/Instagram${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/LinkedIN/LinkedIN${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Patreon/Patreon${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Pinterest/Pinterest${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Reddit/Reddit${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Spotify/Spotify${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Telegram/Telegram${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/TikTok/TikTok${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Twitch/Twitch${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Youtube/Youtube${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/AppleMusic/AppleMusic${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/SoundCloud/SoundCloud${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/WhatsApp/WhatsApp${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Discord/Discord${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Dribbble/Dribbble${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/GitHub/GitHub${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Vimeo/Vimeo${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Twitter/Twitter${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/BandCamp/BandCamp${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Boosty/Boosty${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Ebay/Ebay${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Email/Email${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/IMDB/IMDB${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Kinopoisk/Kinopoisk${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Snapchat/Snapchat${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Substack/Substack${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Threads/Threads${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/VK/VK${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/Weibo/Weibo${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div
                          className={`${designIcons === "round_bw" ? "" : "rounded-full shadow-customblock"} flex-shrink-0`}
                        >
                          <img
                            src={`/images/social_media_icons/YandexMusic/YandexMusic${designIcons === "round_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="flex space-x-[7px] items-center">
                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Behance/Behance_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Facebook/Facebook_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`lex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Instagram/Instagram_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/LinkedIN/LinkedIN_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Patreon/Patreon_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Pinterest/Pinterest_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Reddit/Reddit_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Spotify/Spotify_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Telegram/Telegram_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/TikTok/TikTok_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Twitch/Twitch_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Youtube/Youtube_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/AppleMusic/AppleMusic_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/SoundCloud/SoundCloud_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/WhatsApp/WhatsApp_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Discord/Discord_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Dribbble/Dribbble_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/GitHub/GitHub_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Vimeo/Vimeo_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Twitter/Twitter_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/BandCamp/BandCamp_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Boosty/Boosty_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Ebay/Ebay_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Email/Email_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>

                      <div className="mt-[10px] flex space-x-[7px] items-center">
                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/IMDB/IMDB_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Kinopoisk/Kinopoisk_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Snapchat/Snapchat_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Substack/Substack_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Threads/Threads_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/VK/VK_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/Weibo/Weibo_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>

                        <div className={`flex-shrink-0`}>
                          <img
                            src={`/images/social_media_icons/YandexMusic/YandexMusic_original${designIcons === "original_bw" ? "" : "_colored"}.svg`}
                            alt="icon"
                            className="w-[37px] h-[37px]"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-[84px]">
                  <div className="grid grid-cols-2 gap-[10px]">
                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom pl-[15px] pr-[5px]"
                      onClick={() => {
                        setDesignIcons("round_bw");
                      }}
                    >
                      <div className="text-[16px] font-medium text-[#161616]">
                        {t("round_bw")}
                      </div>
                      <Radio
                        name="icon"
                        crossOrigin={""}
                        checked={designIcons === "round_bw"}
                        onChange={() => {
                          setDesignIcons("round_bw");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom pl-[15px] pr-[5px]"
                      onClick={() => {
                        setDesignIcons("round");
                      }}
                    >
                      <div className="text-[16px] font-medium text-[#161616]">
                        {t("round")}
                      </div>
                      <Radio
                        name="icon"
                        crossOrigin={""}
                        checked={designIcons === "round"}
                        onChange={() => {
                          setDesignIcons("round");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom pl-[15px] pr-[5px]"
                      onClick={() => {
                        setDesignIcons("original_bw");
                      }}
                    >
                      <div className="text-[16px] font-medium text-[#161616]">
                        {t("original_bw")}
                      </div>
                      <Radio
                        name="icon"
                        crossOrigin={""}
                        checked={designIcons === "original_bw"}
                        onChange={() => {
                          setDesignIcons("original_bw");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] rounded-[10px] flex justify-between items-center shadow-custom pl-[15px] pr-[5px]"
                      onClick={() => {
                        setDesignIcons("original");
                      }}
                    >
                      <div className="text-[16px] font-medium text-[#161616]">
                        {t("original")}
                      </div>
                      <Radio
                        name="icon"
                        crossOrigin={""}
                        checked={designIcons === "original"}
                        onChange={() => {
                          setDesignIcons("original");
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[15px]">
                  <div
                    className="w-full h-[50px] rounded-[10px] bg-[#161616] flex justify-center items-center text-[16px] text-white font-semibold"
                    onClick={() => {
                      if (planStatus === "inactive") {
                        toggleSubscriptionBottomWindow();
                      } else {
                        handleChangeDesign();
                      }
                    }}
                  >
                    {t("save")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
