import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as utils from "../../components/utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export function postLoginData(
  constant: any,
  dispatch: any,
  url: string,
  method: string,
  queryParams: string | undefined = "",
  form: object | undefined = undefined,
  auth: boolean = true
) {
  return async function func() {
    try {
      dispatch({ type: constant.load });
      let config: any = {};
      if (auth) {
        const tokenCookie = utils.GetCookieValue("token");
        if (tokenCookie) {
          const tokenAccess = JSON.parse(
            utils.GetCookieValue("token") ?? "{}"
          ).access;
          config["Authorization"] = `Bearer ${tokenAccess}`;
        } else {
          config["Authorization"] = "";
        }
      }

      let response: any = undefined;

      switch (method.toLowerCase()) {
        case "get": {
          response = await axios.get(
            `${constants.API}${url}${queryParams}`,
            // @ts-ignore
            { headers: config }
          );
          break;
        }
        case "post": {
          response = await axios.post(
            `${constants.API}${url}`,
            form,
            // @ts-ignore
            { headers: config }
          );
          break;
        }
        default: {
          // todo raise exception
          // console.error("Unexpected method");
        }
      }
      if (response && response.data) {
        dispatch({
          type: constant.success,
          payload: response.data,
        });
      } else {
        dispatch({
          type: constant.error,
          payload: response.statusText,
        });
      }
    } catch (error: any) {
      // console.log("error", error);

      // TODO Нужно посмотреть есть ли в error data (При сторонней ошибки нет данных в error.response.data.error (Это у меня так))
      dispatch({
        type: constant.error,
        payload: error.response.data.error,
      });
      // console.error("error: ", error);
    }
  };
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [authBool, setAuthBool] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  // const [isFirstTime, setIsFirstTime] = useState(false);

  const [userLanguage, setUserLanguage] = useState("");

  useEffect(() => {
    // console.log("userLanguage", userLanguage);
    if (userLanguage === "") {
      const userLocalLanguage = navigator.language || navigator.languages[0];
      i18n.changeLanguage(userLocalLanguage);
      setUserLanguage(userLocalLanguage);
    } else {
      i18n.changeLanguage(userLanguage);
    }
  }, [userLanguage]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendLoginDataStore = useSelector(
    (state: any) => state.sendLoginDataStore
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  async function sendData(event: any) {
    event.preventDefault();
    await postLoginData(
      constants.sendLoginData,
      dispatch,
      `api/token/`,
      "post",
      "",
      {
        username: username,
        password: password,
      },
      false
    )();
  }

  useEffect(() => {
    // console.log("sendLoginDataStore", sendLoginDataStore);
    if (
      sendLoginDataStore &&
      (sendLoginDataStore.fail || sendLoginDataStore.error)
    ) {
      setAuthBool(false);
    }

    if (sendLoginDataStore && sendLoginDataStore.data) {
      utils.SetCookieValue(
        "token",
        JSON.stringify(sendLoginDataStore.data.token)
        // 600,
      );

      dispatch({
        type: constants.token.success,
        payload: sendLoginDataStore.data.token,
      });

      // setIsFirstTime(sendLoginDataStore.data.is_first_time);
      setAuthBool(true);
    }

    const tokenCookie = utils.GetCookieValue("token");
    if (tokenCookie) {
      setLoggedIn(true);
    }
    dispatch({ type: constants.loginData.reset });
  }, [sendLoginDataStore]);

  // useEffect(() => {
  //   console.log("authBool", authBool);
  // }, [authBool]);

  useEffect(() => {
    // console.log("loggedIn", loggedIn);
    if (loggedIn) {
      // if (isFirstTime) {
      //   navigate("/auth/launch");
      // } else {
      //   navigate("/home");
      // }
      navigate("/auth/launch");
    }
  }, [loggedIn]);

  if (sendLoginDataStore && sendLoginDataStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendLoginDataStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    // <div className="font-manrope flex justify-center px-[24px] pb-[50px] min-w-screen max-w-md min-h-screen bg-[#F5F5F5]">
    <div className="flex justify-center">
      {/* <div className="font-manrope px-[24px] pb-[50px] w-screen max-w-md min-h-screen bg-[#F5F5F5]"> */}
      <div className="font-manrope pb-[40px] w-screen overflow-hidden max-w-md">
        <div className="w-full py-[35px] bg-[#161616]">
          <div className="px-[24px]">
            <div className="flex justify-center items-center">
              <div className="flex flex-col text-white">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/logo_light.svg"
                    alt="logo_light"
                    className="w-[132px] h-[80px]"
                  />
                </div>
                <div className="mt-[25px] font-extrabold text-[46px] leading-[50px] text-center">
                  {t("login_image_text1-1")}
                  <br />
                  {t("login_image_text1-2")}
                </div>

                <div className="mt-[20px] text-[15px] leading-[24px] text-center">
                  {t("login_image_text2")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[24px]">
          <div className="mt-[15px]">
            <div className="text-[24px] font-bold leading-[28px] text-[#161616]">
              {t("login_text1")}
              <br />
              {t("login_text2")}
            </div>
          </div>

          <div className="mt-[20px] flex justify-center">
            <input
              type="username"
              maxLength={30}
              className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] px-6 py-4 text-[#8391A1] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]"
              placeholder={t("username")}
              value={username}
              onChange={(e) => {
                const value = e.target.value;

                const generalPattern = /^[A-Za-z0-9][A-Za-z0-9._]*$/;
                const isValidSequence = !/(_\.)|(\._)$/.test(value);

                if (
                  (generalPattern.test(value) && isValidSequence) ||
                  value === ""
                ) {
                  setUsername(value);
                }
              }}
            />
          </div>

          <div className="mt-[10px] flex justify-center">
            <div className="flex flex-row items-center w-full relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]"
                placeholder={t("password")}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <img
                src="/images/login/eye-invisible.svg"
                alt="eye-invisible.svg"
                className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                onClick={togglePasswordVisibility}
              />

              {/* <Icon
                icon={
                  showPassword ? "streamline:invisible-1" : "streamline:visible"
                }
                className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                onClick={togglePasswordVisibility}
              /> */}
            </div>
          </div>

          {!authBool && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
              {t("login_error")}
            </div>
          )}

          <div
            className={`${!authBool ? "mt-[15px]" : "mt-[20px]"}  flex justify-center`}
          >
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white font-medium text-[18px]"
              onClick={sendData}
            >
              {t("sign_in")}
            </button>
          </div>

          <div className="mt-[10px] text-[16px] leading-[22px] text-[#161616] text-center">
            {t("forgot_password")}
          </div>

          <div className="mt-[25px] text-[#8391A1] text-[16px] leading-[22px] text-center">
            {t("dont_have_acc")}{" "}
            <button
              className="text-[#161616]"
              onClick={() => {
                dispatch({ type: constants.sendLoginData.reset });
                navigate("/auth/registration");
              }}
            >
              {t("register")}
            </button>
          </div>

          <div className="mt-[25px] flex justify-center">
            <div className="flex space-x-[10px] items-center">
              <div
                onClick={() => {
                  setUserLanguage("ru-RU");
                }}
                className={`w-[70px] h-[30px] rounded-[5px] ${userLanguage === "ru-RU" ? "bg-[#161616] text-white" : "border-[1px] border-[#161616] text-[#161616]"} text-center`}
              >
                Рус
              </div>
              <div
                onClick={() => {
                  setUserLanguage("en-EN");
                }}
                className={`w-[70px] h-[30px] rounded-[5px] ${userLanguage === "en-EN" ? "bg-[#161616] text-white" : "border-[1px] border-[#161616] text-[#161616]"} text-center`}
              >
                Eng
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
