import * as constants from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { token } from "./constants";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as actions from "./actions";
import heic2any from "heic2any";
import Meta from "../components/ui/Meta";
import { Helmet } from "react-helmet";

export function PrintConsoleLog(text: any, prefix: string = "") {
  if (constants.DEBUG) {
    console.log(`${prefix}: `, text);
  }
}

// TODO decode encode

// TODO LocalStorage
// localStorage.setItem("key", "val")
// localStorage.getItem("key")
// localStorage.removeItem("key")

export const ContainerWithLines = ({ children }: any) => {
  return (
    <div className="relative max-w-md mx-auto overflow-hidden">
      {/* Линии по краям */}
      {/* <div className="absolute inset-y-0 left-0 w-6 bg-[#FAFAFA]"></div> */}
      {/* <div className="absolute inset-y-0 right-0 w-6 bg-[#FAFAFA]"></div> */}

      {/* Контент */}
      <div>{children}</div>
    </div>
  );
};

export function GetCookieValue(key: string) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Разбиваем куку на части по знаку "="
    const [cookieKey, cookieValue] = cookie.split("=");

    // Если ключ совпадает, возвращаем значение
    if (cookieKey === key) {
      return cookieValue;
    }
  }

  // Если ключ не найден, возвращаем null или другое значение по умолчанию
  return null;
}

export function SetCookieValue(
  key: string,
  value: string
  // lifetimeSecond: number,
) {
  // const futureDate = new Date(new Date().getTime() + lifetimeSecond * 1000);

  // const cookie = `${key}=${value}; path=/; expires=${futureDate.toUTCString()}`;
  const cookie = `${key}=${value}; path=/`;

  document.cookie = cookie;

  // Если ключ не найден, возвращаем null или другое значение по умолчанию
  return null;
}

export function DeleteCookieValue(key: string) {
  if (GetCookieValue(key)) {
    // document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
}

export function PageTitle({ title }: any) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="This is a description of my app" />
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="My App - Open Graph Title" />
      <meta
        property="og:description"
        content="This is an Open Graph description of my app"
      />
      {/* <meta property="og:url" content={url} /> */}
      <meta property="og:image" content="https://hubbbox.com/favicon.ico" />
      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content="My App - Twitter Title" />
      <meta
        name="twitter:description"
        content="This is a Twitter description of my app"
      />
      {/* <meta name="twitter:url" content={url} /> */}
      <meta name="twitter:image" content="https://hubbbox.com/favicon.ico" />
    </Helmet>
  );
}

export async function profileInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.profileInfo,
    dispatch,
    `api/profile/info/`,
    "get",
    "",
    form,
    true
  )();
}

export function AuthGuard({ children }: any) {
  const token = useSelector((state: any) => state.token);
  const navigate = useNavigate();
  const location = useLocation();
  const tokenCookie = GetCookieValue("token");

  const dispatch = useDispatch();

  useEffect(() => {
    profileInfoFunc(dispatch, "", {});
    // window.scrollTo(0, 0);
  }, []);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    if (tokenCookie) {
      if (location.pathname === "/auth") {
        navigate("/");
      }
    } else {
      // TODO Можно проверять по UserDetail отправлять запрос в user/me (не записывать в кукисы)
      navigate("/auth");
    }
  }, []);

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      if (
        profileInfoStore.data.response &&
        profileInfoStore.data.response.profile_name === ""
      ) {
        navigate("/auth/launch");
      }
    }
  }, [profileInfoStore]);

  const logoutStore = useSelector((state: any) => state.logoutStore);

  useEffect(() => {
    // console.log("logoutStore", logoutStore);
    if (logoutStore && logoutStore.data) {
      DeleteCookieValue("token");
      // LOCALSTORAGE: CreateProductData, PersonalInformationEditData, CreateBlockData, EditBlockData, AccountLaunchData
      LocalStorage.remove("CreateProductData");
      LocalStorage.remove("DesignEditData");
      LocalStorage.remove("EditProductData");
      LocalStorage.remove("PersonalInformationEditData");
      LocalStorage.remove("CreateBlockData");
      LocalStorage.remove("EditBlockData");
      LocalStorage.remove("AccountLaunchData");
      LocalStorage.remove("MakeCollaborationData");
      window.location.reload();
    }
  }, [logoutStore]);

  return <>{children}</>;
}

export function LanguageChange({ children }: any) {
  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (Object.keys(profileInfoStore).length === 0) {
      const userLanguage = navigator.language || navigator.languages[0];
      i18n.changeLanguage(userLanguage);
    } else if (profileInfoStore && profileInfoStore.data) {
      const userSetLanguage = profileInfoStore.data.response.language;

      if (userSetLanguage) {
        i18n.changeLanguage(userSetLanguage);
      } else {
        const userLanguage = navigator.language || navigator.languages[0];
        i18n.changeLanguage(userLanguage);
      }
    }
    // else {
    //   const userLanguage = navigator.language || navigator.languages[0];
    //   i18n.changeLanguage(userLanguage);
    // }
  }, [profileInfoStore]);

  return <>{children}</>;
}

export function Modal1({ open, onClose, children }: any) {
  return (
    <div
      // onClick={onClose}
      onClick={(e) => e.stopPropagation()}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-xl shadow p-6 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 text-4lg"
        ></button>
        {children}
      </div>
    </div>
  );
}

export class LocalStorage {
  static get(key: string, isParse = true): any {
    const store: any = localStorage.getItem(key);
    if (isParse) {
      return store ? JSON.parse(store) : null;
    }
    return store ? store : null;
  }
  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static remove(key: string) {
    localStorage.removeItem(key);
  }
}

export const convertImageToBase64 = (file: any, func: any) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const result = reader.result;
    func(result);
  };
  reader.readAsDataURL(file);
};

export const convertHeicOrHeifToBase64 = async (file: any, func: any) => {
  try {
    const convertedBlob = await heic2any({
      blob: file,
      toType: "image/jpeg",
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      func(result);
    };
    //@ts-ignore
    reader.readAsDataURL(convertedBlob);
  } catch (error) {
    console.error("Ошибка при конвертации HEIC/HEIF:", error);
  }
};

export function splitText(text: string) {
  const words = text.split("\n"); // Split the text into an array of words using space as a separator
  // console.log("words", words);
  return (
    <div>
      {words.map((elem: any, index: number) =>
        elem !== "" ? (
          <div className="leading-[20px]" key={`bio-${index}`}>
            {elem}
          </div>
        ) : (
          <br />
        )
      )}
      {/* <div className="-mb-[4px]"></div> */}
    </div>
  );
}

export function notifySuccess(text: string) {
  toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function notifyError(text: string) {
  toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function notifyWarning(text: string) {
  toast.warning(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
