export const listOfSocialMediaLinks = [
  {
    name: "Apple Music (user)",
    checked: false,
    value: "",
    placeholder: "Username with @",
    url: "https://www.music.apple.com/profile/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/AppleMusic/AppleMusic.svg",
    iconRound: "/images/social_media_icons/AppleMusic/AppleMusic_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/AppleMusic/AppleMusic_original.svg",
    iconOriginal:
      "/images/social_media_icons/AppleMusic/AppleMusic_original_colored.svg",
  },
  {
    name: "Bandcamp",
    checked: false,
    value: "",
    placeholder: "Channel name with @",
    url: "https://{username}.bandcamp.com",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/BandCamp/BandCamp.svg",
    iconRound: "/images/social_media_icons/BandCamp/BandCamp_colored.svg",
    iconOriginalBW: "/images/social_media_icons/BandCamp/BandCamp_original.svg",
    iconOriginal:
      "/images/social_media_icons/BandCamp/BandCamp_original_colored.svg",
  },
  {
    name: "Behance",
    checked: false,
    value: "",
    placeholder: "Behance username",
    url: "https://www.behance.net/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Behance/white.svg",
    iconRoundBW: "/images/social_media_icons/Behance/Behance.svg",
    iconRound: "/images/social_media_icons/Behance/Behance_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Behance/Behance_original.svg",
    iconOriginal:
      "/images/social_media_icons/Behance/Behance_original_colored.svg",
  },
  {
    name: "Boosty",
    checked: false,
    value: "",
    placeholder: "Channel name with @",
    url: "https://www.boosty.to/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/Boosty/Boosty.svg",
    iconRound: "/images/social_media_icons/Boosty/Boosty_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Boosty/Boosty_original.svg",
    iconOriginal:
      "/images/social_media_icons/Boosty/Boosty_original_colored.svg",
  },
  {
    name: "Email",
    checked: false,
    value: "",
    placeholder: "",
    url: "",
    // icon: "https://api.hubbbox.com/media/icons/Email/Email.svg",
    iconRoundBW: "/images/social_media_icons/Email/Email.svg",
    iconRound: "/images/social_media_icons/Email/Email_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Email/Email_original.svg",
    iconOriginal: "/images/social_media_icons/Email/Email_original_colored.svg",
  },
  {
    name: "Facebook",
    checked: false,
    value: "",
    placeholder: "Facebook username",
    url: "https://www.facebook.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Facebook/white.svg",
    iconRoundBW: "/images/social_media_icons/Facebook/Facebook.svg",
    iconRound: "/images/social_media_icons/Facebook/Facebook_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Facebook/Facebook_original.svg",
    iconOriginal:
      "/images/social_media_icons/Facebook/Facebook_original_colored.svg",
  },
  {
    name: "IMDb",
    checked: false,
    value: "",
    placeholder: "Page id",
    url: "https://www.imdb.com/user/ur{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/IMDB/IMDB.svg",
    iconRound: "/images/social_media_icons/IMDB/IMDB_colored.svg",
    iconOriginalBW: "/images/social_media_icons/IMDB/IMDB_original.svg",
    iconOriginal: "/images/social_media_icons/IMDB/IMDB_original_colored.svg",
  },
  {
    name: "Instagram",
    checked: false,
    value: "",
    placeholder: "Instagram username",
    url: "https://www.instagram.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Instagram/white.svg",
    iconRoundBW: "/images/social_media_icons/Instagram/Instagram.svg",
    iconRound: "/images/social_media_icons/Instagram/Instagram_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/Instagram/Instagram_original.svg",
    iconOriginal:
      "/images/social_media_icons/Instagram/Instagram_original_colored.svg",
  },
  {
    name: "Kinopoisk",
    checked: false,
    value: "",
    placeholder: "Page id",
    url: "https://www.kinopoisk.ru/user/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/Kinopoisk/Kinopoisk.svg",
    iconRound: "/images/social_media_icons/Kinopoisk/Kinopoisk_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/Kinopoisk/Kinopoisk_original.svg",
    iconOriginal:
      "/images/social_media_icons/Kinopoisk/Kinopoisk_original_colored.svg",
  },
  {
    name: "LinkedIn",
    checked: false,
    value: "",
    placeholder: "LinkedIn account url",
    url: "{username}",
    // icon: "https://api.hubbbox.com/media/icons/LinkedIN/white.svg",
    iconRoundBW: "/images/social_media_icons/LinkedIN/LinkedIN.svg",
    iconRound: "/images/social_media_icons/LinkedIN/LinkedIN_colored.svg",
    iconOriginalBW: "/images/social_media_icons/LinkedIN/LinkedIN_original.svg",
    iconOriginal:
      "/images/social_media_icons/LinkedIN/LinkedIN_original_colored.svg",
  },
  {
    name: "Patreon",
    checked: false,
    value: "",
    placeholder: "Patreon username",
    url: "https://www.patreon.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Patreon/white.svg",
    iconRoundBW: "/images/social_media_icons/Patreon/Patreon.svg",
    iconRound: "/images/social_media_icons/Patreon/Patreon_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Patreon/Patreon_original.svg",
    iconOriginal:
      "/images/social_media_icons/Patreon/Patreon_original_colored.svg",
  },
  {
    name: "Pinterest",
    checked: false,
    value: "",
    placeholder: "Pinterest username",
    url: "https://www.pinterest.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Pinterest/white.svg",
    iconRoundBW: "/images/social_media_icons/Pinterest/Pinterest.svg",
    iconRound: "/images/social_media_icons/Pinterest/Pinterest_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/Pinterest/Pinterest_original.svg",
    iconOriginal:
      "/images/social_media_icons/Pinterest/Pinterest_original_colored.svg",
  },
  {
    name: "Reddit (subreddit)",
    checked: false,
    value: "",
    placeholder: "Subreddit name",
    url: "https://www.reddit.com/r/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Reddit/white.svg",
    iconRoundBW: "/images/social_media_icons/Reddit/Reddit.svg",
    iconRound: "/images/social_media_icons/Reddit/Reddit_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Reddit/Reddit_original.svg",
    iconOriginal:
      "/images/social_media_icons/Reddit/Reddit_original_colored.svg",
  },
  {
    name: "SoundCloud",
    checked: false,
    value: "",
    placeholder: "SoundCloud username",
    url: "https://www.soundcloud.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/SoundCloud/SoundCloud.svg",
    iconRound: "/images/social_media_icons/SoundCloud/SoundCloud_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/SoundCloud/SoundCloud_original.svg",
    iconOriginal:
      "/images/social_media_icons/SoundCloud/SoundCloud_original_colored.svg",
  },
  {
    name: "Spotify",
    checked: false,
    value: "",
    placeholder: "Spotify username",
    url: "https://open.spotify.com/user/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Spotify/white.svg",
    iconRoundBW: "/images/social_media_icons/Spotify/Spotify.svg",
    iconRound: "/images/social_media_icons/Spotify/Spotify_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Spotify/Spotify_original.svg",
    iconOriginal:
      "/images/social_media_icons/Spotify/Spotify_original_colored.svg",
  },
  {
    name: "Substack",
    checked: false,
    value: "",
    placeholder: "Substack username",
    url: "https://{username}.substack.com",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/Substack/Substack.svg",
    iconRound: "/images/social_media_icons/Substack/Substack_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Substack/Substack_original.svg",
    iconOriginal:
      "/images/social_media_icons/Substack/Substack_original_colored.svg",
  },
  {
    name: "Telegram",
    checked: false,
    value: "",
    placeholder: "Username with @",
    url: "https://www.t.me/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Telegram/white.svg",
    iconRoundBW: "/images/social_media_icons/Telegram/Telegram.svg",
    iconRound: "/images/social_media_icons/Telegram/Telegram_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Telegram/Telegram_original.svg",
    iconOriginal:
      "/images/social_media_icons/Telegram/Telegram_original_colored.svg",
  },
  {
    name: "Threads",
    checked: false,
    value: "",
    placeholder: "Username with @",
    url: "https://www.threads.net/@{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/Threads/Threads.svg",
    iconRound: "/images/social_media_icons/Threads/Threads_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Threads/Threads_original.svg",
    iconOriginal:
      "/images/social_media_icons/Threads/Threads_original_colored.svg",
  },
  {
    name: "TikTok",
    checked: false,
    value: "",
    placeholder: "Username with @",
    url: "https://www.tiktok.com/@{username}",
    // icon: "https://api.hubbbox.com/media/icons/TikTok/white.svg",
    iconRoundBW: "/images/social_media_icons/TikTok/TikTok.svg",
    iconRound: "/images/social_media_icons/TikTok/TikTok_colored.svg",
    iconOriginalBW: "/images/social_media_icons/TikTok/TikTok_original.svg",
    iconOriginal:
      "/images/social_media_icons/TikTok/TikTok_original_colored.svg",
  },
  {
    name: "Twitch",
    checked: false,
    value: "",
    placeholder: "Channel name with @",
    url: "https://www.twitch.tv/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Twitch/white.svg",
    iconRoundBW: "/images/social_media_icons/Twitch/Twitch.svg",
    iconRound: "/images/social_media_icons/Twitch/Twitch_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Twitch/Twitch_original.svg",
    iconOriginal:
      "/images/social_media_icons/Twitch/Twitch_original_colored.svg",
  },
  {
    name: "Twitter",
    checked: false,
    value: "",
    placeholder: "Username with @",
    url: "https://www.twitter.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Twitter/white.svg",
    iconRoundBW: "/images/social_media_icons/Twitter/Twitter.svg",
    iconRound: "/images/social_media_icons/Twitter/Twitter_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Twitter/Twitter_original.svg",
    iconOriginal:
      "/images/social_media_icons/Twitter/Twitter_original_colored.svg",
  },
  {
    name: "Vimeo",
    checked: false,
    value: "",
    placeholder: "Channel name with @",
    url: "https://www.vimeo.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Vimeo/white.svg",
    iconRoundBW: "/images/social_media_icons/Vimeo/Vimeo.svg",
    iconRound: "/images/social_media_icons/Vimeo/Vimeo_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Vimeo/Vimeo_original.svg",
    iconOriginal: "/images/social_media_icons/Vimeo/Vimeo_original_colored.svg",
  },
  {
    name: "VKontakte (VK)",
    checked: false,
    value: "",
    placeholder: "VK id or username",
    url: "https://www.vk.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/VK/white.svg",
    iconRoundBW: "/images/social_media_icons/VK/VK.svg",
    iconRound: "/images/social_media_icons/VK/VK_colored.svg",
    iconOriginalBW: "/images/social_media_icons/VK/VK_original.svg",
    iconOriginal: "/images/social_media_icons/VK/VK_original_colored.svg",
  },
  {
    name: "Weibo",
    checked: false,
    value: "",
    placeholder: "Weibo username",
    url: "https://www.weibo.com/{username}",
    // icon: "https://api.hubbbox.com/media/icons/Weibo/white.svg",
    iconRoundBW: "/images/social_media_icons/Weibo/Weibo.svg",
    iconRound: "/images/social_media_icons/Weibo/Weibo_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Weibo/Weibo_original.svg",
    iconOriginal: "/images/social_media_icons/Weibo/Weibo_original_colored.svg",
  },
  {
    name: "WhatsApp",
    checked: false,
    value: "",
    placeholder: "Phone number without +",
    url: "https://www.wa.me/{username}",
    // icon: "https://api.hubbbox.com/media/icons/WhatsApp/white.svg",
    iconRoundBW: "/images/social_media_icons/WhatsApp/WhatsApp.svg",
    iconRound: "/images/social_media_icons/WhatsApp/WhatsApp_colored.svg",
    iconOriginalBW: "/images/social_media_icons/WhatsApp/WhatsApp_original.svg",
    iconOriginal:
      "/images/social_media_icons/WhatsApp/WhatsApp_original_colored.svg",
  },
  {
    name: "Yandex music (artist)",
    checked: false,
    value: "",
    placeholder: "Yandex music account number",
    url: "https://music.yandex.com/artist/{username}?utm_medium=copy_link",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/YandexMusic/YandexMusic.svg",
    iconRound: "/images/social_media_icons/YandexMusic/YandexMusic_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/YandexMusic/YandexMusic_original.svg",
    iconOriginal:
      "/images/social_media_icons/YandexMusic/YandexMusic_original_colored.svg",
  },
  {
    name: "Yandex Zen",
    checked: false,
    value: "",
    placeholder: "Yandex username",
    url: "https://zen.yandex.ru/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/YandexZen/YandexZen.svg",
    iconRound: "/images/social_media_icons/YandexZen/YandexZen_colored.svg",
    iconOriginalBW:
      "/images/social_media_icons/YandexZen/YandexZen_original.svg",
    iconOriginal:
      "/images/social_media_icons/YandexZen/YandexZen_original_colored.svg",
  },
  {
    name: "YouTube",
    checked: false,
    value: "",
    placeholder: "Channel name with @",
    url: "https://www.youtube.com/@{username}",
    // icon: "https://api.hubbbox.com/media/icons/Youtube/white.svg",
    iconRoundBW: "/images/social_media_icons/Youtube/Youtube.svg",
    iconRound: "/images/social_media_icons/Youtube/Youtube_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Youtube/Youtube_original.svg",
    iconOriginal:
      "/images/social_media_icons/Youtube/Youtube_original_colored.svg",
  },
  {
    name: "Zvuk",
    checked: false,
    value: "",
    placeholder: "Zvuk account number",
    url: "https://zvuk.com/artist/{username}",
    // icon: "https://api.hubbbox.com/media/icons/AppleMusic/AppleMusic.svg",
    iconRoundBW: "/images/social_media_icons/Zvuk/Zvuk.svg",
    iconRound: "/images/social_media_icons/Zvuk/Zvuk_colored.svg",
    iconOriginalBW: "/images/social_media_icons/Zvuk/Zvuk_original.svg",
    iconOriginal: "/images/social_media_icons/Zvuk/Zvuk_original_colored.svg",
  },
];
