import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import "preline/preline";
// import { IStaticMethods } from "preline/preline";

export default function VerifyCodeEmailPage() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  return (
    <div className="flex justify-center break-words overflow-hidden">
      {/* <div className="font-manrope px-[24px] pb-[50px] bg-[#F5F5F5] w-screen max-w-md min-h-screen"> */}
      <div className="font-manrope px-[24px] w-screen max-w-md">
        <div className="flex flex-col">
          <div className="mt-[150px] flex justify-center">
            <img
              src="/images/email_verified/verified.svg"
              alt="verified.svg"
              className="w-[124px] h-[124px]"
            />

            {/* <Icon
              icon="carbon:checkmark-filled"
              className="w-[124px] h-[124px] text-[#18C07A]"
            /> */}
          </div>

          <div className="mt-[40px]">
            <div className="text-[26px] text-[#161616] leading-[30px] font-bold text-center">
              {t("registration_completed")}
            </div>
          </div>

          <div className="mt-[10px] flex justify-center text-[#8391A1] leading-[24px] text-[18px] text-center">
            {t("email_verify_code_completed_text2")}
          </div>

          <div className="mt-[162px] flex justify-center">
            <button
              className="w-full h-[50px] rounded-xl bg-black text-white text-[18px] font-medium leading-[22px]"
              onClick={() => {
                navigate("/auth");
              }}
            >
              {t("login")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
