import { useEffect, useState } from "react";
import LoadingScreen from "../../ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../constants";
import * as actions from "../../actions";
import * as utils from "../../utils";
import BurgerMenu from "../BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import CustomInput from "../CustomInput";
import NumberInput from "..//NumberInput";

export default function Component({
  handleChangeStep,
  selectDistributorData,
  handleChangeSelectDistributorData,
  data,
  setItemToListOfProducts,
  choosenProductId,
  choosenProduct,
  handleSendCollaborationData,
  errorResponse,
}: any) {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [clickButton, setClickButton] = useState(false);
  const [firstClickButton, setFirstClickButton] = useState(false);

  const [usernameErrorBool, setUsernameErrorBool] = useState(false);
  const [productErrorBool, setProductErrorBool] = useState(false);
  const [priceErrorBool, setPriceErrorBool] = useState(false);
  const [feeErrorBool, setFeeErrorBool] = useState(false);
  const [termsErrorBool, setTermsErrorBool] = useState(false);
  const [commentsErrorBool, setCommentsErrorBool] = useState(false);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [blockDesign, setBlockDesign] = useState("first");

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
    }
  }, [profileInfoStore]);

  useEffect(() => {
    if (firstClickButton) {
      if (selectDistributorData.distributorUsername === "") {
        setUsernameErrorBool(true);
      }
      if (Object.keys(choosenProduct).length === 0) {
        setProductErrorBool(true);
      }
      if (selectDistributorData.price === "") {
        setPriceErrorBool(true);
      }
      if (selectDistributorData.fee === "") {
        setFeeErrorBool(true);
      }
      if (
        selectDistributorData.terms === "duration" &&
        selectDistributorData.duration === ""
      ) {
        setTermsErrorBool(true);
      } else if (
        selectDistributorData.terms === "quantity" &&
        selectDistributorData.quantity === ""
      ) {
        setTermsErrorBool(true);
      }
      if (selectDistributorData.comments === "") {
        setCommentsErrorBool(true);
      }
    }
  }, [clickButton]);

  useEffect(() => {
    if (firstClickButton) {
      if (selectDistributorData.distributorUsername !== "") {
        setUsernameErrorBool(false);
      }
      if (Object.keys(choosenProduct).length > 0) {
        setProductErrorBool(false);
      }
      if (selectDistributorData.price !== "") {
        setPriceErrorBool(false);
      }
      if (selectDistributorData.fee !== "") {
        setFeeErrorBool(false);
      }
      if (
        selectDistributorData.terms === "duration" &&
        selectDistributorData.duration !== ""
      ) {
        setTermsErrorBool(false);
      } else if (
        selectDistributorData.terms === "quantity" &&
        selectDistributorData.quantity !== ""
      ) {
        setTermsErrorBool(false);
      }
      if (selectDistributorData.comments !== "") {
        setCommentsErrorBool(false);
      }
    }
  }, [selectDistributorData]);

  return (
    <div>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    handleChangeStep("selection");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  <span className="text-[#F5F5F5] invisible">iiii</span>
                  {t("select_a_distributor")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[66px]">
              <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                {t("distributor_username")}
              </div>
            </div>

            <div className="mt-[10px]">
              <CustomInput
                handleChangeValue={(newVal: string) => {
                  handleChangeSelectDistributorData(
                    "distributorUsername",
                    newVal
                  );
                }}
                value={selectDistributorData.distributorUsername}
                placeholder={t("distributor_username_placeholder")}
                rows={1}
                maxLength={100}
                borderColor={usernameErrorBool ? "FF2C20" : "E8ECF4"}
              />
            </div>

            {errorResponse && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                * {errorResponse}
              </div>
            )}

            <div className="mt-[10px]">
              <div className="text-[#161616] text-[16px] leading-[20px]">
                {t("product")}
              </div>
            </div>

            {Object.keys(choosenProduct).length > 0 && (
              // <div
              //   className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
              //   // onClick={() => {
              //   //   setIsOpen(true);
              //   // }}
              // >
              //   <div className="flex space-x-[10px] items-center">
              //     {choosenProduct.thumbnail === "" ? (
              //       <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
              //     ) : (
              //       <img
              //         src={choosenProduct.thumbnail}
              //         alt="image"
              //         className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
              //       />
              //     )}
              //     <div className="ml-[10px]">
              //       <div className="text-[16px] leading-[27px] text-[#161616] font-semibold">
              //         {choosenProduct.name}
              //       </div>
              //       <div className="text-[14px] leading-[22.5px] text-[#161616]">
              //         {t(choosenProduct.type)}
              //       </div>
              //       <div className="text-[14px] leading-[22.5px] text-[#161616]">
              //         {t("price")}:{" "}
              //         {choosenProduct.price
              //           ? `${choosenProduct.currencySymbol}${choosenProduct.price}`
              //           : ""}
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <div>
                {blockDesign === "first" && (
                  <div
                    className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                    onClick={() => {}}
                  >
                    <div className="flex space-x-[15px] items-center">
                      {choosenProduct.thumbnail === "" ? (
                        <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                      ) : (
                        <img
                          src={choosenProduct.thumbnail}
                          alt="image"
                          className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                        />
                      )}
                      <div className="ml-[10px]">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {choosenProduct.name}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t(choosenProduct.type)}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t("price")}:{" "}
                          {choosenProduct.price
                            ? `${choosenProduct.currencySymbol}${choosenProduct.price}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {blockDesign === "second" && (
                  <div
                    className="mt-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                    onClick={() => {}}
                  >
                    <div className="flex space-x-[15px] items-center">
                      {choosenProduct.thumbnail === "" ? (
                        <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                      ) : (
                        <img
                          src={choosenProduct.thumbnail}
                          alt="image"
                          className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                        />
                      )}
                      <div className="ml-[10px]">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {choosenProduct.name}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t(choosenProduct.type)}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t("price")}:{" "}
                          {choosenProduct.price
                            ? `${choosenProduct.currencySymbol}${choosenProduct.price}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {blockDesign === "third" && (
                  <div
                    className="mt-[10px] w-full rounded-[7px] shadow-custom"
                    onClick={() => {}}
                  >
                    <div className="flex space-x-[15px] items-center">
                      {choosenProduct.thumbnail === "" ? (
                        <div className="w-[127px] h-[95px] bg-[#F7F8F9] rounded-l-[7px]"></div>
                      ) : (
                        <img
                          src={choosenProduct.thumbnail}
                          alt="image"
                          className="w-[127px] h-[95px] object-center object-cover rounded-l-[7px]"
                        />
                      )}
                      <div className="pr-[10px] py-[10px]">
                        <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                          {choosenProduct.name}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t(choosenProduct.type)}
                        </div>
                        <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                          {t("price")}:{" "}
                          {choosenProduct.price
                            ? `${choosenProduct.currencySymbol}${choosenProduct.price}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="mt-[10px]">
              <div
                className="w-full h-[50px] flex justify-center items-center text-[18px] leading-[22px] border border-[#161616] rounded-[10px]"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {t("select_product")}
              </div>
            </div>

            {productErrorBool && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                {t("select_a_distributor_error")}
              </div>
            )}

            <div className="mt-[10px]">
              <div className="text-[#161616] text-[16px] leading-[20px]">
                <span className="font-medium">{t("your_price")}</span> ⓘ
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="flex space-x-[10px] items-center">
                <div className="w-full h-[56px]">
                  <NumberInput
                    value={selectDistributorData.price}
                    placeholder={t("set_price")}
                    changeFunc={(newValue: any) => {
                      handleChangeSelectDistributorData("price", newValue);
                    }}
                    borderColor={priceErrorBool ? "FF2C20" : "E8ECF4"}
                  />
                </div>
                <div className="w-[76px] h-[56px] flex-shrink-0">
                  <Menu>
                    <MenuHandler>
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {selectDistributorData.currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </MenuHandler>
                    <MenuList className="w-[228px] rounded-[10px]">
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeSelectDistributorData(
                              "currency",
                              "dollar"
                            );
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            US {t("dollar")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            $
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeSelectDistributorData(
                              "currency",
                              "tenge"
                            );
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            KZ {t("tenge")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₸
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeSelectDistributorData(
                              "currency",
                              "ruble"
                            );
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            RU {t("ruble")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₽
                          </div>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                {t("your_fee")}
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={selectDistributorData.fee}
                  placeholder={t("your_fee_placeholder")}
                  changeFunc={(newValue: any) => {
                    if (0 > parseInt(newValue)) {
                      newValue = "0";
                    } else if (parseInt(newValue) > 100) {
                      newValue = "100";
                    }
                    handleChangeSelectDistributorData("fee", newValue);
                  }}
                  borderColor={feeErrorBool ? "FF2C20" : "E8ECF4"}
                />
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="text-[#161616] text-[16px] leading-[20px]">
                <span className="font-medium">
                  {t("duration_of_the_collaboration")}
                </span>{" "}
                ⓘ
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("time_days")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={selectDistributorData.terms === "duration"}
                  onChange={() => {
                    if (selectDistributorData.terms === "duration") {
                      handleChangeSelectDistributorData("terms", "quantity");
                    } else {
                      handleChangeSelectDistributorData("terms", "duration");
                    }
                  }}
                />
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={selectDistributorData.duration}
                  placeholder={t("time_days_placeholder")}
                  changeFunc={(newValue: any) => {
                    if (selectDistributorData.terms === "duration") {
                      handleChangeSelectDistributorData("duration", newValue);
                    }
                  }}
                  borderColor={
                    termsErrorBool && selectDistributorData.terms === "duration"
                      ? "FF2C20"
                      : "E8ECF4"
                  }
                />
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("product_quantity")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={selectDistributorData.terms === "quantity"}
                  onChange={() => {
                    if (selectDistributorData.terms === "quantity") {
                      handleChangeSelectDistributorData("terms", "duration");
                    } else {
                      handleChangeSelectDistributorData("terms", "quantity");
                    }
                  }}
                />
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={selectDistributorData.quantity}
                  placeholder={t("product_quantity_placeholder")}
                  changeFunc={(newValue: any) => {
                    if (selectDistributorData.terms === "quantity") {
                      handleChangeSelectDistributorData("quantity", newValue);
                    }
                  }}
                  borderColor={
                    termsErrorBool && selectDistributorData.terms === "quantity"
                      ? "FF2C20"
                      : "E8ECF4"
                  }
                />
              </div>
            </div>

            <div className="mt-[10px]">
              <div className="text-[#161616] text-[16px] leading-[20px] font-medium">
                {t("сomments")}
              </div>
            </div>

            <div className="mt-[10px]">
              <CustomInput
                handleChangeValue={(newVal: string) => {
                  handleChangeSelectDistributorData("comments", newVal);
                }}
                value={selectDistributorData.comments}
                placeholder={t("сomments_placeholder")}
                rows={3}
                maxLength={240}
                delimiter={false}
                borderColor={commentsErrorBool ? "FF2C20" : "E8ECF4"}
              />
            </div>

            <div className="mt-[5px]">
              <div className="flex justify-end items-center">
                <div className="text-[12px] text-[#161616]">
                  {selectDistributorData.comments.length}/240
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <div
                className="bg-[#161616] h-[50px] rounded-[10px] text-white flex justify-center items-center font-semibold text-[18px]"
                onClick={() => {
                  setClickButton(!clickButton);
                  setFirstClickButton(true);

                  if (
                    selectDistributorData.distributorUsername !== "" &&
                    Object.keys(choosenProduct).length !== 0 &&
                    selectDistributorData.price !== "" &&
                    selectDistributorData.fee !== "" &&
                    selectDistributorData.comments !== ""
                  ) {
                    if (
                      (selectDistributorData.terms === "duration" &&
                        selectDistributorData.duration !== "") ||
                      (selectDistributorData.terms === "quantity" &&
                        selectDistributorData.quantity !== "")
                    ) {
                      handleSendCollaborationData("select");
                    }
                  }
                }}
              >
                {t("send_a_request")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      if (
        selectDistributorData.terms === "duration" &&
        selectDistributorData.duration === ""
      ) {
        setTermsErrorBool(true);
      } else if (
        selectDistributorData.terms === "quantity" &&
        selectDistributorData.quantity === ""
      ) {
        setTermsErrorBool(true);
      } */}

      {/* ============================================== BOTTOM WINDOW ============================================== */}
      <div
        className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
      >
        {/* Задний план с затемнением */}
        <div
          className="fixed inset-0 bg-gray-500 opacity-75"
          //   onClick={closeWindow}
        ></div>

        {/* Основное окно */}
        <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px] font-urbanist">
          <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
            <div className="flex justify-center mt-[10px]">
              <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
            </div>
            <div className="flex justify-between items-center mt-[23px] px-[24px]">
              <div className="text-white text-[1px] invisible">
                hiiiiiiadfadgasfgafafasfgsdfhsghdfghdfghdfghdfghdfghdfghdfghdfghasdfasfgs
              </div>
              <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
                {t("select_products")}
              </div>
              <button
                className="text-[16px] leading-[18px] text-[#8391A1]"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t("done")}
              </button>
            </div>
            <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
          </div>

          <div className="px-[24px] pb-[40px] mt-[80px]">
            <div className="flex flex-col">
              <div className="mt-[5px]">
                {data.map((elem: any) => (
                  <div
                    className={`mt-[10px] w-full ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[10px] p-[10px]"} shadow-custom`}
                    key={elem.id}
                    onClick={() => {
                      setItemToListOfProducts(elem.id);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      {blockDesign === "first" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {blockDesign === "second" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      {blockDesign === "third" && (
                        <div className="flex space-x-[15px] items-center">
                          {elem.thumbnail === "" ? (
                            <div className="w-[127px] h-[95px] flex-shrink-0 bg-[#F7F8F9] rounded-l-[7px]"></div>
                          ) : (
                            <img
                              src={elem.thumbnail}
                              alt="image"
                              className="w-[127px] h-[95px] bg-[#F7F8F9] object-center object-cover rounded-l-[7px]"
                            />
                          )}
                          <div className="pr-[10px] py-[10px]">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {elem.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(elem.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {elem.price
                                ? `${elem.currencySymbol}${elem.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                      <Radio
                        name={elem.id}
                        containerProps={{
                          className: "",
                        }}
                        checked={choosenProductId === elem.id}
                        crossOrigin={""}
                        onChange={() => {
                          setItemToListOfProducts(elem.id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* WINDOW END */}
          </div>
        </div>
      </div>
    </div>
  );
}
