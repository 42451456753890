import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Avatar,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import CustomInput from "../CustomInput";

export default function Page({
  profileName,
  changeStatus,
  textBlock,
  handleChangeTextBlock,
  handleSendProfileData,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["normal", "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
    ],
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const textareaRef = useRef(null);

  const handleChangeRefTextBlock = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    handleChangeTextBlock(newValue);
    // Устанавливаем высоту textarea так, чтобы она соответствовала высоте содержимого
    // @ts-ignore
    textareaRef.current.style.height = "auto";
    // @ts-ignore
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const [textBlockLength, setTextBlockLength] = useState("");
  const [clickButton, setClickButton] = useState(false);

  useEffect(() => {
    if (textBlock.title.length > 0 || textBlock.mainText.length > 0) {
      setTextBlockLength("");
    } else {
      // setTextBlockLength("Your text must be more than 1 characters");
      setTextBlockLength(t("text_block_error"));
    }
  }, [textBlock]);

  //   useEffect(() => {
  //     console.log("value", value);
  //   }, [value]);

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    if (textBlock.title === "" && textBlock.mainText === "") {
      setErrorCreate(t("create_block_text_error"));
    } else {
      setErrorCreate("");
    }
  }, [textBlock]);

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] w-screen max-w-md">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("edit_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        {/* <ReactQuill
          theme="snow"
          value={textBlock}
          onChange={handleChangeTextBlock}
          modules={modules}
          className="mt-[25px] w-full h-[125px]"
        /> */}

        {/* <textarea
          ref={textareaRef}
          placeholder={t("text")}
          className="mt-[25px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
          value={textBlock}
          onChange={handleChangeRefTextBlock}
          rows={1} // Начнем с одной строки
        /> */}

        <div className="mt-[25px]">
          <CustomInput
            handleChangeValue={(newVal: string) => {
              handleChangeTextBlock("title", newVal);
            }}
            value={textBlock.title}
            placeholder={t("title")}
            rows={1}
            maxLength={100}
          />
        </div>

        <div className="mt-[10px]">
          <CustomInput
            handleChangeValue={(newVal: string) => {
              handleChangeTextBlock("mainText", newVal);
            }}
            value={textBlock.mainText}
            placeholder={t("main_text")}
            rows={3}
            maxLength={1000}
            delimiter={false}
          />
        </div>

        <div className="mt-[5px] flex justify-end">
          <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
            {textBlock.mainText.length}/1000
          </span>
        </div>

        {errorCreate !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {errorCreate}
          </div>
        )}

        {/* {textBlockLength !== "" &&
          (clickButton === true ||
            textBlock.title !== "" ||
            textBlock.mainText !== "") && (
            <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
              {textBlockLength}
            </div>
          )} */}

        <div className="mt-[330px]">
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={() => {
              setClickButton(true);
              if (errorCreate === "") {
                handleSendProfileData("Text");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
