export default function Component({
  value,
  placeholder,
  changeFunc,
  borderColor = "E8ECF4",
  disabled = false,
}: any) {
  const handleKeyPress = (e: any) => {
    // Разрешаем только цифры (ключи 0-9)
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <input
      type="text"
      className={`w-full h-full border-[1px] border-[#${borderColor}] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]`}
      placeholder={placeholder}
      value={value}
      onChange={(event: any) => {
        const newValue = event.target.value;
        if (/^\d*$/.test(newValue)) {
          if (
            newValue === "" ||
            (parseInt(newValue, 10) > 0 && newValue[0] !== "0")
          ) {
            changeFunc(newValue);
          }
        }
      }}
      onKeyPress={handleKeyPress}
      disabled={disabled}
    />
  );
}
