import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/EditBlock/Products";
import YouTube from "../../components/ui/EditBlock/YouTube";
import LinksUrl from "../../components/ui/EditBlock/LinksUrl";
import PDFFile from "../../components/ui/EditBlock/PDFFile";
import Gallery from "../../components/ui/EditBlock/Gallery";
import UrlGalleryImage from "../../components/ui/EditBlock/UrlGalleryImage";
import Newsletter from "../../components/ui/EditBlock/Newsletter";
import Text from "../../components/ui/EditBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import ErrorPage from "../../components/ui/ErrorPage";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export async function sendProfileEditDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendInfoAboutEditBlock,
    dispatch,
    `api/block/edit/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [profileName, setProfileName] = useState("EXAMPLE");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedBlockType, setSelectedBlockType] = useState("Product");
  const [selectedMainBlockType, setSelectedMainBlockType] = useState("Product");
  const [selectedBlockLayout, setSelectedBlockLayout] = useState("Big block");

  const [status, setStatus] = useState("Structure");

  const [listOfProductsData, setListOfProductsData] = useState([]);

  const [bgColor, setBgColor] = useState("#000000");
  const [textColor, setTextColor] = useState("#FFFFFF");

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const navigate = useNavigate();

  // Products ---------------------------------------------------------------

  const [listOfProducts, setListOfProducts] = useState([]);

  const searchItemInListOfProducts = (itemId: any) => {
    // @ts-ignore
    return listOfProducts.some((obj) => obj.id === itemId);
  };

  const deleteItemInListOfProducts = (itemId: any) => {
    // @ts-ignore
    const newArray = listOfProducts.filter((obj) => obj.id !== itemId);
    setListOfProducts(newArray);
  };

  const setDescriptionToItemInListOfProducts = (
    itemId: any,
    description: any
  ) => {
    // @ts-ignore
    const newArray = structuredClone(listOfProducts);
    for (let i = 0; i < newArray.length; i++) {
      // @ts-ignore
      if (newArray[i].id === itemId) {
        // @ts-ignore
        newArray[i].description = description;
      }
    }
    setListOfProducts(newArray);
  };

  const addItemToListOfProducts = (newItem: any) => {
    // @ts-ignore
    if (!searchItemInListOfProducts(newItem.id)) {
      const newList: any = [...listOfProducts, newItem];
      setListOfProducts(newList);
    }
  };

  const setItemToListOfProducts = (newItem: any) => {
    // @ts-ignore
    const newList: any = [newItem];
    setListOfProducts(newList);
  };

  const searchIndexListOfProducts = (itemId: any) => {
    return (
      listOfProducts.findIndex(
        //@ts-ignore
        (item) => item.id === itemId
      ) + 1
    );
  };

  // YouTube ---------------------------------------------------------------

  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState("");

  const handleChangeYoutubeUrl = (newVal: any) => {
    setYoutubeVideoUrl(newVal);
  };

  // URL/Links ---------------------------------------------------------------

  const [urlLinks, setUrlLinks] = useState({
    url: "",
    title: "",
    description: "",
  });
  const [messageImageLinksUrl, setMessageImageLinksUrl] = useState("");
  const [imageLinksUrl, setImageLinksUrl] = useState(null);

  const handleChangeUrlLinks = (
    urlNew: any,
    titleNew: any,
    descriptionNew: any,
    key: any
  ) => {
    let newData = structuredClone(urlLinks);

    if (key === "url") {
      newData.url = urlNew;
    }

    if (key === "title") {
      newData.title = titleNew;
    }

    if (key === "description") {
      newData.description = descriptionNew;
    }

    setUrlLinks(newData);
  };

  const validateFileTypeUrlLinks = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeUrlLinks = (file: any) => {
    return file.size <= constants.MaxSizeImageLinksUrl * 1024 * 1024;
  };

  const handleImageLinksUrlChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageImageLinksUrl("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileTypeUrlLinks(file)) {
    //     setMessageImageLinksUrl(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSizeUrlLinks(file)) {
    //     setMessageImageLinksUrl(
    //       `File size exceeds ${constants.MaxSizeImageLinksUrl}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // @ts-ignore
    //       setImageLinksUrl(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageImageLinksUrl("");
    //   }
    // }

    setImageLinksUrl(fileStr);
  };

  const handleButtonClickImageLinksUrl = () => {
    //@ts-ignore
    document.getElementById("imageLinksUrlInput").click();
  };

  // PDF File ---------------------------------------------------------------

  const [pdfFile, setPdfFile] = useState({
    title: "",
    description: "",
  });
  const [messageImagePdfFile, setMessageImagePdfFile] = useState("");
  const [imagePdfFile, setImagePdfFile] = useState(null);

  const [messageFilePdfFile, setMessageFilePdfFile] = useState("");
  const [filePdfFile, setFilePdfFile] = useState(null);
  const [filePdfFileName, setFilePdfFileName] = useState("");

  const handleChangePdfFile = (
    titleNew: any,
    descriptionNew: any,
    key: any
  ) => {
    let newData = structuredClone(pdfFile);

    if (key === "title") {
      newData.title = titleNew;
    }

    if (key === "description") {
      newData.description = descriptionNew;
    }

    setPdfFile(newData);
  };

  const validateFileTypeImagePdfFile = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeImagePdfFile = (file: any) => {
    return file.size <= constants.MaxSizeImagePdfFile * 1024 * 1024;
  };

  const handleImagePdfFileChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageImagePdfFile("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileTypeImagePdfFile(file)) {
    //     setMessageImagePdfFile(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSizeImagePdfFile(file)) {
    //     setMessageImagePdfFile(
    //       `File size exceeds ${constants.MaxSizeImagePdfFile}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // @ts-ignore
    //       setImagePdfFile(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageImagePdfFile("");
    //   }
    // }

    setImagePdfFile(fileStr);
  };

  const handleButtonClickImagePdfFileUrl = () => {
    //@ts-ignore
    document.getElementById("imagePdfFileInput").click();
  };

  // PDF File
  const validateFileTypeFilePdfFile = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeFilePdfFile = (file: any) => {
    return file.size <= constants.MaxSizeFilePdfFile * 1024 * 1024;
  };

  const handleFilePdfFileChange = (event: any) => {
    const files = event.target.files;

    if (files.length > 1) {
      // setMessageFilePdfFile("Please select only one file.");
      setMessageFilePdfFile(t("pdf_file_upload_error1"));
      return;
    }

    const file = files[0];

    if (file) {
      // if (!validateFileTypeFilePdfFile(file)) {
      //   setMessageImagePdfFile(
      //     "Unsupported file format. Please select an image (jpg, jpeg, png)."
      //   );
      // } else
      if (!validateFileSizeFilePdfFile(file)) {
        setMessageFilePdfFile(
          t("pdf_file_upload_error2")
          // `File size exceeds ${constants.MaxSizeFilePdfFile}MB limit. Please select a smaller file.`
        );
      } else {
        setFilePdfFileName(file.name);
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   const base64String = reader.result;
        //   // @ts-ignore
        //   setFilePdfFile(base64String);
        // };
        // reader.readAsDataURL(file);
        setFilePdfFile(file);
        setMessageFilePdfFile("");
      }
    }
  };

  const handleButtonClickFilePdfFileUrl = () => {
    //@ts-ignore
    document.getElementById("filePdfFileInput").click();
  };

  const changeMessageFilePdfFile = (newVal: string) => {
    setMessageFilePdfFile(newVal);
  };

  // Newsletter ---------------------------------------------------------------

  const [newsletterTitle, setNewsletterTitle] = useState("");

  const listOfNewsletterEmail = [
    "Example1@mail.com",
    "Example2@mail.com",
    "Example3@mail.com",
    "Example4@mail.com",
    "Example5@mail.com",
  ];

  const handleChangeNewsletterTitle = (titleNew: any) => {
    setNewsletterTitle(titleNew);
  };

  const handleCopyNewsletterEmails = () => {
    const valStr = listOfNewsletterEmail.join(" ");
    navigator.clipboard.writeText(valStr);
  };

  // Gallery ---------------------------------------------------------------

  const [listOfGalleryImages, setListOfGalleryImages] = useState([]);
  const [listOfGalleryImagesMain, setListOfGalleryImagesMain] = useState([]);
  const [urlGalleryImage, setUrlGalleryImage] = useState("");

  const handleChangeUrlGalleryImage = (newVal: any) => {
    setUrlGalleryImage(newVal);
  };

  const handleSetUrlGalleryImage = (fileStr: any) => {
    const listOfGalleryImagesMainTmp = structuredClone(listOfGalleryImagesMain);

    //@ts-ignore
    listOfGalleryImagesMainTmp[0].delete = true;

    setListOfGalleryImagesMain(listOfGalleryImagesMainTmp);

    const listOfGalleryImagesTmp = [
      {
        id: 1,
        file: fileStr,
        delete: false,
      },
    ];

    // @ts-ignore
    setListOfGalleryImages(listOfGalleryImagesTmp);
  };

  const handleAddGalleryImage = (fileStr: any) => {
    // setNewsletterTitle(titleNew);
    const listOfGalleryImagesTmp = structuredClone(listOfGalleryImages);
    // @ts-ignore
    listOfGalleryImagesTmp.push({
      id: listOfGalleryImages.length + 1,
      file: fileStr,
      delete: false,
    });

    setListOfGalleryImages(listOfGalleryImagesTmp);
  };

  const handleChangeListGalleryImage = (listNew: any) => {
    setListOfGalleryImages(listNew);
  };

  const handleDeleteGalleryImage = (imageId: number) => {
    let listOfGalleryImagesTmp = [];
    let listOfGalleryImagesCopy = structuredClone(listOfGalleryImages);
    let listOfGalleryImagesMainCopy = structuredClone(listOfGalleryImagesMain);
    let j = 0;

    let block_id = null;

    for (let i = 0; i < listOfGalleryImages.length; i++) {
      // @ts-ignore
      if (listOfGalleryImages[i].id === imageId) {
        // @ts-ignore
        if (listOfGalleryImages[i].image_obj_id) {
          // @ts-ignore
          block_id = listOfGalleryImages[i].image_obj_id;
        }
      }
    }

    if (!block_id) {
      for (let i = 0; i < listOfGalleryImages.length; i++) {
        // @ts-ignore
        if (listOfGalleryImages[i].id !== imageId) {
          j += 1;
          listOfGalleryImagesTmp.push({
            id: j,
            // @ts-ignore
            file: listOfGalleryImages[i].file,
          });
        }
      }

      // @ts-ignore
      setListOfGalleryImages(listOfGalleryImagesTmp);
    } else {
      for (let i = 0; i < listOfGalleryImagesCopy.length; i++) {
        // @ts-ignore
        if (listOfGalleryImagesCopy[i].id === imageId) {
          // @ts-ignore
          listOfGalleryImagesCopy[i].delete = true;
        }
      }

      // @ts-ignore
      setListOfGalleryImages(listOfGalleryImagesCopy);

      for (let i = 0; i < listOfGalleryImagesMainCopy.length; i++) {
        // @ts-ignore
        if (listOfGalleryImagesMainCopy[i].image_obj_id === block_id) {
          // @ts-ignore
          listOfGalleryImagesMainCopy[i].delete = true;
        }
      }

      // @ts-ignore
      setListOfGalleryImagesMain(listOfGalleryImagesMainCopy);
    }
  };

  const handleDeleteUrlGalleryImage = (fileStr: any) => {
    let listOfGalleryImagesMainCopy = structuredClone(listOfGalleryImagesMain);

    // @ts-ignore
    setListOfGalleryImages([]);

    //@ts-ignore
    listOfGalleryImagesMainCopy[0].delete = true;

    setListOfGalleryImagesMain(listOfGalleryImagesMainCopy);
  };

  useEffect(() => {
    console.log("listOfGalleryImagesMain", listOfGalleryImagesMain);
  }, [listOfGalleryImagesMain]);

  useEffect(() => {
    console.log("listOfGalleryImages", listOfGalleryImages);
  }, [listOfGalleryImages]);

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // Text ---------------------------------------------------------------

  const [textBlock, setTextBlock] = useState({ title: "", mainText: "" });

  const handleChangeTextBlock = (key: string, textNew: string) => {
    let oldTextBlock = structuredClone(textBlock);

    if (key === "title") {
      oldTextBlock.title = textNew;
    }

    if (key === "mainText") {
      oldTextBlock.mainText = textNew;
    }

    setTextBlock(oldTextBlock);
  };

  const dispatch = useDispatch();
  const params = useParams();

  async function getBlockInfoFunc() {
    await actions.constructorAction(
      constants.getBlockInfo,
      dispatch,
      "api/block/data/",
      "post",
      "",
      {
        block_numeration: params.blockNum,
      },
      true
    )();
  }

  const getBlockInfoStore = useSelector(
    (state: any) => state.getBlockInfoStore
  );

  // useEffect(() => {
  //   // selectedMainBlockType
  //   if (getBlockInfoStore && getBlockInfoStore.data) {
  //     console.log("ЗАХОЖУ в USEEFFECT");
  //     if (
  //       selectedBlockType !== "Product" &&
  //       selectedMainBlockType !== "Product"
  //     ) {
  //       setListOfProducts([]);
  //     }
  //     if (
  //       selectedBlockType !== "YouTube video" &&
  //       selectedMainBlockType !== "YouTube video"
  //     ) {
  //       setYoutubeVideoUrl("");
  //     }
  //     if (
  //       selectedBlockType !== "Links/URL" &&
  //       selectedMainBlockType !== "Links/URL"
  //     ) {
  //       setUrlLinks({
  //         url: "",
  //         title: "",
  //         description: "",
  //       });
  //       setImageLinksUrl(null);
  //       setMessageImageLinksUrl("");
  //     }
  //     if (
  //       selectedBlockType !== "PDF file" &&
  //       selectedMainBlockType !== "PDF file"
  //     ) {
  //       setPdfFile({
  //         title: "",
  //         description: "",
  //       });
  //       setImagePdfFile(null);
  //       setMessageImagePdfFile("");

  //       setFilePdfFile(null);
  //       setMessageFilePdfFile("");
  //       setFilePdfFileName("");
  //     }
  //     // if (
  //     //   selectedBlockType !== "Newsletter" &&
  //     //   selectedMainBlockType !== "Newsletter"
  //     // ) {
  //     //   setNewsletterTitle("");
  //     // }
  //     if (
  //       selectedBlockType !== "Gallery" &&
  //       selectedMainBlockType !== "Gallery"
  //     ) {
  //       setListOfGalleryImages([]);
  //     }
  //     if (selectedBlockType !== "Text" && selectedMainBlockType !== "Text") {
  //       setTextBlock({ title: "", mainText: "" });
  //     }
  //   }
  // }, [selectedBlockType]);

  useEffect(() => {
    // console.log("getBlockInfoStore", getBlockInfoStore);
    if (getBlockInfoStore && getBlockInfoStore.data) {
      const editBlockDataStorage = utils.LocalStorage.get("EditBlockData");

      const newData = getBlockInfoStore.data.response;
      if (newData.block_type === "product") {
        setSelectedMainBlockType("Product");
        setSelectedBlockType("Product");

        if (editBlockDataStorage && editBlockDataStorage.listOfProducts) {
          setListOfProducts(editBlockDataStorage.listOfProducts);
        } else {
          setListOfProducts(newData.block_data.data);
        }
      }
      if (newData.block_type === "youtube_video") {
        setSelectedMainBlockType("YouTube video");
        setSelectedBlockType("YouTube video");

        if (editBlockDataStorage && editBlockDataStorage.youtubeVideoUrl) {
          setYoutubeVideoUrl(editBlockDataStorage.youtubeVideoUrl);
        } else {
          setYoutubeVideoUrl(newData.block_data.data);
        }
      }
      if (newData.block_type === "links_url") {
        setSelectedMainBlockType("Links/URL");
        setSelectedBlockType("Links/URL");

        setImageLinksUrl(newData.image);

        if (editBlockDataStorage && editBlockDataStorage.urlLinks) {
          setUrlLinks(editBlockDataStorage.urlLinks);
        } else {
          setUrlLinks(newData.block_data.data);
        }
      }
      if (newData.block_type === "pdf_file") {
        setSelectedMainBlockType("PDF file");
        setSelectedBlockType("PDF file");

        setImagePdfFile(newData.image);
        setFilePdfFile(newData.file);
        setFilePdfFileName(newData.file_name);

        if (editBlockDataStorage && editBlockDataStorage.pdfFile) {
          setPdfFile(editBlockDataStorage.pdfFile);
        } else {
          setPdfFile(newData.block_data.data);
        }
      }
      // if (newData.block_type === "newsletter") {
      //   setSelectedBlockType("Newsletter");
      //   setSelectedMainBlockType("Newsletter");
      //   setNewsletterTitle(newData.block_data.data.title);
      // }
      if (newData.block_type === "gallery") {
        setSelectedBlockType("Gallery");
        setSelectedMainBlockType("Gallery");
        setListOfGalleryImages(newData.block_data);
        setListOfGalleryImagesMain(newData.block_data);

        if (newData.block_layout !== "side_slider") {
          setUrlGalleryImage(newData.block_data[0].url);
        }
      }

      if (newData.block_type === "text") {
        setSelectedBlockType("Text");
        setSelectedMainBlockType("Text");

        if (editBlockDataStorage && editBlockDataStorage.textBlock) {
          setTextBlock(editBlockDataStorage.textBlock);
        } else {
          setTextBlock(newData.block_data.data);
        }
      }

      if (newData.block_layout === "big_block") {
        setSelectedBlockLayout("Big block");
      }
      if (newData.block_layout === "side_slider") {
        setSelectedBlockLayout("Side slider");
      }
      if (newData.block_layout === "tile") {
        setSelectedBlockLayout("Tile");
      }
      if (newData.block_layout === "text_box") {
        setSelectedBlockLayout("Text box");
      }

      if (newData.list_of_products) {
        const response = newData.list_of_products;
        let newDataList = [];

        for (let i = 0; i < response.length; i++) {
          newDataList.push({
            id: response[i].id,
            currencySymbol: response[i].currencySymbol,
            name: response[i].name,
            price: response[i].price,
            thumbnail: response[i].thumbnail,
            type: response[i].type,
          });
        }

        // @ts-ignore
        setListOfProductsData(newDataList);
      }
    }
  }, [getBlockInfoStore]);

  useEffect(() => {
    getBlockInfoFunc();

    const editBlockDataStorage = utils.LocalStorage.get("EditBlockData");

    if (editBlockDataStorage && editBlockDataStorage.status) {
      setStatus(editBlockDataStorage.status);
    }

    //   if (editBlockDataStorage.youtubeVideoUrl) {
    //     setYoutubeVideoUrl(editBlockDataStorage.youtubeVideoUrl);
    //   }
    //   if (editBlockDataStorage.urlLinks) {
    //     setUrlLinks(editBlockDataStorage.urlLinks);
    //   }
    //   if (editBlockDataStorage.imageLinksUrl) {
    //     setImageLinksUrl(editBlockDataStorage.imageLinksUrl);
    //   }
    //   if (editBlockDataStorage.pdfFile) {
    //     setPdfFile(editBlockDataStorage.pdfFile);
    //   }
    //   if (editBlockDataStorage.imagePdfFile) {
    //     setImagePdfFile(editBlockDataStorage.imagePdfFile);
    //   }
    //   // if (editBlockDataStorage.filePdfFile) {
    //   //   setFilePdfFile(editBlockDataStorage.filePdfFile);
    //   // }
    //   if (editBlockDataStorage.filePdfFileName) {
    //     setFilePdfFileName(editBlockDataStorage.filePdfFileName);
    //   }
    //   // if (editBlockDataStorage.newsletterTitle) {
    //   //   setNewsletterTitle(editBlockDataStorage.newsletterTitle);
    //   // }
    //   if (editBlockDataStorage.listOfGalleryImages) {
    //     setListOfGalleryImages(editBlockDataStorage.listOfGalleryImages);
    //   }
    //   if (editBlockDataStorage.listOfGalleryImagesMain) {
    //     setListOfGalleryImagesMain(
    //       editBlockDataStorage.listOfGalleryImagesMain
    //     );
    //   }
    //   if (editBlockDataStorage.textBlock) {
    //     setTextBlock(editBlockDataStorage.textBlock);
    //   }
    //   if (editBlockDataStorage.selectedBlockType) {
    //     setSelectedBlockType(editBlockDataStorage.selectedBlockType);
    //   }
    //   if (editBlockDataStorage.selectedBlockLayout) {
    //     setSelectedBlockLayout(editBlockDataStorage.selectedBlockLayout);
    //   }
    //   if (editBlockDataStorage.status) {
    //     setStatus(editBlockDataStorage.status);
    //   }
    //   if (editBlockDataStorage.bgColor) {
    //     setBgColor(editBlockDataStorage.bgColor);
    //   }
    //   if (editBlockDataStorage.textColor) {
    //     setTextColor(editBlockDataStorage.textColor);
    //   }
    //   if (editBlockDataStorage.listOfProductsData) {
    //     setListOfProductsData(editBlockDataStorage.listOfProductsData);
    //   }
    // }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (getBlockInfoStore && getBlockInfoStore.data) {
      utils.LocalStorage.set("EditBlockData", {
        listOfProducts: listOfProducts,
        youtubeVideoUrl: youtubeVideoUrl,
        urlLinks: urlLinks,
        // imageLinksUrl: imageLinksUrl,
        pdfFile: pdfFile,
        // imagePdfFile: imagePdfFile,
        // filePdfFile: filePdfFile,
        // filePdfFileName: filePdfFileName,
        // newsletterTitle: newsletterTitle,
        // listOfGalleryImages: listOfGalleryImages,
        // listOfGalleryImagesMain: listOfGalleryImagesMain,
        textBlock: textBlock,
        selectedBlockType: selectedBlockType,
        selectedBlockLayout: selectedBlockLayout,
        status: status,
        bgColor: bgColor,
        textColor: textColor,
        // listOfProductsData: listOfProductsData,
      });
    }
  }, [
    listOfProducts,
    youtubeVideoUrl,
    urlLinks,
    // imageLinksUrl,
    pdfFile,
    // imagePdfFile,
    // filePdfFile,
    // filePdfFileName,
    // newsletterTitle,
    // listOfGalleryImages,
    // listOfGalleryImagesMain,
    textBlock,
    selectedBlockType,
    selectedBlockLayout,
    status,
    bgColor,
    textColor,
    // listOfProductsData,
  ]);

  // useEffect(() => {
  //   console.log("textBlock", textBlock);
  // }, [textBlock]);

  const sendInfoAboutEditBlockStore = useSelector(
    (state: any) => state.sendInfoAboutEditBlockStore
  );

  const handleSendProfileData = (blockType: string) => {
    let newBlockLayout = "";

    if (selectedBlockLayout === "Big block") {
      newBlockLayout = "big_block";
    } else if (selectedBlockLayout === "Side slider") {
      newBlockLayout = "side_slider";
    } else if (selectedBlockLayout === "Tile") {
      newBlockLayout = "tile";
    } else if (selectedBlockLayout === "Text box") {
      newBlockLayout = "text_box";
    }

    const formData = new FormData();

    if (imageLinksUrl) {
      //@ts-ignore
      if (imageLinksUrl.includes("https://api.hubbbox.com/static/media")) {
        formData.append("image_links_url", imageLinksUrl);
      } else {
        // @ts-ignore
        const base64String = imageLinksUrl.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        const fileType = imageLinksUrl
          // @ts-ignore
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          "image_links_url",
          fileBlob,
          `image_links_url.${fileType}`
        );
      }
    }

    if (imagePdfFile) {
      //@ts-ignore
      if (imagePdfFile.includes("https://api.hubbbox.com/static/media")) {
        formData.append("image_pdf_file", imagePdfFile);
      } else {
        // @ts-ignore
        const base64String = imagePdfFile.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        const fileType = imagePdfFile
          // @ts-ignore
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          "image_pdf_file",
          fileBlob,
          `image_pdf_file.${fileType}`
        );
      }
    }

    if (filePdfFile) {
      formData.append("file_pdf_file", filePdfFile);
      // //@ts-ignore
      // if (filePdfFile.includes("https://api.hubbbox.com/static/media")) {
      //   formData.append("file_pdf_file", filePdfFile);
      // } else {
      //   // @ts-ignore
      //   const base64String = filePdfFile.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'

      //   // Преобразовать строку base64 в бинарные данные
      //   // @ts-ignore
      //   const byteCharacters = atob(base64String);
      //   const byteNumbers = new Array(byteCharacters.length);
      //   for (let i = 0; i < byteCharacters.length; i++) {
      //     byteNumbers[i] = byteCharacters.charCodeAt(i);
      //   }
      //   const byteArray = new Uint8Array(byteNumbers);

      //   // Создать объект Blob из бинарных данных
      //   const fileBlob = new Blob([byteArray], { type: "image/jpeg" });

      //   // @ts-ignore
      //   formData.append("file_pdf_file", fileBlob);
      // }
    }

    if (listOfGalleryImages && listOfGalleryImages.length > 0) {
      for (let i = 0; i < listOfGalleryImages.length; i++) {
        if (
          // @ts-ignore
          listOfGalleryImages[i].file.includes(
            "https://api.hubbbox.com/static/media"
          )
        ) {
          formData.append(
            //@ts-ignore
            `gallery_image${listOfGalleryImages[i].id}`,
            //@ts-ignore
            listOfGalleryImages[i].file
          );
        } else {
          // @ts-ignore
          const base64String = listOfGalleryImages[i].file.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
          // @ts-ignore
          const fileType = listOfGalleryImages[i].file
            // @ts-ignore
            .split(",")[0]
            .split("data:")[1]
            .split(";base64")[0];

          // Преобразовать строку base64 в бинарные данные
          // @ts-ignore
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Создать объект Blob из бинарных данных
          const fileBlob = new Blob([byteArray], { type: fileType });

          // @ts-ignore
          formData.append(
            //@ts-ignore
            `gallery_image${listOfGalleryImages[i].id}`,
            fileBlob,
            //@ts-ignore
            `gallery_image${listOfGalleryImages[i].id}.${fileType}`
          );
        }
      }
    }

    let dataForm = {};

    if (blockType === "Product") {
      // sendProfileDataFunc(dispatch, "", {
      //   block_type: "product",
      //   block_layout: newBlockLayout,
      //   section_numeration: params.sectionNum,
      //   data: listOfProducts,
      // });
      dataForm = {
        block_numeration: params.blockNum,
        block_type: "product",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: listOfProducts,
      };
    }
    if (blockType === "YouTube video") {
      dataForm = {
        block_numeration: params.blockNum,
        block_type: "youtube_video",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: youtubeVideoUrl,
      };
    }
    if (blockType === "Links/URL") {
      let tmpUrlLinks = structuredClone(urlLinks);

      if (tmpUrlLinks.url.includes("https://")) {
        const newUrl = tmpUrlLinks.url.replace("https://", "");
        tmpUrlLinks.url = newUrl;
      }

      if (tmpUrlLinks.url.includes("http://")) {
        const newUrl = tmpUrlLinks.url.replace("http://", "");
        tmpUrlLinks.url = newUrl;
      }

      dataForm = {
        block_numeration: params.blockNum,
        block_type: "links_url",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: tmpUrlLinks,
      };
    }
    if (blockType === "PDF file") {
      dataForm = {
        block_numeration: params.blockNum,
        block_type: "pdf_file",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: pdfFile,
      };
    }
    // if (blockType === "Newsletter") {
    //   dataForm = {
    //     block_numeration: params.blockNum,
    //     block_type: "newsletter",
    //     block_layout: newBlockLayout,
    //     // section_numeration: params.sectionNum,
    //     data: {
    //       title: newsletterTitle,
    //       list_of_emails: [],
    //     },
    //   };
    // }
    if (blockType === "Gallery") {
      if (newBlockLayout === "side_slider") {
        dataForm = {
          block_numeration: params.blockNum,
          block_type: "gallery",
          block_layout: newBlockLayout,
          // section_numeration: params.sectionNum,
          data: {
            length: listOfGalleryImages.length,
            block_data: listOfGalleryImagesMain,
          },
        };
      } else {
        let tmpUrlGalleryImage = structuredClone(urlGalleryImage);

        if (tmpUrlGalleryImage.includes("https://")) {
          const newUrl = tmpUrlGalleryImage.replace("https://", "");
          tmpUrlGalleryImage = newUrl;
        }

        dataForm = {
          block_numeration: params.blockNum,
          block_type: "gallery",
          block_layout: newBlockLayout,
          // section_numeration: params.sectionNum,
          data: {
            length: listOfGalleryImages.length,
            url: tmpUrlGalleryImage,
            block_data: listOfGalleryImagesMain,
          },
        };
      }
    }
    if (blockType === "Text") {
      dataForm = {
        block_numeration: params.blockNum,
        block_type: "text",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: textBlock,
      };
    }

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    sendProfileEditDataFunc(dispatch, "", formData);
  };

  useEffect(() => {
    // console.log("sendInfoAboutEditBlockStore", sendInfoAboutEditBlockStore);
    if (sendInfoAboutEditBlockStore && sendInfoAboutEditBlockStore.data) {
      navigate("/profile");
      utils.LocalStorage.remove("EditBlockData");
    }
  }, [sendInfoAboutEditBlockStore]);

  if (sendInfoAboutEditBlockStore && sendInfoAboutEditBlockStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendInfoAboutEditBlockStore.load} />
      </div>
    );
  }

  if (getBlockInfoStore && getBlockInfoStore.fail) {
    return (
      <div>
        <ErrorPage error={getBlockInfoStore.fail} />
      </div>
    );
  }

  if (getBlockInfoStore && getBlockInfoStore.error) {
    return (
      <div>
        <ErrorPage error={getBlockInfoStore.error} />
      </div>
    );
  }

  if (getBlockInfoStore && getBlockInfoStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getBlockInfoStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        {status === "Structure" && (
          <div className="font-manrope px-[24px] w-screen max-w-md">
            <BurgerMenu
              isOpen={burgerMenuOpen}
              profileName={profileName}
              toggleBurgerMenu={toggleBurgerMenu}
            />
            <div className="flex flex-col">
              <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                <div className="h-[56px] flex justify-between items-center">
                  <button
                    onClick={() => {
                      utils.LocalStorage.remove("EditBlockData");
                      navigate("/profile");
                    }}
                  >
                    {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                    <div className="p-[10px]">
                      <img
                        src="/images/back_arrow.svg"
                        alt="back_arrow.svg"
                        className="w-[7px] h-[14px]"
                      />
                    </div>
                  </button>
                  <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                    {t("edit_block")}
                  </div>
                  <button onClick={toggleBurgerMenu}>
                    {!burgerMenuOpen ? (
                      <Icon
                        icon="iconamoon:menu-burger-horizontal-light"
                        className="w-[24px] h-[26px]"
                      />
                    ) : (
                      <span> </span>
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-[61px]">
                <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                  <div
                    className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                    onClick={() => {
                      changeStatus("Structure");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("structure")}
                    </div>
                  </div>
                  <div
                    className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                    onClick={() => {
                      changeStatus("Content");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("content")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  {t("select_block_type")}
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="grid grid-cols-2 gap-2.5">
                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("Product");
                    //   setSelectedBlockLayout("Big block");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("product")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Product"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("Product");
                      //   setSelectedBlockLayout("Big block");
                      // }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("YouTube video");
                    //   setSelectedBlockLayout("Big block");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("youtube_video")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "YouTube video"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("YouTube video");
                      //   setSelectedBlockLayout("Big block");
                      // }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("Links/URL");
                    //   setSelectedBlockLayout("Tile");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("links_url")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Links/URL"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("Links/URL");
                      //   setSelectedBlockLayout("Tile");
                      // }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("PDF file");
                    //   setSelectedBlockLayout("Tile");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("pdf_file")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "PDF file"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("PDF file");
                      //   setSelectedBlockLayout("Tile");
                      // }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("Gallery");
                    //   setSelectedBlockLayout("Side slider");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("gallery")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Gallery"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("Gallery");
                      //   setSelectedBlockLayout("Side slider");
                      // }}
                    />
                  </div>

                  {/* <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("Newsletter");
                      setSelectedBlockLayout("Tile");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        Newsletter
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Newsletter"}
                      onChange={(e) => {
                        setSelectedBlockType("Newsletter");
                        setSelectedBlockLayout("Tile");
                      }}
                    />
                  </div> */}

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    // onClick={() => {
                    //   setSelectedBlockType("Text");
                    //   setSelectedBlockLayout("Text box");
                    // }}
                  >
                    <div className="pl-[15px] opacity-30">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("text")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "opacity-30",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Text"}
                      disabled
                      // onChange={(e) => {
                      //   setSelectedBlockType("Text");
                      //   setSelectedBlockLayout("Text box");
                      // }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  {t("select_block_layout")}
                </div>
              </div>

              <div className="mt-[15px]">
                {selectedBlockType === "Product" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>

                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "YouTube video" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "Links/URL" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "PDF file" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* {selectedBlockType === "Newsletter" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            Big block
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            Side slider
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            Tile
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[15px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            Title
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )} */}

                {selectedBlockType === "Gallery" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        // checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[15px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "Text" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Text box"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Text box");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  Button colors
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex space-x-3 items-center">
                    <div className="text-[#161616] text-[16px] leading-[19.12px]">
                      Background color
                    </div>
                    <input
                      type="color"
                      value={bgColor}
                      className="w-[30px] h-[30px]"
                      onChange={(e) => {
                        setBgColor(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex space-x-3 items-center">
                    <div className="text-[#161616] text-[16px] leading-[19.12px]">
                      Text color
                    </div>
                    <input
                      type="color"
                      value={textColor}
                      className="w-[30px] h-[30px]"
                      onChange={(e) => {
                        setTextColor(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div> */}

              <div className="mt-[131px]">
                <div
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
                  onClick={() => {
                    changeStatus("Content");
                  }}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("continue")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {status === "Content" && (
          <div>
            {selectedBlockType === "Product" && (
              <Products
                profileName={profileName}
                changeStatus={changeStatus}
                listOfProducts={listOfProducts}
                listOfProductsData={listOfProductsData}
                addItemToListOfProducts={addItemToListOfProducts}
                searchIndexListOfProducts={searchIndexListOfProducts}
                searchItemInListOfProducts={searchItemInListOfProducts}
                deleteItemInListOfProducts={deleteItemInListOfProducts}
                handleSendProfileData={handleSendProfileData}
                setDescriptionToItemInListOfProducts={
                  setDescriptionToItemInListOfProducts
                }
                selectedBlockLayout={selectedBlockLayout}
                setItemToListOfProducts={setItemToListOfProducts}
              />
            )}

            {selectedBlockType === "YouTube video" && (
              <YouTube
                profileName={profileName}
                changeStatus={changeStatus}
                handleChangeYoutubeUrl={handleChangeYoutubeUrl}
                youtubeVideoUrl={youtubeVideoUrl}
                handleSendProfileData={handleSendProfileData}
              />
            )}

            {selectedBlockType === "Links/URL" && (
              <LinksUrl
                profileName={profileName}
                changeStatus={changeStatus}
                urlLinks={urlLinks}
                imageLinksUrl={imageLinksUrl}
                notify={notify}
                handleImageLinksUrlChange={handleImageLinksUrlChange}
                handleButtonClickImageLinksUrl={handleButtonClickImageLinksUrl}
                messageImageLinksUrl={messageImageLinksUrl}
                handleChangeUrlLinks={handleChangeUrlLinks}
                handleSendProfileData={handleSendProfileData}
              />
            )}

            {selectedBlockType === "PDF file" && (
              <PDFFile
                profileName={profileName}
                changeStatus={changeStatus}
                pdfFile={pdfFile}
                notify={notify}
                handleChangePdfFile={handleChangePdfFile}
                handleSendProfileData={handleSendProfileData}
                handleButtonClickImagePdfFileUrl={
                  handleButtonClickImagePdfFileUrl
                }
                handleImagePdfFileChange={handleImagePdfFileChange}
                messageImagePdfFile={messageImagePdfFile}
                imagePdfFile={imagePdfFile}
                handleFilePdfFileChange={handleFilePdfFileChange}
                handleButtonClickFilePdfFileUrl={
                  handleButtonClickFilePdfFileUrl
                }
                messageFilePdfFile={messageFilePdfFile}
                filePdfFile={filePdfFile}
                filePdfFileName={filePdfFileName}
                changeMessageFilePdfFile={changeMessageFilePdfFile}
              />
            )}

            {/* {selectedBlockType === "Newsletter" && (
              <Newsletter
                profileName={profileName}
                changeStatus={changeStatus}
                newsletterTitle={newsletterTitle}
                listOfNewsletterEmail={listOfNewsletterEmail}
                handleChangeNewsletterTitle={handleChangeNewsletterTitle}
                handleCopyNewsletterEmails={handleCopyNewsletterEmails}
                handleSendProfileData={handleSendProfileData}
              />
            )} */}

            {selectedBlockType === "Gallery" && (
              <div>
                {selectedBlockLayout === "Side slider" ? (
                  <Gallery
                    profileName={profileName}
                    changeStatus={changeStatus}
                    handleChangeListGalleryImage={handleChangeListGalleryImage}
                    handleAddGalleryImage={handleAddGalleryImage}
                    handleDeleteGalleryImage={handleDeleteGalleryImage}
                    notify={notify}
                    listOfGalleryImages={listOfGalleryImages}
                    handleSendProfileData={handleSendProfileData}
                  />
                ) : (
                  <UrlGalleryImage
                    profileName={profileName}
                    selectedBlockLayout={selectedBlockLayout}
                    changeStatus={changeStatus}
                    urlGalleryImage={urlGalleryImage}
                    handleChangeUrlGalleryImage={handleChangeUrlGalleryImage}
                    notify={notify}
                    listOfGalleryImages={listOfGalleryImages}
                    handleSetUrlGalleryImage={handleSetUrlGalleryImage}
                    handleDeleteUrlGalleryImage={handleDeleteUrlGalleryImage}
                    handleSendProfileData={handleSendProfileData}
                  />
                )}
              </div>
            )}

            {selectedBlockType === "Text" && (
              <Text
                profileName={profileName}
                changeStatus={changeStatus}
                textBlock={textBlock}
                handleChangeTextBlock={handleChangeTextBlock}
                handleSendProfileData={handleSendProfileData}
              />
            )}
          </div>
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
