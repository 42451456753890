import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";

// import "preline/preline";
// import { IStaticMethods } from "preline/preline";

export default function ChangePasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [sameBool, setSameBool] = useState(true);
  const [usernameTaken, setUsernameTaken] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // This is a test bio of my profile to evaluate the visual perception of this aspect of customization. This is additional text to fill out the field.

  return (
    <div className="flex justify-center break-words">
      {/* <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen bg-[#F5F5F5]"> */}
      <div className="font-manrope px-[24px] w-screen max-w-md">
        <div className="flex flex-col">
          <div className="mt-[70px]">
            <div className="text-[42px] leading-[40px] font-bold">
              New password
            </div>
          </div>
          <div className="mt-[10px] text-[#8391A1] text-[18px] leading-[24px]">
            Create a new password that is safe and easy to
            <br />
            remember
          </div>
          <div className="mt-[20px] flex flex-row items-center w-full">
            {!showPassword ? (
              <input
                type="password"
                className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
            <Icon
              icon="iconoir:eye"
              className="w-[22px] h-[22px] ml-2"
              onClick={togglePasswordVisibility}
            />
          </div>

          <div className="mt-[5px]">
            <div className="flex flex-row items-center">
              <Icon icon="carbon:checkmark" className="w-[14px] h-[14px]" />
              <div className="ml-[5px] text-[14px] leading-[16px] text-[#161616]">
                Must be at least 8 characters
              </div>
            </div>
            <div className="flex flex-row items-center">
              <Icon icon="heroicons:x-mark" className="w-[14px] h-[14px]" />
              <div className="ml-[5px] text-[14px] leading-[16px] text-[#161616]">
                Must have at least one symbol (!, ?, @, $, #, &)
              </div>
            </div>
            <div className="flex flex-row items-center">
              <Icon icon="heroicons:x-mark" className="w-[14px] h-[14px]" />
              <div className="ml-[5px] text-[14px] leading-[16px] text-[#161616]">
                Must have at least one number
              </div>
            </div>
            <div className="flex flex-row items-center">
              <Icon icon="carbon:checkmark" className="w-[14px] h-[14px]" />
              <div className="ml-[5px] text-[14px] leading-[16px] text-[#161616]">
                Must be at least on upper and lower letter
              </div>
            </div>
          </div>

          <div className="mt-[10px] flex flex-row items-center w-full">
            {!showPassword ? (
              sameBool ? (
                <input
                  type="password"
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#18C07A] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              ) : (
                <input
                  type="password"
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#FF2C20] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              )
            ) : sameBool ? (
              <input
                type="text"
                className="w-full h-[56px] rounded-xl border-[1px] border-[#18C07A] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className="w-full h-[56px] rounded-xl border-[1px] border-[#FF2C20] bg-[#E8ECF4] px-6 py-4 text-[#8391A1] text-[17px] lading-[18.75px]"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            )}
            <Icon
              icon="iconoir:eye"
              className="w-[22px] h-[22px] ml-2"
              onClick={togglePasswordVisibility}
            />
          </div>

          {sameBool ? (
            <div className="mt-[5px] text-[#18C07A] text-[14px] leading-[24px]">
              * Password missmatch
            </div>
          ) : (
            <div className="mt-[5px] text-[#FF2C20] text-[14px] leading-[24px]">
              * Password missmatch
            </div>
          )}

          <div className="mt-[20px] flex justify-center">
            <button className="w-full h-[50px] rounded-xl bg-black text-white text-[18px]">
              Sign In
            </button>
          </div>
          <div className="mt-[10px] text-[16px] leading-[22px] text-[#161616] text-center">
            Forgot password?
          </div>
          <div className="mt-[40px] text-[#8391A1] text-[16px] leading-[22px] text-center">
            Don't have account? <span className="text-[#161616]">Register</span>
          </div>
        </div>
      </div>
    </div>
  );
}
