import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function Component({
  name,
  handleMoveListElem,
  isFirstListElem,
  isLastListElem,
  deleteFunc,
}: any) {
  const { t, i18n } = useTranslation();

  return (
    <Menu>
      <MenuHandler>
        <img
          src="/images/profile_info/menu_dots.svg"
          alt="menu_dots"
          className="w-[24px] h-[24px]"
        />
      </MenuHandler>
      <MenuList
        className={`mr-[10px] ${i18n.language === "ru-RU" ? "w-[260px]" : "w-[228px]"} rounded-[15px] font-manrope`}
      >
        <MenuItem
          disabled={isFirstListElem(name)}
          onClick={() => {
            handleMoveListElem(name, "move_to_top");
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-[#161616] text-[14px] leading-[22px]">
              {t("move_to_top")}
            </div>
            <img
              src="/images/profile/double_top_arrow.svg"
              alt=""
              className="w-[24px] h-[24px]"
            />
          </div>
        </MenuItem>
        {/* <hr className="border-gray-300" /> */}
        <MenuItem
          disabled={isFirstListElem(name)}
          onClick={() => {
            handleMoveListElem(name, "move_up");
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-[#161616] text-[14px] leading-[22px]">
              {t("move_up")}
            </div>
            <img
              src="/images/profile/top_arrow.svg"
              alt=""
              className="w-[24px] h-[24px]"
            />
          </div>
        </MenuItem>
        {/* <hr className="border-gray-300" /> */}
        <MenuItem
          disabled={isLastListElem(name)}
          onClick={() => {
            handleMoveListElem(name, "move_down");
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-[#161616] text-[14px] leading-[22px]">
              {t("move_down")}
            </div>
            <img
              src="/images/profile/down_arrow.svg"
              alt=""
              className="w-[24px] h-[24px]"
            />
          </div>
        </MenuItem>
        {/* <hr className="border-gray-300" /> */}
        <MenuItem
          disabled={isLastListElem(name)}
          onClick={() => {
            handleMoveListElem(name, "move_to_bottom");
          }}
        >
          <div className="flex justify-between items-center">
            <div className="text-[#161616] text-[14px] leading-[22px]">
              {t("move_to_bottom")}
            </div>
            <img
              src="/images/profile/double_down_arrow.svg"
              alt=""
              className="w-[24px] h-[24px]"
            />
          </div>
        </MenuItem>
        {/* <hr className="border-gray-300" /> */}
        <MenuItem>
          <div
            className="flex justify-between items-center"
            onClick={(event) => {
              event.preventDefault();
              deleteFunc(name);
            }}
          >
            <div className="text-[#161616] text-[14px] leading-[22px]">
              {/* Delete */}
              {t("deactivate")}
            </div>
            <img
              src="/images/profile_info/deactivate_icon.svg"
              alt=""
              className="w-[24px] h-[24px]"
            />
          </div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
