import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/CreateBlock/Products";
import YouTube from "../../components/ui/CreateBlock/YouTube";
import LinksUrl from "../../components/ui/CreateBlock/LinksUrl";
import PDFFile from "../../components/ui/CreateBlock/PDFFile";
import Newsletter from "../../components/ui/CreateBlock/Newsletter";
import Gallery from "../../components/ui/CreateBlock/Gallery";
import UrlGalleryImage from "../../components/ui/CreateBlock/UrlGalleryImage";
import Text from "../../components/ui/CreateBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export async function sendProfileDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendInfoAboutNewBlock,
    dispatch,
    `api/block/create/`,
    "post",
    "",
    form,
    true
  )();

  // try {
  //   dispatch({ type: constants.sendInfoAboutNewBlock.load });
  //   const config = { headers: { Authorization: `Bearer ${access_token}` } };
  //   const response = await axios.post(
  //     `${constants.API}api/block/create/`,
  //     // @ts-ignore
  //     // config,
  //     form
  //     // { data: [...form] },
  //   );
  //   if (response.data) {
  //     dispatch({
  //       type: constants.sendInfoAboutNewBlock.success,
  //       payload: response.data,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.sendInfoAboutNewBlock.error,
  //       payload: response.statusText,
  //     });
  //   }
  // } catch (error: any) {
  //   if (error.response && error.response.data) {
  //     dispatch({
  //       type: constants.sendInfoAboutNewBlock.fail,
  //       payload: error.response.data.error,
  //     });
  //   } else {
  //     dispatch({
  //       type: constants.sendInfoAboutNewBlock.fail,
  //       payload: error.toString(),
  //     });
  //   }
  //   console.error("error: ", error);
  // }
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [profileName, setProfileName] = useState("EXAMPLE");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedBlockType, setSelectedBlockType] = useState("Product");
  const [selectedBlockLayout, setSelectedBlockLayout] = useState("Big block");

  const [status, setStatus] = useState("Structure");

  const [bgColor, setBgColor] = useState("#000000");
  const [textColor, setTextColor] = useState("#FFFFFF");

  const [data, setData] = useState([]);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const navigate = useNavigate();

  // Products ---------------------------------------------------------------

  const [listOfProducts, setListOfProducts] = useState([]);

  // useEffect(() => {
  //   console.log("listOfProducts", listOfProducts);
  // }, [listOfProducts]);

  const searchItemInListOfProducts = (itemId: any) => {
    // @ts-ignore
    return listOfProducts.some((obj) => obj.id === itemId);
  };

  const deleteItemInListOfProducts = (itemId: any) => {
    // @ts-ignore
    const newArray = listOfProducts.filter((obj) => obj.id !== itemId);
    setListOfProducts(newArray);
  };

  const setDescriptionToItemInListOfProducts = (
    itemId: any,
    description: any
  ) => {
    // @ts-ignore
    const newArray = structuredClone(listOfProducts);
    for (let i = 0; i < newArray.length; i++) {
      // @ts-ignore
      if (newArray[i].id === itemId) {
        // @ts-ignore
        newArray[i].description = description;
      }
    }
    setListOfProducts(newArray);
  };

  const addItemToListOfProducts = (newItem: any) => {
    // @ts-ignore
    if (!searchItemInListOfProducts(newItem.id)) {
      const newList: any = [...listOfProducts, newItem];
      setListOfProducts(newList);
    }
  };

  const setItemToListOfProducts = (newItem: any) => {
    // @ts-ignore
    const newList: any = [newItem];
    setListOfProducts(newList);
  };

  const searchIndexListOfProducts = (itemId: any) => {
    return (
      listOfProducts.findIndex(
        //@ts-ignore
        (item) => item.id === itemId
      ) + 1
    );
  };

  // YouTube ---------------------------------------------------------------

  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState("");

  const handleChangeYoutubeUrl = (newVal: any) => {
    setYoutubeVideoUrl(newVal);
  };

  // URL/Links ---------------------------------------------------------------

  const [urlLinks, setUrlLinks] = useState({
    url: "",
    title: "",
    description: "",
  });
  const [messageImageLinksUrl, setMessageImageLinksUrl] = useState("");
  const [imageLinksUrl, setImageLinksUrl] = useState(null);

  const handleChangeUrlLinks = (
    urlNew: any,
    titleNew: any,
    descriptionNew: any,
    key: any
  ) => {
    let newData = structuredClone(urlLinks);

    if (key === "url") {
      newData.url = urlNew;
    }

    if (key === "title") {
      newData.title = titleNew;
    }

    if (key === "description") {
      newData.description = descriptionNew;
    }

    setUrlLinks(newData);
  };

  const validateFileTypeUrlLinks = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeUrlLinks = (file: any) => {
    return file.size <= constants.MaxSizeImageLinksUrl * 1024 * 1024;
  };

  const handleImageLinksUrlChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageImageLinksUrl("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileTypeUrlLinks(file)) {
    //     setMessageImageLinksUrl(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSizeUrlLinks(file)) {
    //     setMessageImageLinksUrl(
    //       `File size exceeds ${constants.MaxSizeImageLinksUrl}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // @ts-ignore
    //       setImageLinksUrl(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageImageLinksUrl("");
    //   }
    // }

    setImageLinksUrl(fileStr);
  };

  const handleButtonClickImageLinksUrl = () => {
    //@ts-ignore
    document.getElementById("imageLinksUrlInput").click();
  };

  // PDF File ---------------------------------------------------------------

  const [pdfFile, setPdfFile] = useState({
    title: "",
    description: "",
  });
  const [messageImagePdfFile, setMessageImagePdfFile] = useState("");
  const [imagePdfFile, setImagePdfFile] = useState(null);

  const [messageFilePdfFile, setMessageFilePdfFile] = useState("");
  const [filePdfFile, setFilePdfFile] = useState(null);
  const [filePdfFileName, setFilePdfFileName] = useState("");

  const handleChangePdfFile = (
    titleNew: any,
    descriptionNew: any,
    key: any
  ) => {
    let newData = structuredClone(pdfFile);

    if (key === "title") {
      newData.title = titleNew;
    }

    if (key === "description") {
      newData.description = descriptionNew;
    }

    setPdfFile(newData);
  };

  const validateFileTypeImagePdfFile = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeImagePdfFile = (file: any) => {
    return file.size <= constants.MaxSizeImagePdfFile * 1024 * 1024;
  };

  const handleImagePdfFileChange = (fileStr: any) => {
    // const files = event.target.files;

    // if (files.length > 1) {
    //   setMessageImagePdfFile("Please select only one file.");
    //   return;
    // }

    // const file = files[0];

    // if (file) {
    //   if (!validateFileTypeImagePdfFile(file)) {
    //     setMessageImagePdfFile(
    //       "Unsupported file format. Please select an image (jpg, jpeg, png)."
    //     );
    //   } else if (!validateFileSizeImagePdfFile(file)) {
    //     setMessageImagePdfFile(
    //       `File size exceeds ${constants.MaxSizeImagePdfFile}MB limit. Please select a smaller file.`
    //     );
    //   } else {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       const base64String = reader.result;
    //       // @ts-ignore
    //       setImagePdfFile(base64String);
    //     };
    //     reader.readAsDataURL(file);
    //     setMessageImagePdfFile("");
    //   }
    // }

    setImagePdfFile(fileStr);
  };

  const handleButtonClickImagePdfFileUrl = () => {
    //@ts-ignore
    document.getElementById("imagePdfFileInput").click();
  };

  // PDF File

  const validateFileTypeFilePdfFile = (file: any) => {
    return file.type.startsWith("image/");
  };

  const validateFileSizeFilePdfFile = (file: any) => {
    return file.size <= constants.MaxSizeFilePdfFile * 1024 * 1024;
  };

  const handleFilePdfFileChange = (event: any) => {
    const files = event.target.files;

    if (files.length > 1) {
      // setMessageFilePdfFile("Please select only one file.");
      setMessageFilePdfFile(t("pdf_file_upload_error1"));
      return;
    }

    const file = files[0];

    if (file) {
      // if (!validateFileTypeFilePdfFile(file)) {
      //   setMessageImagePdfFile(
      //     "Unsupported file format. Please select an image (jpg, jpeg, png)."
      //   );
      // } else
      if (!validateFileSizeFilePdfFile(file)) {
        setMessageFilePdfFile(
          t("pdf_file_upload_error2")
          // `File size exceeds ${constants.MaxSizeFilePdfFile}MB limit. Please select a smaller file.`
        );
      } else {
        setFilePdfFileName(file.name);
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   const base64String = reader.result;
        //   // @ts-ignore
        //   setFilePdfFile(base64String);
        // };
        // reader.readAsDataURL(file);
        setFilePdfFile(file);
        setMessageFilePdfFile("");
      }
    }
  };

  const handleButtonClickFilePdfFileUrl = () => {
    //@ts-ignore
    document.getElementById("filePdfFileInput").click();
  };

  const changeMessageFilePdfFile = (newVal: string) => {
    setMessageFilePdfFile(newVal);
  };

  // Newsletter ---------------------------------------------------------------

  const [newsletterTitle, setNewsletterTitle] = useState("");

  const listOfNewsletterEmail = [
    "Example1@mail.com",
    "Example2@mail.com",
    "Example3@mail.com",
    "Example4@mail.com",
    "Example5@mail.com",
  ];

  const handleChangeNewsletterTitle = (titleNew: any) => {
    setNewsletterTitle(titleNew);
  };

  const handleCopyNewsletterEmails = () => {
    const valStr = listOfNewsletterEmail.join(" ");
    navigator.clipboard.writeText(valStr);
  };

  // Gallery ---------------------------------------------------------------

  const [listOfGalleryImages, setListOfGalleryImages] = useState([]);
  const [urlGalleryImage, setUrlGalleryImage] = useState("");

  const handleAddGalleryImage = (fileStr: any) => {
    // setNewsletterTitle(titleNew);
    const listOfGalleryImagesTmp = structuredClone(listOfGalleryImages);
    // @ts-ignore
    listOfGalleryImagesTmp.push({
      id: listOfGalleryImages.length + 1,
      file: fileStr,
    });

    setListOfGalleryImages(listOfGalleryImagesTmp);
  };

  const handleDeleteGalleryImage = (imageId: number) => {
    let listOfGalleryImagesTmp = [];
    let j = 0;

    for (let i = 0; i < listOfGalleryImages.length; i++) {
      // @ts-ignore
      if (listOfGalleryImages[i].id !== imageId) {
        j += 1;
        listOfGalleryImagesTmp.push({
          id: j,
          // @ts-ignore
          file: listOfGalleryImages[i].file,
        });
      }
    }
    // @ts-ignore
    setListOfGalleryImages(listOfGalleryImagesTmp);
  };

  const handleChangeListGalleryImage = (listNew: any) => {
    setListOfGalleryImages(listNew);
  };

  const handleChangeUrlGalleryImage = (newVal: any) => {
    setUrlGalleryImage(newVal);
  };

  const handleSetUrlGalleryImage = (fileStr: any) => {
    const listOfGalleryImagesTmp = [
      {
        id: 1,
        file: fileStr,
      },
    ];

    // @ts-ignore
    setListOfGalleryImages(listOfGalleryImagesTmp);
  };

  const handleDeleteUrlGalleryImage = (fileStr: any) => {
    // @ts-ignore
    setListOfGalleryImages([]);
  };

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // Text ---------------------------------------------------------------

  const [textBlock, setTextBlock] = useState({ title: "", mainText: "" });

  const handleChangeTextBlock = (key: string, textNew: string) => {
    let oldTextBlock = structuredClone(textBlock);

    if (key === "title") {
      oldTextBlock.title = textNew;
    }

    if (key === "mainText") {
      oldTextBlock.mainText = textNew;
    }

    setTextBlock(oldTextBlock);
  };

  useEffect(() => {
    if (selectedBlockType !== "Product") {
      setListOfProducts([]);
    }
    if (selectedBlockType !== "YouTube video") {
      setYoutubeVideoUrl("");
    }
    if (selectedBlockType !== "Links/URL") {
      setUrlLinks({
        url: "",
        title: "",
        description: "",
      });
      setImageLinksUrl(null);
      setMessageImageLinksUrl("");
    }
    if (selectedBlockType !== "PDF file") {
      setPdfFile({
        title: "",
        description: "",
      });
      setImagePdfFile(null);
      setMessageImagePdfFile("");

      setFilePdfFile(null);
      setMessageFilePdfFile("");
      setFilePdfFileName("");
    }
    if (selectedBlockType !== "Newsletter") {
      setNewsletterTitle("");
    }
    if (selectedBlockType !== "Gallery") {
      setListOfGalleryImages([]);
    }
    if (selectedBlockType !== "Text") {
      setTextBlock({ title: "", mainText: "" });
    }
  }, [selectedBlockType]);

  useEffect(() => {
    if (selectedBlockType === "Product") {
      setListOfProducts([]);
    }
  }, [selectedBlockLayout]);

  async function getBlockInfoFunc() {
    await actions.constructorAction(
      constants.allListOfProducts,
      dispatch,
      "api/product/all/",
      "get",
      "",
      {},
      true
    )();
  }

  const allListOfProductsStore = useSelector(
    (state: any) => state.allListOfProductsStore
  );

  useEffect(() => {
    // console.log("allListOfProductsStore", allListOfProductsStore);
    if (
      allListOfProductsStore &&
      allListOfProductsStore.data &&
      allListOfProductsStore.data.response
    ) {
      const response = allListOfProductsStore.data.response;
      let newData = [];

      for (let i = 0; i < response.length; i++) {
        newData.push({
          id: response[i].id,
          currencySymbol: response[i].currencySymbol,
          name: response[i].name,
          price: response[i].price,
          thumbnail: response[i].thumbnail,
          type: response[i].type,
        });
      }

      // @ts-ignore
      setData(newData);
    }
  }, [allListOfProductsStore]);

  useEffect(() => {
    const createBlockDataStorage = utils.LocalStorage.get("CreateBlockData");
    if (createBlockDataStorage) {
      if (createBlockDataStorage.listOfProducts) {
        setListOfProducts(createBlockDataStorage.listOfProducts);
      }
      if (createBlockDataStorage.youtubeVideoUrl) {
        setYoutubeVideoUrl(createBlockDataStorage.youtubeVideoUrl);
      }
      if (createBlockDataStorage.urlLinks) {
        setUrlLinks(createBlockDataStorage.urlLinks);
      }
      if (createBlockDataStorage.imageLinksUrl) {
        setImageLinksUrl(createBlockDataStorage.imageLinksUrl);
      }
      if (createBlockDataStorage.pdfFile) {
        setPdfFile(createBlockDataStorage.pdfFile);
      }
      if (createBlockDataStorage.imagePdfFile) {
        setImagePdfFile(createBlockDataStorage.imagePdfFile);
      }
      // if (createBlockDataStorage.filePdfFile) {
      //   setFilePdfFile(createBlockDataStorage.filePdfFile);
      // }
      if (createBlockDataStorage.filePdfFileName) {
        setFilePdfFileName(createBlockDataStorage.filePdfFileName);
      }
      if (createBlockDataStorage.listOfGalleryImages) {
        setListOfGalleryImages(createBlockDataStorage.listOfGalleryImages);
      }
      // if (createBlockDataStorage.newsletterTitle) {
      //   setNewsletterTitle(createBlockDataStorage.newsletterTitle);
      // }
      if (createBlockDataStorage.urlGalleryImage) {
        setUrlGalleryImage(createBlockDataStorage.urlGalleryImage);
      }
      if (createBlockDataStorage.textBlock) {
        setTextBlock(createBlockDataStorage.textBlock);
      }
      if (createBlockDataStorage.selectedBlockType) {
        setSelectedBlockType(createBlockDataStorage.selectedBlockType);
      }
      if (createBlockDataStorage.selectedBlockLayout) {
        setSelectedBlockLayout(createBlockDataStorage.selectedBlockLayout);
      }
      if (createBlockDataStorage.status) {
        setStatus(createBlockDataStorage.status);
      }
      if (createBlockDataStorage.bgColor) {
        setBgColor(createBlockDataStorage.bgColor);
      }
      if (createBlockDataStorage.textColor) {
        setTextColor(createBlockDataStorage.textColor);
      }
    }

    getBlockInfoFunc();

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    utils.LocalStorage.set("CreateBlockData", {
      listOfProducts: listOfProducts,
      youtubeVideoUrl: youtubeVideoUrl,
      urlLinks: urlLinks,
      // imageLinksUrl: imageLinksUrl,
      pdfFile: pdfFile,
      // imagePdfFile: imagePdfFile,
      // filePdfFile: filePdfFile,
      // filePdfFileName: filePdfFileName,
      // listOfGalleryImages: listOfGalleryImages,
      // newsletterTitle: newsletterTitle,
      urlGalleryImage: urlGalleryImage,
      textBlock: textBlock,
      selectedBlockType: selectedBlockType,
      selectedBlockLayout: selectedBlockLayout,
      status: status,
      bgColor: bgColor,
      textColor: textColor,
    });
  }, [
    listOfProducts,
    youtubeVideoUrl,
    urlLinks,
    // imageLinksUrl,
    pdfFile,
    // imagePdfFile,
    // filePdfFile,
    // filePdfFileName,
    // listOfGalleryImages,
    // newsletterTitle,
    urlGalleryImage,
    textBlock,
    selectedBlockType,
    selectedBlockLayout,
    status,
    bgColor,
    textColor,
  ]);

  const dispatch = useDispatch();

  const sendInfoAboutNewBlockStore = useSelector(
    (state: any) => state.sendInfoAboutNewBlockStore
  );

  const handleSendProfileData = (blockType: string) => {
    let newBlockLayout = "";

    if (selectedBlockLayout === "Big block") {
      newBlockLayout = "big_block";
    } else if (selectedBlockLayout === "Side slider") {
      newBlockLayout = "side_slider";
    } else if (selectedBlockLayout === "Tile") {
      newBlockLayout = "tile";
    } else if (selectedBlockLayout === "Text box") {
      newBlockLayout = "text_box";
    }

    const formData = new FormData();

    if (imageLinksUrl) {
      // @ts-ignore
      const base64String = imageLinksUrl.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = imageLinksUrl
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      // const fileBlob = new Blob([byteArray], { type: "image/jpeg" });
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append(
        "image_links_url",
        fileBlob,
        `image_links_url.${fileType}`
      );
    }

    if (imagePdfFile) {
      // @ts-ignore
      const base64String = imagePdfFile.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
      const fileType = imagePdfFile
        // @ts-ignore
        .split(",")[0]
        .split("data:")[1]
        .split(";base64")[0];

      // Преобразовать строку base64 в бинарные данные
      // @ts-ignore
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Создать объект Blob из бинарных данных
      const fileBlob = new Blob([byteArray], { type: fileType });

      // @ts-ignore
      formData.append("image_pdf_file", fileBlob, `image_pdf_file.${fileType}`);
    }

    if (filePdfFile) {
      formData.append("file_pdf_file", filePdfFile);
    }

    if (listOfGalleryImages && listOfGalleryImages.length > 0) {
      for (let i = 0; i < listOfGalleryImages.length; i++) {
        // @ts-ignore
        const base64String = listOfGalleryImages[i].file.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = listOfGalleryImages[i].file
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        // const fileBlob = new Blob([byteArray], { type: "image/jpeg" });
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append(
          //@ts-ignore
          `gallery_image${listOfGalleryImages[i].id}`,
          fileBlob,
          //@ts-ignore
          `gallery_image${listOfGalleryImages[i].id}.${fileType}`
        );
      }
    }

    let dataForm = {};

    if (blockType === "Product") {
      // sendProfileDataFunc(dispatch, "", {
      //   block_type: "product",
      //   block_layout: newBlockLayout,
      //   section_numeration: params.sectionNum,
      //   data: listOfProducts,
      // });
      dataForm = {
        block_type: "product",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: listOfProducts,
      };
    }
    if (blockType === "YouTube video") {
      dataForm = {
        block_type: "youtube_video",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: youtubeVideoUrl,
      };
    }
    if (blockType === "Links/URL") {
      let tmpUrlLinks = structuredClone(urlLinks);

      if (tmpUrlLinks.url.includes("https://")) {
        const newUrl = tmpUrlLinks.url.replace("https://", "");
        tmpUrlLinks.url = newUrl;
      }

      dataForm = {
        block_type: "links_url",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: tmpUrlLinks,
      };
    }
    if (blockType === "PDF file") {
      dataForm = {
        block_type: "pdf_file",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: pdfFile,
      };
    }
    // if (blockType === "Newsletter") {
    //   dataForm = {
    //     block_type: "newsletter",
    //     block_layout: newBlockLayout,
    //     // section_numeration: params.sectionNum,
    //     data: {
    //       title: newsletterTitle,
    //       list_of_emails: [],
    //     },
    //   };
    // }

    if (blockType === "Gallery") {
      if (newBlockLayout === "side_slider") {
        dataForm = {
          block_type: "gallery",
          block_layout: newBlockLayout,
          // section_numeration: params.sectionNum,
          data: {
            length: listOfGalleryImages.length,
          },
        };
      } else {
        let tmpUrlGalleryImage = structuredClone(urlGalleryImage);

        if (tmpUrlGalleryImage.includes("https://")) {
          const newUrl = tmpUrlGalleryImage.replace("https://", "");
          tmpUrlGalleryImage = newUrl;
        }

        dataForm = {
          block_type: "gallery",
          block_layout: newBlockLayout,
          // section_numeration: params.sectionNum,
          data: {
            length: listOfGalleryImages.length,
            url: tmpUrlGalleryImage,
          },
        };
      }
    }

    if (blockType === "Text") {
      dataForm = {
        block_type: "text",
        block_layout: newBlockLayout,
        // section_numeration: params.sectionNum,
        data: textBlock,
      };
    }

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    sendProfileDataFunc(dispatch, "", formData);
  };

  useEffect(() => {
    // console.log("sendInfoAboutNewBlockStore", sendInfoAboutNewBlockStore);
    if (sendInfoAboutNewBlockStore && sendInfoAboutNewBlockStore.data) {
      navigate("/profile");
      utils.LocalStorage.remove("CreateBlockData");
    }
  }, [sendInfoAboutNewBlockStore]);

  if (sendInfoAboutNewBlockStore && sendInfoAboutNewBlockStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendInfoAboutNewBlockStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        {status === "Structure" && (
          <div className="font-manrope px-[24px] w-screen max-w-md">
            <BurgerMenu
              isOpen={burgerMenuOpen}
              profileName={profileName}
              toggleBurgerMenu={toggleBurgerMenu}
            />
            <div className="flex flex-col">
              <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                <div className="h-[56px] flex justify-between items-center">
                  <button
                    onClick={() => {
                      utils.LocalStorage.remove("CreateBlockData");
                      navigate("/profile");
                    }}
                  >
                    {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                    <div className="p-[10px]">
                      <img
                        src="/images/back_arrow.svg"
                        alt="back_arrow.svg"
                        className="w-[7px] h-[14px]"
                      />
                    </div>
                  </button>
                  <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                    {t("create_block")}
                  </div>
                  <button onClick={toggleBurgerMenu}>
                    {!burgerMenuOpen ? (
                      <Icon
                        icon="iconamoon:menu-burger-horizontal-light"
                        className="w-[24px] h-[26px]"
                      />
                    ) : (
                      <span> </span>
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-[61px]">
                <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                  <div
                    className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                    onClick={() => {
                      changeStatus("Structure");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("structure")}
                    </div>
                  </div>
                  <div
                    className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                    onClick={() => {
                      changeStatus("Content");
                    }}
                  >
                    <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                      {t("content")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  {t("select_block_type")}
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="grid grid-cols-2 gap-2.5">
                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("Product");
                      setSelectedBlockLayout("Big block");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("product")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Product"}
                      onChange={(e) => {
                        setSelectedBlockType("Product");
                        setSelectedBlockLayout("Big block");
                      }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("YouTube video");
                      setSelectedBlockLayout("Big block");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("youtube_video")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "YouTube video"}
                      onChange={(e) => {
                        setSelectedBlockType("YouTube video");
                        setSelectedBlockLayout("Big block");
                      }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("Links/URL");
                      setSelectedBlockLayout("Tile");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("links_url")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Links/URL"}
                      onChange={(e) => {
                        setSelectedBlockType("Links/URL");
                        setSelectedBlockLayout("Tile");
                      }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("PDF file");
                      setSelectedBlockLayout("Tile");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("pdf_file")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "PDF file"}
                      onChange={(e) => {
                        setSelectedBlockType("PDF file");
                        setSelectedBlockLayout("Tile");
                      }}
                    />
                  </div>

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("Gallery");
                      setSelectedBlockLayout("Big block");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("image")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Gallery"}
                      onChange={(e) => {
                        setSelectedBlockType("Gallery");
                        setSelectedBlockLayout("Big block");
                      }}
                    />
                  </div>

                  {/* <div
                    className="w-full h-[44px] border-[1px] border-[#E8ECF4] rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      // setSelectedBlockType("API block");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[14px] leading-[20px] font-medium text-[#E8ECF4]">
                        API block
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      disabled
                      checked={selectedBlockType === "API block"}
                      onChange={(e) => {
                        setSelectedBlockType("API block");
                      }}
                    />
                  </div> */}

                  <div
                    className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center"
                    onClick={() => {
                      setSelectedBlockType("Text");
                      setSelectedBlockLayout("Text box");
                    }}
                  >
                    <div className="pl-[15px]">
                      <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                        {t("text")}
                      </div>
                    </div>
                    <Radio
                      name="block_type"
                      containerProps={{
                        className: "",
                      }}
                      crossOrigin={""}
                      checked={selectedBlockType === "Text"}
                      onChange={(e) => {
                        setSelectedBlockType("Text");
                        setSelectedBlockLayout("Text box");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  {t("select_block_layout")}
                </div>
              </div>

              <div className="mt-[15px]">
                {selectedBlockType === "Product" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>

                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "YouTube video" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "Links/URL" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "PDF file" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "Gallery" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        // checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[15px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        // checked={selectedBlockLayout === "Text"}
                        onChange={(e) => {
                          // setSelectedBlockLayout("Text");
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedBlockType === "Text" && (
                  <div className="grid grid-cols-2 gap-2.5">
                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Big block");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/big_block.svg"
                            alt="big_block.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("big_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Big block"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Big block");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Side slider");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/side_slider.svg"
                            alt="side_slider.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("side_slider")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        disabled
                        checked={selectedBlockLayout === "Side slider"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Side slider");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0 opacity-30">
                          <img
                            src="/images/create_block/tile.svg"
                            alt="tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#E8ECF4]">
                            {t("tile")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        disabled
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Tile"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Tile");
                        }}
                      />
                    </div>

                    <div
                      className="w-full h-[44px] shadow-custom rounded-[10px] flex justify-between items-center pl-[5px]"
                      onClick={() => {
                        // setSelectedBlockLayout("Tile");
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/create_block/text_tile.svg"
                            alt="text_tile.svg"
                            className="w-[36px] h-[36px]"
                          />
                        </div>
                        <div className="pl-[10px]">
                          <div className="text-[16px] leading-[20px] font-medium text-[#161616]">
                            {t("title_block")}
                          </div>
                        </div>
                      </div>
                      <Radio
                        name="block_layout"
                        containerProps={{
                          className: "",
                        }}
                        crossOrigin={""}
                        checked={selectedBlockLayout === "Text box"}
                        onChange={(e) => {
                          setSelectedBlockLayout("Text box");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="mt-[25px]">
                <div className="text-[18px] text-[#161616] leading-[20px] font-semibold">
                  Button colors
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex space-x-3 items-center">
                    <div className="text-[#161616] text-[16px] leading-[19.12px]">
                      Background color
                    </div>
                    <input
                      type="color"
                      value={bgColor}
                      className="w-[30px] h-[30px]"
                      onChange={(e) => {
                        setBgColor(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex space-x-3 items-center">
                    <div className="text-[#161616] text-[16px] leading-[19.12px]">
                      Text color
                    </div>
                    <input
                      type="color"
                      value={textColor}
                      className="w-[30px] h-[30px]"
                      onChange={(e) => {
                        setTextColor(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div> */}

              <div className="mt-[131px]">
                <div
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
                  onClick={() => {
                    changeStatus("Content");
                  }}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("continue")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {status === "Content" && (
          <div>
            {selectedBlockType === "Product" && (
              <Products
                profileName={profileName}
                changeStatus={changeStatus}
                data={data}
                listOfProducts={listOfProducts}
                addItemToListOfProducts={addItemToListOfProducts}
                searchIndexListOfProducts={searchIndexListOfProducts}
                searchItemInListOfProducts={searchItemInListOfProducts}
                deleteItemInListOfProducts={deleteItemInListOfProducts}
                handleSendProfileData={handleSendProfileData}
                setDescriptionToItemInListOfProducts={
                  setDescriptionToItemInListOfProducts
                }
                selectedBlockLayout={selectedBlockLayout}
                setItemToListOfProducts={setItemToListOfProducts}
              />
            )}

            {selectedBlockType === "YouTube video" && (
              <YouTube
                profileName={profileName}
                changeStatus={changeStatus}
                handleChangeYoutubeUrl={handleChangeYoutubeUrl}
                youtubeVideoUrl={youtubeVideoUrl}
                handleSendProfileData={handleSendProfileData}
              />
            )}

            {selectedBlockType === "Links/URL" && (
              <LinksUrl
                profileName={profileName}
                changeStatus={changeStatus}
                urlLinks={urlLinks}
                imageLinksUrl={imageLinksUrl}
                notify={notify}
                handleImageLinksUrlChange={handleImageLinksUrlChange}
                handleButtonClickImageLinksUrl={handleButtonClickImageLinksUrl}
                messageImageLinksUrl={messageImageLinksUrl}
                handleChangeUrlLinks={handleChangeUrlLinks}
                handleSendProfileData={handleSendProfileData}
              />
            )}

            {selectedBlockType === "PDF file" && (
              <PDFFile
                profileName={profileName}
                changeStatus={changeStatus}
                pdfFile={pdfFile}
                notify={notify}
                handleChangePdfFile={handleChangePdfFile}
                handleSendProfileData={handleSendProfileData}
                handleButtonClickImagePdfFileUrl={
                  handleButtonClickImagePdfFileUrl
                }
                handleImagePdfFileChange={handleImagePdfFileChange}
                messageImagePdfFile={messageImagePdfFile}
                imagePdfFile={imagePdfFile}
                handleFilePdfFileChange={handleFilePdfFileChange}
                handleButtonClickFilePdfFileUrl={
                  handleButtonClickFilePdfFileUrl
                }
                messageFilePdfFile={messageFilePdfFile}
                filePdfFile={filePdfFile}
                filePdfFileName={filePdfFileName}
                changeMessageFilePdfFile={changeMessageFilePdfFile}
              />
            )}

            {/* {selectedBlockType === "Newsletter" && (
              <Newsletter
                profileName={profileName}
                changeStatus={changeStatus}
                newsletterTitle={newsletterTitle}
                listOfNewsletterEmail={listOfNewsletterEmail}
                handleChangeNewsletterTitle={handleChangeNewsletterTitle}
                handleCopyNewsletterEmails={handleCopyNewsletterEmails}
                handleSendProfileData={handleSendProfileData}
              />
            )} */}

            {selectedBlockType === "Gallery" && (
              <div>
                {selectedBlockLayout === "Side slider" ? (
                  <Gallery
                    profileName={profileName}
                    changeStatus={changeStatus}
                    handleChangeListGalleryImage={handleChangeListGalleryImage}
                    handleAddGalleryImage={handleAddGalleryImage}
                    handleDeleteGalleryImage={handleDeleteGalleryImage}
                    notify={notify}
                    listOfGalleryImages={listOfGalleryImages}
                    handleSendProfileData={handleSendProfileData}
                  />
                ) : (
                  <UrlGalleryImage
                    profileName={profileName}
                    selectedBlockLayout={selectedBlockLayout}
                    changeStatus={changeStatus}
                    urlGalleryImage={urlGalleryImage}
                    handleChangeUrlGalleryImage={handleChangeUrlGalleryImage}
                    notify={notify}
                    listOfGalleryImages={listOfGalleryImages}
                    handleSetUrlGalleryImage={handleSetUrlGalleryImage}
                    handleDeleteUrlGalleryImage={handleDeleteUrlGalleryImage}
                    handleSendProfileData={handleSendProfileData}
                  />
                )}
              </div>
            )}

            {selectedBlockType === "Text" && (
              <Text
                profileName={profileName}
                changeStatus={changeStatus}
                textBlock={textBlock}
                handleChangeTextBlock={handleChangeTextBlock}
                handleSendProfileData={handleSendProfileData}
              />
            )}
          </div>
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </utils.AuthGuard>
  );
}
