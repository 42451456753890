import React, { useEffect, useCallback, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as constants from "../../constants";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import GallerySlider from "../Profile/Gallery";
import heic2any from "heic2any";

export default function Page({
  profileName,
  changeStatus,
  handleChangeListGalleryImage,
  handleAddGalleryImage,
  handleDeleteGalleryImage,
  notify,
  listOfGalleryImages,
  handleSendProfileData,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  // useEffect(() => {
  //   console.log("listOfGalleryImages", listOfGalleryImages);
  // }, [listOfGalleryImages]);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);
  const [imageType, setImageType] = useState(null);

  // useEffect(() => {
  //   console.log("imageType", imageType);
  // }, [imageType]);

  // const openModal = () => setModalIsOpen(true);

  // const closeModal = () => {
  //   setImage(null); // Сбрасываем выбранное изображение при закрытии модального окна
  //   setModalIsOpen(false);
  // };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];
  //   const isValidImage = file.type.startsWith("image/");

  //   if (!isValidImage) {
  //     notify(
  //       t("image_upload_error1")
  //       // "Unsupported file format. Please select an image (jpg, jpeg, png)."
  //     );
  //     // setError(
  //     //   //@ts-ignore
  //     //   "Unsupported file format. Please select an image (jpg, jpeg, png)."
  //     // );
  //     return;
  //   }

  //   if (file.size > constants.MaxSizeAvatar * 1024 * 1024) {
  //     notify(
  //       t("image_upload_error2")
  //       // `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
  //     );
  //     // setError(
  //     //   //@ts-ignore
  //     //   `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
  //     // );
  //     return;
  //   }

  //   if (file) {
  //     if (file.type === "image/heic" || file.type === "image/heif") {
  //       // Обработка HEIC или HEIF файла
  //       convertHeicOrHeifToBase64(file);
  //     } else if (file.size <= 5 * 1024 * 1024) {
  //       // Обработка обычного изображения (до 5 МБ)
  //       convertImageToBase64(file);
  //     } else {
  //       alert("Размер файла превышает 5 МБ.");
  //     }
  //   }

  //   // const reader = new FileReader();
  //   // reader.onload = () => {
  //   //   //@ts-ignore
  //   //   setImage(reader.result);
  //   //   setImageType(file.type);
  //   //   setError(null);
  //   //   openModal();
  //   // };
  //   // reader.readAsDataURL(file);
  // }, []);

  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files); // Преобразуем FileList в массив

    files.forEach((file) => {
      if (file) {
        //@ts-ignore
        const isValidImage = file.type.startsWith("image/");

        if (!isValidImage) {
          notify(t("image_upload_error1"));
          return;
        }
        //@ts-ignore
        if (file.size > 5 * 1024 * 1024) {
          notify(t("image_upload_error2"));
          return;
        }
        //@ts-ignore
        if (file.type === "image/heic" || file.type === "image/heif") {
          // Обработка HEIC или HEIF файла
          convertHeicOrHeifToBase64(file);
        } else {
          // Обработка обычного изображения (до 5 МБ)
          convertImageToBase64(file);
        }
      }
    });
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleAddProductImageThere(result);
      // setImageType(file.type);
      // //@ts-ignore
      // setImage(result); // Устанавливаем изображение для обрезки
      // setError(null);
      // openModal();
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleAddProductImageThere(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("galleryImage").click();
  };

  const [listOfImages, setListOfImages] = useState(listOfGalleryImages);

  const handleAddProductImageThere = (fileStr: any) => {
    setListOfImages(
      //@ts-ignore
      (prevImages: any) => [
        ...prevImages,
        { id: prevImages.length + 1, file: fileStr, delete: false },
      ]
    );
  };

  useEffect(() => {
    // console.log("listOfImages", listOfImages);
    handleChangeListGalleryImage(listOfImages);
  }, [listOfImages]);

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperRef.current.cropper;
  //   // Получаем обрезанное изображение в формате PNG
  //   //@ts-ignore
  //   cropper.getCroppedCanvas({ width: 300, height: 400 }).toBlob((blob) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       //@ts-ignore
  //       setCroppedImage(base64data);

  //       closeModal();
  //     };
  //     reader.readAsDataURL(blob);
  //     // }, "image/png");
  //   }, imageType);
  // };

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   onDrop,
  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: false, // Разрешаем выбор только одного файла
  //   //@ts-ignore
  //   accept: "image/*", // Разрешаем только изображения
  // });

  // // const getCroppedImage = () => {
  // //   //@ts-ignore
  // //   const cropper = cropperRef.current.cropper;
  // //   const canvas = cropper.getCroppedCanvas({ width: 300, height: 400 }); // 4:3 aspect ratio

  // //   // const mimeType = imageType || "image/jpeg"; // используем оригинальный тип
  // //   setCroppedImage(canvas.toDataURL("image/png"));
  // //   // setCroppedImage(canvas.toDataURL(mimeType, 1.0));
  // //   closeModal();
  // // };

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperRef.current.cropper;

  //   // Получаем обрезанное изображение в формате PNG
  //   cropper
  //     .getCroppedCanvas({ width: 300, height: 400 })
  //     .toBlob(async (blob: any) => {
  //       // Конвертируем Blob в Data URL (Base64)
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         const base64data = reader.result;
  //         //@ts-ignore
  //         setCroppedImage(base64data);

  //         closeModal();
  //       };
  //       reader.readAsDataURL(blob);
  //     }, "image/png"); // Используем формат PNG
  // };

  // useEffect(() => {
  //   if (croppedImage) {
  //     // console.log("croppedImage", croppedImage);
  //     handleAddGalleryImage(croppedImage);
  //   }
  // }, [croppedImage]);

  // const handleFileChange = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     if (file.type === "image/heic") {
  //       // Обработка HEIC файла
  //       convertHeicToBase64(file);
  //     } else if (file.size <= 5 * 1024 * 1024) {
  //       // Обработка обычного изображения (до 5 МБ)
  //       convertImageToBase64(file);
  //     } else {
  //       alert("Размер файла превышает 5 МБ.");
  //     }
  //   }
  // };

  // const convertImageToBase64 = (file: any) => {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const result = reader.result;

  //     handleAddGalleryImage(result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  // const convertHeicToBase64 = async (file: any) => {
  //   try {
  //     const convertedBlob = await heic2any({
  //       blob: file,
  //       toType: "image/png",
  //     });
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const result = reader.result;

  //       handleAddGalleryImage(result);
  //     };
  //     //@ts-ignore
  //     reader.readAsDataURL(convertedBlob);
  //   } catch (error) {
  //     console.error("Ошибка при конвертации HEIC:", error);
  //     alert("Не удалось конвертировать HEIC файл.");
  //   }
  // };

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] w-screen max-w-md pb-[20px]">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("edit_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[25px]">
          <div className="text-[#161616] text-[18px] leading-[20px] font-semibold text-start">
            {t("upload_at_least_3_images")}
          </div>
        </div>

        {listOfGalleryImages && listOfGalleryImages.length > 0 && (
          <div className="mt-[15px]">
            <div className="grid grid-cols-3 gap-2.5">
              {listOfGalleryImages.map(
                (elem: any) =>
                  !elem.delete && (
                    <div key={elem.id} className="flex flex-col">
                      {/* <img
                        src={elem.file}
                        alt="GalleryImage"
                        className="w-full h-full object-cover rounded-[10px]" w-[108px] h-[144px]
                      /> */}
                      <div className="relative w-full pt-[133.33%] overflow-hidden rounded-[10px]">
                        <img
                          src={elem.file}
                          alt={`GalleryImage${elem.id}`}
                          className="absolute inset-0 w-full h-full object-cover rounded-[10px]"
                        />
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          handleDeleteGalleryImage(elem.id);
                        }}
                      >
                        {/* <img
          src="/images/create_block/delete_gallery_image.svg"
          alt="delete.svg"
          className="w-[108px] h-[20px]"
          /> */}
                        <div className="mt-[3px] w-full text-red-500 text-[14px] border-[1px] border-red-500 text-center rounded-[4px]">
                          {t("delete")}
                        </div>
                      </div>
                    </div>
                  )
                // <div key={elem.id} className="relative">
                //   <img
                //     src={elem.file}
                //     alt="GalleryImage"
                //     className="w-[108px] h-full object-cover rounded-[10px]"
                //   />
                //   <div
                //     className="absolute top-1 right-4"
                //     onClick={() => {
                //       handleDeleteGalleryImage(elem.id);
                //     }}
                //   >
                //     <img
                //       src="/images/create_block/delete_gallery_image.svg"
                //       alt="delete.svg"
                //       className="w-[20px] h-[20px]"
                //     />
                //   </div>
                // </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-[15px]">
          <div
            className="w-full h-[50px] flex justify-center items-center rounded-[10px] p-[16px] border-[1px] border-[#161616]"
            onClick={handleButtonImageInputClick}
          >
            <span className="text-[18px] leading-[22px] text-[#161616] text-center">
              {t("add_images")}
            </span>

            <input
              id="galleryImage"
              type="file"
              multiple
              accept=".jpg,.jpeg,.png,.heic,.heif"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        {isConverting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="text-center">
              {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
              <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                Идет конвертация ...
              </div>
            </div>
          </div>
        )}

        {/* <div {...getRootProps()} className="hidden">
          <input {...getInputProps()} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          onRequestClose={closeModal}
          className="flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          appElement={document.getElementById("root")}
        >
          <div className="bg-white p-4 rounded shadow-lg">
            {image && (
              <div>
                <Cropper
                  src={image}
                  style={{ width: 300, height: 400 }}
                  //   initialAspectRatio={1}
                  //   aspectRatio={1}
                  initialAspectRatio={3 / 4}
                  aspectRatio={3 / 4}
                  viewMode={1}
                  guides={false}
                  cropBoxResizable={false}
                  dragMode="move"
                  cropBoxMovable={false}
                  ref={cropperRef}
                />
                <div className="flex justify-between mt-4 px-[30px]">
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={getCroppedImage}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("crop_image")}
                    </div>
                  </button>
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={closeModal}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("cancel")}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal> */}

        <div
          className={
            listOfGalleryImages.length > 0 ? "mt-[230px]" : "mt-[390px]"
          }
        >
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={() => {
              if (listOfGalleryImages.length < 3) {
                notify("Upload at least 3 images");
              } else {
                handleSendProfileData("Gallery");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
