import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../ui/CustomInput";
import { useTranslation } from "react-i18next";
import ErrorPage from "../ui/ErrorPage";

export async function getProfileDataAndInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getProfileDataAndInfo,
    dispatch,
    `api/profile/data_and_info_by_username/`,
    "post",
    "",
    form,
    false
  )();
}

export async function subscribeUpdatesFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.subscribeUpdates,
    dispatch,
    `api/profile/subscribe_updates/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [username, setUsername] = useState(params.userUsername);
  const [avatar, setAvatar] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSend, setIsSend] = useState(false);

  const getProfileDataAndInfoStore = useSelector(
    (state: any) => state.getProfileDataAndInfoStore
  );

  const subscribeUpdatesStore = useSelector(
    (state: any) => state.subscribeUpdatesStore
  );

  useEffect(() => {
    getProfileDataAndInfoFunc(dispatch, "", { username: params.userUsername });
  }, []);

  const handleClickSubscribe = () => {
    if (emailError === "" && email !== "") {
      subscribeUpdatesFunc(dispatch, "", {
        username: params.userUsername,
        email: email,
      });
    }
  };

  useEffect(() => {
    // console.log("getProfileDataAndInfoStore", getProfileDataAndInfoStore);
    if (getProfileDataAndInfoStore && getProfileDataAndInfoStore.data) {
      setUsername(getProfileDataAndInfoStore.data.info.username);
      setAvatar(getProfileDataAndInfoStore.data.info.avatar);
    }
  }, [getProfileDataAndInfoStore]);

  useEffect(() => {
    // console.log("subscribeUpdatesStore", subscribeUpdatesStore);
    if (subscribeUpdatesStore && subscribeUpdatesStore.data) {
      setEmail("");
      setEmailError("");
      setIsSend(true);
    }
  }, [subscribeUpdatesStore]);

  useEffect(() => {
    if (isSend) {
      setTimeout(() => {
        setIsSend(false);
      }, 2000);
    }
  }, [isSend]);

  if (
    getProfileDataAndInfoStore &&
    (getProfileDataAndInfoStore.fail || getProfileDataAndInfoStore.error)
  ) {
    return (
      <div>
        <ErrorPage error={"Страница не существует"} />
      </div>
    );
  }

  return (
    <div className="flex justify-center break-words">
      <div className="font-manrope w-screen overflow-hidden pb-[20px] max-w-md min-h-screen bg-white">
        <div className="flex flex-col">
          <div className="mt-[155px]">
            <div className="flex justify-center items-center">
              <div className="w-[116px] h-[116px] rounded-full overflow-hidden shadow-customblock border-4 border-white">
                {avatar ? (
                  <img
                    src={avatar}
                    alt="Avatar"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src="/images/avatar.svg"
                    alt="avatar.svg"
                    className="w-full h-full bg-white"
                  />
                )}
              </div>
            </div>

            <div className="mt-[10px] flex justify-center items-center text-[15px] leading-[17px] text-[#8391A1]">
              @{username}
            </div>

            <div className="mt-[15px] flex justify-center">
              <span className="font-semibold text-center text-[28px] leading-[32px] text-[#161616]">
                {t("get_updates_text")}
              </span>
            </div>

            <div className="px-[24px] mt-[20px]">
              <div className="flex items-center border border-[#161616] space-x-[10px] h-[56px] px-[10px] rounded-[10px]">
                <img src="/images/profile_preview/email.svg" alt="email" />
                <input
                  type="text"
                  value={email}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    setEmail(value);

                    // Регулярное выражение для проверки email
                    const emailPattern =
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    if (value === "") {
                      setEmailError("");
                    } else if (emailPattern.test(value)) {
                      setEmailError("");
                    } else {
                      setEmailError(t("registration_error7"));
                    }
                  }}
                  placeholder="Email"
                  className="outline-none w-full placeholder:text-[#8391A1] text-[16px] text-[#161616]"
                />
              </div>

              {emailError !== "" && (
                <div className="mt-[5px] text-[14px] text-center leading-[24px] text-[#FF2C20]">
                  {emailError}
                </div>
              )}
            </div>

            <div className="mt-[10px] px-[24px]">
              <button
                className="h-[50px] w-full bg-black text-white flex justify-center items-center text-[18px] font-semibold rounded-[10px]"
                onClick={handleClickSubscribe}
              >
                {!isSend ? t("subscribe") : t("sended")}
              </button>
            </div>

            <div className="mt-[30px] flex justify-center">
              <button
                className="text-[16px] text-black font-medium"
                onClick={() => {
                  navigate(`/${params.userUsername}`);
                }}
              >
                &larr; {t("return_back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
