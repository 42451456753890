import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/CreateBlock/Products";
import YouTube from "../../components/ui/CreateBlock/YouTube";
import LinksUrl from "../../components/ui/CreateBlock/LinksUrl";
import PDFFile from "../../components/ui/CreateBlock/PDFFile";
import Newsletter from "../../components/ui/CreateBlock/Newsletter";
import Gallery from "../../components/ui/CreateBlock/Gallery";
import Text from "../../components/ui/CreateBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import SelectProductType from "../../components/ui/CreateProduct/SelectProductType";
import ProductDetails from "../../components/ui/CreateProduct/ProductDetails";
import ErrorPage from "../../components/ui/ErrorPage";
import { useTranslation } from "react-i18next";
import DigitalProduct from "../../components/ui/EditProduct/DigitalProduct";
import PhysicalProduct from "../../components/ui/EditProduct/PhysicalProduct";
import ProductDetailsThumbnail from "../../components/ui/EditProduct/ProductDetailsThumbnail";
import ProductDetailsCover from "../../components/ui/EditProduct/ProductDetailsCover";
import Bundle from "../../components/ui/EditProduct/Bundle";
import Membership from "../../components/ui/EditProduct/Membership";

export async function sendProductDataFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendInfoAboutEditProduct,
    dispatch,
    `api/product/edit/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [selectedProductType, setSelectedProductType] =
    useState("digital_product");

  const handleChangeSelectedProductType = (newVal: string) => {
    setSelectedProductType(newVal);
  };

  const [stage, setStage] = useState("select_product_type");

  const handleChangeStage = (newVal: string) => {
    setStage(newVal);
  };

  const [status, setStatus] = useState("info");

  const handleChangeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  // PRODUCT ---------------------------------------------------------------
  const [productInfo, setProductInfo] = useState({
    name: "",
    description: "",
  });

  const [productThumbnail, setProductThumbnail] = useState("");

  const handleChangeProductInfo = (key: string, newValue: any) => {
    let oldProductInfo = structuredClone(productInfo);

    if (key === "name") {
      oldProductInfo.name = newValue;
    }

    if (key === "description") {
      oldProductInfo.description = newValue;
    }

    setProductInfo(oldProductInfo);
  };

  const handleChangeProductThumbnail = (newValue: any) => {
    setProductThumbnail(newValue);
  };

  const [listOfProductImages, setListOfProductImages] = useState([]);
  const [listOfProductImagesMain, setListOfProductImagesMain] = useState([]);

  const handleAddProductImage = (fileStr: any) => {
    // setNewsletterTitle(titleNew);
    // let listOfProductImagesTmp = structuredClone(listOfProductImages);
    let listOfProductImagesTmp = listOfProductImages;
    // @ts-ignore
    listOfProductImagesTmp.push({
      id: listOfProductImages.length + 1,
      file: fileStr,
    });

    setListOfProductImages(listOfProductImagesTmp);
  };

  const handleChangeListProductImage = (listNew: any) => {
    setListOfProductImages(listNew);
  };

  // useEffect(() => {
  //   console.log("listOfProductImages", listOfProductImages);
  // }, [listOfProductImages]);

  const handleDeleteProductImage = (imageId: number) => {
    let listOfProductImagesTmp = [];
    let listOfProductImagesCopy = structuredClone(listOfProductImages);
    let listOfProductImagesMainCopy = structuredClone(listOfProductImagesMain);

    let block_id = null;

    for (let i = 0; i < listOfProductImages.length; i++) {
      // @ts-ignore
      if (listOfProductImages[i].id === imageId) {
        // @ts-ignore
        if (listOfProductImages[i].image_obj_id) {
          // @ts-ignore
          block_id = listOfProductImages[i].image_obj_id;
        }
      }
    }

    let j = 0;

    if (!block_id) {
      for (let i = 0; i < listOfProductImages.length; i++) {
        // @ts-ignore
        if (listOfProductImages[i].id !== imageId) {
          j += 1;
          listOfProductImagesTmp.push({
            id: j,
            // @ts-ignore
            file: listOfProductImages[i].file,
          });
        }
      }

      // @ts-ignore
      setListOfProductImages(listOfProductImagesTmp);
    } else {
      for (let i = 0; i < listOfProductImagesCopy.length; i++) {
        // @ts-ignore
        if (listOfProductImagesCopy[i].id === imageId) {
          // @ts-ignore
          listOfProductImagesCopy[i].delete = true;
        }
      }

      // @ts-ignore
      setListOfProductImages(listOfProductImagesCopy);

      for (let i = 0; i < listOfProductImagesMainCopy.length; i++) {
        // @ts-ignore
        if (listOfProductImagesMainCopy[i].image_obj_id === block_id) {
          // @ts-ignore
          listOfProductImagesMainCopy[i].delete = true;
        }
      }

      // @ts-ignore
      setListOfProductImagesMain(listOfProductImagesMainCopy);
    }
  };

  useEffect(() => {
    console.log("listOfProductImages", listOfProductImages);
  }, [listOfProductImages]);

  // Digital Product ---------------------------------------------------------------
  const [digitalProductContent, setDigitalProductContent] = useState({
    type: "url",
    currency: "dollar",
    currencySymbol: "$",
    fileVersions: [
      { name: "", details: "", fileName: "", file: "", price: "" },
    ],
    urlVersions: [{ name: "", details: "", url: "", price: "" }],
    action: "I want this",
  });

  const handleChangeDigitalProductContent = (key: string, newValue: string) => {
    let oldDigitalProductContent = structuredClone(digitalProductContent);

    if (key === "type") {
      oldDigitalProductContent.type = newValue;
    }

    if (key === "currency") {
      oldDigitalProductContent.currency = newValue;

      if (newValue === "dollar") {
        oldDigitalProductContent.currencySymbol = "$";
      }

      if (newValue === "tenge") {
        oldDigitalProductContent.currencySymbol = "₸";
      }

      if (newValue === "ruble") {
        oldDigitalProductContent.currencySymbol = "₽";
      }
    }

    if (key === "action") {
      oldDigitalProductContent.action = newValue;
    }

    setDigitalProductContent(oldDigitalProductContent);
  };

  const handleChangeDigitalProductList = (
    type: string,
    index: number,
    key: string,
    newValue: string,
    action = ""
  ) => {
    let oldDigitalProductContent = structuredClone(digitalProductContent);

    if (action === "") {
      if (type === "file") {
        let listTmp = oldDigitalProductContent.fileVersions;

        if (key === "name") {
          listTmp[index].name = newValue;
        }

        if (key === "details") {
          listTmp[index].details = newValue;
        }

        if (key === "fileName") {
          listTmp[index].fileName = newValue;
        }

        if (key === "file") {
          listTmp[index].file = newValue;
        }

        if (key === "price") {
          listTmp[index].price = newValue;
        }

        oldDigitalProductContent.fileVersions = listTmp;
      }

      if (type === "url") {
        let listTmp = oldDigitalProductContent.urlVersions;

        if (key === "name") {
          listTmp[index].name = newValue;
        }

        if (key === "details") {
          listTmp[index].details = newValue;
        }

        if (key === "url") {
          listTmp[index].url = newValue;
        }

        if (key === "price") {
          listTmp[index].price = newValue;
        }

        oldDigitalProductContent.urlVersions = listTmp;
      }
    } else if (action === "add") {
      if (type === "file") {
        oldDigitalProductContent.fileVersions.push({
          name: "",
          details: "",
          fileName: "",
          file: "",
          price: "",
        });
      }

      if (type === "url") {
        oldDigitalProductContent.urlVersions.push({
          name: "",
          details: "",
          url: "",
          price: "",
        });
      }
    } else if (action === "delete") {
      if (type === "file") {
        oldDigitalProductContent.fileVersions.splice(index, 1);
      }

      if (type === "url") {
        oldDigitalProductContent.urlVersions.splice(index, 1);
      }
    }

    setDigitalProductContent(oldDigitalProductContent);
  };

  const validateFileSizeFilePdfFile = (file: any) => {
    return file.size <= 200 * 1024 * 1024;
  };

  const handleFileVersionChange = (event: any, index: number) => {
    const files = event.target.files;

    if (files.length > 1) {
      // setMessageFilePdfFile("Please select only one file.");
      return;
    }

    const file = files[0];

    if (file) {
      if (!validateFileSizeFilePdfFile(file)) {
        // setMessageFilePdfFile(
        //   `File size exceeds ${constants.MaxSizeFilePdfFile}MB limit. Please select a smaller file.`
        // );
      } else {
        console.log("file.name", file.name);
        handleChangeDigitalProductList("file", index, "fileName", file.name);
        handleChangeDigitalProductList("file", index, "file", file);
        // setFilePdfFile(file);
        // setMessageFilePdfFile("");
      }
    }
  };

  const handleButtonClickFileVersionUrl = () => {
    //@ts-ignore
    document.getElementById("fileVersionInput").click();
  };

  // Physical product ---------------------------------------------------------------
  const [physicalProductContent, setPhysicalProductContent] = useState({
    versions: [
      {
        name: "",
        options: [{ name: "", details: "", price: "" }],
      },
    ],
    currency: "dollar",
    currencySymbol: "$",
    action: "I want this",
    deliveryArea: [],
  });

  const [countryData, setCountryData] = useState([
    { country: "Afghanistan", activate: false, price: "" },
    { country: "Albania", activate: false, price: "" },
    { country: "Algeria", activate: false, price: "" },
    { country: "Andorra", activate: false, price: "" },
    { country: "Angola", activate: false, price: "" },
    { country: "Antigua and Barbuda", activate: false, price: "" },
    { country: "Argentina", activate: false, price: "" },
    { country: "Armenia", activate: false, price: "" },
    { country: "Australia", activate: false, price: "" },
    { country: "Austria", activate: false, price: "" },
    { country: "Azerbaijan", activate: false, price: "" },
    { country: "Bahamas", activate: false, price: "" },
    { country: "Bahrain", activate: false, price: "" },
    { country: "Bangladesh", activate: false, price: "" },
    { country: "Barbados", activate: false, price: "" },
    { country: "Belarus", activate: false, price: "" },
    { country: "Belgium", activate: false, price: "" },
    { country: "Belize", activate: false, price: "" },
    { country: "Benin", activate: false, price: "" },
    { country: "Bhutan", activate: false, price: "" },
    { country: "Bolivia", activate: false, price: "" },
    { country: "Bosnia and Herzegovina", activate: false, price: "" },
    { country: "Botswana", activate: false, price: "" },
    { country: "Brazil", activate: false, price: "" },
    { country: "Brunei", activate: false, price: "" },
    { country: "Bulgaria", activate: false, price: "" },
    { country: "Burkina Faso", activate: false, price: "" },
    { country: "Burundi", activate: false, price: "" },
    { country: "Cabo Verde", activate: false, price: "" },
    { country: "Cambodia", activate: false, price: "" },
    { country: "Cameroon", activate: false, price: "" },
    { country: "Canada", activate: false, price: "" },
    { country: "Central African Republic", activate: false, price: "" },
    { country: "Chad", activate: false, price: "" },
    { country: "Chile", activate: false, price: "" },
    { country: "China", activate: false, price: "" },
    { country: "Colombia", activate: false, price: "" },
    { country: "Comoros", activate: false, price: "" },
    { country: "Congo (Congo-Brazzaville)", activate: false, price: "" },
    { country: "Costa Rica", activate: false, price: "" },
    { country: "Croatia", activate: false, price: "" },
    { country: "Cuba", activate: false, price: "" },
    { country: "Cyprus", activate: false, price: "" },
    { country: "Czechia (Czech Republic)", activate: false, price: "" },
    { country: "Democratic Republic of the Congo", activate: false, price: "" },
    { country: "Denmark", activate: false, price: "" },
    { country: "Djibouti", activate: false, price: "" },
    { country: "Dominica", activate: false, price: "" },
    { country: "Dominican Republic", activate: false, price: "" },
    { country: "Ecuador", activate: false, price: "" },
    { country: "Egypt", activate: false, price: "" },
    { country: "El Salvador", activate: false, price: "" },
    { country: "Equatorial Guinea", activate: false, price: "" },
    { country: "Eritrea", activate: false, price: "" },
    { country: "Estonia", activate: false, price: "" },
    { country: 'Eswatini (fmr. "Swaziland")', activate: false, price: "" },
    { country: "Ethiopia", activate: false, price: "" },
    { country: "Fiji", activate: false, price: "" },
    { country: "Finland", activate: false, price: "" },
    { country: "France", activate: false, price: "" },
    { country: "Gabon", activate: false, price: "" },
    { country: "Gambia", activate: false, price: "" },
    { country: "Georgia", activate: false, price: "" },
    { country: "Germany", activate: false, price: "" },
    { country: "Ghana", activate: false, price: "" },
    { country: "Greece", activate: false, price: "" },
    { country: "Grenada", activate: false, price: "" },
    { country: "Guatemala", activate: false, price: "" },
    { country: "Guinea", activate: false, price: "" },
    { country: "Guinea-Bissau", activate: false, price: "" },
    { country: "Guyana", activate: false, price: "" },
    { country: "Haiti", activate: false, price: "" },
    { country: "Honduras", activate: false, price: "" },
    { country: "Hungary", activate: false, price: "" },
    { country: "Iceland", activate: false, price: "" },
    { country: "India", activate: false, price: "" },
    { country: "Indonesia", activate: false, price: "" },
    { country: "Iran", activate: false, price: "" },
    { country: "Iraq", activate: false, price: "" },
    { country: "Ireland", activate: false, price: "" },
    { country: "Israel", activate: false, price: "" },
    { country: "Italy", activate: false, price: "" },
    { country: "Jamaica", activate: false, price: "" },
    { country: "Japan", activate: false, price: "" },
    { country: "Jordan", activate: false, price: "" },
    { country: "Kazakhstan", activate: false, price: "" },
    { country: "Kenya", activate: false, price: "" },
    { country: "Kiribati", activate: false, price: "" },
    { country: "Kuwait", activate: false, price: "" },
    { country: "Kyrgyzstan", activate: false, price: "" },
    { country: "Laos", activate: false, price: "" },
    { country: "Latvia", activate: false, price: "" },
    { country: "Lebanon", activate: false, price: "" },
    { country: "Lesotho", activate: false, price: "" },
    { country: "Liberia", activate: false, price: "" },
    { country: "Libya", activate: false, price: "" },
    { country: "Liechtenstein", activate: false, price: "" },
    { country: "Lithuania", activate: false, price: "" },
    { country: "Luxembourg", activate: false, price: "" },
    { country: "Madagascar", activate: false, price: "" },
    { country: "Malawi", activate: false, price: "" },
    { country: "Malaysia", activate: false, price: "" },
    { country: "Maldives", activate: false, price: "" },
    { country: "Mali", activate: false, price: "" },
    { country: "Malta", activate: false, price: "" },
    { country: "Marshall Islands", activate: false, price: "" },
    { country: "Mauritania", activate: false, price: "" },
    { country: "Mauritius", activate: false, price: "" },
    { country: "Mexico", activate: false, price: "" },
    { country: "Micronesia", activate: false, price: "" },
    { country: "Moldova", activate: false, price: "" },
    { country: "Monaco", activate: false, price: "" },
    { country: "Mongolia", activate: false, price: "" },
    { country: "Montenegro", activate: false, price: "" },
    { country: "Morocco", activate: false, price: "" },
    { country: "Mozambique", activate: false, price: "" },
    { country: "Myanmar (formerly Burma)", activate: false, price: "" },
    { country: "Namibia", activate: false, price: "" },
    { country: "Nauru", activate: false, price: "" },
    { country: "Nepal", activate: false, price: "" },
    { country: "Netherlands", activate: false, price: "" },
    { country: "New Zealand", activate: false, price: "" },
    { country: "Nicaragua", activate: false, price: "" },
    { country: "Niger", activate: false, price: "" },
    { country: "Nigeria", activate: false, price: "" },
    { country: "North Korea", activate: false, price: "" },
    { country: "North Macedonia", activate: false, price: "" },
    { country: "Norway", activate: false, price: "" },
    { country: "Oman", activate: false, price: "" },
    { country: "Pakistan", activate: false, price: "" },
    { country: "Palau", activate: false, price: "" },
    { country: "Palestine State", activate: false, price: "" },
    { country: "Panama", activate: false, price: "" },
    { country: "Papua New Guinea", activate: false, price: "" },
    { country: "Paraguay", activate: false, price: "" },
    { country: "Peru", activate: false, price: "" },
    { country: "Philippines", activate: false, price: "" },
    { country: "Poland", activate: false, price: "" },
    { country: "Portugal", activate: false, price: "" },
    { country: "Qatar", activate: false, price: "" },
    { country: "Romania", activate: false, price: "" },
    { country: "Russia", activate: false, price: "" },
    { country: "Rwanda", activate: false, price: "" },
    { country: "Saint Kitts and Nevis", activate: false, price: "" },
    { country: "Saint Lucia", activate: false, price: "" },
    { country: "Saint Vincent and the Grenadines", activate: false, price: "" },
    { country: "Samoa", activate: false, price: "" },
    { country: "San Marino", activate: false, price: "" },
    { country: "Sao Tome and Principe", activate: false, price: "" },
    { country: "Saudi Arabia", activate: false, price: "" },
    { country: "Senegal", activate: false, price: "" },
    { country: "Serbia", activate: false, price: "" },
    { country: "Seychelles", activate: false, price: "" },
    { country: "Sierra Leone", activate: false, price: "" },
    { country: "Singapore", activate: false, price: "" },
    { country: "Slovakia", activate: false, price: "" },
    { country: "Slovenia", activate: false, price: "" },
    { country: "Solomon Islands", activate: false, price: "" },
    { country: "Somalia", activate: false, price: "" },
    { country: "South Africa", activate: false, price: "" },
    { country: "South Korea", activate: false, price: "" },
    { country: "South Sudan", activate: false, price: "" },
    { country: "Spain", activate: false, price: "" },
    { country: "Sri Lanka", activate: false, price: "" },
    { country: "Sudan", activate: false, price: "" },
    { country: "Suriname", activate: false, price: "" },
    { country: "Sweden", activate: false, price: "" },
    { country: "Switzerland", activate: false, price: "" },
    { country: "Syria", activate: false, price: "" },
    { country: "Tajikistan", activate: false, price: "" },
    { country: "Tanzania", activate: false, price: "" },
    { country: "Thailand", activate: false, price: "" },
    { country: "Timor-Leste", activate: false, price: "" },
    { country: "Togo", activate: false, price: "" },
    { country: "Tonga", activate: false, price: "" },
    { country: "Trinidad and Tobago", activate: false, price: "" },
    { country: "Tunisia", activate: false, price: "" },
    { country: "Turkey", activate: false, price: "" },
    { country: "Turkmenistan", activate: false, price: "" },
    { country: "Tuvalu", activate: false, price: "" },
    { country: "Uganda", activate: false, price: "" },
    { country: "Ukraine", activate: false, price: "" },
    { country: "United Arab Emirates", activate: false, price: "" },
    { country: "United Kingdom", activate: false, price: "" },
    { country: "United States of America", activate: false, price: "" },
    { country: "Uruguay", activate: false, price: "" },
    { country: "Uzbekistan", activate: false, price: "" },
    { country: "Vanuatu", activate: false, price: "" },
    { country: "Vatican", activate: false, price: "" },
    { country: "Venezuela", activate: false, price: "" },
    { country: "Vietnam", activate: false, price: "" },
    { country: "Yemen", activate: false, price: "" },
    { country: "Zambia", activate: false, price: "" },
    { country: "Zimbabwe", activate: false, price: "" },
  ]);

  const handleChangeCountryData = (
    key: string,
    countryName: string,
    newValue: any
  ) => {
    let oldCountryData = structuredClone(countryData);

    for (let i = 0; i < oldCountryData.length; i++) {
      if (oldCountryData[i].country === countryName) {
        if (key === "activate") {
          oldCountryData[i].activate = !oldCountryData[i].activate;
        } else if (key === "price") {
          oldCountryData[i].price = newValue;
        }
      }
    }

    setCountryData(oldCountryData);
  };

  useEffect(() => {
    // console.log("countryData", countryData);
    let oldCountryData = structuredClone(countryData);
    let listNew = [];

    for (let i = 0; i < oldCountryData.length; i++) {
      if (oldCountryData[i].activate === true) {
        listNew.push(oldCountryData[i]);
      }
    }

    let oldPhysicalProductContent = structuredClone(physicalProductContent);
    //@ts-ignore
    oldPhysicalProductContent.deliveryArea = listNew;
    setPhysicalProductContent(oldPhysicalProductContent);
  }, [countryData]);

  const handleChangePhysicalProductContent = (
    key: string,
    newValue: string
  ) => {
    let oldPhysicalProductContent = structuredClone(physicalProductContent);

    if (key === "action") {
      oldPhysicalProductContent.action = newValue;
    }

    if (key === "currency") {
      oldPhysicalProductContent.currency = newValue;

      if (newValue === "dollar") {
        oldPhysicalProductContent.currencySymbol = "$";
      }

      if (newValue === "tenge") {
        oldPhysicalProductContent.currencySymbol = "₸";
      }

      if (newValue === "ruble") {
        oldPhysicalProductContent.currencySymbol = "₽";
      }
    }

    setPhysicalProductContent(oldPhysicalProductContent);
  };

  const handleChangePhysicalProductList = (
    indexVersion: number,
    indexOption: number,
    key: string,
    newValue: string,
    action = ""
  ) => {
    let oldPhysicalProductContent = structuredClone(physicalProductContent);

    if (action === "version") {
      let listTmp = oldPhysicalProductContent.versions;

      if (key === "name") {
        listTmp[indexVersion].name = newValue;
      }

      oldPhysicalProductContent.versions = listTmp;
    } else if (action === "option") {
      let listTmp = oldPhysicalProductContent.versions[indexVersion].options;

      if (key === "name") {
        listTmp[indexOption].name = newValue;
      }

      if (key === "details") {
        listTmp[indexOption].details = newValue;
      }

      if (key === "price") {
        listTmp[indexOption].price = newValue;
      }

      oldPhysicalProductContent.versions[indexVersion].options = listTmp;
    } else if (action === "add_version") {
      oldPhysicalProductContent.versions.push({
        name: "",
        options: [{ name: "", details: "", price: "" }],
      });
    } else if (action === "add_option") {
      oldPhysicalProductContent.versions[indexVersion].options.push({
        name: "",
        details: "",
        price: "",
      });
    } else if (action === "delete_version") {
      oldPhysicalProductContent.versions.splice(indexVersion, 1);
    } else if (action === "delete_option") {
      oldPhysicalProductContent.versions[indexVersion].options.splice(
        indexOption,
        1
      );
    }

    setPhysicalProductContent(oldPhysicalProductContent);
  };

  // Bundle ---------------------------------------------------------------
  const [bundleListOfProducts, setBundleListOfProducts] = useState([]);
  const [bundlePrice, setBundlePrice] = useState({
    currency: "dollar",
    currencySymbol: "$",
    action: "I want this",
    price: "",
  });

  const searchItemInListOfProducts = (itemId: any) => {
    // @ts-ignore
    return bundleListOfProducts.some((obj) => obj.id === itemId);
  };

  const deleteItemInBundleListOfProducts = (itemId: any) => {
    // @ts-ignore
    const newArray = bundleListOfProducts.filter((obj) => obj.id !== itemId);
    setBundleListOfProducts(newArray);
  };

  const setDescriptionToItemInBundleListOfProducts = (
    itemId: any,
    description: any
  ) => {
    // @ts-ignore
    const newArray = structuredClone(bundleListOfProducts);
    for (let i = 0; i < newArray.length; i++) {
      // @ts-ignore
      if (newArray[i].id === itemId) {
        // @ts-ignore
        newArray[i].description = description;
      }
    }
    setBundleListOfProducts(newArray);
  };

  const addItemToBundleListOfProducts = (newItem: any) => {
    // @ts-ignore
    if (!searchItemInListOfProducts(newItem.id)) {
      const newList: any = [...bundleListOfProducts, newItem];
      setBundleListOfProducts(newList);
    }
  };

  const setItemToBundleListOfProducts = (newItem: any) => {
    // @ts-ignore
    const newList: any = [newItem];
    setBundleListOfProducts(newList);
  };

  const searchIndexBundleListOfProducts = (itemId: any) => {
    return (
      bundleListOfProducts.findIndex(
        //@ts-ignore
        (item) => item.id === itemId
      ) + 1
    );
  };

  const handleChangeBundlePrice = (key: string, newValue: string) => {
    let oldBundlePrice = structuredClone(bundlePrice);

    if (key === "currency") {
      oldBundlePrice.currency = newValue;

      if (newValue === "dollar") {
        oldBundlePrice.currencySymbol = "$";
      }

      if (newValue === "tenge") {
        oldBundlePrice.currencySymbol = "₸";
      }

      if (newValue === "ruble") {
        oldBundlePrice.currencySymbol = "₽";
      }
    }

    if (key === "action") {
      oldBundlePrice.action = newValue;
    }

    if (key === "price") {
      // @ts-ignore
      oldBundlePrice.price = newValue;
    }

    setBundlePrice(oldBundlePrice);
  };

  // Membership
  const [membershipContent, setMembershipContent] = useState({
    intervals: [
      {
        interval: "1 month",
        active: false,
        price: "",
      },
      {
        interval: "3 months",
        active: false,
        price: "",
      },
      {
        interval: "6 months",
        active: false,
        price: "",
      },
      {
        interval: "1 year",
        active: false,
        price: "",
      },
    ],
    currency: "dollar",
    currencySymbol: "$",
    action: "I want this",
  });

  const handleChangeMembershipContent = (
    key: string,
    newValue: any,
    action: string
  ) => {
    let oldMembershipContent = structuredClone(membershipContent);

    if (key === "currency") {
      oldMembershipContent.currency = newValue;

      if (newValue === "dollar") {
        oldMembershipContent.currencySymbol = "$";
      }

      if (newValue === "tenge") {
        oldMembershipContent.currencySymbol = "₸";
      }

      if (newValue === "ruble") {
        oldMembershipContent.currencySymbol = "₽";
      }
    } else if (key === "action") {
      oldMembershipContent.action = newValue;
    } else if (key === "1 month") {
      let listTmp = structuredClone(oldMembershipContent.intervals);

      for (let i = 0; i < listTmp.length; i++) {
        if (listTmp[i].interval === "1 month") {
          if (action === "activate") {
            listTmp[i].active = !listTmp[i].active;
          } else {
            listTmp[i].price = newValue;
          }
        }
      }

      oldMembershipContent.intervals = listTmp;
    } else if (key === "3 months") {
      let listTmp = structuredClone(oldMembershipContent.intervals);

      for (let i = 0; i < listTmp.length; i++) {
        if (listTmp[i].interval === "3 months") {
          if (action === "activate") {
            listTmp[i].active = !listTmp[i].active;
          } else {
            listTmp[i].price = newValue;
          }
        }
      }

      oldMembershipContent.intervals = listTmp;
    } else if (key === "6 months") {
      let listTmp = structuredClone(oldMembershipContent.intervals);

      for (let i = 0; i < listTmp.length; i++) {
        if (listTmp[i].interval === "6 months") {
          if (action === "activate") {
            listTmp[i].active = !listTmp[i].active;
          } else {
            listTmp[i].price = newValue;
          }
        }
      }

      oldMembershipContent.intervals = listTmp;
    } else if (key === "1 year") {
      let listTmp = structuredClone(oldMembershipContent.intervals);

      for (let i = 0; i < listTmp.length; i++) {
        if (listTmp[i].interval === "1 year") {
          if (action === "activate") {
            listTmp[i].active = !listTmp[i].active;
          } else {
            listTmp[i].price = newValue;
          }
        }
      }

      oldMembershipContent.intervals = listTmp;
    }

    setMembershipContent(oldMembershipContent);
  };

  const handleCheckedMembershipContent = (key: string) => {
    let active_bool = null;

    for (let i = 0; i < membershipContent.intervals.length; i++) {
      if (membershipContent.intervals[i].interval === key) {
        active_bool = membershipContent.intervals[i].active;
      }
    }

    return active_bool;
  };

  // ALL Settings
  const params = useParams();

  async function getProductInfoFunc() {
    await actions.constructorAction(
      constants.getProductData,
      dispatch,
      "api/product/data/",
      "post",
      "",
      {
        productId: params.productId,
      },
      true
    )();
  }

  const getProductDataStore = useSelector(
    (state: any) => state.getProductDataStore
  );

  useEffect(() => {
    // console.log("getProductDataStore", getProductDataStore);
    const editProductDataStorage = utils.LocalStorage.get("EditProductData");
    if (getProductDataStore && getProductDataStore.data) {
      const newData = getProductDataStore.data.response;

      if (editProductDataStorage && editProductDataStorage.productInfo) {
        setProductInfo(editProductDataStorage.productInfo);
      } else {
        setProductInfo(newData.product_info);
      }

      if (
        editProductDataStorage &&
        editProductDataStorage.selectedProductType
      ) {
        setSelectedProductType(editProductDataStorage.selectedProductType);
      } else {
        setSelectedProductType(newData.product_type);
      }

      setProductThumbnail(newData.thumbnail);
      setListOfProductImages(newData.cover_images);
      setListOfProductImagesMain(newData.cover_images);

      if (selectedProductType === "digital_product") {
        if (
          editProductDataStorage &&
          editProductDataStorage.digitalProductContent
        ) {
          setDigitalProductContent(
            editProductDataStorage.digitalProductContent
          );
        } else {
          setDigitalProductContent(newData.product_data);
        }
      } else if (selectedProductType === "physical_product") {
        if (
          editProductDataStorage &&
          editProductDataStorage.physicalProductContent
        ) {
          setPhysicalProductContent(
            editProductDataStorage.physicalProductContent
          );
        } else {
          setPhysicalProductContent(newData.product_data);
        }
      } else if (selectedProductType === "bundle_product") {
        if (
          editProductDataStorage &&
          editProductDataStorage.bundleListOfProducts
        ) {
          setPhysicalProductContent(
            editProductDataStorage.physicalProductContent
          );
        } else {
          setPhysicalProductContent(newData.product_data);
        }
      } else if (selectedProductType === "membership") {
        if (
          editProductDataStorage &&
          editProductDataStorage.membershipContent
        ) {
          setMembershipContent(editProductDataStorage.membershipContent);
        } else {
          setMembershipContent(newData.product_data);
        }
      }
    }
  }, [getProductDataStore]);

  // useEffect(() => {
  //   console.log("digitalProductContent", digitalProductContent);
  // }, [digitalProductContent]);

  useEffect(() => {
    getProductInfoFunc();

    const editProductDataStorage = utils.LocalStorage.get("EditProductData");

    if (editProductDataStorage && editProductDataStorage.status) {
      setStatus(editProductDataStorage.status);
    }

    // const createProductDataStorage =
    //   utils.LocalStorage.get("EditProductData");
    // if (createProductDataStorage) {
    //   if (createProductDataStorage.selectedProductType) {
    //     setSelectedProductType(createProductDataStorage.selectedProductType);
    //   }
    //   if (createProductDataStorage.stage) {
    //     setStage(createProductDataStorage.stage);
    //   }
    //   if (createProductDataStorage.status) {
    //     setStatus(createProductDataStorage.status);
    //   }
    //   if (createProductDataStorage.productInfo) {
    //     setProductInfo(createProductDataStorage.productInfo);
    //   }
    //   if (createProductDataStorage.listOfProductImages) {
    //     setListOfProductImages(createProductDataStorage.listOfProductImages);
    //   }
    //   if (createProductDataStorage.digitalProductContent) {
    //     setDigitalProductContent(
    //       createProductDataStorage.digitalProductContent
    //     );
    //   }
    //   if (createProductDataStorage.physicalProductContent) {
    //     setPhysicalProductContent(
    //       createProductDataStorage.physicalProductContent
    //     );

    //     let deliveryArea =
    //       createProductDataStorage.physicalProductContent.deliveryArea;
    //     let oldCountryData = structuredClone(countryData);

    //     for (let i = 0; i < deliveryArea.length; i++) {
    //       for (let j = 0; j < oldCountryData.length; j++) {
    //         // @ts-ignore
    //         if (oldCountryData[j].country === deliveryArea[i].country) {
    //           oldCountryData[j].activate = true;
    //           // @ts-ignore
    //           oldCountryData[j].price = deliveryArea[i].price;
    //         }
    //       }
    //     }

    //     setCountryData(oldCountryData);
    //   }
    //   if (createProductDataStorage.bundleListOfProducts) {
    //     setBundleListOfProducts(createProductDataStorage.bundleListOfProducts);
    //   }
    //   if (createProductDataStorage.bundlePrice) {
    //     setBundlePrice(createProductDataStorage.bundlePrice);
    //   }
    //   if (createProductDataStorage.membershipContent) {
    //     setMembershipContent(createProductDataStorage.membershipContent);
    //   }
    // }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (getProductDataStore && getProductDataStore.data) {
      utils.LocalStorage.set("EditProductData", {
        selectedProductType: selectedProductType,
        stage: stage,
        status: status,
        productInfo: productInfo,
        // listOfProductImages: listOfProductImages,
        digitalProductContent: digitalProductContent,
        physicalProductContent: physicalProductContent,
        bundleListOfProducts: bundleListOfProducts,
        bundlePrice: bundlePrice,
        membershipContent: membershipContent,
      });
    }
  }, [
    selectedProductType,
    stage,
    status,
    productInfo,
    // listOfProductImages,
    digitalProductContent,
    physicalProductContent,
    bundleListOfProducts,
    bundlePrice,
    membershipContent,
  ]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sendInfoAboutEditProductStore = useSelector(
    (state: any) => state.sendInfoAboutEditProductStore
  );

  // SEND DATA
  const handleSendProductData = (blockType: string) => {
    const formData = new FormData();

    if (productThumbnail) {
      if (productThumbnail.includes("https://api.hubbbox.com/static/media")) {
        formData.append("thumbnail", productThumbnail);
      } else {
        // @ts-ignore
        const base64String = productThumbnail.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
        // @ts-ignore
        const fileType = productThumbnail
          .split(",")[0]
          .split("data:")[1]
          .split(";base64")[0];

        // Преобразовать строку base64 в бинарные данные
        // @ts-ignore
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создать объект Blob из бинарных данных
        // const fileBlob = new Blob([byteArray], { type: "image/jpeg" });
        const fileBlob = new Blob([byteArray], { type: fileType });

        // @ts-ignore
        formData.append("thumbnail", fileBlob, `thumbnail.${fileType}`);
      }
    }

    if (listOfProductImages && listOfProductImages.length > 0) {
      for (let i = 0; i < listOfProductImages.length; i++) {
        if (
          // @ts-ignore
          listOfProductImages[i].file.includes(
            "https://api.hubbbox.com/static/media"
          )
        ) {
          formData.append(
            //@ts-ignore
            `product_image${listOfProductImages[i].id}`,
            //@ts-ignore
            listOfProductImages[i].file
          );
        } else {
          // @ts-ignore
          const base64String = listOfProductImages[i].file.split(",")[1]; // Отбросить префикс 'data:image/jpeg;base64,'
          // @ts-ignore
          const fileType = listOfProductImages[i].file
            .split(",")[0]
            .split("data:")[1]
            .split(";base64")[0];

          // Преобразовать строку base64 в бинарные данные
          // @ts-ignore
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Создать объект Blob из бинарных данных
          const fileBlob = new Blob([byteArray], { type: fileType });

          // @ts-ignore
          formData.append(
            //@ts-ignore
            `product_image${listOfProductImages[i].id}`,
            fileBlob,
            //@ts-ignore
            `product_image${listOfProductImages[i].id}.${fileType}`
          );
        }
      }
    }

    //@ts-ignore
    formData.append("productId", params.productId);

    let dataForm = {
      product_type: selectedProductType,
      product_info: {
        name: productInfo.name,
        description: productInfo.description,
        image_length: listOfProductImages.length,
        block_data: listOfProductImagesMain,
      },
      product_data: {},
    };

    if (selectedProductType == "digital_product") {
      dataForm.product_data = digitalProductContent;
    }
    if (selectedProductType == "physical_product") {
      dataForm.product_data = physicalProductContent;
    }
    if (selectedProductType == "bundle") {
      dataForm.product_data = {
        products: bundleListOfProducts,
        price: bundlePrice,
      };
    }
    if (selectedProductType == "membership") {
      dataForm.product_data = membershipContent;
    }

    const dataFormString = JSON.stringify(dataForm);

    formData.append("data", dataFormString);

    sendProductDataFunc(dispatch, "", formData);
  };

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: constants.ErrorNotificationDuration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    // console.log("sendInfoAboutNewProductStore", sendInfoAboutNewProductStore);
    if (sendInfoAboutEditProductStore && sendInfoAboutEditProductStore.data) {
      navigate("/product/all");
      utils.LocalStorage.remove("EditProductData");
      dispatch({ type: constants.sendInfoAboutEditProduct.reset });
    }
  }, [sendInfoAboutEditProductStore]);

  const textareaRefProductName = useRef(null);
  const textareaRefProductDescription = useRef(null);

  const handleChangeRefProductName = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    // handleChangeProductName(newValue);
    handleChangeProductInfo("name", newValue);
    // @ts-ignore
    textareaRefProductName.current.style.height = "auto";
    // @ts-ignore
    textareaRefProductName.current.style.height = `${textareaRefProductName.current.scrollHeight}px`;
  };

  const handleChangeRefProductDescription = (event: any) => {
    const value = event.target.value;
    // const newValue = value.replace("\n", "");
    // handleChangeProductDescription(newValue);
    handleChangeProductInfo("description", value);
    // @ts-ignore
    textareaRefProductDescription.current.style.height = "auto";
    // @ts-ignore
    textareaRefProductDescription.current.style.height = `${textareaRefProductDescription.current.scrollHeight}px`;
  };

  if (getProductDataStore && getProductDataStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getProductDataStore.load} />
      </div>
    );
  }

  if (getProductDataStore && getProductDataStore.fail) {
    return (
      <div>
        <ErrorPage error={getProductDataStore.fail} />
      </div>
    );
  }

  if (getProductDataStore && getProductDataStore.error) {
    return (
      <div>
        <ErrorPage error={getProductDataStore.error} />
      </div>
    );
  }

  if (sendInfoAboutEditProductStore && sendInfoAboutEditProductStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={sendInfoAboutEditProductStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        <div>
          {status === "info" && (
            <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
              <BurgerMenu
                isOpen={burgerMenuOpen}
                profileName={"profileName"}
                toggleBurgerMenu={toggleBurgerMenu}
              />
              <div className="flex flex-col">
                <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
                  <div className="h-[56px] flex justify-between items-center">
                    <button
                      onClick={() => {
                        navigate("/product/all");
                        utils.LocalStorage.remove("EditProductData");
                      }}
                    >
                      {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                      <div className="p-[10px]">
                        <img
                          src="/images/back_arrow.svg"
                          alt="back_arrow.svg"
                          className="w-[7px] h-[14px]"
                        />
                      </div>
                    </button>
                    <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                      {t("edit_product")}
                    </div>
                    <button onClick={toggleBurgerMenu}>
                      {!burgerMenuOpen ? (
                        <Icon
                          icon="iconamoon:menu-burger-horizontal-light"
                          className="w-[24px] h-[26px]"
                        />
                      ) : (
                        <span> </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="mt-[61px]">
                  <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                    <div
                      className="ml-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
                      onClick={() => {
                        //   changeStatus("Info");
                      }}
                    >
                      <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                        {t("info_product")}
                      </div>
                    </div>
                    <div
                      className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                      onClick={() => {
                        handleChangeStatus("content");
                      }}
                    >
                      <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                        {t("content")}
                      </div>
                    </div>
                    <div
                      className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                      onClick={() => {
                        handleChangeStatus("price");
                      }}
                    >
                      <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                        {t("price")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[25px]">
                  <textarea
                    ref={textareaRefProductName}
                    maxLength={100}
                    placeholder={t("product_name")}
                    className="mt-[10px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                    value={productInfo.name}
                    onChange={handleChangeRefProductName}
                    rows={1} // Начнем с одной строки
                  />
                </div>

                <div className="mt-[10px]">
                  <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                    {t("description")}
                  </span>
                </div>

                <div className="mt-[10px]">
                  <textarea
                    ref={textareaRefProductDescription}
                    maxLength={1000}
                    placeholder={t("description")}
                    className="w-full h-[125px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
                    value={productInfo.description}
                    onChange={handleChangeRefProductDescription}
                    rows={3} // Начнем с одной строки
                  />
                </div>

                <div className="mt-[10px]">
                  <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                    {t("thumbnail")}
                  </span>
                </div>

                <ProductDetailsThumbnail
                  notify={notify}
                  productThumbnail={productThumbnail}
                  handleChangeProductThumbnail={handleChangeProductThumbnail}
                  productInfo={productInfo}
                  handleChangeProductInfo={handleChangeProductInfo}
                />

                <div className="mt-[10px]">
                  <span className="text-start text-[#161616] text-[16px] leading-[18px] font-medium">
                    {t("product_images_gallery")}
                  </span>
                </div>

                <ProductDetailsCover
                  notify={notify}
                  listOfProductImages={listOfProductImages}
                  handleChangeListProductImage={handleChangeListProductImage}
                  handleAddProductImage={handleAddProductImage}
                  handleDeleteProductImage={handleDeleteProductImage}
                />

                <div className="mt-[14px] flex justify-center">
                  <button
                    className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                    onClick={() => {
                      handleChangeStatus("content");
                    }}
                  >
                    {t("continue")}
                  </button>
                </div>
              </div>
            </div>
          )}

          {(status === "content" || status === "price") &&
            selectedProductType == "digital_product" && (
              <DigitalProduct
                burgerMenuOpen={burgerMenuOpen}
                toggleBurgerMenu={toggleBurgerMenu}
                selectedProductType={selectedProductType}
                digitalProductContent={digitalProductContent}
                handleChangeDigitalProductContent={
                  handleChangeDigitalProductContent
                }
                handleChangeDigitalProductList={handleChangeDigitalProductList}
                handleFileVersionChange={handleFileVersionChange}
                handleButtonClickFileVersionUrl={
                  handleButtonClickFileVersionUrl
                }
                status={status}
                handleChangeStatus={handleChangeStatus}
                handleChangeStage={handleChangeStage}
                handleSendProductData={handleSendProductData}
              />
            )}

          {(status === "content" || status === "price") &&
            selectedProductType == "physical_product" && (
              <PhysicalProduct
                burgerMenuOpen={burgerMenuOpen}
                toggleBurgerMenu={toggleBurgerMenu}
                selectedProductType={selectedProductType}
                physicalProductContent={physicalProductContent}
                handleChangePhysicalProductContent={
                  handleChangePhysicalProductContent
                }
                handleChangePhysicalProductList={
                  handleChangePhysicalProductList
                }
                countryData={countryData}
                handleChangeCountryData={handleChangeCountryData}
                status={status}
                handleChangeStatus={handleChangeStatus}
                handleChangeStage={handleChangeStage}
                handleSendProductData={handleSendProductData}
              />
            )}

          {(status === "content" || status === "price") &&
            selectedProductType == "bundle" && (
              <Bundle
                burgerMenuOpen={burgerMenuOpen}
                toggleBurgerMenu={toggleBurgerMenu}
                selectedProductType={selectedProductType}
                productInfo={productInfo}
                handleChangeProductInfo={handleChangeProductInfo}
                bundleListOfProducts={bundleListOfProducts}
                searchItemInListOfProducts={searchItemInListOfProducts}
                deleteItemInBundleListOfProducts={
                  deleteItemInBundleListOfProducts
                }
                setDescriptionToItemInBundleListOfProducts={
                  setDescriptionToItemInBundleListOfProducts
                }
                addItemToBundleListOfProducts={addItemToBundleListOfProducts}
                setItemToBundleListOfProducts={setItemToBundleListOfProducts}
                searchIndexBundleListOfProducts={
                  searchIndexBundleListOfProducts
                }
                bundlePrice={bundlePrice}
                handleChangeBundlePrice={handleChangeBundlePrice}
                status={status}
                handleChangeStatus={handleChangeStatus}
                handleChangeStage={handleChangeStage}
                handleSendProductData={handleSendProductData}
              />
            )}

          {(status === "content" || status === "price") &&
            selectedProductType == "membership" && (
              <Membership
                burgerMenuOpen={burgerMenuOpen}
                toggleBurgerMenu={toggleBurgerMenu}
                selectedProductType={selectedProductType}
                productInfo={productInfo}
                handleChangeProductInfo={handleChangeProductInfo}
                membershipContent={membershipContent}
                handleChangeMembershipContent={handleChangeMembershipContent}
                handleCheckedMembershipContent={handleCheckedMembershipContent}
                status={status}
                handleChangeStatus={handleChangeStatus}
                handleChangeStage={handleChangeStage}
                handleSendProductData={handleSendProductData}
              />
            )}
        </div>
      </div>
    </utils.AuthGuard>
  );
}
