// // src/App.js

// import React, { useState } from "react";
// import ToastContainer from "./ToastContainer";

// function App() {
//   const [toasts, setToasts] = useState([]);

//   const addToast = () => {
//     const newToast = {
//       id: Date.now(),
//       message: "New notification!j askdnfjn lkaskf",
//     };
//     setToasts([...toasts, newToast]);

//     // Удаление уведомления через 3 секунды
//     setTimeout(() => {
//       setToasts((toasts) => toasts.filter((toast) => toast.id !== newToast.id));
//     }, 3000);
//   };

//   return (
//     <div className="App">
//       <button
//         onClick={addToast}
//         className="px-4 py-2 bg-blue-500 text-white rounded"
//       >
//         Add Toast
//       </button>
//       <ToastContainer toasts={toasts} setToasts={setToasts} />
//     </div>
//   );
// }

// export default App;

import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const notify = () => {
    toast("🦄 Wow so easy!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className="App">
      <button
        onClick={notify}
        className="px-4 py-2 bg-blue-500 text-white rounded"
      >
        Show Notification
      </button>
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
