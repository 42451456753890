export default function Component({ id, text, deactivated }: any) {
  const words = text.mainText.split("\n");

  return (
    <div className={`flex justify-start ${deactivated && "opacity-50"}`}>
      {/* <div dangerouslySetInnerHTML={{ __html: text }} /> */}
      <div className="">
        <div className="font-bold text-[#161616] text-[22px] leading-[26px]">
          {text.title}
        </div>

        {text.mainText !== "" && (
          <div className="mt-[10px] mb-[10px] text-[#161616] leading-[20px]">
            {words.map((elem: any, index: number) =>
              elem !== "" ? (
                <div
                  className="leading-[20px]"
                  key={`text-block-${id}-${index}`}
                >
                  {elem}
                </div>
              ) : (
                <br />
              )
            )}
            <div className="-mb-[10px]"></div>
          </div>
        )}
      </div>
    </div>
  );
}
