import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import * as utils from "../../components/utils";
import * as actions from "../../components/actions";
import * as constants from "../../components/constants";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export async function logoutFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.logout,
    dispatch,
    `api/logout/`,
    "post",
    "",
    form,
    true
  )();
}

const BurgerMenu = ({
  isOpen,
  profileName,
  username = "admin123",
  toggleBurgerMenu,
}: any) => {
  const { t, i18n } = useTranslation();

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [newProfileName, setNewProfileName] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newAvatar, setNewAvatar] = useState("");

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setNewProfileName(profileInfoStore.data.response.profile_name);
      setNewUsername(profileInfoStore.data.response.username);
      setNewAvatar(profileInfoStore.data.response.avatar);
    }
  }, [profileInfoStore]);

  const navigate = useNavigate();
  const locationRouterDom = useLocation();
  const dispatch = useDispatch();

  const logoutStore = useSelector((state: any) => state.logoutStore);

  const handleClickLogout = () => {
    let form: any = {};
    const tokenCookie = utils.GetCookieValue("token");
    if (tokenCookie) {
      const tokenRefresh = JSON.parse(
        utils.GetCookieValue("token") ?? "{}"
      ).refresh;
      form["refresh"] = `${tokenRefresh}`;
    }
    logoutFunc(dispatch, "", form);
  };

  useEffect(() => {
    // console.log("logoutStore", logoutStore);
    if (logoutStore && logoutStore.data) {
      utils.DeleteCookieValue("token");
      utils.LocalStorage.remove("CreateProductData");
      utils.LocalStorage.remove("EditProductData");
      utils.LocalStorage.remove("PersonalInformationEditData");
      utils.LocalStorage.remove("CreateBlockData");
      utils.LocalStorage.remove("EditBlockData");
      utils.LocalStorage.remove("AccountLaunchData");
      utils.LocalStorage.remove("CreatePostData");
      utils.LocalStorage.remove("EditPostData");
      window.location.reload();
    }
  }, [logoutStore]);

  if (logoutStore && logoutStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={logoutStore.load} />
      </div>
    );
  }

  return (
    <div className="break-all">
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={toggleBurgerMenu}
        ></div>
      )}
      <div
        className={`fixed inset-y-0 left-0 w-4/5 bg-white min-h-screen shadow z-50 transform transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="px-[15px] pb-[50px] font-manrope">
          <div className="flex flex-col">
            <div className="pt-[50px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/home/profile") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/home/profile");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  {/* <Icon
                    icon="carbon:user-avatar-filled"
                    className="w-[50px] h-[50px] text-[#F7F8F9]"
                  /> */}

                  {newAvatar ? (
                    <Avatar
                      src={newAvatar}
                      alt="avatar"
                      className="w-[50px] h-[50px]"
                    />
                  ) : (
                    <img
                      src="/images/avatar.svg"
                      alt="avatar.svg"
                      className="w-[50px] h-[50px]"
                    />
                  )}

                  <div className="">
                    {newProfileName ? (
                      <div className="text-[16px] leading-[19.2px] font-medium tracking-[-1%]">
                        {newProfileName}
                      </div>
                    ) : (
                      <div className="h-[16px]"></div>
                    )}
                    {/* <div className="text-[16px] leading-[19.2px] font-medium tracking-[-1%]">
                      {profileName}
                    </div> */}
                    <div className="mt-[5px] text-[14px] text-[#161616] leading-[14.4px]">
                      @{newUsername}
                    </div>
                  </div>
                </div>

                {/* <Icon
                  icon="carbon:chevron-right"
                  className="w-[24px] h-[24px]"
                /> */}
                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>
            </div>

            <div className="mt-[30px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/home") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/home");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  {/* <Icon
                    icon="solar:home-smile-linear"
                    className="text-[#8391A1] w-[24px] h-[24px]"
                  /> */}
                  <img
                    src="/images/hamburger_menu/home.svg"
                    alt="home.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("home")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>
            </div>

            {/* OLD */}

            {/* <div className="mt-[23px] text-[#8391A1] text-[14px] leading-[16.8px]">
              {t("PRODUCT")}
            </div>

            <div className="mt-[22px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/product/create") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/product/create");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/hamburger_menu/product.svg"
                    alt="product.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("create_product")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/product/all") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/product/all");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/hamburger_menu/all_products.svg"
                    alt="all_products.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("all_products")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/collaboration") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/collaboration");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/hamburger_menu/collaboration.svg"
                    alt="collaboration.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("make_collaboration")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div className="mt-[23px] text-[#8391A1] text-[14px] leading-[16.8px]">
                {t("MANAGEMENT")}
              </div>

              <div className="mt-[20px]">
                <div
                  className="flex justify-between items-center"
                  onClick={() => {
                    if (locationRouterDom.pathname === "/analytics") {
                      toggleBurgerMenu();
                    } else {
                      navigate("/analytics");
                    }
                  }}
                >
                  <div className="flex space-x-2.5 items-center">
                    <img
                      src="/images/hamburger_menu/analytics.svg"
                      alt="analytics.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("analytics")}
                    </span>
                  </div>

                  <img
                    src="/images/hamburger_menu/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>

                <div className="mt-[20px] flex justify-between items-center opacity-30">
                  <div className="flex space-x-2.5 items-center">
                    <img
                      src="/images/hamburger_menu/payout.svg"
                      alt="payout.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("payout")}
                    </span>
                  </div>

                  <img
                    src="/images/hamburger_menu/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>

                <div className="mt-[23px] text-[#8391A1] text-[14px] leading-[16.8px]">
                  {t("GENERAL")}
                </div>

                <div className="mt-[20px]">
                  <div
                    className="flex justify-between items-center"
                    onClick={() => {
                      if (locationRouterDom.pathname === "/settings") {
                        toggleBurgerMenu();
                      } else {
                        navigate("/settings");
                      }
                    }}
                  >
                    <div className="flex space-x-2.5 items-center">
                      <img
                        src="/images/hamburger_menu/settings.svg"
                        alt="settings.svg"
                        className="w-[24px] h-[24px]"
                      />
                      <span className="text-[#161616] text-[16px] leading-[19.2px]">
                        {t("settings")}
                      </span>
                    </div>

                    <img
                      src="/images/hamburger_menu/arrow.svg"
                      alt="arrow.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>

                  <div className="mt-[20px] flex justify-between items-center opacity-30">
                      <img
                        src="/images/hamburger_menu/support.svg"
                        alt="support.svg"
                        className="w-[24px] h-[24px]"
                      />
                      <span className="text-[#161616] text-[16px] leading-[19.2px]">
                        {t("support")}
                      </span>
                    </div>

                    <img
                      src="/images/hamburger_menu/arrow.svg"
                      alt="arrow.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>

                  <div className="mt-[23px] flex justify-center">
                    <span
                      className="text-[#FF2C20] text-[16px] font-medium leading-[21.86px]"
                      onClick={handleClickLogout}
                    >
                      {t("sign_out")}
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="mt-[45px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/profile") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/profile");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/profile_page/profile_page.svg"
                    alt="profile_page.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("profile_page")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/profile/info") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/profile/info");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/profile_page/personal_information.svg"
                    alt="personal_information.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("profile_header")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/design") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/design");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/profile_page/design.svg"
                    alt="design.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("design")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>
            </div>

            <div className="mt-[40px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/product") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/product");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/home_page/product.svg"
                    alt="product.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("products")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/post") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/post");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/home_page/personal_blog.svg"
                    alt="personal_blog.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("personal_blog")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>
            </div>

            <div className="mt-[40px]">
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/analytics") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/analytics");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/home_page/analytics.svg"
                    alt="analytics.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("analytics")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center"
                onClick={() => {
                  if (locationRouterDom.pathname === "/profile/settings") {
                    toggleBurgerMenu();
                  } else {
                    navigate("/profile/settings");
                  }
                }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/home_page/settings.svg"
                    alt="settings.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("settings")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div
                className="mt-[20px] flex justify-between items-center opacity-30"
                // onClick={() => {
                //   if (locationRouterDom.pathname === "/collaboration") {
                //     toggleBurgerMenu();
                //   } else {
                //     navigate("/collaboration");
                //   }
                // }}
              >
                <div className="flex space-x-2.5 items-center">
                  <img
                    src="/images/home_page/support.svg"
                    alt="support.svg"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-[#161616] text-[16px] leading-[19.2px]">
                    {t("support")}
                  </span>
                </div>

                <img
                  src="/images/hamburger_menu/arrow.svg"
                  alt="arrow.svg"
                  className="w-[24px] h-[24px]"
                />
              </div>

              <div className="mt-[45px] flex justify-center">
                <span
                  className="text-[#FF2C20] text-[16px] font-medium leading-[21.86px]"
                  onClick={handleClickLogout}
                >
                  {t("sign_out")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Кнопка закрытия меню в оставшейся области */}
      {isOpen && (
        <button
          className="fixed top-5 right-5 z-50 block"
          onClick={toggleBurgerMenu}
        >
          {/* Иконка закрытия меню */}
          {/* <svg
            className="w-4 h-4 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg> */}
          {/* <Icon icon="heroicons:x-mark" className="w-[24px] h-[24px]" /> */}
          <img
            src="/images/hamburger_menu/close.svg"
            alt="close.svg"
            className="w-[24px] h-[26.88px]"
          />
        </button>
      )}
    </div>
  );
};

export default BurgerMenu;
