import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import * as utils from "../../components/utils";
import * as actions from "../../components/actions";
import * as constants from "../../components/constants";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useTranslation } from "react-i18next";

export async function logoutFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.logout,
    dispatch,
    `api/logout/`,
    "post",
    "",
    form,
    true
  )();
}

export async function profileInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.profileInfo,
    dispatch,
    `api/profile/info/`,
    "get",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [profileName, setProfileName] = useState("");
  const [username, setUsername] = useState("");
  const [avatar, setAvatar] = useState("");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutStore = useSelector((state: any) => state.logoutStore);

  const handleClickLogout = () => {
    let form: any = {};
    const tokenCookie = utils.GetCookieValue("token");
    if (tokenCookie) {
      const tokenRefresh = JSON.parse(
        utils.GetCookieValue("token") ?? "{}"
      ).refresh;
      form["refresh"] = `${tokenRefresh}`;
    }
    logoutFunc(dispatch, "", form);
  };

  useEffect(() => {
    // console.log("logoutStore", logoutStore);
    if (logoutStore && logoutStore.data) {
      utils.DeleteCookieValue("token");
      // LOCALSTORAGE: CreateProductData, PersonalInformationEditData, CreateBlockData, EditBlockData, AccountLaunchData
      utils.LocalStorage.remove("DesignEditData");
      utils.LocalStorage.remove("CreateProductData");
      utils.LocalStorage.remove("NewCollaborationData");
      utils.LocalStorage.remove("EditProductData");
      utils.LocalStorage.remove("PersonalInformationEditData");
      utils.LocalStorage.remove("CreateBlockData");
      utils.LocalStorage.remove("EditBlockData");
      utils.LocalStorage.remove("AccountLaunchData");
      utils.LocalStorage.remove("MakeCollaborationData");
      window.location.reload();
    }
  }, [logoutStore]);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  useEffect(() => {
    utils.LocalStorage.remove("CreateProductData");
    utils.LocalStorage.remove("DesignEditData");
    utils.LocalStorage.remove("NewCollaborationData");
    utils.LocalStorage.remove("EditProductData");
    utils.LocalStorage.remove("PersonalInformationEditData");
    utils.LocalStorage.remove("CreateBlockData");
    utils.LocalStorage.remove("EditBlockData");
    utils.LocalStorage.remove("AccountLaunchData");
    utils.LocalStorage.remove("MakeCollaborationData");

    dispatch({ type: constants.changeDesignSettings.reset });
    dispatch({ type: constants.checkTransactionByToken.reset });
    dispatch({ type: constants.subscriptionPayment.reset });
  }, []);

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setProfileName(profileInfoStore.data.response.profile_name);
      setUsername(profileInfoStore.data.response.username);
      setAvatar(profileInfoStore.data.response.avatar);
    }
  }, [profileInfoStore]);

  if (logoutStore && logoutStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={logoutStore.load} />
      </div>
    );
  }

  // if (profileInfoStore && profileInfoStore.load) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={profileInfoStore.load} />
  //     </div>
  //   );
  // }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center break-words">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-[#FAFAFA]">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={profileName}
            username={username}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <Icon
                    icon="iconamoon:menu-burger-horizontal-light"
                    className="w-[24px] h-[26px] invisible"
                  />
                </button>
                <div className="flex justify-center items-center text-[18px] font-semibold leading-[28px] text-center text-[#161616]">
                  {t("home")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[70px] flex justify-center">
              {!avatar ? (
                <Icon
                  icon="carbon:user-avatar-filled"
                  className="w-[110px] h-[110px] text-white"
                />
              ) : (
                <div className="border-[6px] border-white rounded-full shadow-customblock flex-shrink-0">
                  <Avatar
                    src={avatar}
                    alt="avatar"
                    className="w-[110px] h-[110px]"
                  />
                </div>
              )}
            </div>

            <div className="mt-[15px] text-center">
              {/* <div className="font-medium text-[20px] leading-[27.32px]">
              Amanzhol Shungeyev
            </div> */}
              {profileName ? (
                <div className="font-medium text-[20px] leading-[27.32px]">
                  {profileName}
                </div>
              ) : (
                <div className="h-[20px]"></div>
              )}
              <div className="mt-[7px] text-[12px] leading-[16.39px]">
                @{username}
              </div>
            </div>

            <div className="mt-[15px]">
              <button
                className="relative inline-block items-center text-center h-[50px] w-full text-[16px] font-bold text-white rounded-[10px] overflow-hidden"
                onClick={() => {
                  // navigate("/pricing");

                  window.open(`https://www.hubbbox.com/pricing`, "_blank");
                }}
              >
                {/* <span className="absolute inset-0 bg-gradient-to-r from-blue-500 via-cyan-500 to-pink-500 rounded-[10px]"></span> */}
                <span className="absolute inset-0 bg-gradient-to-tr from-[#130EFF] to-[#FB047B] rounded-[10px]"></span>
                <span className="absolute inset-[2px] bg-[#161616] rounded-[8px]"></span>
                <span className="relative">Hubbbox Premium</span>
              </button>
            </div>

            <div className="mt-[20px] shadow-custom rounded-[10px]">
              <div
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-t-[10px]"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                      icon="iconoir:profile-circle"
                      className="text-[#8391A1] w-[24px] h-[24px]"
                    /> */}
                    <img
                      src="/images/profile_page/profile_page.svg"
                      alt="profile_page.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("profile_page")}
                    </span>
                  </div>

                  <img
                    src="/images/profile_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>

              <div
                className="w-full h-[45px] py-[10px] px-[15px]  border-gray-200 border-b border-t bg-white"
                onClick={() => {
                  navigate("/profile/info");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                      icon="mdi:drivers-license-outline"
                      className="text-[#8391A1] w-[24px] h-[24px]"
                    /> */}
                    <img
                      src="/images/profile_page/personal_information.svg"
                      alt="personal_information.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {/* {t("personal_information")} */}
                      {t("profile_header")}
                    </span>
                  </div>

                  {/* <Icon
                    icon="carbon:chevron-right"
                    className="w-[24px] h-[24px]"
                  /> */}
                  <img
                    src="/images/profile_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>

              <div
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-b-[10px]"
                onClick={() => {
                  navigate("/design");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                      icon="mdi:drivers-license-outline"
                      className="text-[#8391A1] w-[24px] h-[24px]"
                    /> */}
                    <img
                      src="/images/profile_page/design.svg"
                      alt="personal_information.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("design")}
                    </span>
                  </div>

                  {/* <Icon
                    icon="carbon:chevron-right"
                    className="w-[24px] h-[24px]"
                  /> */}
                  <img
                    src="/images/profile_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[20px] shadow-custom rounded-[10px]">
              <div
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-t-[10px] border-gray-200 border-b"
                onClick={() => {
                  navigate("/product");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    <img
                      src="/images/home_page/product.svg"
                      alt="product.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("products")}
                    </span>
                  </div>

                  <img
                    src="/images/home_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>

              <div
                onClick={() => {
                  navigate("/post");
                }}
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-b-[10px]"
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    <img
                      src="/images/home_page/personal_blog.svg"
                      alt="personal_blog.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("personal_blog")}
                    </span>
                  </div>

                  <img
                    src="/images/home_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[20px] shadow-custom rounded-[10px]">
              <div
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-t-[10px] border-gray-200 border-b"
                onClick={() => {
                  navigate("/analytics");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                          icon="mage:dashboard-bar"
                          className="text-[#8391A1] w-[24px] h-[24px]"
                        /> */}
                    <img
                      src="/images/home_page/analytics.svg"
                      alt="product.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("analytics")}
                    </span>
                  </div>

                  <img
                    src="/images/home_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>

              <div
                className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-t-[10px] border-gray-200 border-b"
                onClick={() => {
                  navigate("/profile/settings");
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                          icon="solar:settings-linear"
                          className="text-[#8391A1] w-[24px] h-[24px]"
                        /> */}
                    <img
                      src="/images/home_page/settings.svg"
                      alt="product.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("settings")}
                    </span>
                  </div>

                  <img
                    src="/images/home_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>

              <div className="w-full h-[45px] py-[10px] px-[15px] bg-white rounded-b-[10px] opacity-30">
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2.5 items-center">
                    {/* <Icon
                          icon="ic:outline-contact-support"
                          className="text-[#8391A1] w-[24px] h-[24px]"
                        /> */}
                    <img
                      src="/images/home_page/support.svg"
                      alt="product.svg"
                      className="w-[24px] h-[24px]"
                    />
                    <span className="text-[#161616] text-[16px] leading-[19.2px]">
                      {t("support")}
                    </span>
                  </div>

                  <img
                    src="/images/home_page/arrow.svg"
                    alt="arrow.svg"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-[30px]">
              <div
                className="w-full h-[45px] rounded-[10px] bg-white shadow-custom flex justify-center items-center"
                onClick={handleClickLogout}
              >
                <span className="text-[16px] font-medium leading-[21.86px] text-[#FF2C20]">
                  {t("sign_out")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
