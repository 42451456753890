import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";

// import "preline/preline";
// import { IStaticMethods } from "preline/preline";

export default function ChangePasswordCompletedPage() {
  return (
    <div className="font-manrope flex flex-col px-[24px] w-full max-w-sm min-h-screen">
      <div className="mt-[100px] flex justify-center">
        <Icon
          icon="carbon:checkmark-filled"
          className="w-[124px] h-[124px] text-[#18C07A]"
        />
      </div>
      <div className="mt-[70px]">
        <div className="text-[26px] text-[#161616] leading-[30px] font-bold text-center">
          Password Changed
        </div>
      </div>

      <div className="mt-[10px] flex justify-center text-[#8391A1] leading-[24px] text-[18px] text-center">
        Password changed successfully, you can
        <br />
        login again with a new password
      </div>

      <div className="mt-[100px] flex justify-center">
        <button className="w-[342px] h-[50px] rounded-xl bg-black text-white text-[18px]">
          Sign In
        </button>
      </div>
    </div>
  );
}
