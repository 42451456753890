import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

export async function changePasswordSettingsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.changePasswordSettings,
    dispatch,
    `api/profile/settings/change/password/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [allPasswordError, setAllPasswordError] = useState("");

  const [isCorrectLength, setIsCorrectLength] = useState(false);
  const [isOneSymbol, setIsOneSymbol] = useState(false);
  const [isOneNumber, setIsOneNumber] = useState(false);
  const [isOneUpperOneLowerLetter, setIsOneUpperOneLowerLetter] =
    useState(false);
  const [clickButton, setClickButton] = useState(false);

  // const [isFirstTime, setIsFirstTime] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  useEffect(() => {
    if (clickButton) {
      if (oldPassword === "") {
        setOldPasswordError(t("change_password_settings_error1"));
      }

      if (newPasswordConfirm === "") {
        setNewPasswordConfirmError(t("change_password_settings_error2"));
      }
    }
  }, [clickButton]);

  useEffect(() => {
    if (oldPassword !== "") {
      setOldPasswordError("");

      setAllPasswordError("");
    }
  }, [oldPassword]);

  useEffect(() => {
    const isCorrectLengthBool = newPassword.length >= 8;
    setIsCorrectLength(isCorrectLengthBool);

    const isOneNumberBool = /\d/.test(newPassword);
    setIsOneNumber(isOneNumberBool);

    const isOneUpperOneLowerLetterBool = /^(?=.*[a-z])(?=.*[A-Z])/.test(
      newPassword
    );
    setIsOneUpperOneLowerLetter(isOneUpperOneLowerLetterBool);

    // !, ?, @, $, #, &
    const isOneSymbolBool = /[@$!%*?&]/.test(newPassword);
    setIsOneSymbol(isOneSymbolBool);
  }, [newPassword]);

  useEffect(() => {
    if (newPassword !== "") {
      setAllPasswordError("");
      if (newPassword !== newPasswordConfirm) {
        setNewPasswordConfirmError(t("change_password_settings_error2"));
      } else {
        setNewPasswordConfirmError("");
      }
    } else {
      setNewPasswordConfirmError("");
    }
  }, [newPassword, newPasswordConfirm]);

  const handleClickChangePassword = () => {
    if (
      newPassword !== "" &&
      newPasswordConfirm !== "" &&
      oldPassword !== "" &&
      allPasswordError === "" &&
      oldPasswordError === "" &&
      newPasswordConfirmError === "" &&
      isCorrectLength &&
      isOneNumber &&
      isOneSymbol &&
      isOneUpperOneLowerLetter
    ) {
      changePasswordSettingsFunc(dispatch, "", {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
      });
    }
  };

  const changePasswordSettingsStore = useSelector(
    (state: any) => state.changePasswordSettingsStore
  );

  useEffect(() => {
    // console.log("changePasswordSettingsStore", changePasswordSettingsStore);
    if (changePasswordSettingsStore) {
      if (changePasswordSettingsStore.data) {
        navigate("/profile/settings");
      } else if (
        changePasswordSettingsStore.fail ||
        changePasswordSettingsStore.error
      ) {
        setOldPasswordError(t("change_password_settings_error1"));
      }
    }
    // else if (changePasswordSettingsStore)
  }, [changePasswordSettingsStore]);

  if (changePasswordSettingsStore && changePasswordSettingsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={changePasswordSettingsStore.load} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/profile/settings");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("settings")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[71px]">
              <span className="text-[28px] leading-[32px] text-[#161616] font-bold">
                {t("change_password_settings")}
              </span>
            </div>

            <div className="mt-[5px]">
              <span className="text-[16px] leading-[22px] text-[#8391A1]">
                {t("change_password_settings_text")}
              </span>
            </div>

            <div className="mt-[15px] flex justify-center">
              <div className="flex flex-row items-center w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`w-full h-[56px] rounded-xl border-[1px] border-[${oldPasswordError === "" ? "#E8ECF4" : "#FF2C20"}] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]`}
                  placeholder={t("old_password")}
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />

                <img
                  src="/images/login/eye-invisible.svg"
                  alt="eye-invisible.svg"
                  className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>

            {oldPasswordError !== "" && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                {oldPasswordError}
              </div>
            )}

            {/* <div className="mt-[10px] flex justify-center">
              <div className="flex flex-row items-center w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]"
                  placeholder={t("new_password")}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />

                <img
                  src="/images/login/eye-invisible.svg"
                  alt="eye-invisible.svg"
                  className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div> */}

            <div className="flex justify-center">
              <div className="mt-[10px] flex flex-row items-center w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full h-[56px] rounded-xl border-[1px] border-[#E8ECF4] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] placeholder:text-[#8391A1] text-[16px] leading-[18.75px]"
                  placeholder={t("new_password")}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />

                <img
                  src="/images/login/eye-invisible.svg"
                  alt="eye-invisible.svg"
                  className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                  onClick={togglePasswordVisibility}
                />

                {/* <Icon
                icon={
                  showPassword ? "streamline:invisible-1" : "streamline:visible"
                }
                className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                onClick={togglePasswordVisibility}
              /> */}
              </div>
            </div>

            {newPassword !== "" && (
              <div className="flex justify-start">
                <div className="mt-[5px]">
                  <div className="flex flex-row items-center">
                    <img
                      src={
                        isCorrectLength
                          ? "/images/login/check.svg"
                          : "/images/login/cross.svg"
                      }
                      alt={isCorrectLength ? "check.svg" : "cross.svg"}
                      className="w-[22px] h-[22px]"
                    />

                    <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                      {t("registration_password_error1")}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <img
                      src={
                        isOneSymbol
                          ? "/images/login/check.svg"
                          : "/images/login/cross.svg"
                      }
                      alt={isOneSymbol ? "check.svg" : "cross.svg"}
                      className="w-[22px] h-[22px]"
                    />

                    <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                      {t("registration_password_error2")}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <img
                      src={
                        isOneNumber
                          ? "/images/login/check.svg"
                          : "/images/login/cross.svg"
                      }
                      alt={isOneNumber ? "check.svg" : "cross.svg"}
                      className="w-[22px] h-[22px]"
                    />

                    <div className="ml-[8px] text-[14px] leading-[16px] text-[#161616]">
                      {t("registration_password_error3")}
                    </div>
                  </div>
                  <div className="flex space-x-2 items-center">
                    {/* <Icon
                    icon={
                      isOneUpperOneLowerLetter
                        ? "carbon:checkmark"
                        : "heroicons:x-mark"
                    }
                    className="w-[14px] h-[14px]"
                  /> */}

                    <img
                      src={
                        isOneUpperOneLowerLetter
                          ? "/images/login/check.svg"
                          : "/images/login/cross.svg"
                      }
                      alt={isOneUpperOneLowerLetter ? "check.svg" : "cross.svg"}
                      className="w-[22px] h-[22px]"
                    />

                    <div className="text-[14px] leading-[16px] text-[#161616]">
                      {t("registration_password_error4")}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-[10px] flex justify-center">
              <div className="flex flex-row items-center w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className={`w-full h-[56px] rounded-xl border-[1px] border-[${newPasswordConfirmError === "" ? "#E8ECF4" : "#FF2C20"}] bg-[#F7F8F9] pl-6 pr-[45px] py-4 text-[#8391A1] text-[16px] lading-[18.75px] placeholder:text-[#8391A1]`}
                  placeholder={t("confirm_new_password")}
                  value={newPasswordConfirm}
                  onChange={(e) => {
                    setNewPasswordConfirm(e.target.value);
                  }}
                />

                <img
                  src="/images/login/eye-invisible.svg"
                  alt="eye-invisible.svg"
                  className="w-[22px] h-[22px] absolute top-0 right-0 m-4"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>

            {newPasswordConfirmError !== "" && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                {newPasswordConfirmError}
              </div>
            )}

            {allPasswordError !== "" && (
              <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
                {allPasswordError}
              </div>
            )}

            <div className="mt-[20px]">
              <div
                className="h-[50px] rounded-[10px] bg-black text-white border flex justify-center items-center text-[18px] leading-[22px]"
                onClick={() => {
                  setClickButton(true);
                  if (oldPassword === newPassword) {
                    setAllPasswordError(t("change_password_settings_error3"));
                  } else {
                    handleClickChangePassword();
                  }
                }}
              >
                {t("continue")}
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </utils.AuthGuard>
  );
}
