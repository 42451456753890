export const DEBUG =
  window.location.host === "127.0.0.1:3000" ||
  window.location.host === "localhost:3000";

// export const API = DEBUG
//   ? "http://127.0.0.1:8000/"
//   : "https://api.mensense.kz/";

export const API = "https://api.hubbbox.com/";
// export const API = "http://127.0.0.1:8000/";

export const MaxSizeAvatar = 10;
export const MaxSizeImageLinksUrl = 5;
export const MaxSizeImagePdfFile = 5;
export const MaxSizeFilePdfFile = 10;
export const ErrorNotificationDuration = 3000;

function constructorConstant(name: string) {
  return {
    load: `load_${name}`,
    success: `success_${name}`,
    fail: `fail_${name}`,
    error: `error_${name}`,
    reset: `reset_${name}`,
  };
}

export const sendLoginData = constructorConstant("sendLoginData");
export const logout = constructorConstant("logout");
export const profileInfo = constructorConstant("profileInfo");
export const checkAccountLaunch = constructorConstant("checkAccountLaunch");

export const editProfileInfo = constructorConstant("editProfileInfo");

export const checkUsername = constructorConstant("checkUsername");
export const checkEmail = constructorConstant("checkEmail");
export const registration = constructorConstant("registration");
export const verifyRegistrationCode = constructorConstant(
  "verifyRegistrationCode"
);
export const sendProfileParameters = constructorConstant(
  "sendProfileParameters"
);
export const sendInfoAboutNewBlock = constructorConstant(
  "sendInfoAboutNewBlock"
);
export const getProfileData = constructorConstant("getProfileData");
export const getProfileDataAndInfo = constructorConstant(
  "getProfileDataAndInfo"
);
// export const sectionEdit = constructorConstant("sectionEdit");
// export const sectionDelete = constructorConstant("sectionDelete");
export const blockDelete = constructorConstant("blockDelete");
export const blockDeactivate = constructorConstant("blockDeactivate");
export const getBlockInfo = constructorConstant("getBlockInfo");
export const sendInfoAboutEditBlock = constructorConstant(
  "sendInfoAboutEditBlock"
);
export const moveElemInBlockList = constructorConstant("moveElemInBlockList");
export const sendInfoAboutNewProduct = constructorConstant(
  "sendInfoAboutNewProduct"
);
export const productDelete = constructorConstant("productDelete");
export const allListOfProducts = constructorConstant("allListOfProducts");
export const productDetails = constructorConstant("productDetails");
export const changeSettings = constructorConstant("changeSettings");
export const getProductDataById = constructorConstant("getProductDataById");
export const getProductData = constructorConstant("getProductData");
export const sendInfoAboutEditProduct = constructorConstant(
  "sendInfoAboutEditProduct"
);
export const changePasswordSettings = constructorConstant(
  "changePasswordSettings"
);
export const createCollaboration = constructorConstant("createCollaboration");
export const getListCollaboration = constructorConstant("getListCollaboration");
export const getCollaborationDetails = constructorConstant(
  "getCollaborationDetails"
);
export const BecomeADistributorProductLink = constructorConstant(
  "BecomeADistributorProductLink"
);
export const changeDesignSettings = constructorConstant("changeDesignSettings");
export const giveCollaborationAnswer = constructorConstant(
  "giveCollaborationAnswer"
);
export const subscribeUpdates = constructorConstant("subscribeUpdates");
export const sendAnalytics = constructorConstant("sendAnalytics");
export const getAnalytics = constructorConstant("getAnalytics");
export const checkTransactionByToken = constructorConstant(
  "checkTransactionByToken"
);
export const subscriptionPayment = constructorConstant("subscriptionPayment");
export const subscriptionDetails = constructorConstant("subscriptionDetails");
export const sendInfoAboutNewPost = constructorConstant("sendInfoAboutNewPost");
export const allListOfPosts = constructorConstant("allListOfPosts");
export const postDetails = constructorConstant("postDetails");
export const postDelete = constructorConstant("postDelete");
export const getPostInfo = constructorConstant("getPostInfo");
export const postDeactivate = constructorConstant("postDeactivate");
export const sendInfoAboutEditPost = constructorConstant(
  "sendInfoAboutEditPost"
);

// OLD
export const messageList = constructorConstant("messageList");
// export const messageDetail = constructorConstant("messageDetail");
export const nowMessageList = constructorConstant("nowMessageList");
export const constantUserRegister = constructorConstant("constantUserRegister");
export const questionsList = constructorConstant("questionsList");
export const riskFactorsList = constructorConstant("riskFactorsList");
export const symptomsVersionsList = constructorConstant("symptomsVersionsList");
export const riskFactorVersionsList = constructorConstant(
  "riskFactorVersionsList"
);
export const testList = constructorConstant("testList");
export const saveSymptomVersion = constructorConstant("saveSymptomVersion");
export const saveRiskFactorVersion = constructorConstant(
  "saveRiskFactorVersion"
);
export const selectedSymptomVersionConst = constructorConstant(
  "selectedSymptomVersionConst"
);
export const selectedRiskFactorVersionConst = constructorConstant(
  "selectedRiskFactorVersionConst"
);
export const questionnaireStructureList = constructorConstant(
  "questionnaireStructureList"
);
export const loginData = constructorConstant("loginData");
export const captchaCheck = constructorConstant("captchaCheck");
export const token = constructorConstant("token");
export const resultPageStatus = constructorConstant("resultPageStatus");
export const listOfSelectedSymptoms = constructorConstant(
  "listOfSelectedSymptoms"
);

// TODO NEW //////////////////////////////////////////////////////////////////
