import React from "react";
import { useNavigate, Link } from "react-router-dom";

const NotFoundPage = ({ error = "" }: any) => {
  const navigate = useNavigate();

  return (
    <div className="max-w-md w-screen min-h-screen font-manrope break-words pb-[20px]">
      <div className="flex justify-center items-center">
        <div className="mt-[66px]">
          <img
            src="/images/error_page/error_icon2.svg"
            alt="error_icon"
            className="w-[342px] h-[320px]"
          />
        </div>
      </div>

      <div className="mt-[15px]">
        <div className="text-[38px] flex justify-center items-center text-black font-semibold">
          Are you lost?
        </div>
      </div>

      <div className="mt-[20px]">
        <div className="text-black text-[18px] flex justify-center items-center text-center">
          Looks like the page you were looking for is not found.
        </div>
      </div>

      <div className="mt-[10px] px-[20px]">
        <div className="text-black text-[18px] flex justify-center items-center text-center">
          {error}
        </div>
      </div>

      <div className="mt-[35px] flex justify-center">
        <div
          className="w-[161px] h-[42px] rounded-[40px] border-[1px] p-[10px] border-[#FFFFFF] bg-black flex justify-center items-center"
          // onClick={() => window.history.back()}
          onClick={() => {
            navigate("/home");
          }}
        >
          <span className="text-white text-[16px] ">Go home</span>
        </div>
      </div>
    </div>

    // <div className="max-w-md w-screen min-h-screen bg-black font-manrope">
    //   <div className="max-w-md mx-auto text-center">
    //     <img
    //       src="images/error_page/error_icon.svg"
    //       alt=""
    //       className="flex justify-start pt-[120px]"
    //     />
    //     <div className="pt-[20px] text-[#FFFFFF] text-[36px] font-bold text-center">
    //       Are you lost?
    //     </div>
    //     <div className="pt-[10px] text-[#FFFFFF] text-[16px] text-center leading-[21.86px]">
    //       Looks like the page you were looking
    //       <br />
    //       for is not found.
    //     </div>
    //     <div className="pt-[20px] flex justify-center">
    //       <div
    //         className="w-[161px] h-[42px] rounded-[40px] border-[1px] p-[10px] border-[#FFFFFF] flex justify-center items-center"
    //         onClick={() => window.history.back()}
    //       >
    //         <span className="text-white text-[16px] ">Go home</span>
    //       </div>
    //     </div>
    //     {/* <button
    //       className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
    //       onClick={() => window.history.back()}
    //     >
    //       Вернуться назад
    //     </button> */}
    //   </div>
    // </div>
  );
};

export default NotFoundPage;
