import React, { useEffect, useState, useRef } from "react";

export default function Component({
  handleChangeValue,
  value,
  placeholder,
  rows,
  maxLength = null,
  delimiter = true,
  borderColor = "E8ECF4",
  disabled = false,
}: any) {
  const textareaRefValue = useRef(null);

  const handleChangeRefValue = (event: any) => {
    const value = event.target.value;

    if (delimiter) {
      const newValue = value.replace("\n", "");
      handleChangeValue(newValue);
    } else {
      handleChangeValue(value);
    }

    // @ts-ignore
    textareaRefValue.current.style.height = "auto";
    // @ts-ignore
    textareaRefValue.current.style.height = `${textareaRefValue.current.scrollHeight}px`;
  };

  return (
    <div className="">
      {maxLength ? (
        <textarea
          ref={textareaRefValue}
          maxLength={maxLength}
          placeholder={placeholder}
          className={`w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#${borderColor}] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden`}
          value={value}
          onChange={handleChangeRefValue}
          rows={rows} // Начнем с одной строки
          disabled={disabled}
        />
      ) : (
        <textarea
          ref={textareaRefValue}
          placeholder={placeholder}
          className={`w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#${borderColor}] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden`}
          value={value}
          onChange={handleChangeRefValue}
          rows={rows} // Начнем с одной строки
          disabled={disabled}
        />
      )}
    </div>
  );
}
