import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import { useNavigate, Link } from "react-router-dom";
import * as constants from "../../constants";
import * as actions from "../../actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as utils from "../../utils";
import LoadingScreen from "../../ui/LoadingScreen";
import ErrorPage from "../../ui/ErrorPage";
import ProductDetailImageSlider from "./ProductDetailImageSlider";

export async function getProfileDataAndInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getProfileDataAndInfo,
    dispatch,
    `api/profile/data_and_info_by_username/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Page() {
  const [profileName, setProfileName] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [price, setPrice] = useState("Basic");
  const [listOfImages, setListOfImages] = useState([
    "/images/product_detail/ProductSlider1.jpg",
    "/images/product_detail/ProductSlider2.jpg",
  ]);

  const dispatch = useDispatch();
  const params = useParams();

  const getProfileDataAndInfoStore = useSelector(
    (state: any) => state.getProfileDataAndInfoStore
  );

  useEffect(() => {
    getProfileDataAndInfoFunc(dispatch, "", { username: params.userUsername });
  }, []);

  useEffect(() => {
    console.log("getProfileDataAndInfoStore", getProfileDataAndInfoStore);
    if (getProfileDataAndInfoStore && getProfileDataAndInfoStore.data) {
      setProfileName(getProfileDataAndInfoStore.data.info.profile_name);
      setImageStr(getProfileDataAndInfoStore.data.info.str_path);
    }
  }, [getProfileDataAndInfoStore]);

  if (getProfileDataAndInfoStore && getProfileDataAndInfoStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getProfileDataAndInfoStore.load} />
      </div>
    );
  }

  if (
    getProfileDataAndInfoStore &&
    (getProfileDataAndInfoStore.fail || getProfileDataAndInfoStore.error)
  ) {
    return (
      <div>
        <ErrorPage error={"Страница не существует"} />
      </div>
    );
  }

  return (
    <div className="flex justify-center break-words">
      <div className="font-manrope w-screen max-w-md min-h-screen bg-white">
        <div className="w-full fixed inset-x-0 z-10 bg-white">
          <div className="my-[15px] w-full px-[24px]">
            <div className="flex justify-start items-center">
              {!imageStr ? (
                <Icon
                  icon="carbon:user-avatar-filled"
                  className="w-[40px] h-[40px] text-[#F7F8F9]"
                />
              ) : (
                <Avatar
                  src={imageStr}
                  alt="avatar"
                  className="w-[40px] h-[40px]"
                />
              )}
              <div className="ml-[10px] text-center text-[18px] leading-[28px] font-semibold text-[#161616]">
                {profileName}
              </div>
            </div>
          </div>
        </div>

        <div className="pb-[40px] pt-[70px]">
          <div className="flex flex-col">
            <ProductDetailImageSlider listOfImages={listOfImages} />
            <div className="px-[24px]">
              <div className="mt-[10px] text-[26px] font-semibold leading-[32.78px] text-[#161616]">
                Масштабирование бизнеса через инвестиции
              </div>
              <div className="mt-[15px]">
                {/* <div className="font-medium text-[18px] leading-[21.86px] text-[#161616]">
                  This is full description of the product
                </div>
                <div className="text-[16px] leading-[19.12px] text-[#161616] font-normal">
                  <ol className="list-disc pl-[24px] mt-[10px]">
                    <li>Feature one</li>
                    <li>Feature two</li>
                    <li>Feature three</li>
                  </ol>
                  <div className="mt-[10px]">
                    Some additional information about the product just to fill
                    the space for illustration of the product description
                    section.
                  </div>
                  <div className="mt-[10px]">What is included?</div>
                  <ol className="list-disc pl-[24px] mt-[10px]">
                    <li>All-in-one dashboard</li>
                    <li>Goal settings</li>
                    <li>Project & Task Management</li>
                    <li>Knowledge Base</li>
                    <li>Quotes & Highlights</li>
                    <li>Readinf List</li>
                    <li>Weekly Review</li>
                  </ol>
                </div> */}
                <div className="text-[14px] text-[#161616] leading-[16.39px] font-medium">
                  Workshop от <span className="font-bold">@club10.kz</span>{" "}
                  совместно с <span className="font-bold">@ayan.birimzhan</span>{" "}
                  по масштабированию бизнеса через инвестиции
                  <br />
                  <br />
                  <span className="font-bold">Аян Бірімжан</span> - основатель
                  сети кофеен Health Project и партнер диджитал кофеен Drinkit в
                  Астане.
                  <br />
                  <br />
                  <span className="font-bold">На workshop мы обсудим:</span>
                  <br />
                  <br />
                  <ol className="list-disc pl-[24px]">
                    <li>как проводить оценку бизнеса</li>
                    <li>как удалось привлечь инвестиции 50 раз</li>
                    <li>
                      как правильно масштабировать бизнес и выходить на новый
                      уровень
                    </li>
                  </ol>
                  <br />
                  <span className="font-bold">Дата:</span> 01.06.2024
                  <br />
                  <span className="font-bold">Время:</span> 11:00 - 16:00
                  <br />
                  <br />
                  Доп. вопросы по номеру: +7 747 746 5650 Алишер
                </div>
              </div>

              <div className="mt-[30px] flex justify-center">
                <div
                  className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                  onClick={() => {
                    setPrice("Basic");
                  }}
                >
                  <div className="">
                    <div className="flex justify-between items-center">
                      <div className="h-[35px] rounded-[40px] bg-[#161616] text-[16px] text-white p-[16px] flex items-center mr-[15px]">
                        4.900 ₸
                      </div>
                      <div className="">
                        <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                          Базовый
                        </div>
                        <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          Вход на мероприятие
                        </div>
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="description"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={price === "Basic"}
                    onChange={() => {
                      setPrice("Basic");
                    }}
                  />
                </div>
              </div>

              <div className="mt-[10px] flex justify-center">
                <div
                  className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                  onClick={() => {
                    setPrice("Standard");
                  }}
                >
                  <div className="">
                    <div className="flex justify-between items-center">
                      <div className="h-[35px] rounded-[40px] bg-[#161616] text-[16px] text-white flex justify-center px-[16px] items-center mr-[15px]">
                        14.900 ₸
                      </div>
                      <div className="">
                        <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                          Стандартный
                        </div>
                        <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          - Вход на мероприятие
                        </div>
                        <div className="text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          - Кейтеринг и нетворкинг
                        </div>
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="description"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={price === "Standard"}
                    onChange={() => {
                      setPrice("Standard");
                    }}
                  />
                </div>
              </div>

              <div className="mt-[10px] flex justify-center">
                <div
                  className="w-full rounded-[10px] flex justify-between items-center shadow-custom p-[15px]"
                  onClick={() => {
                    setPrice("Ultra");
                  }}
                >
                  <div className="">
                    <div className="flex justify-between items-center">
                      <div className="h-[35px] rounded-[40px] bg-[#161616] text-[16px] text-white p-[16px] flex items-center mr-[15px]">
                        24.900 ₸
                      </div>
                      <div className="">
                        <div className="text-[18px] font-semibold leading-[21.86px] text-[#161616]">
                          Ультра
                        </div>
                        <div className="mt-[5px] text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          - Вход на мероприятие
                        </div>
                        <div className="text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          - Кейтеринг и нетворкинг
                        </div>
                        <div className="text-[14px] font-normal leading-[16.39px] text-[#161616]">
                          - Личная сессия с Аяном
                        </div>
                      </div>
                    </div>
                  </div>
                  <Radio
                    name="description"
                    containerProps={{
                      className: "",
                    }}
                    crossOrigin={""}
                    checked={price === "Ultra"}
                    onChange={() => {
                      setPrice("Ultra");
                    }}
                  />
                </div>
              </div>

              <div className="mt-[30px]">
                <button
                  className="w-full h-[47px] rounded-[10px] text-[#161616] text-center text-[18px] leading-[22px] border-[1px] border-[#161616]"
                  // onClick={handleButtonClickAvatar}
                >
                  Добавить в корзину
                </button>
              </div>

              <div className="mt-[10px] flex justify-center">
                <button
                  className="w-full h-[50px] rounded-xl bg-black text-white text-[18px]"
                  // onClick={handleChangeStep}
                >
                  Купить сейчас
                </button>
              </div>

              <div className="mt-[30px]">
                <div className="flex justify-between items-center">
                  <div className="font-medium leading-[19.12px] text-[16px] text-[#161616]">
                    Review
                  </div>
                  <div className="flex space-x-1 items-end">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                      4.9 (241 ratings)
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                  </div>
                  <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                    225 ratings
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                  </div>
                  <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                    10 ratings
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                  </div>
                  <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                    2 ratings
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                  </div>
                  <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                    2 ratings
                  </div>
                </div>
              </div>

              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex space-x-2">
                    <img
                      src="/images/product_detail/star_yellow.svg"
                      alt="star_yellow.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                    <img
                      src="/images/product_detail/star_gray.svg"
                      alt="star_gray.svg"
                      className="w-[17.07px] h-[16px]"
                    />
                  </div>
                  <div className="font-normal leading-[16.39px] text-[14px] text-[#161616]">
                    2 ratings
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
