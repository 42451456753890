import React, { useState, useRef, useEffect } from "react";

const ImagePreview = ({ src, onRemove }: any) => (
  <div className="relative mb-4">
    <img src={src} alt="Uploaded" className="w-full h-auto rounded-md" />
    <button
      onClick={onRemove}
      className="absolute top-1 right-1 bg-red-500 text-white px-[5px] rounded-full"
    >
      ✕
    </button>
  </div>
);

const AutoResizeTextarea = ({ id, value, onChange, onRemove }: any) => {
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  useEffect(() => {
    console.log("textarea id", id);
  }, [id]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      //@ts-ignore
      textarea.style.height = "auto";
      //@ts-ignore
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault(); // Останавливаем потерю фокуса перед удалением
    onRemove(id);
  };

  return (
    <div className="relative mb-4">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="Enter your text here..."
        className="w-full p-2 border border-gray-300 rounded-md resize-none overflow-hidden"
        rows={1}
      />
      {isFocused && (
        <button
          onMouseDown={handleMouseDown}
          className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full"
        >
          ✕
        </button>
      )}
    </div>
  );
};

export default function TextareaContainer() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    console.log("content", content);
  }, [content]);

  const handleInputChange = (id: number, value: any) => {
    setContent(
      //@ts-ignore
      content.map((item) => (item.id === id ? { ...item, text: value } : item))
    );
  };

  const handleRemoveItem = (id: number) => {
    console.log("remove id", id);
    //@ts-ignore
    setContent(content.filter((item) => item.id !== id));
  };

  const addTextarea = () => {
    setContent([
      //@ts-ignore
      ...content,
      //@ts-ignore
      { id: content.length + 1, type: "textarea", text: "" },
    ]);
  };

  const addImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setContent([
          //@ts-ignore
          ...content,
          //@ts-ignore
          { id: Date.now(), type: "image", src: reader.result },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="mb-4">
        <button
          onClick={addTextarea}
          className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
        >
          Add Textarea
        </button>
        <label className="bg-green-500 text-white px-4 py-2 rounded-md cursor-pointer">
          Upload Image
          <input
            type="file"
            accept="image/*"
            onChange={addImage}
            className="hidden"
          />
        </label>
      </div>

      {content.map((item) =>
        //@ts-ignore
        item.type === "textarea" ? (
          <AutoResizeTextarea
            key={
              //@ts-ignore
              item.id
            }
            id={
              //@ts-ignore
              item.id
            }
            value={
              //@ts-ignore
              item.text
            }
            onChange={handleInputChange}
            onRemove={handleRemoveItem}
          />
        ) : (
          <ImagePreview
            key={
              //@ts-ignore
              item.id
            }
            src={
              //@ts-ignore
              item.src
            }
            onRemove={
              //@ts-ignore
              () => handleRemoveItem(item.id)
            }
          />
        )
      )}
    </div>
  );
}
