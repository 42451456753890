import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Component({
  notify,
  listOfProductImages,
  handleChangeListProductImage,
  handleAddProductImage,
  handleDeleteProductImage,
}: any) {
  const { t, i18n } = useTranslation();

  const [imageThumbnail, setImageThumbnail] = useState(null);
  const [croppedImageThumbnail, setCroppedImageThumbnail] = useState(null);
  const [modalIsOpenThumbnail, setModalIsOpenThumbnail] = useState(false);
  const [error, setError] = useState(null);
  const cropperThumbnailRef = useRef(null);

  // const openModalThumbnail = () => setModalIsOpenThumbnail(true);
  // const closeModalThumbnail = () => {
  //   setImageThumbnail(null); // Сбрасываем выбранное изображение при закрытии модального окна
  //   setModalIsOpenThumbnail(false);
  // };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];
  //   const isValidImage = file.type.startsWith("image/");

  //   if (!isValidImage) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error1")
  //       // "Unsupported file format. Please select an image (jpg, jpeg, png)."
  //     );
  //     return;
  //   }

  //   if (file.size > constants.MaxSizeAvatar * 1024 * 1024) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error2")
  //       // `File size exceeds ${constants.MaxSizeAvatar}MB limit. Please select a smaller file.`
  //     );
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     //@ts-ignore
  //     setImageThumbnail(reader.result);
  //     setError(null);
  //     openModalThumbnail();
  //   };
  //   reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   onDrop,
  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: false, // Разрешаем выбор только одного файла
  //   //@ts-ignore
  //   // accept: "image/*", // Разрешаем только изображения
  //   accept: {
  //     "image/*": [".jpeg", ".jpg", ".png", ".svg"],
  //   },
  // });

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperThumbnailRef.current.cropper;
  //   const canvas = cropper.getCroppedCanvas({ width: 400, height: 300 }); // 4:3 aspect ratio
  //   setCroppedImageThumbnail(canvas.toDataURL("image/jpeg"));
  //   closeModalThumbnail();
  // };

  // useEffect(() => {
  //   if (croppedImageThumbnail) {
  //     // console.log("croppedImage", croppedImage);
  //     // handleChangeProductInfo("cover", croppedImageThumbnail);
  //     handleAddProductImage(croppedImageThumbnail);
  //   }
  // }, [croppedImageThumbnail]);

  // Upload IMAGE NEW

  // const handleMutipleFileChange = (event: any) => {
  //   const files = event.target.files;

  //   for (let i = 0; i < files.length; i++) {
  //     handleFileChange(files[i]);
  //   }
  // };

  // const handleFileChange = (event: any) => {
  //   const files = event.target.files;

  //   // let listOfProductImagesTmp = structuredClone(listOfProductImages);

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];

  //     if (file) {
  //       const isValidImage = file.type.startsWith("image/");

  //       if (!isValidImage) {
  //         notify(t("image_upload_error1"));
  //         return;
  //       }

  //       if (file.size > 5 * 1024 * 1024) {
  //         notify(t("image_upload_error2"));
  //         return;
  //       }

  //       if (file.type === "image/heic" || file.type === "image/heif") {
  //         // Обработка HEIC или HEIF файла
  //         convertHeicOrHeifToBase64(file);
  //       } else if (file.size <= 5 * 1024 * 1024) {
  //         // Обработка обычного изображения (до 5 МБ)
  //         convertImageToBase64(file);
  //       } else {
  //         notify("Размер файла превышает 5 МБ.");
  //       }
  //     }

  //     // @ts-ignore
  //     // listOfProductImagesTmp.push({
  //     //   id: listOfProductImagesTmp.length + 1,
  //     //   file: fileStr,
  //     // });
  //   }

  //   // handleChangeListProductImage(listOfProductImagesTmp);
  // };

  const [listOfImages, setListOfImages] = useState(listOfProductImages);

  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files); // Преобразуем FileList в массив

    files.forEach((file) => {
      if (file) {
        //@ts-ignore
        const isValidImage = file.type.startsWith("image/");

        if (!isValidImage) {
          notify(t("image_upload_error1"));
          return;
        }
        //@ts-ignore
        if (file.size > 5 * 1024 * 1024) {
          notify(t("image_upload_error2"));
          return;
        }
        //@ts-ignore
        if (file.type === "image/heic" || file.type === "image/heif") {
          // Обработка HEIC или HEIF файла
          convertHeicOrHeifToBase64(file);
        } else {
          // Обработка обычного изображения (до 5 МБ)
          convertImageToBase64(file);
        }
      }
    });
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleAddProductImageThere(result);
      //@ts-ignore
      // res = result;
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleAddProductImageThere(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("productImage").click();
  };

  const handleAddProductImageThere = (fileStr: any) => {
    setListOfImages(
      //@ts-ignore
      (prevImages: any) => [
        ...prevImages,
        { id: prevImages.length + 1, file: fileStr, delete: false },
      ]
    );
  };

  useEffect(() => {
    // console.log("listOfImages", listOfImages);
    handleChangeListProductImage(listOfImages);
  }, [listOfImages]);

  return (
    <div className="">
      {listOfProductImages && listOfProductImages.length > 0 && (
        <div className="mt-[10px]">
          <div className="grid grid-cols-2 gap-[10px]">
            {listOfProductImages.map(
              (elem: any) =>
                !elem.delete && (
                  <div key={elem.id} className="flex flex-col">
                    {/* <img
                  src={elem.file}
                  alt="GalleryImage"
                  className="w-[144px] h-[108px] object-cover object-center rounded-[10px]"
                /> */}

                    <div className="relative w-full pt-[100%] bg-gray-200 overflow-hidden rounded-[10px]">
                      <img
                        src={elem.file}
                        alt={`GalleryImage${elem.id}`}
                        className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        handleDeleteProductImage(elem.id);
                      }}
                    >
                      <div className="mt-[3px] w-full text-red-500 text-[14px] border-[1px] border-red-500 text-center rounded-[4px]">
                        {t("delete")}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}

      <div className="mt-[10px]">
        <div
          className="w-full border-[1px] border-[#8391A1] border-dashed bg-[#F7F8F9] rounded-[10px]"
          onClick={handleButtonImageInputClick}
        >
          <div className="mt-[15px] flex justify-center">
            <img
              src="/images/create_block/upload_file.svg"
              alt="upload_file.svg"
              className="w-[40px] h-[40px]"
            />
          </div>
          <div className="px-[24px] pb-[15px]">
            <div className="mt-[10px] text-[14px] leading-[16.39px] text-[#8391A1] flex justify-center text-center">
              {t("cover_image_text")}
            </div>
          </div>
        </div>
      </div>

      <input
        id="productImage"
        type="file"
        multiple
        accept=".jpg,.jpeg,.png,.heic,.heif"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      {isConverting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-center">
            {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
            <div className="text-center mt-[5px] text-[#161616] text-[20px]">
              Идет конвертация ...
            </div>
          </div>
        </div>
      )}

      {/* {!productInfo.cover ? (
        <div className="mt-[10px]">
          <div
            className="w-full border-[2px] border-[#8391A1] border-dotted bg-[#F7F8F9] rounded-[10px]"
            onClick={open}
          >
            <div className="mt-[15px] flex justify-center">
              <img
                src="/images/create_block/upload_file.svg"
                alt="upload_file.svg"
                className="w-[40px] h-[40px]"
              />
            </div>
            <div className="px-[24px] pb-[15px]">
              <div className="mt-[10px] text-[14px] leading-[16.39px] text-[#8391A1] flex justify-center text-center">
                Upload cover images. Your image should be at least 1280x720px
                and must be in JPG or PNG format.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-[10px]">
          <div
            className="w-full border-[2px] border-[#8391A1] border-dotted bg-[#F7F8F9] rounded-[10px]"
            onClick={open}
          >
            <div className="mt-[15px] flex justify-center items-center">
              <img
                src={productInfo.cover}
                alt="cover"
                className="w-[100px] h-[75px] flex-shrink-0 rounded-[10px]"
              />
            </div>
            <div className="px-[24px] pb-[15px]">
              <div className="mt-[10px] text-[14px] leading-[16.39px] text-[#8391A1] flex justify-center text-center">
                Upload cover images. Your image should be at least 1280x720px
                and must be in JPG or PNG format.
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* <div {...getRootProps()} className="hidden">
        <input {...getInputProps()} />
      </div>

      <Modal
        isOpen={modalIsOpenThumbnail}
        onRequestClose={closeModalThumbnail}
        className="flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        appElement={document.getElementById("root")}
        ariaHideApp={false}
      >
        <div className="bg-white p-4 rounded shadow-lg">
          {imageThumbnail && (
            <div>
              <Cropper
                src={imageThumbnail}
                style={{ width: 400, height: 300 }}
                //   initialAspectRatio={1}
                //   aspectRatio={1}
                initialAspectRatio={4 / 3}
                aspectRatio={4 / 3}
                viewMode={1}
                guides={false}
                cropBoxResizable={false}
                dragMode="move"
                cropBoxMovable={false}
                ref={cropperThumbnailRef}
              />
              <div className="flex justify-between mt-4 px-[30px]">
                <button
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                  onClick={getCroppedImage}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("crop_image")}
                  </div>
                </button>
                <button
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                  onClick={closeModalThumbnail}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("cancel")}
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal> */}
    </div>
  );
}
