import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";

export async function getListCollaborationFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getListCollaboration,
    dispatch,
    `api/collaboration/get/`,
    "get",
    "",
    form,
    true
  )();
}

export async function giveCollaborationAnswerFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.giveCollaborationAnswer,
    dispatch,
    `api/collaboration/answer/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("received");
  const [receivedList, setReceivedList] = useState([]);
  const [sendList, setSendList] = useState([]);

  useEffect(() => {
    getListCollaborationFunc(dispatch, "", {});

    const MakeCollaborationDataStorage = utils.LocalStorage.get(
      "MakeCollaborationData"
    );

    if (MakeCollaborationDataStorage && MakeCollaborationDataStorage.status) {
      setStatus(MakeCollaborationDataStorage.status);
    }

    dispatch({ type: constants.BecomeADistributorProductLink.reset });
    dispatch({ type: constants.allListOfProducts.reset });
    dispatch({ type: constants.createCollaboration.reset });
    utils.LocalStorage.remove("NewCollaborationData");
  }, []);

  useEffect(() => {
    utils.LocalStorage.set("MakeCollaborationData", { status: status });
  }, [status]);

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const changeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const handleAccept = (elemId: string) => {
    giveCollaborationAnswerFunc(dispatch, "", {
      collaborationId: elemId,
      answer: "accept",
    });
  };

  const handleDecline = (elemId: string) => {
    giveCollaborationAnswerFunc(dispatch, "", {
      collaborationId: elemId,
      answer: "decline",
    });
  };

  const handleDelete = (elemId: string) => {
    giveCollaborationAnswerFunc(dispatch, "", {
      collaborationId: elemId,
      answer: "delete",
    });
  };

  const giveCollaborationAnswerStore = useSelector(
    (state: any) => state.giveCollaborationAnswerStore
  );

  const getListCollaborationStore = useSelector(
    (state: any) => state.getListCollaborationStore
  );

  useEffect(() => {
    console.log("giveCollaborationAnswerStore", giveCollaborationAnswerStore);
    if (giveCollaborationAnswerStore && giveCollaborationAnswerStore.data) {
      window.location.reload();
    }
  }, [giveCollaborationAnswerStore]);

  useEffect(() => {
    // console.log("getListCollaborationStore", getListCollaborationStore);
    if (getListCollaborationStore && getListCollaborationStore.data) {
      setReceivedList(getListCollaborationStore.data.response.received);
      setSendList(getListCollaborationStore.data.response.send);
    }
  }, [getListCollaborationStore]);

  if (giveCollaborationAnswerStore && giveCollaborationAnswerStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={giveCollaborationAnswerStore.load} />
      </div>
    );
  }

  if (getListCollaborationStore && getListCollaborationStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getListCollaborationStore.load} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[20px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/home");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("make_collaboration")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    // <Icon
                    //   icon="iconamoon:menu-burger-horizontal-light"
                    //   className="w-[24px] h-[26px]"
                    // />
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[66px]">
              <div
                className="h-[50px] rounded-[10px] border-[#161616] text-[#161616] border flex justify-center items-center text-[18px] leading-[22px]"
                onClick={() => {
                  navigate("new");
                }}
              >
                {t("new_collaboration")}
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="text-[#161616] text-[18px] leading-[22px] font-semibold">
                {t("collaborations_history")}
              </div>
            </div>

            <div className="mt-[15px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className={`ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] ${status === "received" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    changeStatus("received");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("received")}
                  </div>
                </div>
                <div
                  className={`mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]  ${status === "send" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    changeStatus("send");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("send")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[5px]">
              {status === "received" && (
                <div>
                  {receivedList.map((elem: any, index: number) => (
                    <div className="" key={`received-${index}`}>
                      <div>
                        {elem.status === "waiting" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#105DFB]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleAccept(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("accept")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/accept_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={() => {
                                    handleDecline(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("decline")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/decline_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "active" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#18C07A]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "declined" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#FF2C20]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "finished" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#18C07A]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {status === "send" && (
                <div>
                  {sendList.map((elem: any, index: number) => (
                    <div className="" key={`send-${index}`}>
                      <div>
                        {elem.status === "waiting" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#105DFB]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleAccept(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("accept")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/accept_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={() => {
                                    handleDecline(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("decline")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/decline_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "active" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#18C07A]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "declined" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#FF2C20]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                              <hr className="border-gray-300" />
                              <MenuItem>
                                <div
                                  className="flex justify-between items-center"
                                  onClick={(event) => {
                                    handleDelete(elem.id);
                                  }}
                                >
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("delete")}
                                  </div>
                                  <img
                                    src="/images/make_collaboration/delete_icon.svg"
                                    alt=""
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {elem.status === "finished" && (
                          <Menu>
                            <MenuHandler>
                              <div
                                className="mt-[10px] w-full rounded-[10px] py-[10px] pl-[10px] pr-[20px] shadow-custom"
                                onClick={() => {}}
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex space-x-[15px] items-center">
                                    {elem.thumbnail === "" ? (
                                      <div className="w-[100px] h-[75px] bg-[#F7F8F9] rounded-[5px]"></div>
                                    ) : (
                                      <img
                                        src={elem.thumbnail}
                                        alt="image"
                                        className="w-[100px] h-[75px] object-center object-cover rounded-[5px]"
                                      />
                                    )}
                                    <div className="ml-[10px]">
                                      <div className="text-[16px] text-[#161616] font-semibold">
                                        {t("status")}: {t(elem.status)}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        @{elem.username}
                                      </div>
                                      <div className="text-[14px] leading-[22.5px] text-[#161616]">
                                        {t("date")}: {elem.date}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-[10px] h-[10px] rounded-full bg-[#18C07A]"></div>
                                </div>
                              </div>
                            </MenuHandler>
                            <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                              <MenuItem
                                onClick={() => {
                                  navigate(`${elem.id}`);
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#161616] text-[16px] leading-[22px]">
                                    {t("preview")}
                                  </div>

                                  <img
                                    src="/images/profile/preview.svg"
                                    alt="preview.svg"
                                    className="w-[24px] h-[24px]"
                                  />
                                </div>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      ;
    </utils.AuthGuard>
  );
}
