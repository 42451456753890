import React, { useEffect, useState, useRef, useCallback } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Avatar,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Page({
  profileName,
  changeStatus,
  urlLinks,
  handleChangeUrlLinks,
  handleSendProfileData,
  messageImageLinksUrl,
  imageLinksUrl,
  notify,
  handleImageLinksUrlChange,
  handleButtonClickImageLinksUrl,
}: any) {
  const { t, i18n } = useTranslation();

  //   const [profileName, setProfileName] = useState("Amanzhol Shungeyev");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const textareaRefDescription = useRef(null);
  const textareaRefUrl = useRef(null);
  const textareaRefTileTitle = useRef(null);

  const handleChangeRefDescription = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    handleChangeUrlLinks("", "", newValue, "description");
    // Устанавливаем высоту textarea так, чтобы она соответствовала высоте содержимого
    // @ts-ignore
    textareaRefDescription.current.style.height = "auto";
    // @ts-ignore
    textareaRefDescription.current.style.height = `${textareaRefDescription.current.scrollHeight}px`;
  };

  const handleChangeRefTileTitle = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    handleChangeUrlLinks("", newValue, "", "title");
    // Устанавливаем высоту textarea так, чтобы она соответствовала высоте содержимого
    // @ts-ignore
    textareaRefTileTitle.current.style.height = "auto";
    // @ts-ignore
    textareaRefTileTitle.current.style.height = `${textareaRefTileTitle.current.scrollHeight}px`;
  };

  const handleChangeRefUrl = (event: any) => {
    const value = event.target.value;
    const newValue = value.replace("\n", "");
    handleChangeUrlLinks(newValue, "", "", "url");
    // Устанавливаем высоту textarea так, чтобы она соответствовала высоте содержимого
    // @ts-ignore
    textareaRefUrl.current.style.height = "auto";
    // @ts-ignore
    textareaRefUrl.current.style.height = `${textareaRefUrl.current.scrollHeight}px`;
  };

  // useEffect(() => {
  //   console.log("text", text);
  // }, [text]);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const cropperRef = useRef(null);
  const [imageType, setImageType] = useState(null);

  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => {
  //   setImage(null); // Сбрасываем выбранное изображение при закрытии модального окна
  //   setModalIsOpen(false);
  // };

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   const file = acceptedFiles[0];
  //   const isValidImage = file.type.startsWith("image/");

  //   if (!isValidImage) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error1")
  //       // "Unsupported file format. Please select an image (jpg, jpeg, png)."
  //     );
  //     return;
  //   }

  //   if (file.size > constants.MaxSizeImageLinksUrl * 1024 * 1024) {
  //     setError(
  //       //@ts-ignore
  //       t("image_upload_error2")
  //       // `File size exceeds ${constants.MaxSizeImageLinksUrl}MB limit. Please select a smaller file.`
  //     );
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     //@ts-ignore
  //     setImage(reader.result);
  //     setImageType(file.type);
  //     setError(null);
  //     openModal();
  //   };
  //   reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, open } = useDropzone({
  //   onDrop,
  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: false, // Разрешаем выбор только одного файла
  //   //@ts-ignore
  //   accept: "image/*", // Разрешаем только изображения
  // });

  // // const getCroppedImage = () => {
  // //   //@ts-ignore
  // //   const cropper = cropperRef.current.cropper;
  // //   const canvas = cropper.getCroppedCanvas({ width: 400, height: 300 }); // 4:3 aspect ratio
  // //   setCroppedImage(canvas.toDataURL("image/jpeg"));
  // //   closeModal();
  // // };

  // const getCroppedImage = () => {
  //   //@ts-ignore
  //   const cropper = cropperRef.current.cropper;
  //   const canvas = cropper.getCroppedCanvas();

  //   // определяем mime тип из оригинального типа изображения
  //   const mimeType = imageType || "image/jpeg"; // используем оригинальный тип
  //   setCroppedImage(canvas.toDataURL(mimeType));
  //   closeModal();
  // };

  // useEffect(() => {
  //   if (croppedImage) {
  //     // console.log("croppedImage", croppedImage);
  //     handleImageLinksUrlChange(croppedImage);
  //   }
  // }, [croppedImage]);

  // Image NEW

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleImageLinksUrlChange(result);
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleImageLinksUrlChange(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("linksUrlImage").click();
  };

  const [errorCreate, setErrorCreate] = useState("");

  useEffect(() => {
    // "Side slider", "Big block"
    if (urlLinks.url === "") {
      setErrorCreate(t("create_block_urls_error1"));
    } else if (urlLinks.title === "") {
      setErrorCreate(t("create_block_urls_error2"));
    } else if (!imageLinksUrl) {
      setErrorCreate(t("create_block_urls_error3"));
    } else {
      setErrorCreate("");
    }
  }, [urlLinks, imageLinksUrl]);

  return (
    // <div className="font-manrope px-[24px] w-screen max-w-md min-h-screen">
    <div className="font-manrope px-[24px] w-screen max-w-md">
      <BurgerMenu
        isOpen={burgerMenuOpen}
        profileName={profileName}
        toggleBurgerMenu={toggleBurgerMenu}
      />
      <div className="flex flex-col">
        <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
          <div className="h-[56px] flex justify-between items-center">
            <button
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
              <div className="p-[10px]">
                <img
                  src="/images/back_arrow.svg"
                  alt="back_arrow.svg"
                  className="w-[7px] h-[14px]"
                />
              </div>
            </button>
            <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
              {t("edit_block")}
            </div>
            <button onClick={toggleBurgerMenu}>
              {!burgerMenuOpen ? (
                <Icon
                  icon="iconamoon:menu-burger-horizontal-light"
                  className="w-[24px] h-[26px]"
                />
              ) : (
                <span> </span>
              )}
            </button>
          </div>
        </div>

        <div className="mt-[61px]">
          <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
            <div
              className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
              onClick={() => {
                changeStatus("Structure");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("structure")}
              </div>
            </div>
            <div
              className="mr-[2px] w-full h-[26px] bg-white flex justify-center items-center rounded-[40px] shadow-custom"
              onClick={() => {
                changeStatus("Content");
              }}
            >
              <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                {t("content")}
              </div>
            </div>
          </div>
        </div>

        {/* <input
          type="text"
          value={urlLinks.url}
          placeholder="URL"
          className="mt-[25px] w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
          onChange={(e) => {
            handleChangeUrlLinks(e.target.value, "", "");
          }}
        /> */}

        <textarea
          ref={textareaRefUrl}
          placeholder={t("url")}
          className="mt-[25px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
          value={urlLinks.url}
          onChange={handleChangeRefUrl}
          rows={1} // Начнем с одной строки
        />

        {/* <input
          type="text"
          value={urlLinks.title}
          maxLength={30}
          placeholder="Tile title"
          className="mt-[10px] w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
          onChange={(e) => {
            handleChangeUrlLinks("", e.target.value, "");
          }}
        /> */}

        <textarea
          ref={textareaRefTileTitle}
          maxLength={30}
          placeholder={t("tile_title")}
          className="mt-[10px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
          value={urlLinks.title}
          onChange={handleChangeRefTileTitle}
          rows={1} // Начнем с одной строки
        />

        <div className="mt-[5px] flex justify-end">
          <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
            {urlLinks.title.length}/30
          </span>
        </div>

        {/* <input
          type="text"
          value={urlLinks.description}
          maxLength={30}
          placeholder="Tile description (optional)"
          className="mt-[5px] w-full h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
          onChange={(e) => {
            handleChangeUrlLinks("", "", e.target.value);
          }}
        /> */}

        <textarea
          ref={textareaRefDescription}
          maxLength={30}
          placeholder={t("tile_description_optional")}
          className="mt-[5px] w-full min-h-[56px] resize-none rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[16px] text-[16px] text-[#161616] bg-[#F7F8F9] p-[15px] overflow-y-hidden"
          value={urlLinks.description}
          onChange={handleChangeRefDescription}
          rows={1} // Начнем с одной строки
        />

        <div className="mt-[5px] flex justify-end">
          <span className="text-[12px] leading-[13.66px] text-[#8391A1]">
            {urlLinks.description.length}/30
          </span>
        </div>

        {isConverting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="text-center">
              {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              </div>
              <div className="text-center mt-[5px] text-[#161616] text-[20px]">
                Идет конвертация ...
              </div>
            </div>
          </div>
        )}

        {!imageLinksUrl ? (
          <div className="mt-[29px] flex justify-between">
            <div
              className="w-[150px] h-[100px] border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dashed rounded-[10px] flex justify-center items-center"
              onClick={handleButtonImageInputClick}
            >
              {/* <Icon
                icon="uil:image-upload"
                className="w-[40px] h-[40px] text-[#8391A1]"
                download_image
              /> */}
              <img
                src="/images/create_block/upload_image.svg"
                alt="upload_image.svg"
                className="w-[40px] h-[40px]"
              />
            </div>
            <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[16.39px]">
              {t("upload_image_links_url_text")}
            </div>
          </div>
        ) : (
          <div className="mt-[29px] flex justify-between">
            <div
              className="w-[150px] h-[100px] bg-[#F7F8F9] rounded-[10px] flex justify-center items-center"
              onClick={handleButtonImageInputClick}
            >
              <img
                src={imageLinksUrl}
                alt="image"
                className="h-[100px] w-[150px] object-center object-cover rounded-[10px]"
              />
            </div>
            <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[16.39px]">
              {t("upload_image_links_url_text")}
            </div>
          </div>
        )}

        <input
          id="linksUrlImage"
          type="file"
          accept=".jpg,.jpeg,.png,.heic,.heif"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {/* <div {...getRootProps()} className="hidden">
          <input {...getInputProps()} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          appElement={document.getElementById("root")}
          ariaHideApp={false}
        >
          <div className="bg-white p-4 rounded shadow-lg">
            {image && (
              <div>
                <Cropper
                  src={image}
                  style={{ height: 400, width: 400 }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  viewMode={1}
                  guides={false}
                  cropBoxResizable={false}
                  dragMode="move"
                  cropBoxMovable={false}
                  ref={cropperRef}
                />
                <div className="flex justify-between mt-4 px-[30px]">
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={getCroppedImage}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("crop_image")}
                    </div>
                  </button>
                  <button
                    className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                    onClick={closeModal}
                  >
                    <div className="text-white text-[18px] leading-[22px] font-semibold">
                      {t("cancel")}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal> */}

        {/* <div className="mt-[29px] flex justify-between">
          <div
            className="w-[150px] h-[100px] border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dotted rounded-[10px] flex justify-center items-center"
            onClick={handleButtonClickImageLinksUrl}
          >
            <input
              id="imageLinksUrlInput"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleImageLinksUrlChange}
              style={{ display: "none" }}
            />
            {!imageLinksUrl ? (
              <Icon
                icon="uil:image-upload"
                className="w-[40px] h-[40px] text-[#8391A1]"
              />
            ) : (
              <img
                src={imageLinksUrl}
                alt="image"
                className="max-h-[100px] max-w-[150px] rounded-[10px]"
              />
            )}
          </div>
          <div className="ml-[10px] w-full text-[12px] text-[#8391A1] leading-[16.39px]">
            Upload Thumbnail. Your image should be at least 400x400px and must
            be in JPG or PNG format.
          </div>
        </div> */}

        {error !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {error}
          </div>
        )}

        {errorCreate !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[14px] leading-[24px]">
            {errorCreate}
          </div>
        )}

        {/* {messageImageLinksUrl !== "" && (
          <div className="mt-[5px] text-start text-[#FF2C20] text-[12px] leading-[24px]">
            {messageImageLinksUrl}
          </div>
        )} */}

        {/* <div className="mt-[10px] w-[342px] border-[#E8ECF4] border-[1px] rounded-[10px] px-[10px]">
          <div className="flex justify-between items-center">
            <div className="pt-[10px] font-medium text-[14px] text-[#161616] leading-[19.12px]">
              Tile 2
            </div>
            <Icon
              icon="fluent:delete-48-regular"
              className="w-[18.5px] h-[19.5px] text-[#FF2C20]"
            />
          </div>
          <input
            type="text"
            value={url2}
            placeholder="URL"
            className="mt-[10px] w-[322px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              setUrl2(e.target.value);
            }}
          />
          <input
            type="text"
            value={title2}
            maxLength={30}
            placeholder="Tile title"
            className="mt-[10px] w-[322px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              setTitle2(e.target.value);
            }}
          />
          <div className="mt-[2.5px] flex justify-end">
            <span className="text-[10px] leading-[13.66px] text-[#8391A1]">
              {title2.length}/30
            </span>
          </div>
          <input
            type="text"
            value={description2}
            maxLength={30}
            placeholder="Tile description (optional)"
            className="mt-[2.5px] w-[322px] h-[56px] rounded-[10px] border-[1px] border-[#E8ECF4] placeholder:text-[#8391A1] placeholder:text-[14px] text-[14px] text-[#161616] bg-[#F7F8F9] px-[15px]"
            onChange={(e) => {
              setDescription2(e.target.value);
            }}
          />
          <div className="mt-[2.5px] flex justify-end">
            <span className="text-[10px] leading-[13.66px] text-[#8391A1]">
              {description2.length}/30
            </span>
          </div>

          <div className="mt-[5px] mb-[10px] flex justify-between">
            <div className="w-[100px] h-[100px] border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dotted rounded-[10px] flex justify-center items-center">
              <Icon
                icon="uil:image-upload"
                className="w-[40px] h-[40px] text-[#8391A1]"
              />
            </div>
            <div className="w-[211px] text-[12px] text-[#8391A1] leading-[16.39px]">
              Upload Thumbnail. Your image should be at least 400x400px and must
              be in JPG or PNG format.
            </div>
          </div>
        </div> */}

        {/* <div className="mt-[10px]">
          <div className="w-[342px] h-[50px] rounded-[10px] border-[1px] border-[#161616] text-[#161616] flex justify-center items-center">
            Add Tile
          </div>
        </div> */}

        <div className="mt-[130px]">
          <div
            className="flex justify-center items-center rounded-[10px] bg-[#161616] w-full h-[50px]"
            onClick={() => {
              if (errorCreate == "") {
                handleSendProfileData("Links/URL");
              }
            }}
          >
            <div className="text-white text-[18px] leading-[22px] font-semibold">
              {t("publish")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
