import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import Modal from "react-modal";
import * as constants from "../../constants";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";

export default function Component({
  notify,
  productThumbnail,
  handleChangeProductThumbnail,
}: any) {
  const { t, i18n } = useTranslation();

  const [imageThumbnail, setImageThumbnail] = useState(null);
  const [croppedImageThumbnail, setCroppedImageThumbnail] = useState(null);
  const [modalIsOpenThumbnail, setModalIsOpenThumbnail] = useState(false);
  const [error, setError] = useState(null);
  const cropperThumbnailRef = useRef(null);

  // Upload IMAGE NEW
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const isValidImage = file.type.startsWith("image/");

      if (!isValidImage) {
        notify(t("image_upload_error1"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        notify(t("image_upload_error2"));
        return;
      }

      if (file.type === "image/heic" || file.type === "image/heif") {
        // Обработка HEIC или HEIF файла
        convertHeicOrHeifToBase64(file);
      } else if (file.size <= 5 * 1024 * 1024) {
        // Обработка обычного изображения (до 5 МБ)
        convertImageToBase64(file);
      } else {
        notify("Размер файла превышает 5 МБ.");
      }
    }
  };

  const convertImageToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      handleChangeProductThumbnail(result);
    };
    reader.readAsDataURL(file);
  };

  const [isConverting, setIsConverting] = useState(false);

  const convertHeicOrHeifToBase64 = async (file: any) => {
    try {
      setIsConverting(true);
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        handleChangeProductThumbnail(result);
      };
      //@ts-ignore
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error("Ошибка при конвертации HEIC/HEIF:", error);
      // alert("Не удалось конвертировать HEIC/HEIF файл.");
    } finally {
      setIsConverting(false); // Сбрасываем состояние "идет конвертация"
    }
  };

  const handleButtonImageInputClick = () => {
    //@ts-ignore
    document.getElementById("postThumbnailImage").click();
  };

  return (
    <div className="">
      {!productThumbnail ? (
        <div className="mt-[10px] flex justify-between">
          <div
            className="w-[100px] h-[100px] flex-shrink-0 border-[1px] border-[#8391A1] bg-[#F7F8F9] border-dashed rounded-[10px] flex justify-center items-center"
            onClick={handleButtonImageInputClick}
          >
            <img
              src="/images/create_block/upload_image.svg"
              alt="upload_image.svg"
              className="w-[40px] h-[40px]"
            />
          </div>
          <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[18px]">
            {t("thumbnail_image_text")}
          </div>
        </div>
      ) : (
        <div className="mt-[10px] flex justify-between">
          <div
            className="w-[100px] h-[100px] flex-shrink-0 bg-[#F7F8F9] rounded-[10px] flex justify-center items-center"
            onClick={handleButtonImageInputClick}
          >
            <img
              src={productThumbnail}
              alt="image"
              className="w-[100px] h-[100px] object-cover object-center rounded-[10px]"
            />
          </div>
          <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[18px]">
            {t("thumbnail_image_text")}
          </div>
        </div>
      )}

      {/* <div className="mt-[10px] flex justify-between" onClick={open}>
              <div
                className="w-[150px] h-[100px] border-[2px] border-[#8391A1] bg-[#F7F8F9] border-dotted rounded-[10px] flex justify-center items-center"
                //   onClick={open}
              >
                <img
                  src="/images/create_block/upload_image.svg"
                  alt="upload_image.svg"
                  className="w-[40px] h-[40px]"
                />
              </div>
              <div className="ml-[10px] w-full text-[14px] text-[#8391A1] leading-[16.39px]">
                Upload thumbnail image. Your image should be at least 1280x720px
                and must be in JPG or PNG format.
              </div>
            </div> */}

      <input
        id="postThumbnailImage"
        type="file"
        accept=".jpg,.jpeg,.png,.heic,.heif"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      {isConverting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-center">
            {/* <div className="loader animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-white mb-4"></div> */}
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
            <div className="text-center mt-[5px] text-[#161616] text-[20px]">
              Идет конвертация ...
            </div>
          </div>
        </div>
      )}

      {/* <div {...getRootProps()} className="hidden">
        <input {...getInputProps()} />
      </div>

      <Modal
        isOpen={modalIsOpenThumbnail}
        onRequestClose={closeModalThumbnail}
        className="flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        appElement={document.getElementById("root")}
        ariaHideApp={false}
      >
        <div className="bg-white p-4 rounded shadow-lg">
          {imageThumbnail && (
            <div>
              <Cropper
                src={imageThumbnail}
                style={{ width: 400, height: 300 }}
                //   initialAspectRatio={1}
                //   aspectRatio={1}
                initialAspectRatio={4 / 3}
                aspectRatio={4 / 3}
                viewMode={1}
                guides={false}
                cropBoxResizable={false}
                dragMode="move"
                cropBoxMovable={false}
                ref={cropperThumbnailRef}
              />
              <div className="flex justify-between mt-4 px-[30px]">
                <button
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                  onClick={getCroppedImage}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("crop_image")}
                  </div>
                </button>
                <button
                  className="flex justify-center items-center rounded-[10px] bg-[#161616] px-4 py-2 h-[50px]"
                  onClick={closeModalThumbnail}
                >
                  <div className="text-white text-[18px] leading-[22px] font-semibold">
                    {t("cancel")}
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal> */}
    </div>
  );
}
