import {
  Switch,
  Avatar,
  list,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Radio,
} from "@material-tailwind/react";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { listOfSocialMediaLinks as listOfSocialMediaLinksMain } from "../ui/ListOfSocialMediaLinks";

export default function Component({
  listOfSocialLinks,
  isEdit,
  accType,
  username,
  isPreview,
  handleCopy,
  lineProfileNameCount,
  iconDesign,
  page = "",
  senderFunc = null,
}: any) {
  const { t, i18n } = useTranslation();

  const [listOfSocialLinksNew, setListOfSocialLinksNew] = useState([]);
  const [firstLineSocialLinks, setFirstLineSocialLinks] = useState(7);

  useEffect(() => {
    if (accType === "minimalist") {
      if (lineProfileNameCount < 2) {
        setFirstLineSocialLinks(7);
      } else {
        setFirstLineSocialLinks(9);
      }
    }
  }, [lineProfileNameCount]);

  // useEffect(() => {
  //   console.log("firstLineSocialLinks", firstLineSocialLinks);
  // }, [firstLineSocialLinks]);

  const goUrl = (url: string, value: string) => {
    // https://www.instagram.com/{username}
    // let newUrl = url.replace("{username}", value);

    if (value.includes("https://")) {
      return value;
    } else {
      return `https://${value}`;
    }

    // return value;
  };

  const handleShare = async (url: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: url,
        });
        console.log("Ссылка успешно поделена!");
      } else {
        throw new Error("Web Share API не поддерживается в вашем браузере.");
      }
    } catch (error) {
      console.error("Ошибка при попытке поделиться ссылкой:", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("listOfSocialLinks", listOfSocialLinks);
    let index = 0;
    let j = 0;
    let tmpListOfSocialLinks = [];
    let tmpList = [];

    let listOfSocialLinksMain = [];

    // {"name":"Apple Music (user)",
    // "checked":true,
    // "value":"asdasd",
    // "placeholder":"Username with @",
    // "url":"https://www.music.apple.com/profile/{username}",
    // "iconMonochrome":"/images/social_media_icons/AppleMusic/AppleMusic.svg",
    // "iconColored":"/images/social_media_icons/AppleMusic/AppleMusic_colored.svg"},
    for (let i = 0; i < listOfSocialLinks.length; i++) {
      for (let j = 0; j < listOfSocialMediaLinksMain.length; j++) {
        if (listOfSocialLinks[i].name === listOfSocialMediaLinksMain[j].name) {
          listOfSocialLinksMain.push({
            name: listOfSocialMediaLinksMain[j].name,
            checked: listOfSocialLinks[i].checked,
            value: listOfSocialLinks[i].value,
            placeholder: listOfSocialMediaLinksMain[j].placeholder,
            url: listOfSocialMediaLinksMain[j].url,
            iconRoundBW: listOfSocialMediaLinksMain[j].iconRoundBW,
            iconRound: listOfSocialMediaLinksMain[j].iconRound,
            iconOriginalBW: listOfSocialMediaLinksMain[j].iconOriginalBW,
            iconOriginal: listOfSocialMediaLinksMain[j].iconOriginal,
          });
        }
      }
    }

    listOfSocialLinksMain.push(listOfSocialLinks[listOfSocialLinks.length - 1]);

    for (let i = 0; i < listOfSocialLinks.length; i++) {
      j += 1;

      index += 1;
      // if (accType === "minimalist") {
      //   if (tmpListOfSocialLinks.length === 0) {
      //     if (j === firstLineSocialLinks) {
      //       tmpList.push({ ...listOfSocialLinks[i], index: index });
      //       j = 0;
      //       tmpListOfSocialLinks.push(tmpList);
      //       tmpList = [];
      //     } else {
      //       tmpList.push({ ...listOfSocialLinks[i], index: index });
      //     }
      //   } else if (j === 9) {
      //     tmpList.push({ ...listOfSocialLinks[i], index: index });
      //     j = 0;
      //     tmpListOfSocialLinks.push(tmpList);
      //     tmpList = [];
      //   } else {
      //     tmpList.push({ ...listOfSocialLinks[i], index: index });
      //   }
      // } else {

      if (i === listOfSocialLinksMain.length - 1) {
        // if (j === 7 || j === 6 || j === 5) {
        if (j > 6) {
          tmpListOfSocialLinks.push(tmpList);
          tmpListOfSocialLinks.push([
            { ...listOfSocialLinksMain[i], index: index },
          ]);
          j = 0;
        } else {
          tmpList.push({ ...listOfSocialLinksMain[i], index: index });
        }
      } else if (j === 8) {
        tmpList.push({ ...listOfSocialLinksMain[i], index: index });
        j = 0;
        tmpListOfSocialLinks.push(tmpList);
        tmpList = [];
      } else {
        tmpList.push({ ...listOfSocialLinksMain[i], index: index });
      }
      // }
    }

    if (j !== 0) {
      tmpListOfSocialLinks.push(tmpList);
    }

    // @ts-ignore
    setListOfSocialLinksNew(tmpListOfSocialLinks);
  }, [listOfSocialLinks, firstLineSocialLinks, accType]);

  // useEffect(() => {
  //   console.log("listOfSocialLinks", listOfSocialLinks);
  // }, [listOfSocialLinks]);

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {listOfSocialLinks.length === 0 ? (
        // accType === "classical" ? (
        //   <div className="flex justify-between items-center">
        //     <div className="invisible">12asdfasfgsdfgsdfhdfgh3asdfaasdfasd</div>
        //     <img
        //       src={listOfSocialLinks[0].icon}
        //       alt="icon"
        //       className={"w-[40px] h-[40px]"}
        //     />
        //   </div>
        // ) : (
        //   <div className="flex justify-start items-center">
        //     <img
        //       src={listOfSocialLinks[0].icon}
        //       alt="icon"
        //       className={"w-[30px] h-[30px]"}
        //     />
        //   </div>
        // )
        isEdit && (
          <div
            className={
              accType === "classical"
                ? "flex justify-center items-center"
                : "flex justify-start items-center"
            }
          >
            <div className="flex items-center space-x-[10px]">
              <div className="w-[40px] h-[40px] rounded-full bg-[#F7F8F9]"></div>
              <div className="w-[40px] h-[40px] rounded-full bg-[#F7F8F9]"></div>
              <div className="w-[40px] h-[40px] rounded-full bg-[#F7F8F9]"></div>
              <div className="w-[40px] h-[40px] rounded-full bg-[#F7F8F9]"></div>
              <div className="w-[40px] h-[40px] rounded-full bg-[#F7F8F9]"></div>
            </div>
          </div>
        )
      ) : (
        <div>
          {accType === "classical" ? (
            <div>
              {listOfSocialLinksNew.map((elemMain: any, index: number) => (
                <div
                  key={index}
                  // className={"mb-[5px] flex justify-center items-center space-x-2"}
                  className={"mb-[10px] flex justify-center items-center"}
                  // className={"mb-[5px] grid grid-cols-7 gap-2"}
                >
                  <div className="flex justify-between items-center gap-2">
                    {elemMain.map(
                      (elem: any) =>
                        // <div className="" key={elem.index}>
                        elem.name ? (
                          elem.name === "Email" ? (
                            <a
                              key={elem.index}
                              className={`${iconDesign === "round" && "rounded-full shadow-customblock"} flex-shrink-0`}
                              // href="mailto:example@example.com?subject=Тема письма&body=Привет,%20это%20тестовое%20сообщение."
                              href={`mailto:${elem.value}`}
                              title="Send email"
                              onClick={() => {
                                if (senderFunc !== null) {
                                  senderFunc(`${elem.name}`);
                                }
                              }}
                            >
                              <img
                                src={
                                  iconDesign === "round_bw"
                                    ? elem.iconRoundBW
                                    : iconDesign === "round"
                                      ? elem.iconRound
                                      : iconDesign === "original_bw"
                                        ? elem.iconOriginalBW
                                        : elem.iconOriginal
                                }
                                alt="icon"
                                className="w-[37px] h-[37px]"
                              />
                            </a>
                          ) : (
                            <div
                              key={elem.index}
                              className={`${iconDesign === "round" && "rounded-full shadow-customblock"} flex-shrink-0`}
                              onClick={() => {
                                if (senderFunc !== null) {
                                  senderFunc(`${elem.name}`);

                                  if (elem.name === "WhatsApp") {
                                    window.open(
                                      goUrl(
                                        elem.url,
                                        `https://wa.me/${elem.value}`
                                      ),
                                      "_blank"
                                    );
                                  } else {
                                    window.open(
                                      goUrl(elem.url, elem.value),
                                      "_blank"
                                    );
                                  }
                                }
                              }}
                            >
                              <img
                                src={
                                  iconDesign === "round_bw"
                                    ? elem.iconRoundBW
                                    : iconDesign === "round"
                                      ? elem.iconRound
                                      : iconDesign === "original_bw"
                                        ? elem.iconOriginalBW
                                        : elem.iconOriginal
                                }
                                alt="icon"
                                className="w-[37px] h-[37px]"
                              />
                            </div>
                          )
                        ) : (
                          <button
                            key={elem.index}
                            className="w-[124px] h-[37px] shadow-customblock rounded-[30px] flex justify-center items-center flex-shrink-0"
                            onClick={() => {
                              if (page === "ProfilePreview") {
                                navigate(`updates`);
                              }
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <img
                                src="/images/social_media_icons/bell.svg"
                                alt="bell.svg"
                                className="w-[24.2px] h-[24px]"
                              />

                              <span className="ml-[3px] text-[13px] leading-[15px] text-[#161616]">
                                {t("get_updates")}
                              </span>
                            </div>
                          </button>
                        )
                      // </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {listOfSocialLinksNew.map((elemMain: any, index: number) => (
                <div
                  key={index}
                  // className={"mb-[5px] flex justify-center items-center space-x-2"}
                  className={"mb-[10px] flex justify-start items-center"}
                  // className={"mb-[5px] grid grid-cols-7 gap-2"}
                >
                  <div className="flex justify-between items-center gap-2">
                    {elemMain.map(
                      (elem: any) =>
                        // <div className="" key={elem.index}>
                        elem.name ? (
                          elem.name === "Email" ? (
                            <a
                              key={elem.index}
                              className={`${iconDesign === "round" && "rounded-full shadow-customblock"} flex-shrink-0`}
                              // href="mailto:example@example.com?subject=Тема письма&body=Привет,%20это%20тестовое%20сообщение."
                              href={`mailto:${elem.value}`}
                              title="Send email"
                              onClick={() => {
                                if (senderFunc !== null) {
                                  senderFunc(`${elem.name}`);
                                }
                              }}
                            >
                              <img
                                src={
                                  iconDesign === "round_bw"
                                    ? elem.iconRoundBW
                                    : iconDesign === "round"
                                      ? elem.iconRound
                                      : iconDesign === "original_bw"
                                        ? elem.iconOriginalBW
                                        : elem.iconOriginal
                                }
                                alt="icon"
                                className="w-[37px] h-[37px]"
                              />
                            </a>
                          ) : (
                            <div
                              key={elem.index}
                              className={`${iconDesign === "round" && "rounded-full shadow-customblock"} flex-shrink-0`}
                              onClick={() => {
                                if (senderFunc !== null) {
                                  senderFunc(`${elem.name}`);

                                  if (elem.name === "WhatsApp") {
                                    window.open(
                                      goUrl(
                                        elem.url,
                                        `https://wa.me/${elem.value}`
                                      ),
                                      "_blank"
                                    );
                                  } else {
                                    window.open(
                                      goUrl(elem.url, elem.value),
                                      "_blank"
                                    );
                                  }
                                }
                              }}
                            >
                              <img
                                src={
                                  iconDesign === "round_bw"
                                    ? elem.iconRoundBW
                                    : iconDesign === "round"
                                      ? elem.iconRound
                                      : iconDesign === "original_bw"
                                        ? elem.iconOriginalBW
                                        : elem.iconOriginal
                                }
                                alt="icon"
                                className="w-[37px] h-[37px]"
                              />
                            </div>
                          )
                        ) : (
                          <div
                            key={elem.index}
                            className="w-[124px] h-[37px] shadow-customblock rounded-[30px] flex justify-center items-center flex-shrink-0"
                            onClick={() => {
                              if (page === "ProfilePreview") {
                                navigate(`updates`);
                              }
                            }}
                          >
                            <div className="flex justify-between items-center">
                              <img
                                src="/images/social_media_icons/bell.svg"
                                alt="bell.svg"
                                className="w-[24.2px] h-[24px]"
                              />

                              <span className="ml-[3px] text-[13px] leading-[15px] text-[#161616]">
                                {t("get_updates")}
                              </span>
                            </div>
                          </div>
                        )
                      // </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            // <div>
            //   {listOfSocialLinksNew.map((elemMain: any, index: number) => (
            //     <div
            //       key={index}
            //       className="mb-[5px] flex justify-start items-center space-x-1.5"
            //       // className="mb-[5px] flex justify-start items-center"
            //       // className="mb-[5px]"
            //     >
            //       {elemMain.map(
            //         (elem: any) =>
            //           // <div className="" key={elem.index}>
            //           elem.name ? (
            //             <div
            //               key={elem.index}
            //               onClick={() => {
            //                 window.open(goUrl(elem.url, elem.value), "_blank");
            //               }}
            //             >
            //               <img
            //                 src={elem.icon}
            //                 alt="icon"
            //                 className="w-[30px] h-[30px]"
            //               />
            //             </div>
            //           ) : isPreview ? (
            //             <div key={elem.index}>
            //               <Menu>
            //                 <MenuHandler>
            //                   <img
            //                     src={elem.icon}
            //                     alt="icon"
            //                     className="w-[30px] h-[30px]"
            //                   />
            //                 </MenuHandler>
            //                 <MenuList className="mr-[10px]">
            //                   <MenuItem
            //                     onClick={() => {
            //                       handleCopy(
            //                         `https://www.hubbbox.com/${username}`
            //                       );
            //                     }}
            //                   >
            //                     <div className="flex justify-between">
            //                       <div className="text-[#161616] text-[16px] leading-[22px]">
            //                         Copy profile link
            //                       </div>
            //                       <img
            //                         src="/images/profile_preview/copy_profile_link.svg"
            //                         alt="copy_profile_link.svg"
            //                         className="w-[24px] h-[24px]"
            //                       />
            //                     </div>
            //                   </MenuItem>
            //                   <MenuItem
            //                     onClick={() => {
            //                       handleCopy(username);
            //                     }}
            //                   >
            //                     <div className="flex justify-between items-center">
            //                       <div className="text-[#161616] text-[16px] leading-[22px]">
            //                         Copy username
            //                       </div>
            //                       <img
            //                         src="/images/profile_preview/copy_username.svg"
            //                         alt="copy_username.svg"
            //                         className="w-[24px] h-[24px]"
            //                       />
            //                     </div>
            //                   </MenuItem>
            //                   <MenuItem
            //                     onClick={() => {
            //                       navigate("/auth/registration");
            //                     }}
            //                   >
            //                     <div className="flex justify-between items-center">
            //                       <div className="text-[#161616] text-[16px] leading-[22px]">
            //                         Create your account
            //                       </div>
            //                       <img
            //                         src="/images/profile_preview/create_your_account.svg"
            //                         alt="create_your_account.svg"
            //                         className="w-[24px] h-[24px] ml-[20px]"
            //                       />
            //                     </div>
            //                   </MenuItem>
            //                 </MenuList>
            //               </Menu>
            //             </div>
            //           ) : (
            //             <div
            //               key={elem.index}
            //               onClick={() => {
            //                 handleShare(`https://hubbbox.com/${username}`);
            //               }}
            //             >
            //               <img
            //                 src={elem.icon}
            //                 alt="icon"
            //                 className="w-[30px] h-[30px]"
            //               />
            //             </div>
            //           )
            //         // </div>
            //       )}
            //       {/* </div> */}
            //       {/* </div> */}
            //     </div>
            //   ))}
            // </div>

            // <div
            //   className={
            //     listOfSocialLinks.length >= 7
            //       ? "grid grid-cols-7 gap-x-1 items-center"
            //       : "flex space-x-1.5 items-center"
            //   }
            // >
            //   {listOfSocialLinks.map((elem: any) =>
            //     elem.name ? (
            //       <div
            //         key={elem.index}
            //         onClick={() => {
            //           window.open(goUrl(elem.url, elem.value), "_blank");
            //         }}
            //       >
            //         <img
            //           src={elem.icon}
            //           alt="icon"
            //           className="w-[30px] h-[30px]"
            //         />
            //       </div>
            //     ) : isPreview ? (
            //       <div key={elem.index}>
            //         <Menu>
            //           <MenuHandler>
            //             <img
            //               src={elem.icon}
            //               alt="icon"
            //               className="w-[30px] h-[30px]"
            //             />
            //           </MenuHandler>
            //           <MenuList className="mr-[10px]">
            //             <MenuItem
            //               onClick={() => {
            //                 handleCopy(`https://www.hubbbox.com/${username}`);
            //               }}
            //             >
            //               <div className="flex justify-between">
            //                 <div className="text-[#161616] text-[14px] leading-[22px]">
            //                   Copy profile link
            //                 </div>
            //                 {/* <Icon
            //               icon="icon-park-outline:preview-open"
            //               className="w-[24px] h-[24px] text-[#8391A1]"
            //             /> */}
            //                 <img
            //                   src="/images/profile_preview/copy_profile_link.svg"
            //                   alt="copy_profile_link.svg"
            //                   className="w-[24px] h-[24px]"
            //                 />
            //               </div>
            //             </MenuItem>
            //             <MenuItem
            //               onClick={() => {
            //                 handleCopy(username);
            //               }}
            //             >
            //               <div className="flex justify-between items-center">
            //                 <div className="text-[#161616] text-[14px] leading-[22px]">
            //                   Copy username
            //                 </div>
            //                 {/* <Icon
            //               icon="icon-park-outline:copy-link"
            //               className="w-[24px] h-[24px] text-[#8391A1]"
            //             /> */}
            //                 <img
            //                   src="/images/profile_preview/copy_username.svg"
            //                   alt="copy_username.svg"
            //                   className="w-[24px] h-[24px]"
            //                 />
            //               </div>
            //             </MenuItem>
            //             <MenuItem
            //               onClick={() => {
            //                 navigate("/auth/registration");
            //               }}
            //             >
            //               <div className="flex justify-between items-center">
            //                 <div className="text-[#161616] text-[14px] leading-[22px]">
            //                   Create your account
            //                 </div>
            //                 {/* <Icon
            //               icon="icon-park-outline:copy-link"
            //               className="w-[24px] h-[24px] text-[#8391A1]"
            //             /> */}
            //                 <img
            //                   src="/images/profile_preview/create_your_account.svg"
            //                   alt="create_your_account.svg"
            //                   className="w-[24px] h-[24px] ml-[20px]"
            //                 />
            //               </div>
            //             </MenuItem>
            //           </MenuList>
            //         </Menu>
            //       </div>
            //     ) : (
            //       <div
            //         key={elem.index}
            //         onClick={() => {
            //           handleShare(`https://hubbbox.com/${username}`);
            //         }}
            //       >
            //         <img
            //           src={elem.icon}
            //           alt="icon"
            //           className="w-[30px] h-[30px]"
            //         />
            //       </div>
            //     )
            //   )}
            // </div>
          )}
        </div>
      )}
    </div>
  );
}
