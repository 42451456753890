const LoadingAnimation = ({ isLoading }: any) => {
  //   const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="flex items-center justify-center min-h-screen">
      {isLoading ? (
        <div className="">
          <img
            src="/images/loading/loading_animation.gif"
            alt="loading_animation"
            className="h-[160px] w-[160px]"
          />
        </div>
      ) : (
        // <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <div>Данные загружены!</div>
      )}
    </div>
  );
};

export default LoadingAnimation;
