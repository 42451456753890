import { useEffect, useState } from "react";
import LoadingScreen from "../../components/ui/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import * as utils from "../../components/utils";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { listOfSocialMediaLinks as listOfSocialMediaLinksMain } from "../../components/ui/ListOfSocialMediaLinks";

export async function getAnalyticsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getAnalytics,
    dispatch,
    `api/profile/get_analytics/`,
    "get",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataBlocks, setDataBlocks] = useState([]);
  const [dataFollowers, setDataFollowers] = useState([]);
  const [dataSocialLinks, setDataSocialLinks] = useState([]);
  const [totalPageVisits, setTotalPageVisits] = useState(0);
  const [uniquePageVisits, setUniquePageVisits] = useState(0);

  const [status, setStatus] = useState("profile_page");
  const [selectMenuItem, setSelectMenuItem] = useState("social_links");

  const handleChangeStatus = (newVal: string) => {
    setStatus(newVal);
  };

  const getAnalyticsStore = useSelector(
    (state: any) => state.getAnalyticsStore
  );

  useEffect(() => {
    // console.log("getAnalyticsStore", getAnalyticsStore);
    if (getAnalyticsStore && getAnalyticsStore.data) {
      setDataBlocks(getAnalyticsStore.data.response.blocks);
      setDataFollowers(getAnalyticsStore.data.response.followers);

      const responseSocialLinks = getAnalyticsStore.data.response.social_links;
      console.log("responseSocialLinks", responseSocialLinks);
      let tmpDataSocialLinks = [];

      for (let i = 0; i < responseSocialLinks.length; i++) {
        for (let j = 0; j < listOfSocialMediaLinksMain.length; j++) {
          if (
            responseSocialLinks[i].name === listOfSocialMediaLinksMain[j].name
          ) {
            tmpDataSocialLinks.push({
              name: responseSocialLinks[i].name,
              checked: responseSocialLinks[i].checked,
              value: responseSocialLinks[i].value,
              clicks: responseSocialLinks[i].clicks,
              unique_clicks: responseSocialLinks[i].unique_clicks,
              iconRoundBW: listOfSocialMediaLinksMain[j].iconRoundBW,
              iconRound: listOfSocialMediaLinksMain[j].iconRound,
              iconOriginalBW: listOfSocialMediaLinksMain[j].iconOriginalBW,
              iconOriginal: listOfSocialMediaLinksMain[j].iconOriginal,
            });
          }
        }
      }

      // @ts-ignore
      setDataSocialLinks(tmpDataSocialLinks);
      setTotalPageVisits(getAnalyticsStore.data.response.total_page_visits);
      setUniquePageVisits(getAnalyticsStore.data.response.unique_page_visits);
    }
  }, [getAnalyticsStore]);

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [blockDesign, setBlockDesign] = useState("first");
  const [iconDesign, setIconDesign] = useState("monochrome");

  useEffect(() => {
    // console.log("profileInfoStore", profileInfoStore);
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
      setIconDesign(profileInfoStore.data.response.design_icons);
    }
  }, [profileInfoStore]);

  useEffect(() => {
    getAnalyticsFunc(dispatch, "", {});

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
  }, []);

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  if (getAnalyticsStore && getAnalyticsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getAnalyticsStore.load} />
      </div>
    );
  }

  // if (isLoading) {
  //   return (
  //     <div>
  //       <LoadingScreen isLoading={isLoading} />
  //     </div>
  //   );
  // }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center">
        <div className="font-manrope px-[24px] pb-[40px] w-screen overflow-hidden max-w-md min-h-screen bg-white break-words">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          />

          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={(event) => {
                    navigate("/home");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="flex justify-center items-center font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("analytics")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <img
                      src="/images/hamburger_menu/hamburger_menu.svg"
                      alt="hamburger_menu.svg"
                      className="w-[24px] h-[26.88px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            {/* <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className={`ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] ${status === "profile_page" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    handleChangeStatus("profile_page");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("profile_page_analytics")}
                  </div>
                </div>
                <div
                  className={`mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]  ${status === "finance" ? "shadow-custom bg-white" : ""}`}
                  onClick={() => {
                    handleChangeStatus("finance");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("finance")}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="mt-[20px]"> */}
            <div className="mt-[61px]">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-[40px] leading-[44px] text-[#161616] font-extrabold">
                    {totalPageVisits}
                  </div>
                  <div className="text-[14px] leading-[18px] text-[#161616] font-medium">
                    {t("total_page_visits")}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-[40px] leading-[44px] text-[#161616] font-extrabold">
                    {uniquePageVisits}
                  </div>
                  <div className="text-[14px] leading-[18px] text-[#161616] font-medium">
                    {t("unique_page_visits")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[20px]">
              <div className="w-full h-[56px] flex-shrink-0">
                <Menu>
                  <MenuHandler>
                    <div className="w-full h-full flex justify-between items-center px-[20px] border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[20px]">
                      {t(selectMenuItem)}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="w-[320px] rounded-[10px] font-manrope">
                    <MenuItem
                      onClick={() => {
                        setSelectMenuItem("social_links");
                      }}
                    >
                      <div className="py-[2px] flex justify-start items-center">
                        <div className="text-[16px] text-[#161616] leading-[20px]">
                          {t("social_links")}
                        </div>
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem
                      onClick={() => {
                        setSelectMenuItem("blocks");
                      }}
                    >
                      <div className="py-[2px] flex justify-start items-center">
                        <div className="text-[16px] text-[#161616] leading-[20px]">
                          {t("blocks")}
                        </div>
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem
                      onClick={() => {
                        setSelectMenuItem("followers");
                      }}
                    >
                      <div className="py-[2px] flex justify-start items-center">
                        <div className="text-[16px] text-[#161616] leading-[20px]">
                          {t("followers")}
                        </div>
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            {selectMenuItem === "social_links" && (
              <div className="mt-[5px]">
                {dataSocialLinks.map(
                  (elemSocialLinks: any, indexSocialLinks: number) => (
                    <div
                      key={`social-links-${indexSocialLinks}`}
                      className="p-[10px] rounded-[10px] shadow-customblock mt-[10px]"
                    >
                      <div className="flex space-x-[10px] items-center">
                        <div className="w-[56px] h-[56px] bg-[#F7F8F9] rounded-[5px] flex justify-center items-center flex-shrink-0">
                          {/* <div className="w-[37px] h-[37px] flex-shrink-0 rounded-full bg-gray-700"></div> */}
                          <div
                            className={`${iconDesign === "round" && "rounded-full shadow-customblock"} flex-shrink-0`}
                          >
                            <img
                              src={
                                iconDesign === "round_bw"
                                  ? elemSocialLinks.iconRoundBW
                                  : iconDesign === "round"
                                    ? elemSocialLinks.iconRound
                                    : iconDesign === "original_bw"
                                      ? elemSocialLinks.iconOriginalBW
                                      : elemSocialLinks.iconOriginal
                              }
                              alt="icon"
                              className="w-[37px] h-[37px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col max-w-[calc(100%-66px)]">
                          <div className="text-[#161616] text-[14px] font-bold">
                            {t("clicks")}: {elemSocialLinks.clicks}
                          </div>
                          <div className="text-[#161616] text-[12px]">
                            {t("unique_clicks")}:{" "}
                            {elemSocialLinks.unique_clicks}
                          </div>
                          <div className="text-[#161616] text-[12px] truncate overflow-hidden whitespace-nowrap">
                            {elemSocialLinks.value}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            {selectMenuItem === "blocks" && (
              <div className="mt-[5px]">
                {/* <div className="p-[10px] rounded-[10px] shadow-customblock mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div className="w-[100px] h-[56px] bg-[#F7F8F9] rounded-[5px] flex justify-center items-center flex-shrink-0"></div>
                    <div className="flex flex-col">
                      <div className="text-[#161616] text-[14px] font-bold">
                        Clicks: 89
                      </div>
                      <div className="text-[#161616] text-[12px]">
                        Unique clicks: 55
                      </div>
                      <div className="text-[#161616] text-[12px]">URL</div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="p-[10px] rounded-[10px] shadow-customblock mt-[10px]">
                  <div className="flex space-x-[10px] items-center">
                    <div className="w-[100px] h-[20px] bg-[#F7F8F9] rounded-[5px] flex justify-center items-center flex-shrink-0"></div>
                    <div className="flex flex-col">
                      <div className="text-[#161616] text-[14px] font-bold">
                        Clicks: 89
                      </div>
                      <div className="text-[#161616] text-[12px]">
                        Unique clicks: 55
                      </div>
                      <div className="text-[#161616] text-[12px]">URL</div>
                    </div>
                  </div>
                </div> */}

                {dataBlocks.map((elemBlock: any, indexBlock: number) => (
                  <div
                    key={`block-${indexBlock}`}
                    className="p-[10px] rounded-[10px] shadow-customblock mt-[10px]"
                  >
                    <div className="flex space-x-[10px] items-center">
                      {elemBlock.image === "" ? (
                        <div className="w-[56px] h-[56px] bg-[#F7F8F9] rounded-[5px] flex justify-center items-center flex-shrink-0"></div>
                      ) : (
                        <img
                          src={elemBlock.image}
                          alt="image"
                          className="w-[56px] h-[56px] object-center object-cover rounded-[5px]"
                        />
                      )}
                      <div className="flex flex-col max-w-[calc(100%-66px)]">
                        <div className="text-[#161616] text-[14px] font-bold">
                          {t("clicks")}: {elemBlock.clicks}
                        </div>
                        <div className="text-[#161616] text-[12px]">
                          {t("unique_clicks")}: {elemBlock.unique_clicks}
                        </div>
                        <div className="text-[#161616] text-[12px] truncate overflow-hidden whitespace-nowrap">
                          {elemBlock.url}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {selectMenuItem === "followers" && (
              <div className="mt-[15px]">
                <div className="flex flex-col">
                  <div className="text-[14px] text-[#161616] font-medium">
                    {t("new_followers")}:
                  </div>

                  <div className="text-[32px] font-bold text-[#161616]">
                    {
                      //@ts-ignore
                      dataFollowers.new_followers
                    }
                  </div>
                </div>

                <div className="mt-[10px]">
                  <div className="flex justify-between items-center">
                    <div className="text-[16px] text-[#161616] font-semibold">
                      {t("all_followers")}
                    </div>

                    <Icon
                      icon="solar:copy-linear"
                      className="w-[24px] h-[24px]"
                    />
                  </div>

                  <div className="mt-[10px]">
                    <div className="w-full bg-[#F7F8F9] border-[1px] border-[#E8ECF4] rounded-[10px] p-[20px]">
                      <div className="flex flex-col break-all">
                        {
                          //@ts-ignore
                          dataFollowers.all_followers.map(
                            (elemFollower: any, indexFollower: number) => (
                              <div
                                key={`follower-index-${indexFollower}`}
                                className="text-[#161616] text-[14px]"
                              >
                                {elemFollower}
                              </div>
                            )
                          )
                        }

                        {/* <div className="">Example1@gmail.com</div>
                        <div className="">Example2@gmail.com</div>
                        <div className="">Example3@gmail.com</div>
                        <div className="">Example4@gmail.com</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
