import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate } from "react-router-dom";
import Products from "../../components/ui/CreateBlock/Products";
import YouTube from "../../components/ui/CreateBlock/YouTube";
import LinksUrl from "../../components/ui/CreateBlock/LinksUrl";
import PDFFile from "../../components/ui/CreateBlock/PDFFile";
import Newsletter from "../../components/ui/CreateBlock/Newsletter";
import Gallery from "../../components/ui/CreateBlock/Gallery";
import Text from "../../components/ui/CreateBlock/Text";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import * as utils from "../../components/utils";
import { ToastContainer, toast } from "react-toastify";
import SelectProductType from "../../components/ui/CreateProduct/SelectProductType";
import ProductDetails from "../../components/ui/CreateProduct/ProductDetails";
import { useTranslation } from "react-i18next";

export async function productDeleteFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.productDelete,
    dispatch,
    `api/product/delete/`,
    "post",
    "",
    form,
    true
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [deletedProductId, setDeletedProductId] = useState(null);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const [litOfProducts, setLitOfProducts] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const allListOfProductsStore = useSelector(
    (state: any) => state.allListOfProductsStore
  );

  const productDeleteStore = useSelector(
    (state: any) => state.productDeleteStore
  );

  const productTypeFunc = (productType: string) => {
    if (productType === "digital_product") {
      return t("digital_product");
    } else if (productType === "physical_product") {
      return t("physical_product");
    } else if (productType === "bundle") {
      return t("bundle");
    } else if (productType === "membership") {
      return t("membership");
    }
  };

  const profileInfoStore = useSelector((state: any) => state.profileInfoStore);

  const [blockDesign, setBlockDesign] = useState("first");

  useEffect(() => {
    if (profileInfoStore && profileInfoStore.data) {
      setBlockDesign(profileInfoStore.data.response.design_blocks);
    }
  }, [profileInfoStore]);

  const handleProductDelete = (productId: number) => {
    productDeleteFunc(dispatch, "", {
      id: productId,
    });

    // @ts-ignore
    setDeletedProductId(productId);
  };

  useEffect(() => {
    // console.log("sectionDeleteStore", sectionDeleteStore);
    if (productDeleteStore && productDeleteStore.data) {
      const newData = litOfProducts.filter(
        // @ts-ignore
        (obj) => obj.id !== deletedProductId
      );

      setLitOfProducts(newData);
    }
  }, [productDeleteStore]);

  async function getBlockInfoFunc() {
    await actions.constructorAction(
      constants.allListOfProducts,
      dispatch,
      "api/product/all/",
      "get",
      "",
      {},
      true
    )();
  }

  useEffect(() => {
    // const allProductsDataStorage = utils.LocalStorage.get("AllProductsData");

    // if (allProductsDataStorage && allProductsDataStorage.litOfProducts) {
    //   setLitOfProducts(allProductsDataStorage.litOfProducts);
    // } else {
    //   getBlockInfoFunc();
    // }
    dispatch({ type: constants.getProductData.reset });
    dispatch({ type: constants.sendInfoAboutNewProduct.reset });

    utils.LocalStorage.remove("CreateProductData");
    utils.LocalStorage.remove("EditProductData");
    utils.LocalStorage.remove("PersonalInformationEditData");
    utils.LocalStorage.remove("CreateBlockData");
    utils.LocalStorage.remove("EditBlockData");
    utils.LocalStorage.remove("AccountLaunchData");

    getBlockInfoFunc();

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //   useEffect(() => {
  //     utils.LocalStorage.set("AllProductsData", {
  //       litOfProducts: litOfProducts,
  //     });
  //   }, [litOfProducts]);

  useEffect(() => {
    // console.log("allListOfProductsStore", allListOfProductsStore);
    if (
      allListOfProductsStore &&
      allListOfProductsStore.data &&
      allListOfProductsStore.data.response
    ) {
      setLitOfProducts(allListOfProductsStore.data.response);
    }
  }, [allListOfProductsStore]);

  if (allListOfProductsStore && allListOfProductsStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={allListOfProductsStore.load} />
      </div>
    );
  }

  if (productDeleteStore && productDeleteStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={productDeleteStore.load} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingScreen isLoading={isLoading} />
      </div>
    );
  }

  return (
    <utils.AuthGuard>
      <div className="flex justify-center overflow-hidden break-words">
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-[#FFFFFF] min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    utils.LocalStorage.remove("AllProductsData");
                    navigate("/home");
                  }}
                >
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("products")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div
              className="mt-[66px]"
              onClick={() => {
                navigate("create");
              }}
            >
              <div className="w-full h-[50px] rounded-[10px] border-[1px] border-[#161616] text-[18px] text-[#161616] flex justify-center items-center">
                {t("create_product")}
              </div>
            </div>

            <div className="mt-[15px]">
              <span className="text-[#161616] text-[18px] leading-[20px] font-semibold">
                {t("all_products")}
              </span>
            </div>

            <div className="">
              {litOfProducts.map((product: any) => (
                <div className="" key={product.id}>
                  <Menu>
                    <MenuHandler>
                      <div className="mt-[10px] flex justify-between items-center">
                        <div className="pb-[10px] px-[10px]">
                          <img
                            src="/images/profile/dots.svg"
                            alt="menu_dots"
                            className="w-[18px] h-[4px] invisible"
                          />
                        </div>

                        <div className="pb-[10px] px-[10px]">
                          <img
                            src="/images/profile/dots.svg"
                            alt="menu_dots"
                            className="w-[18px] h-[4px]"
                          />
                        </div>
                      </div>
                    </MenuHandler>
                    <MenuList className="w-[228px] rounded-[15px] overflow-x-hidden overflow-y-hidden font-manrope">
                      <MenuItem
                        onClick={() => {
                          window.open(
                            `https://www.hubbbox.com/product/${product.id}`,
                            "_blank"
                          );
                        }}
                      >
                        <div className="flex justify-between">
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("preview")}
                          </div>

                          <img
                            src="/images/profile/preview.svg"
                            alt="preview.svg"
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate(`/product/edit/${product.id}`);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("edit")}
                          </div>
                          <img
                            src="/images/profile/edit.svg"
                            alt=""
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>

                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            // handleBlockDeactivate(elem.block_numeration);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("activate")}
                          </div>
                          <Switch
                            crossOrigin={""}
                            // checked={!elem.deactivated}
                            checked={true}
                            onChange={(event) => {
                              //   handleBlockDeactivate(elem.block_numeration);
                            }}
                          />
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center"
                          onClick={(event) => {
                            event.preventDefault();
                            handleProductDelete(product.id);
                          }}
                        >
                          <div className="text-[#161616] text-[16px] leading-[22px]">
                            {t("delete")}
                          </div>
                          <img
                            src="/images/profile/delete.svg"
                            alt=""
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <div>
                    {blockDesign === "first" && (
                      <div
                        className="mb-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                        onClick={() => {}}
                      >
                        <div className="flex space-x-[15px] items-center">
                          {product.thumbnail === "" ? (
                            <div className="w-[100px] h-[100px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={product.thumbnail}
                              alt="image"
                              className="w-[100px] h-[100px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="ml-[10px]">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {product.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(product.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {product.price
                                ? `${product.currencySymbol}${product.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {blockDesign === "second" && (
                      <div
                        className="mb-[10px] w-full rounded-[10px] p-[10px] shadow-custom"
                        onClick={() => {}}
                      >
                        <div className="flex space-x-[15px] items-center">
                          {product.thumbnail === "" ? (
                            <div className="w-[100px] h-[100px] bg-[#F7F8F9] rounded-[5px]"></div>
                          ) : (
                            <img
                              src={product.thumbnail}
                              alt="image"
                              className="w-[100px] h-[100px] object-center object-cover rounded-[5px]"
                            />
                          )}
                          <div className="ml-[10px]">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {product.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(product.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {product.price
                                ? `${product.currencySymbol}${product.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {blockDesign === "third" && (
                      <div
                        className="mb-[10px] w-full rounded-[7px] shadow-custom"
                        onClick={() => {}}
                      >
                        <div className="flex space-x-[15px] items-center">
                          {product.thumbnail === "" ? (
                            <div className="w-[127px] h-[127px] bg-[#F7F8F9] rounded-l-[7px]"></div>
                          ) : (
                            <img
                              src={product.thumbnail}
                              alt="image"
                              className="w-[127px] h-[127px] object-center object-cover rounded-l-[7px]"
                            />
                          )}
                          <div className="pr-[10px] py-[10px]">
                            <div className="text-[16px] leading-[20px] text-[#161616] font-semibold">
                              {product.name}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t(product.type)}
                            </div>
                            <div className="mt-[2px] text-[14px] leading-[18px] text-[#161616]">
                              {t("price")}:{" "}
                              {product.price
                                ? `${product.currencySymbol}${product.price}`
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </utils.AuthGuard>
  );
}
