import React from "react";
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import GalleryImageComponent from "../GalleryImageComponent";

const ProductSlider = ({ images, deactivated, blockDesign }: any) => {
  const settings = {
    className: "slider",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    arrows: false,
    initialSlide: 0,
  };

  return (
    <div className="product-slider custom-slider">
      {/* <div className="slider-container"> */}
      <Slider {...settings}>
        {images.map((image: any, index: any) => (
          <div key={index} className="px-[10px] break-words">
            {/* <GalleryImageComponent src={image} alt={`gallery_image${index}`} /> */}
            <div
              className={`relative w-full pt-[133.33%] bg-gray-200 overflow-hidden ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
            >
              <img
                src={image}
                alt={`gallery_image${index}`}
                className={`absolute inset-0 w-full h-full object-cover ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
              />
            </div>
            {/* <div
              className={`max-w-[285px] rounded-[15px] shadow-custom ${deactivated && "opacity-50"}`}
            >
              
              <img
                src={image.file}
                alt={`gallery_image${index}`}
                className="w-full h-full rounded-[15px] object-cover"
              />
            </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default function Component({
  listOfGalleryImages,
  deactivated,
  blockDesign,
}: any) {
  return (
    <div className="container mb-[40px]">
      <ProductSlider
        images={listOfGalleryImages}
        deactivated={deactivated}
        blockDesign={blockDesign}
      />
    </div>
  );
}
