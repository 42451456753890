import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as utils from "../../utils";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import CustomInput from "../CustomInput";
import NumberInput from "../NumberInput";
import CustomRadioButton from "../CreateBlock/CustomRadioButton";
import { useTranslation } from "react-i18next";
// import Menu from "../Menu";

export default function Component({
  burgerMenuOpen,
  toggleBurgerMenu,
  selectedProductType,
  physicalProductContent,
  handleChangePhysicalProductContent,
  handleChangePhysicalProductList,
  countryData,
  handleChangeCountryData,
  productName,
  status,
  handleChangeStatus,
  handleChangeStage,
  handleSendProductData,
}: any) {
  const { t, i18n } = useTranslation();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {status === "content" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <span className="text-start text-[18px] text-[#161616] leading-[20px]">
                <span className="font-semibold">
                  {t("variations_and_options")}
                </span>{" "}
                ⓘ
              </span>
            </div>

            {physicalProductContent.versions.map(
              (elemVersion: any, indexVersion: number) => (
                <div
                  className="mt-[15px]"
                  key={`physical-product-version-${indexVersion + 1}`}
                >
                  <div className="w-full rounded-[10px] p-[10px] shadow-custom">
                    <div className="flex justify-between items-center">
                      <div className="text-[#161616] text-[16px] font-semibold leading-[18px]">
                        {t("variation")} {indexVersion + 1}
                      </div>
                      {indexVersion !== 0 && (
                        <button
                          className="w-[18.5px] h-[19.5px]"
                          onClick={() => {
                            handleChangePhysicalProductList(
                              indexVersion,
                              0,
                              "",
                              "",
                              "delete_version"
                            );
                          }}
                        >
                          <img
                            src="/images/create_product/delete.svg"
                            alt="delete.svg"
                            className="w-full h-full"
                          />
                        </button>
                      )}
                    </div>

                    <div className="pt-[15px]">
                      <CustomInput
                        handleChangeValue={(newVal: string) => {
                          handleChangePhysicalProductList(
                            indexVersion,
                            0,
                            "name",
                            newVal,
                            "version"
                          );
                        }}
                        value={
                          physicalProductContent.versions[indexVersion].name
                        }
                        placeholder={t("variation_name")}
                        rows={1}
                        maxLength={30}
                      />
                    </div>

                    {elemVersion.options.map(
                      (elemOption: any, indexOption: number) => (
                        <div
                          className="pt-[15px]"
                          key={`physical-product-option-${indexOption + 1}`}
                        >
                          <div className="flex justify-between items-center">
                            <div className="text-[#161616] text-[16px] font-medium leading-[18px]">
                              {t("option")} {indexOption + 1}
                            </div>
                            {indexOption !== 0 && (
                              <button
                                className="w-[18.5px] h-[19.5px]"
                                onClick={() => {
                                  handleChangePhysicalProductList(
                                    indexVersion,
                                    indexOption,
                                    "",
                                    "",
                                    "delete_option"
                                  );
                                }}
                              >
                                <img
                                  src="/images/create_product/delete.svg"
                                  alt="delete.svg"
                                  className="w-full h-full"
                                />
                              </button>
                            )}
                          </div>

                          <div className="pt-[15px]">
                            <CustomInput
                              handleChangeValue={(newVal: string) => {
                                handleChangePhysicalProductList(
                                  indexVersion,
                                  indexOption,
                                  "name",
                                  newVal,
                                  "option"
                                );
                              }}
                              value={
                                physicalProductContent.versions[indexVersion]
                                  .options[indexOption].name
                              }
                              placeholder={t("option_name")}
                              rows={1}
                              maxLength={30}
                            />
                          </div>

                          <div className="pt-[10px]">
                            <CustomInput
                              handleChangeValue={(newVal: string) => {
                                handleChangePhysicalProductList(
                                  indexVersion,
                                  indexOption,
                                  "details",
                                  newVal,
                                  "option"
                                );
                              }}
                              value={
                                physicalProductContent.versions[indexVersion]
                                  .options[indexOption].details
                              }
                              placeholder={t("option_details_optional")}
                              rows={1}
                              maxLength={100}
                              delimiter={false}
                            />
                          </div>

                          <div className="pt-[5px]">
                            <div className="flex justify-end items-center text-[12px] leading-[14px] text-[#8391A1]">
                              {
                                physicalProductContent.versions[indexVersion]
                                  .options[indexOption].details.length
                              }
                              /100
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    <div className="pt-[15px]">
                      <div
                        className="w-full h-[47px] rounded-[10px] flex justify-center items-center text-[16px] leading-[22px] font-medium text-[#161616] border-[1px] border-[#161616]"
                        onClick={() => {
                          handleChangePhysicalProductList(
                            indexVersion,
                            0,
                            "",
                            "",
                            "add_option"
                          );
                        }}
                      >
                        {t("add_option")}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

            <div className="mt-[15px]">
              <button
                className="w-full h-[47px] flex justify-center items-center rounded-[10px] border-[1px] font-medium border-[#161616] text-[16px] leading-[22px]"
                onClick={() => {
                  handleChangePhysicalProductList(0, 0, "", "", "add_version");
                }}
              >
                {t("create_variation")}
              </button>
            </div>

            <div className="mt-[15px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleChangeStatus("price");
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      )}

      {status === "price" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[10px]"></div>

            {physicalProductContent.versions.map(
              (elemVersion: any, indexVersion: number) => (
                <div
                  className="mt-[15px]"
                  key={`physical-product-version-price-${indexVersion + 1}`}
                >
                  <div className="w-full rounded-[10px] p-[10px] shadow-custom">
                    <div className="text-[16px] text-[#161616] font-semibold leading-[18px]">
                      {elemVersion.name != ""
                        ? elemVersion.name
                        : `${t("variation")} ${indexVersion + 1}`}
                    </div>

                    {elemVersion.options.map(
                      (elemOption: any, indexOption: number) => (
                        <div
                          className=""
                          key={`physical-product-option-price-${indexOption + 1}`}
                        >
                          {indexOption === 0 && indexVersion === 0 ? (
                            <div className="mt-[15px]">
                              <div className="text-[16px] text-[#161616] font-medium leading-[18px]">
                                {elemOption.name != ""
                                  ? elemOption.name
                                  : `${t("option")} ${indexOption + 1}`}
                              </div>
                              <div className="mt-[10px]">
                                <div className="flex space-x-[10px] items-center">
                                  <div className="w-full h-[56px]">
                                    <NumberInput
                                      value={elemOption.price}
                                      placeholder={t("set_price")}
                                      changeFunc={(newValue: any) => {
                                        handleChangePhysicalProductList(
                                          indexVersion,
                                          indexOption,
                                          "price",
                                          newValue,
                                          "option"
                                        );
                                      }}
                                    />

                                    {/* <input
                                      type="number"
                                      className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                                      placeholder="Set price"
                                      value={elemOption.price}
                                      onChange={(event: any) => {
                                        const newValue = event.target.value;
                                        if (
                                          newValue === "" ||
                                          parseFloat(newValue) >= 0
                                        ) {
                                          handleChangePhysicalProductList(
                                            indexVersion,
                                            indexOption,
                                            "price",
                                            newValue,
                                            "option"
                                          );
                                        }
                                      }}
                                    /> */}
                                  </div>
                                  <div className="w-[76px] h-[56px] flex-shrink-0">
                                    <Menu>
                                      <MenuHandler>
                                        <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                                          {
                                            physicalProductContent.currencySymbol
                                          }
                                          <img
                                            src="/images/create_product/arrow_down.svg"
                                            alt="arrow_down"
                                            className="w-[24px] h-[24px] flex-shrink-0"
                                          />
                                        </div>
                                      </MenuHandler>
                                      <MenuList className="w-[228px] rounded-[10px] font-manrope">
                                        <MenuItem>
                                          <div
                                            className="flex justify-between items-center"
                                            onClick={() => {
                                              handleChangePhysicalProductContent(
                                                "currency",
                                                "dollar"
                                              );
                                              // handleChangePhysicalProductList(
                                              //   indexVersion,
                                              //   0,
                                              //   "currency",
                                              //   "dollar",
                                              //   "version"
                                              // );
                                            }}
                                          >
                                            <div className="text-[16px] leading-[22px] text-[#161616]">
                                              US {t("dollar")}
                                            </div>
                                            <div className="text-[22px] leading-[24px] text-[#161616]">
                                              $
                                            </div>
                                          </div>
                                        </MenuItem>
                                        <hr className="border-gray-300" />
                                        <MenuItem>
                                          <div
                                            className="flex justify-between items-center"
                                            onClick={() => {
                                              handleChangePhysicalProductContent(
                                                "currency",
                                                "tenge"
                                              );
                                            }}
                                          >
                                            <div className="text-[16px] leading-[22px] text-[#161616]">
                                              KZ {t("tenge")}
                                            </div>
                                            <div className="text-[22px] leading-[24px] text-[#161616]">
                                              ₸
                                            </div>
                                          </div>
                                        </MenuItem>
                                        <hr className="border-gray-300" />
                                        <MenuItem>
                                          <div
                                            className="flex justify-between items-center"
                                            onClick={() => {
                                              handleChangePhysicalProductContent(
                                                "currency",
                                                "ruble"
                                              );
                                            }}
                                          >
                                            <div className="text-[16px] leading-[22px] text-[#161616]">
                                              RU {t("ruble")}
                                            </div>
                                            <div className="text-[22px] leading-[24px] text-[#161616]">
                                              ₽
                                            </div>
                                          </div>
                                        </MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="mt-[10px]">
                              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                                {elemOption.name != ""
                                  ? elemOption.name
                                  : `${t("option")} ${indexOption + 1}`}
                              </span>
                              <div className="mt-[10px]">
                                <div className="w-full h-[56px]">
                                  <NumberInput
                                    value={elemOption.price}
                                    placeholder={t("set_price")}
                                    changeFunc={(newValue: any) => {
                                      handleChangePhysicalProductList(
                                        indexVersion,
                                        indexOption,
                                        "price",
                                        newValue,
                                        "option"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )
            )}

            <div className="mt-[20px]">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                {t("call_to_action")}
              </span>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <Menu>
                  <MenuHandler>
                    <div className="flex justify-between items-center w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                      {getAction(physicalProductContent.action)}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="w-full px-[24px] rounded-[10px] font-manrope">
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangePhysicalProductContent(
                            "action",
                            "I want this"
                          );
                        }}
                      >
                        {getAction("I want this")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangePhysicalProductContent(
                            "action",
                            "Buy now"
                          );
                        }}
                      >
                        {getAction("Buy now")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangePhysicalProductContent(
                            "action",
                            "Take the course"
                          );
                        }}
                      >
                        {getAction("Take the course")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <div className="mt-[15px]">
              <span className="text-[18px] text-[#161616] font-semibold leading-[20px]">
                {t("delivery_area")}
              </span>
            </div>

            {physicalProductContent.deliveryArea.length > 0 && (
              <div className="mt-[5px]"></div>
            )}

            {physicalProductContent.deliveryArea.map(
              (elem: any, index: number) => (
                <div
                  className="mt-[10px]"
                  key={`delivery-area-choosen-country-${index}`}
                >
                  <div className="text-[16px] text-[#161616] font-medium leading-[18px]">
                    {elem.country}
                  </div>
                  <div className="mt-[10px]">
                    <div className="w-full h-[56px]">
                      <input
                        type="number"
                        className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                        placeholder="Delivery price"
                        value={elem.price}
                        onChange={(event: any) => {
                          const newValue = event.target.value;
                          if (newValue === "" || parseFloat(newValue) >= 0) {
                            handleChangeCountryData(
                              "price",
                              elem.country,
                              newValue
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            )}

            <div className="mt-[15px]">
              <button
                className="w-full h-[47px] flex justify-center items-center text-[16px] leading-[22px] text-[#161616] border-[1px] border-[#161616] rounded-[10px]"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {t("select_countries")}
              </button>
            </div>

            <div className="mt-[15px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleSendProductData();
                }}
              >
                {t("publish")}
              </button>
            </div>

            {/* ============================================== BOTTOM WINDOW ============================================== */}
            <div
              className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? "block" : "hidden"}`}
            >
              {/* Задний план с затемнением */}
              <div
                className="fixed inset-0 bg-gray-500 opacity-75"
                //   onClick={closeWindow}
              ></div>

              {/* Основное окно */}
              <div className="fixed inset-x-0 bottom-0 z-50 bg-white h-4/5 overflow-y-auto rounded-t-[25px] font-urbanist">
                <div className="bg-white w-full fixed inset-x-0 z-10 rounded-t-[25px]">
                  <div className="flex justify-center mt-[10px]">
                    <div className="w-[63px] h-[4px] bg-[#8391A1] rounded-[100px]"></div>
                  </div>
                  <div className="flex justify-between items-center mt-[23px] px-[24px]">
                    <div className="text-white text-[1px] invisible">
                      hiiiiiiadfadgasfgafafasfgsdfhsghdfghdfghdfghdfghdfghdfghdfghdfghasdfasfgs
                    </div>
                    <div className="text-[18px] leading-[28px] text-[#161616] font-semibold">
                      {t("select_countries")}
                    </div>
                    <button
                      className="text-[16px] leading-[18px] text-[#8391A1]"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      {t("done")}
                    </button>
                  </div>
                  <div className="border-b-[1px] border-[#E8ECF4] w-full mt-[17px]"></div>
                </div>

                <div className="px-[24px] pb-[40px] mt-[80px]">
                  <div className="flex flex-col">
                    <div className="mt-[5px]">
                      {countryData.map((elem: any, index: number) => (
                        <div
                          className="mt-[10px] w-full"
                          key={`delivery-area-country-${index}`}
                        >
                          <div className="flex justify-between items-center">
                            <div className="">{elem.country}</div>

                            <Switch
                              crossOrigin={""}
                              checked={elem.activate}
                              onChange={() => {
                                handleChangeCountryData(
                                  "activate",
                                  elem.country,
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* WINDOW END */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
