import { Icon } from "@iconify/react";

export default function Component({
  listOfGalleryImages,
  url = "",
  block_layout,
  deactivated,
  blockDesign,
}: any) {
  return (
    <div className="break-words px-[24px]">
      {block_layout === "big_block" && (
        <div
          // className={`relative w-full pt-[75%] overflow-hidden ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
          className={`relative w-full ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
        >
          <img
            src={listOfGalleryImages[0]}
            alt={`url_gallery_image`}
            // className={`absolute inset-0 w-full h-full object-cover ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
            className={`w-full h-full ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
          />
          {url !== "" && (
            <div className="absolute bottom-2 right-2 p-1">
              <img
                src="/images/profile/block_link.svg"
                alt="block_link"
                className=""
              />

              {/* <Icon
                icon="solar:link-bold"
                className="text-[#161616] w-[24px] h-[24px]"
              /> */}
            </div>
          )}
        </div>
      )}

      {block_layout === "tile" && (
        <div
          className={`relative w-full h-[70px] overflow-hidden ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
        >
          <img
            src={listOfGalleryImages[0]}
            alt="url_gallery_image"
            className={`w-full h-full object-cover ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"} ${deactivated && "opacity-50"}`}
          />

          {url !== "" && (
            <div className="absolute bottom-2 right-2 p-1">
              <img
                src="/images/profile/block_link.svg"
                alt="block_link"
                className=""
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
