import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Radio,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
} from "@material-tailwind/react";
import BurgerMenu from "../BurgerMenu";
import * as utils from "../../utils";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import NumberInput from "../NumberInput";
import { useTranslation } from "react-i18next";
// import Menu from "../Menu";

export default function Component({
  burgerMenuOpen,
  toggleBurgerMenu,
  selectedProductType,
  membershipContent,
  handleChangeMembershipContent,
  handleCheckedMembershipContent,
  status,
  handleChangeStatus,
  handleChangeStage,
  handleSendProductData,
}: any) {
  const { t, i18n } = useTranslation();

  function getAction(val: string) {
    if (val === "I want this") {
      return t("i_want_this");
    } else if (val === "Buy now") {
      return t("buy_now");
    } else if (val === "Take the course") {
      return t("take_the_course");
    } else {
      return val;
    }
  }
  return (
    <div>
      {status === "content" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <span className="text-start text-[18px] text-[#161616] leading-[20px]">
                <span className="font-semibold">{t("library_of_content")}</span>{" "}
                ⓘ
              </span>
            </div>

            <div className="mt-[15px]">
              <div
                className="w-full border-[2px] border-[#8391A1] border-dotted bg-[#F7F8F9] rounded-[10px]"
                // onClick={handleButtonClickFilePdfFileUrl}
              >
                <input
                  id="filePdfFileInput"
                  type="file"
                  // accept=".jpg,.jpeg,.png"
                  //   onChange={handleFilePdfFileChange}
                  style={{ display: "none" }}
                />
                <div className="mt-[15px] flex justify-center">
                  {/* <Icon
                icon="solar:upload-linear"
                className="w-[40px] h-[40px] text-[#8391A1]"
              /> */}
                  <img
                    src="/images/create_block/upload_file.svg"
                    alt="upload_file.svg"
                    className="w-[40px] h-[40px]"
                  />
                </div>
                <div className="pb-[15px] text-center">
                  <div className="mt-[10px] text-[14px] leading-[16.39px] text-[#8391A1] flex justify-center px-[10px]">
                    {/* {!filePdfFile ? ( */}
                    <span>
                      {/* {t("membership_upload_text1")}
                      <br />
                      {t("membership_upload_text2")} */}
                      {t("membership_upload_text")}{" "}
                      <span className="text-[#161616] font-medium">
                        Hubbbox Premium.
                      </span>
                    </span>
                    {/* ) : (
                  <span className="">{filePdfFileName}</span>
                )} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[335px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleChangeStatus("price");
                }}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      )}

      {status === "price" && (
        <div className="font-manrope px-[24px] pb-[20px] w-screen max-w-md bg-white min-h-screen">
          <BurgerMenu
            isOpen={burgerMenuOpen}
            profileName={"profileName"}
            toggleBurgerMenu={toggleBurgerMenu}
          />
          <div className="flex flex-col">
            <div className="w-full fixed inset-x-0 z-10 backdrop-blur-md px-[24px]">
              <div className="h-[56px] flex justify-between items-center">
                <button
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  {/* <Icon icon="ion:chevron-back" className="text-[18px]" /> */}
                  <div className="p-[10px]">
                    <img
                      src="/images/back_arrow.svg"
                      alt="back_arrow.svg"
                      className="w-[7px] h-[14px]"
                    />
                  </div>
                </button>
                <div className="font-semibold text-[18px] leading-[28px] text-center text-[#161616]">
                  {t("edit_product")}
                </div>
                <button onClick={toggleBurgerMenu}>
                  {!burgerMenuOpen ? (
                    <Icon
                      icon="iconamoon:menu-burger-horizontal-light"
                      className="w-[24px] h-[26px]"
                    />
                  ) : (
                    <span> </span>
                  )}
                </button>
              </div>
            </div>

            <div className="mt-[61px]">
              <div className="w-full h-[30px] rounded-[50px] bg-[#F7F8F9] flex justify-between items-center">
                <div
                  className="ml-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("info");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("info_product")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px]"
                  onClick={() => {
                    handleChangeStatus("content");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("content")}
                  </div>
                </div>
                <div
                  className="mr-[2px] w-full h-[26px] flex justify-center items-center rounded-[40px] bg-white shadow-custom"
                  onClick={() => {
                    // handleChangeStatus("price");
                  }}
                >
                  <div className="text-[#161616] text-[14px] leading-[16px] text-center">
                    {t("price")}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("per_1_month")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={handleCheckedMembershipContent("1 month")}
                  onChange={(event) => {
                    handleChangeMembershipContent("1 month", "", "activate");
                  }}
                />
              </div>
            </div>

            <div className="mt-[8px]">
              <div className="flex space-x-[10px] items-center">
                <div className="w-full h-[56px]">
                  <NumberInput
                    value={membershipContent.intervals[0].price}
                    placeholder={t("set_price")}
                    changeFunc={(newValue: any) => {
                      handleChangeMembershipContent("1 month", newValue, "");
                    }}
                  />

                  {/* <input
                    type="number"
                    className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                    placeholder="Set price"
                    value={membershipContent.intervals[0].price}
                    onChange={(event: any) => {
                      const newValue = event.target.value;
                      if (newValue === "" || parseFloat(newValue) >= 0) {
                        handleChangeMembershipContent("1 month", newValue, "");
                      }
                    }}
                  /> */}
                </div>
                <div className="w-[76px] h-[56px] flex-shrink-0">
                  <Menu>
                    <MenuHandler>
                      <div className="w-full h-full flex justify-center items-center border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px]">
                        {membershipContent.currencySymbol}
                        <img
                          src="/images/create_product/arrow_down.svg"
                          alt="arrow_down"
                          className="w-[24px] h-[24px] flex-shrink-0"
                        />
                      </div>
                    </MenuHandler>
                    <MenuList className="w-[228px] rounded-[10px]">
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeMembershipContent("currency", "dollar");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            US {t("dollar")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            $
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeMembershipContent("currency", "tenge");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            KZ {t("tenge")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₸
                          </div>
                        </div>
                      </MenuItem>
                      <hr className="border-gray-300" />
                      <MenuItem>
                        <div
                          className="flex justify-between items-center font-manrope"
                          onClick={() => {
                            handleChangeMembershipContent("currency", "ruble");
                          }}
                        >
                          <div className="text-[16px] leading-[22px] text-[#161616]">
                            RU {t("ruble")}
                          </div>
                          <div className="text-[22px] leading-[24px] text-[#161616]">
                            ₽
                          </div>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("per_3_months")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={handleCheckedMembershipContent("3 months")}
                  onChange={(event) => {
                    handleChangeMembershipContent("3 months", "", "activate");
                  }}
                />
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={membershipContent.intervals[1].price}
                  placeholder={t("set_price")}
                  changeFunc={(newValue: any) => {
                    handleChangeMembershipContent("3 months", newValue, "");
                  }}
                />

                {/* <input
                  type="number"
                  className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                  placeholder="Set price"
                  value={membershipContent.intervals[1].price}
                  onChange={(event: any) => {
                    const newValue = event.target.value;
                    if (newValue === "" || parseFloat(newValue) >= 0) {
                      handleChangeMembershipContent("3 months", newValue, "");
                    }
                  }}
                /> */}
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("per_6_months")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={handleCheckedMembershipContent("6 months")}
                  onChange={(event) => {
                    handleChangeMembershipContent("6 months", "", "activate");
                  }}
                />
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={membershipContent.intervals[2].price}
                  placeholder={t("set_price")}
                  changeFunc={(newValue: any) => {
                    handleChangeMembershipContent("6 months", newValue, "");
                  }}
                />

                {/* <input
                  type="number"
                  className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                  placeholder="Set price"
                  value={membershipContent.intervals[2].price}
                  onChange={(event: any) => {
                    const newValue = event.target.value;
                    if (newValue === "" || parseFloat(newValue) >= 0) {
                      handleChangeMembershipContent("6 months", newValue, "");
                    }
                  }}
                /> */}
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="flex justify-between items-center">
                <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                  {t("per_1_year")}
                </span>
                <Switch
                  crossOrigin={""}
                  checked={handleCheckedMembershipContent("1 year")}
                  onChange={(event) => {
                    handleChangeMembershipContent("1 year", "", "activate");
                  }}
                />
              </div>
            </div>

            <div className="mt-[5px]">
              <div className="w-full h-[56px]">
                <NumberInput
                  value={membershipContent.intervals[3].price}
                  placeholder={t("set_price")}
                  changeFunc={(newValue: any) => {
                    handleChangeMembershipContent("1 year", newValue, "");
                  }}
                />

                {/* <input
                  type="number"
                  className="w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] placeholder:text-[16px] placeholder:text-[#8391A1] px-[15px]"
                  placeholder="Set price"
                  value={membershipContent.intervals[3].price}
                  onChange={(event: any) => {
                    const newValue = event.target.value;
                    if (newValue === "" || parseFloat(newValue) >= 0) {
                      handleChangeMembershipContent("1 year", newValue, "");
                    }
                  }}
                /> */}
              </div>
            </div>

            <div className="mt-[20px]">
              <span className="text-start text-[16px] text-[#161616] leading-[18px] font-medium">
                {t("call_to_action")}
              </span>
            </div>

            <div className="mt-[10px]">
              <div className="w-full h-[56px]">
                <Menu>
                  <MenuHandler>
                    <div className="flex justify-between items-center w-full h-full border-[1px] border-[#E8ECF4] bg-[#F7F8F9] rounded-[10px] text-[16px] text-[#161616] leading-[18px] px-[15px]">
                      {getAction(membershipContent.action)}
                      <img
                        src="/images/create_product/arrow_down.svg"
                        alt="arrow_down"
                        className="w-[24px] h-[24px] flex-shrink-0"
                      />
                    </div>
                  </MenuHandler>
                  <MenuList className="w-[342px] rounded-[10px] font-manrope">
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeMembershipContent(
                            "action",
                            "I want this",
                            ""
                          );
                        }}
                      >
                        {getAction("I want this")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeMembershipContent(
                            "action",
                            "Buy now",
                            ""
                          );
                        }}
                      >
                        {getAction("Buy now")}
                      </div>
                    </MenuItem>
                    <hr className="border-gray-300" />
                    <MenuItem>
                      <div
                        className="text-[16px] leading-[22px] text-[#161616]"
                        onClick={() => {
                          handleChangeMembershipContent(
                            "action",
                            "Take the course",
                            ""
                          );
                        }}
                      >
                        {getAction("Take the course")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <div className="mt-[43px] flex justify-center">
              <button
                className="w-full h-[50px] rounded-xl bg-black text-white font-semibold text-[18px]"
                onClick={() => {
                  handleSendProductData();
                }}
              >
                {t("publish")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
