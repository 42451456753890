import React, { useEffect, useState, useRef } from "react";
// import { ColorPicker } from "primereact/colorpicker";
import { Icon } from "@iconify/react";
import {
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import BurgerMenu from "../../components/ui/BurgerMenu";
import { useNavigate, Link } from "react-router-dom";
import * as constants from "../../components/constants";
import * as actions from "../../components/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/ui/LoadingScreen";
import Newsletter from "../../components/ui/Profile/Newsletter";
import UrlLinks from "../../components/ui/Profile/UrlLinks";
import YoutubeVideo from "../../components/ui/Profile/YoutubeVideo";
import BigBlock from "../../components/ui/Profile/BigBlock";
import Gallery from "../../components/ui/Profile/Gallery";
import UrlGalleryImage from "../../components/ui/Profile/UrlGalleryImage";
import Carousel from "../../components/ui/Carousel";
import SmallBlock from "../../components/ui/Profile/SmallBlock";
import TextBlock from "../../components/ui/Profile/TextBlock";
import * as utils from "../../components/utils";
import ErrorPage from "../../components/ui/ErrorPage";
import ProfileType from "../../components/ui/ProfileType";
import ProductSlider from "../../components/ui/ProductSlider";
import SlickSlider from "../../components/ui/SlickSlider";
import { useTranslation } from "react-i18next";
import { profile } from "console";

export async function getProfileDataAndInfoFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.getProfileDataAndInfo,
    dispatch,
    `api/profile/data_and_info_by_username/`,
    "post",
    "",
    form,
    false
  )();
}

export async function sendAnalyticsFunc(
  dispatch: any,
  access_token: string,
  form: object
) {
  actions.constructorAction(
    constants.sendAnalytics,
    dispatch,
    `api/profile/send_analytics/`,
    "post",
    "",
    form,
    false
  )();
}

export default function Page() {
  const { t, i18n } = useTranslation();

  const [profileName, setProfileName] = useState("");
  const [username, setUsername] = useState("");
  const [profileType, setProfileType] = useState("");
  const [bio, setBio] = useState("");
  const [status, setStatus] = useState("");
  const [profileStatusLogo, setProfileStatusLogo] = useState("");
  const [avatar, setAvatar] = useState("");
  const [profileCover, setProfileCover] = useState("");
  const [listOfSocialMediaLinks, setListOfSocialMediaLinks] = useState([]);
  const [listOfSelectedSocialMediaLinks, setListOfSelectedSocialMediaLinks] =
    useState([]);

  const [data, setData] = useState([]);
  const [profileBlogData, setProfileBlogData] = useState([]);
  const [pageStatus, setPageStatus] = useState("blocks");
  const [blockDesign, setBlockDesign] = useState("first");
  const [iconDesign, setIconDesign] = useState("monochrome");

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();

  const getProfileDataAndInfoStore = useSelector(
    (state: any) => state.getProfileDataAndInfoStore
  );

  const sendAnalyticsStore = useSelector(
    (state: any) => state.sendAnalyticsStore
  );

  const navigate = useNavigate();

  const handleClickSendAnalytics = (
    blockId: any,
    sendType: string,
    body = ""
  ) => {
    // username, block_id, send_type, body
    sendAnalyticsFunc(dispatch, "", {
      username: params.userUsername,
      blockId: blockId,
      type: sendType,
      body: body,
    });
  };

  useEffect(() => {
    getProfileDataAndInfoFunc(dispatch, "", { username: params.userUsername });
    dispatch({ type: constants.sendAnalytics.reset });
  }, []);

  useEffect(() => {
    // console.log("getProfileDataAndInfoStore", getProfileDataAndInfoStore);
    if (getProfileDataAndInfoStore && getProfileDataAndInfoStore.data) {
      if (
        getProfileDataAndInfoStore.data.data &&
        getProfileDataAndInfoStore.data.data.length !== 0
      ) {
        setData(getProfileDataAndInfoStore.data.data);
      }
      if (
        getProfileDataAndInfoStore.data.personal_blog &&
        getProfileDataAndInfoStore.data.personal_blog.length !== 0
      ) {
        setProfileBlogData(getProfileDataAndInfoStore.data.personal_blog);
      }
      setProfileName(getProfileDataAndInfoStore.data.info.profile_name);
      setUsername(getProfileDataAndInfoStore.data.info.username);
      setBio(getProfileDataAndInfoStore.data.info.bio);
      setProfileType(getProfileDataAndInfoStore.data.info.profile_type);
      setAvatar(getProfileDataAndInfoStore.data.info.avatar);
      setStatus(getProfileDataAndInfoStore.data.info.status);
      setProfileStatusLogo(
        getProfileDataAndInfoStore.data.info.profile_status_logo
      );
      setProfileCover(getProfileDataAndInfoStore.data.info.profile_cover);
      setListOfSocialMediaLinks(
        getProfileDataAndInfoStore.data.info.list_of_social_media_links
      );
      setBlockDesign(getProfileDataAndInfoStore.data.info.design_blocks);
      setIconDesign(getProfileDataAndInfoStore.data.info.design_icons);
    }
  }, [getProfileDataAndInfoStore]);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  useEffect(() => {
    // console.log("status", status);
    if (listOfSocialMediaLinks.length > 0) {
      let listOfCheckedSocialMediaLinks = [];

      for (let i = 0; i < listOfSocialMediaLinks.length; i++) {
        if (listOfSocialMediaLinks[i]["checked"]) {
          listOfCheckedSocialMediaLinks.push({
            //@ts-ignore
            ...listOfSocialMediaLinks[i],
            index: i + 1,
          });
        }
      }

      const share_obj = {
        icon: "/images/social_media_icons/share.svg",
      };

      // if (listOfCheckedSocialMediaLinks.length > 0) {
      listOfCheckedSocialMediaLinks.push({
        ...share_obj,
        index: listOfSocialMediaLinks.length + 1,
      });
      // }

      //@ts-ignore
      setListOfSelectedSocialMediaLinks(listOfCheckedSocialMediaLinks);
    }
  }, [listOfSocialMediaLinks]);

  if (getProfileDataAndInfoStore && getProfileDataAndInfoStore.load) {
    return (
      <div>
        <LoadingScreen isLoading={getProfileDataAndInfoStore.load} />
      </div>
    );
  }

  if (
    getProfileDataAndInfoStore &&
    (getProfileDataAndInfoStore.fail || getProfileDataAndInfoStore.error)
  ) {
    return (
      <div>
        <ErrorPage error={"Страница не существует"} />
      </div>
    );
  }

  return (
    <div className="flex justify-center break-words">
      <div className="font-manrope pb-[40px] w-screen overflow-hidden max-w-md min-h-screen bg-white">
        <div className="flex flex-col">
          <div className="">
            <ProfileType
              page={"ProfilePreview"}
              profileName={profileName}
              bio={bio}
              avatar={avatar}
              status={status}
              profileStatusLogo={profileStatusLogo}
              profileCover={profileCover}
              listOfSelectedSocialMediaLinks={listOfSelectedSocialMediaLinks}
              profileType={profileType}
              username={username}
              iconDesign={iconDesign}
              // isPreview={true}
            />
          </div>

          {profileBlogData.length !== 0 && (
            <div className="mt-[30px]">
              <div className="px-[24px]">
                <div className="flex justify-between items-center">
                  <div
                    className={`flex justify-center items-center w-full pb-[10px] ${pageStatus === "blocks" && "border-b-[2px] border-[#161616]"}`}
                    onClick={() => {
                      setPageStatus("blocks");
                    }}
                  >
                    <img
                      src={`/images/profile/blocks_${pageStatus === "blocks" ? "" : "in"}active.svg`}
                      alt="blocks.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>

                  <div
                    className={`flex justify-center items-center w-full pb-[10px] ${pageStatus === "posts" && "border-b-[2px] border-[#161616]"}`}
                    onClick={() => {
                      setPageStatus("posts");
                    }}
                  >
                    <img
                      src={`/images/profile/posts_${pageStatus === "posts" ? "" : "in"}active.svg`}
                      alt="posts.svg"
                      className="w-[24px] h-[24px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {pageStatus === "blocks" && data.length > 0 && (
            <div className="mt-[30px]">
              {data.length > 0 &&
                data.map((elem: any, indexBlock: number) => (
                  <div
                    key={elem.block_numeration}
                    className={`mt-[15px] ${
                      (elem.block_type === "product" &&
                        elem.block_layout === "side_slider") ||
                      elem.block_type === "gallery"
                        ? ""
                        : "px-[24px]"
                    } `}
                    // className={`mt-[10px] flex ${elem.block_type === "product" && elem.block_layout === "side_slider" ? "justify-start" : "justify-center"}`}
                  >
                    {elem.block_type === "product" &&
                      elem.block_layout === "big_block" && (
                        <BigBlock
                          product_id={elem.block_data.data[0].id}
                          product_name={elem.block_data.data[0].name}
                          description={elem.block_data.data[0].description}
                          buttonName={elem.block_data.data[0].buttonName}
                          image={elem.block_data.data[0].image}
                          username={username}
                          isPreview={true}
                          blockDesign={blockDesign}
                          // senderFunc={() => {
                          //   handleClickSendAnalytics(
                          //     elem.block_id,
                          //     "product"
                          //   );
                          // }}
                        />
                      )}

                    {elem.block_type === "product" &&
                      elem.block_layout === "side_slider" && (
                        <div className="mb-[35px]">
                          {/* <ProductSlider
                            listOfSmallBlock={elem.block_data.data}
                            username={username}
                            isPreview={true}
                          /> */}

                          <SlickSlider
                            listOfSmallBlock={elem.block_data.data}
                            username={username}
                            isPreview={true}
                            blockDesign={blockDesign}
                            // senderFunc={() => {
                            //   handleClickSendAnalytics(
                            //     elem.block_id,
                            //     "product"
                            //   );
                            // }}
                          />
                        </div>
                        // <div className="flex flex-col">
                        //   {elem.block_data.data.length > 0 &&
                        //     elem.block_data.data.map(
                        //       (
                        //         elem_block_data: any,
                        //         elem_block_index: number
                        //       ) => (
                        //         <SmallBlock
                        //           key={elem_block_index}
                        //           product_name={elem_block_data.name}
                        //           description={elem_block_data.description}
                        //           image={elem_block_data.image}
                        //         />
                        //       )
                        //     )}
                        // </div>

                        // <div className="">
                        // <Carousel
                        //   swipeable={true}
                        //   draggable={false}
                        //   showDots={true}
                        //   responsive={responsive}
                        //   // ssr={true} // means to render carousel on server-side.
                        //   // infinite={true}
                        //   // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        //   // autoPlaySpeed={1000}
                        //   // keyBoardControl={true}
                        //   // customTransition="all .5"
                        //   // transitionDuration={500}
                        //   // containerClass="carousel-container"
                        //   // removeArrowOnDeviceType={["tablet", "mobile"]}
                        //   // deviceType={this.props.deviceType}
                        //   // dotListClass="custom-dot-list-style"
                        //   // itemClass="carousel-item-padding-40-px"
                        // >
                        //   {elem.block_data.data.length > 0 &&
                        //     elem.block_data.data.map(
                        //       (
                        //         elem_block_data: any,
                        //         elem_block_index: number
                        //       ) => (
                        //         <div
                        //           className="flex justify-center"
                        //           key={elem_block_index}
                        //         >
                        //           <SmallBlock
                        //             product_name={elem_block_data.name}
                        //             description={elem_block_data.description}
                        //             image={elem_block_data.image}
                        //           />
                        //         </div>
                        //       )
                        //     )}
                        // </Carousel>
                        // </div>

                        // <div className="flex space-x-2">
                        //   <SmallBlock
                        //     product_name={elem.block_data.data[0].name}
                        //     description={elem.block_data.data[0].description}
                        //     image={elem.block_data.data[0].image}
                        //   />
                        //   <SmallBlock
                        //     product_name={elem.block_data.data[1].name}
                        //     description={elem.block_data.data[1].description}
                        //     image={elem.block_data.data[1].image}
                        //   />
                        // </div>
                      )}

                    {elem.block_type === "youtube_video" && (
                      // <YoutubeVideo link={elem.block_data.data} />
                      <iframe
                        className={`w-full h-full aspect-video ${blockDesign === "third" ? "rounded-[7px]" : "rounded-[15px]"}`}
                        src={elem.block_data.data}
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}

                    {elem.block_type === "links_url" && (
                      <div
                        onClick={() => {
                          handleClickSendAnalytics(elem.block_id, "links_url");
                          window.open(
                            `https://${elem.block_data.data.url}`,
                            "_blank"
                          );
                        }}
                      >
                        <UrlLinks
                          title={elem.block_data.data.title}
                          description={elem.block_data.data.description}
                          image={elem.image}
                          blockDesign={blockDesign}
                          // senderFunc={() => {
                          //   handleClickSendAnalytics(
                          //     elem.block_id,
                          //     "links_url"
                          //   );
                          // }}
                        />
                      </div>
                    )}

                    {elem.block_type === "pdf_file" && (
                      <div
                        onClick={() => {
                          handleClickSendAnalytics(elem.block_id, "pdf_file");
                          window.open(elem.file, "_blank");
                        }}
                      >
                        <UrlLinks
                          title={elem.block_data.data.title}
                          description={elem.block_data.data.description}
                          image={elem.image}
                          blockDesign={blockDesign}
                          // senderFunc={() => {
                          //   handleClickSendAnalytics(elem.block_id, "pdf_file");
                          // }}
                        />
                      </div>
                    )}

                    {/* {elem.block_type === "newsletter" && (
                      <Newsletter
                        title={elem.block_data.data.title}
                        isPreview={true}
                      />
                    )} */}

                    {elem.block_type === "gallery" && (
                      <div>
                        {elem.block_layout === "side_slider" ? (
                          <Gallery
                            listOfGalleryImages={elem.block_data}
                            deactivated={elem.deactivated}
                            blockDesign={blockDesign}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              window.open(
                                `https://${elem.block_data.url}`,
                                "_blank"
                              );
                            }}
                          >
                            <UrlGalleryImage
                              listOfGalleryImages={elem.block_data.images}
                              url={elem.block_data.url}
                              block_layout={elem.block_layout}
                              deactivated={elem.deactivated}
                              blockDesign={blockDesign}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {elem.block_type === "text" && (
                      <div className={indexBlock === 0 ? "" : "mt-[40px]"}>
                        {/* <div className="mt-[40px]"> */}
                        <div className="text-start">
                          <TextBlock text={elem.block_data.data} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}

          {pageStatus === "posts" && (
            <div className="mt-[30px]">
              <div className="px-[24px]">
                {profileBlogData.length > 0 &&
                  profileBlogData.map((post: any, indexPost: number) => (
                    <div key={`post-${indexPost}`}>
                      {blockDesign === "first" && (
                        <div className="mt-[20px] w-full rounded-[15px] p-[15px] shadow-custom">
                          <div className="flex flex-col">
                            {post.thumbnail === "" ? (
                              <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-[10px]">
                                <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-[10px]"></div>
                              </div>
                            ) : (
                              <div className="relative w-full pt-[100%] overflow-hidden rounded-[10px]">
                                <img
                                  src={post.thumbnail}
                                  alt="productImage"
                                  className="absolute inset-0 w-full h-full object-cover object-center rounded-[10px]"
                                />
                              </div>
                            )}

                            <div className="mt-[15px]">
                              <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                                {post.title}
                              </div>
                            </div>

                            <div className="mt-[5px]">
                              <span className="text-[14px] text-[#8391A1]">
                                {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                                {t("reading_time")}: {post.reading_time}{" "}
                                {t("minutes")}
                              </span>
                            </div>

                            <div className="mt-[5px]">
                              <span className="text-[16px] text-[#161616]">
                                {post.description}...
                              </span>
                            </div>

                            <div className="mt-[15px]">
                              <button
                                onClick={() => {
                                  // window.open(
                                  //   `https://www.hubbbox.com/post/${post.id}`,
                                  //   "_blank"
                                  // );
                                  // window.location.href = `https://www.hubbbox.com/post/${post.id}`;
                                }}
                                className="w-full h-[47px] flex justify-center items-center bg-[#161616] text-white rounded-[10px]"
                              >
                                {t("read_more")}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {blockDesign === "third" && (
                        <div
                          onClick={() => {
                            // window.open(
                            //   `https://www.hubbbox.com/post/${post.id}`,
                            //   "_blank"
                            // );
                            window.location.href = `https://www.hubbbox.com/post/${post.id}`;
                          }}
                          className="mt-[20px] w-full rounded-[7px] shadow-custom"
                        >
                          <div className="flex flex-col">
                            {post.thumbnail === "" ? (
                              <div className="relative w-full pt-[100%] overflow-hidden bg-gray-200 rounded-t-[7px]">
                                <div className="absolute inset-0 w-full h-full object-cover object-center bg-gray-200 rounded-t-[7px]"></div>
                              </div>
                            ) : (
                              <div className="relative w-full pt-[100%] overflow-hidden rounded-t-[7px]">
                                <img
                                  src={post.thumbnail}
                                  alt="productImage"
                                  className="absolute inset-0 w-full h-full object-cover object-center rounded-t-[7px]"
                                />
                              </div>
                            )}

                            <div className="my-[15px] px-[24px]">
                              <div className="text-[#161616] font-bold text-[22px] leading-[26px]">
                                {post.title}
                              </div>

                              <div className="mt-[5px]">
                                <span className="text-[14px] text-[#8391A1]">
                                  {post.created}&nbsp;&nbsp;|&nbsp;&nbsp;
                                  {t("reading_time")}: {post.reading_time}{" "}
                                  {t("minutes")}
                                </span>
                              </div>

                              <div className="mt-[5px]">
                                <span className="text-[16px] text-[#161616]">
                                  {post.description}...
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* <div className="mt-[40px] px-[24px]">
            <div className="text-[#8391A1] text-[16px] flex justify-center items-center">
              Created by hubbbox
            </div>
          </div> */}

          <div
            className="mt-[40px] px-[24px] flex justify-center items-center"
            onClick={() => {
              window.open(`https://hubbbox.com/`, "_blank");
            }}
          >
            <div className="h-[40px] px-[15px] bg-white rounded-[100px] shadow-customblock text-[#161616] text-[16px] leading-[18px] flex justify-center items-center">
              {t("profile_preview_text")}&nbsp;
              <span className="font-extrabold">Hubbbox</span>
              {/* <img
                src="/images/profile_preview/logo_hubbbox_full.svg"
                alt="logo_hubbbox_full.svg"
                className="w-[100px] h-[15px] pb-[2px] -ml-[7px] -mr-[9px]"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
